var AutoComplete = (function () {
    "use strict";

    function AutoComplete($timeout, $mdUtil, $log, $injector, $location) {
        this.md = $mdUtil;
        var self = this;
        this.log = $log;
        this.timeout = $timeout;
        this.location = $location;
        self.simulateQuery = false;
        self.isDisabled = false;
        self.$injector = $injector;
        // list of `state` value/display objects
        this.getValues();
        return this;
    }

    AutoComplete.prototype.selectedItemChange = function (/*item*/) {};
    AutoComplete.prototype.querySearch = function (query) {
        return (query ? this.states.filter(this.createFilterFor(query)) : this.states);
    };
    AutoComplete.prototype.getValues = function () {
        this.states = [];
    };
    AutoComplete.prototype.createFilterFor = function (query) {
        var lowercaseQuery = angular.lowercase(query);
        return function (state) {
            return (angular.lowercase(state.display).indexOf(lowercaseQuery) >= 0);
        };
    };
    return AutoComplete;
}());

var init = (function () {
    "use strict";
    var moduleConfig = {
        name: "TCFAutos",
        deps: ["ngRoute", "ngResource", "oc.lazyLoad", "angularSpinner", "ngMask", "ngMaterial", "ui.bootstrap", "ngSanitize",
            "vcRecaptcha", "mwl.calendar", "ngScrollbars", "ngFileUpload", "ngImgCrop", "chart.js", "angular-cache", "textAngular",
            "angularjs-dropdown-multiselect", "ui.sortable", "angularUtils.directives.dirPagination", "ngclipboard", "ngFlash"]
    };
    angular.module(moduleConfig.name, moduleConfig.deps)
        .run(["$rootScope", "$location", "$window", "$injector", "PageNavigationService", "TCFAAppConfigService", "$uibModal",
            function ($rootScope, $location, $window, $injector, PageNavigationService, TCFAAppConfigService, $uibModal) {
                var __showModal,
                    SessionDAO = $injector.get("SessionDAO"),
                    GeoLocationService = $injector.get("GeoLocationService"),
                    DependencyInjectionService = $injector.get("DependencyInjectionService");
                // Show loading splash screen until session is created and retrieved.
                PageNavigationService.showSplash = true;
                // Google Analytics initialization.
                $window.ga("create", TCFAAppConfigService.GOOGLE.ANALITYCS, "auto");
                $rootScope.$on("$locationChangeSuccess", function () {
                    $window.ga("send", "pageview", $location.url());
                });
                // User data init.
                $rootScope.userInfo = {};
                // Jira issue collector config.
                if (TCFAAppConfigService.SHOW_ISSUE_COLLECTOR) {
                    DependencyInjectionService.loadApi("IssueCollector").then(function () {
                        $window.console.log("IssueCollector Loaded");
                    }, function () {
                        $window.console.log("Error loading IssueCollector");
                    });
                }
                // Create session and then update location.
                $rootScope.session_token = SessionDAO.createSession({});
                $rootScope.session_token.$promise.then(function () {
                    GeoLocationService.location.then(function (position) {
                        var crd = position.coords;
                        SessionDAO.updateSession({
                            latitude: crd.latitude,
                            longitude: crd.longitude
                        });
                    });
                    // Finish loading with a session.
                    PageNavigationService.showSplash = false;
                });
                // Modales Cotizador.
                // Show modal generic.
                __showModal = function (modal, message, scope) {
                    var __modals = {
                        loading: {keyboard: false},
                        error: {keyboard: true},
                        alert: {keyboard: false}
                    };
                    scope.message = message;
                    return $uibModal.open({
                        animation: true,
                        windowClass: "tcfa-modal",
                        openedClass: "cotizador-modal-" + modal,
                        backdrop: "static",
                        keyboard: ((__modals[modal] && __modals[modal].keyboard) || false),
                        templateUrl: "./views/cotizador/modal/modal_" + modal + ".html",
                        controller: ["$scope", function ($scope) {
                            // Close modal.
                            $scope.close = function () {
                                $scope.$dismiss("close");
                            };
                            // Accept modal (only the alert modal).
                            $scope.accept = function () {
                                $scope.$close("accept");
                            };
                        }],
                        controllerAs: "modalCotizador",
                        bindToController: true,
                        scope: scope,
                        appendTo: angular.element("#modalComponent")
                    });
                };
                $rootScope.showLoadingModal = function (message, scope) {
                    return __showModal("loading", message, scope);
                };
                $rootScope.showErrorModal = function (message, scope) {
                    return __showModal("error", message, scope);
                };
                $rootScope.showAlertModal = function (message, scope) {
                    return __showModal("alert", message, scope);
                };
                $rootScope.fixScreen = function () {
                    angular.element("body").addClass("fixed-screen-hack");
                };
                $rootScope.unfixScreen = function () {
                    angular.element("body").removeClass("fixed-screen-hack");
                };
            }]);
    return function () {
        angular.element(document).ready(function () {
            angular.bootstrap(window.document, ["TCFAutos"]);
        });
    };
}());
init();

(function () {
    "use strict";
    var moduleConfig = {
            name: "errorHandlerModule",
            deps: ["TCFAutos.services.PageNavigationService"],
            config: {
                deps: ["$httpProvider"]
            }
        },
        module, config;
    module = angular.module("TCFAutos");
    config = function ($httpProvider) {
        // Injectar el servicio dentro de los interceptores de PHP.
        $httpProvider.interceptors.push("tcfaErrorHandler");
        $httpProvider.interceptors.push("tcfaTokenInjection");
    };
    config.$inject = moduleConfig.config.deps;
    module.config(config);
}());

(function () {
    "use strict";
    var module = angular.module("TCFAutos");
    module.config(["$routeProvider", "$httpProvider", "$resourceProvider",
        function ($routeProvider, $httpProvider, $resourceProvider) {
            $resourceProvider.defaults.stripTrailingSlashes = false; // Don't strip trailing slashes from calculated URLs.
            $routeProvider
                // Homepage.
                .when("/", {
                    templateUrl: "views/homepage.html",
                    controller: "HomepageCtrl",
                    controllerAs: "homepage"
                })
                // Cotizador Dinamico Homepage.
                .when("/cotizador/", {
                    templateUrl: "views/cotizador.html",
                    controller: "CotizadorCtrl",
                    controllerAs: "cotizador",
                    reloadOnSearch: false
                })
                // Resultados de busqueda.
                .when("/search/", {
                    templateUrl: "views/search/search.html",
                    controller: "Search_ShowSearchCtrl",
                    controllerAs: "ctrl",
                    reloadOnSearch: true
                })
                // route for the contact confirmation
                .when("/consulta/confirmacion/", {
                    templateUrl: "views/contacts/confirm_consulta.html",
                    controller: "ContactConfirmationController",
                    controllerAs: "ctrl"
                })
                // route for the car info page
                .when("/cars/:carId/", {
                    templateUrl: "views/detalle_publicacion/info.html",
                    controller: "Info_CarInfoCtrl",
                    controllerAs: "ctrl",
                    reloadOnSearch: false
                })
                .when("/not_allowed/", {
                    templateUrl: "views/component/not_allowed.html"
                })
                .when("/secciontyc/:seccionId", {
                    templateUrl: "views/terms_conditions/show_seccion.html",
                    controller: "ShowSeccionController",
                    controllerAs: "ctrl"
                })
                .when("/favoritos/", {
                    templateUrl: "views/favoritos/main.html",
                    controller: "FavoritosListController",
                    controllerAs: "ctrl"
                })
                .when("/mensajes/", {
                    templateUrl: "views/perfil/mensajes.html",
                    controller: "PerfilMensajesController",
                    controllerAs: "ctrl",
                    reloadOnSearch: false
                })
                .when("/perfil/", {
                    templateUrl: "views/perfil/perfil.html",
                    controller: "PerfilController",
                    controllerAs: "ctrl"
                })
                .when("/desuscribir/", {
                    template: "",
                    controller: "DesuscribirClienteController"
                })
                .when("/publicacion/activar/", {
                    templateUrl: "views/detalle_publicacion/info.html",
                    controller: "Info_PublicationPreVisualizationCtrl",
                    controllerAs: "ctrl"
                })
                .when("/externalLoginAsAdmin/", {
                    resolve: {
                        ExternalLoginService: ["ExternalLoginService", function (ExternalLoginService) {
                            ExternalLoginService.externalLogin("admin");
                        }]
                    }
                })
                .when("/externalLoginAsDealer/", {
                    resolve: {
                        ExternalLoginService: ["ExternalLoginService", function (ExternalLoginService) {
                            ExternalLoginService.externalLogin("dealer");
                        }]
                    }
                })
                .when("/externalLoginAsTasa/", {
                    resolve: {
                        ExternalLoginService: ["ExternalLoginService", function (ExternalLoginService) {
                            ExternalLoginService.externalLogin("tasa");
                        }]
                    }
                })
                .when("/externalLoginAsClient/", {
                    resolve: {
                        ExternalLoginService: ["ExternalLoginService", function (ExternalLoginService) {
                            ExternalLoginService.externalLogin("cliente");
                        }]
                    }
                })
                .when("/clientregister/", {
                    templateUrl: "views/clientregister/main.html",
                    controller: "ClientRegisterCtrl",
                    controllerAs: "ClientRegister"
                })
                .when("/userconfirm/:tokenid", {
                    templateUrl: "views/clientregister/confirm.html",
                    controller: "ClientConfirmCtrl",
                    controllerAs: "ClientConfirm"
                })

                .when("/client/emailalternativo/confirm/:tokenid", {
                    templateUrl: "views/clientregister/confirm.html",
                    controller: "ClientConfirmCtrl",
                    controllerAs: "ClientConfirm"
                })
                .when("/user/password/missed/", {
                    templateUrl: "views/clientregister/lostPassword.html",
                    controller: "UserLostPasswordController",
                    controllerAs: "ctrl"
                })
                .when("/user/password/change/:token/", {
                    templateUrl: "views/clientregister/changePassword.html",
                    controller: "UserChangePasswordController",
                    controllerAs: "ctrl"
                })
                .when("/auth/linkedin/code/:code/", {
                    resolve: {
                        linkedinService: ["linkedinService", function (linkedinService) {
                            linkedinService.login();
                        }]
                    }
                })
                .when("/auth/linkedin/error/:error/:error_desc/", {
                    resolve: {
                        linkedinService: ["linkedinService", function (linkedinService) {
                            linkedinService.login_error();
                        }]
                    }
                })
                .otherwise({
                    redirectTo: "/"
                });
            $httpProvider.defaults.useXDomain = true;
            delete $httpProvider.defaults.headers.common["X-Requested-With"];
        }]);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "textAreaModule",
            deps: ["textAngular"],
            config: {
                deps: ["$provide"]
            }
        },
        module, config;
    module = angular.module("TCFAutos");
    // Se evita la ejecucion del RUN en el modulo textAngular.
    var txtangular = angular.module("textAngular");
    txtangular.config(["$provide", function ($provide) {
        $provide.decorator("taTranslations", [function () {
            return {
                html: {tooltip: "Mostrar HTML"},
                // tooltip for heading - might be worth splitting
                heading: {tooltip: "Encabezado "},
                p: {tooltip: "P&aacute;rrafo"},
                pre: {tooltip: "Texto pre-formateado"},
                ul: {tooltip: "Lista no numerada"},
                ol: {tooltip: "Lista numerada"},
                quote: {tooltip: "Citar selecci&oacute;n o p&aacute;rrafo"},
                undo: {tooltip: "Deshacer"},
                redo: {tooltip: "Rehacer"},
                bold: {tooltip: "Negrita"},
                italic: {tooltip: "It&aacute;lica"},
                underline: {tooltip: "Subrayado"},
                strikeThrough: {tooltip: "Interlineado"},
                justifyLeft: {tooltip: "Alinear texto a la izquierda"},
                justifyRight: {tooltip: "Alinear texto a la derecha"},
                justifyFull: {tooltip: "Justificar texto"},
                justifyCenter: {tooltip: "Centrar"},
                indent: {tooltip: "Aumentar separaci&oacute;n"},
                outdent: {tooltip: "Disminuir separaci&oacute;n"},
                clear: {tooltip: "Limpiar formato"},
                insertImage: {
                    dialogPrompt: "Por favor, ingrese una imagen para agregar",
                    tooltip: "Insertar imagen",
                    hotkey: ""
                },
                insertVideo: {
                    tooltip: "Insertar video",
                    dialogPrompt: "Por favor, ingrese una URL de Youtube URL para agregar"
                },
                insertLink: {
                    tooltip: "Insertar link",
                    dialogPrompt: "Por favor, ingrese un link"
                },
                editLink: {
                    reLinkButton: {tooltip: "Nuevo link"},
                    unLinkButton: {tooltip: "Eliminar link"},
                    targetToggle: {buttontext: "Abrir en nueva ventana"}
                },
                wordcount: {tooltip: "Cantidad de palabras contadas"},
                charcount: {tooltip: "Caracteres contados"}
            };
        }]);
    }]);
    config = function ($provide) {
        $provide.decorator("taTools", ["$delegate", function (taTools) {
            taTools.h1.buttontext = "Encabezado 1";
            taTools.h2.buttontext = "Encabezado 2";
            taTools.h3.buttontext = "Encabezado 3";
            taTools.h4.buttontext = "Encabezado 4";
            return taTools;
        }]);
    };
    config.$inject = moduleConfig.config.deps;
    module.config(config);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.CarSliderService",
            deps: [],
            CarSliderService: {
                name: "CarSliderService",
                deps: []
            }
        },
        module, CarSliderService;
    module = angular.module("TCFAutos");
    CarSliderService = function () {
        return {
            prepareSlide: function (scope, data, fullView) {
                var lng, x, many,
                    car_list = [],
                    is_homepage = fullView;
                if (!data) {
                    data = scope.data;
                } else {
                    scope.data = data;
                }
                scope.slides = [];
                if (scope.displayMode === "small") {
                    if (is_homepage) {
                        many = 1;
                        scope.slideClass = "col-xs-12";
                    } else {
                        many = 1;
                        scope.slideClass = "col-xs-12 center-block";
                    }
                } else if (scope.displayMode === "phone") {
                    if (is_homepage) {
                        many = 2;
                        scope.slideClass = "col-xs-6";
                    } else {
                        many = 1;
                        scope.slideClass = "col-xs-6 center-block";
                    }
                } else if (scope.displayMode === "tablet") {
                    if (is_homepage) {
                        many = 3;
                        scope.slideClass = 'col-xs-4';
                    } else {
                        many = 1;
                        scope.slideClass = "col-xs-12 center-block float-none";
                    }
                } else { // scope.displayMode "large" & "desktop" are the same.
                    if (is_homepage) {
                        many = 4;
                        scope.slideClass = "col-xs-3";
                    } else {
                        many = 2;
                        scope.slideClass = "col-xs-6";
                    }
                }
                scope.slidesCount = many;
                lng = data.length;
                for (x = 0; x < lng; x = x + 1) {
                    car_list.push(data[x]);
                    if (car_list.length === many || x + 1 === lng) {
                        scope.slides.push(car_list);
                        car_list = [];
                    }
                }
            },
            updateRes: function (scope, window, fullView) {
                var width = window.innerWidth,
                    oldMode = scope.displayMode;
                scope.displayMode = "desktop"; // Default.
                if (width <= 480) {
                    scope.displayMode = "small";
                } else if (width <= 800) {
                    scope.displayMode = "phone";
                } else if (width <= 1199) {
                    scope.displayMode = "tablet";
                } else if (width >= 1200) {
                    scope.displayMode = "desktop";
                } else {
                    scope.displayMode = "large";
                }
                if (scope.displayMode !== oldMode) {
                    this.prepareSlide(scope, undefined, fullView);
                }
            }
        };
    };
    CarSliderService.$inject = moduleConfig.CarSliderService.deps;
    module.factory(moduleConfig.CarSliderService.name, CarSliderService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.CotizadorService",
            deps: [],
            CotizadorService: {
                name: "CotizadorService",
                deps: ["$rootScope", "$window", "$httpParamSerializer", "TCFAAppConfigService", "$location", "$route"]
            }
        },
        module, CotizadorService;
    module = angular.module("TCFAutos");
    CotizadorService = function ($rootScope, $window, $httpParamSerializer, TCFAAppConfigService, $location, $route) {
        var _EVENT_KEY_UPDATED_PLANES_PLAZOS = "cotizador_updated_planes_plazos",
            _EVENT_KEY_UPDATED_LOAN_AMOUNT = "cotizador_updated_loan_amount",
            _EVENT_KEY_CHANGED_COTIZAR_FORM = "cotizador_changed_cotizar_form",
            _EVENT_KEY_UPDATED_VEHICLE_MODELS = "cotizador_updated_vehicle_models",
            _EVENT_KEY_UPDATED_PLAN = "cotizador_updated_plan",
            _EVENT_KEY_RESTORED_SLIDER = "cotizador_restored_slider";
        return {
            onPlanesAndPlazosUpdated: function (scope, callback) {
                var __listener = $rootScope.$on(_EVENT_KEY_UPDATED_PLANES_PLAZOS, callback);
                scope.$on("$destroy", __listener);
            },
            updatePlanesAndPlazos: function (planes_plazos, is_publication, quiet) {
                $rootScope.$emit(_EVENT_KEY_UPDATED_PLANES_PLAZOS, planes_plazos, is_publication, quiet);
            },
            onLoanAmountUpdated: function (scope, callback) {
                var __listener = $rootScope.$on(_EVENT_KEY_UPDATED_LOAN_AMOUNT, callback);
                scope.$on("$destroy", __listener);
            },
            updateLoanAmount: function (loan_amount) {
                $rootScope.$emit(_EVENT_KEY_UPDATED_LOAN_AMOUNT, loan_amount);
            },
            onCotizarFormChanged: function (scope, callback) {
                var __listener = $rootScope.$on(_EVENT_KEY_CHANGED_COTIZAR_FORM, callback);
                scope.$on("$destroy", __listener);
            },
            changeCotizarForm: function () {
                $rootScope.$emit(_EVENT_KEY_CHANGED_COTIZAR_FORM);
            },
            onVehicleModelsUpdated: function (scope, callback) {
                var __listener = $rootScope.$on(_EVENT_KEY_UPDATED_VEHICLE_MODELS, callback);
                scope.$on("$destroy", __listener);
            },
            updateVehicleModels: function () {
                $rootScope.$emit(_EVENT_KEY_UPDATED_VEHICLE_MODELS);
            },
            onPlanUpdated: function (scope, callback) {
                var __listener = $rootScope.$on(_EVENT_KEY_UPDATED_PLAN, callback);
                scope.$on("$destroy", __listener);
            },
            updatePlan: function (plan) {
                $rootScope.$emit(_EVENT_KEY_UPDATED_PLAN, plan);
            },
            onSliderRestored: function (scope, callback) {
                var __listener = $rootScope.$on(_EVENT_KEY_RESTORED_SLIDER, callback);
                scope.$on("$destroy", __listener);
            },
            restoreSlider: function () {
                $rootScope.$emit(_EVENT_KEY_RESTORED_SLIDER);
            },
            /** Builds an URL suitable to share on social networks. */
            getUrlToSocialShare: function (params) {
                var __url = TCFAAppConfigService.API.HOST + "/cotizador/showSocial/",
                    __params = {};
                if (__url.indexOf("//") === 0) {
                    __url = "http:" + __url;
                }
                // Link IDs.
                __params.il = (params.link_type || 1);
                // ID Campaña PAO.
                if (params.campaign) {
                    __params.ic = params.campaign;
                }
                // Operation IDs.
                if (params.operation_type) {
                    __params.io = params.operation_type;
                }
                // Vehicle's IDs.
                if (params.vehicle_brand || params.vehicle_brand_name) {
                    __params.vb = params.vehicle_brand;
                    __params.vbn = params.vehicle_brand_name;
                }
                if (params.vehicle_model || params.vehicle_model_name) {
                    __params.vm = params.vehicle_model;
                    __params.vmn = params.vehicle_model_name;
                }
                if (params.vehicle_version || params.vehicle_version_name) {
                    __params.vv = params.vehicle_version;
                    __params.vvn = params.vehicle_version_name;
                }
                if (params.vehicle_condition) {
                    __params.vc = params.vehicle_condition;
                }
                if (params.vehicle_year) {
                    __params.vy = params.vehicle_year;
                }
                if (params.vehicle_type) {
                    __params.vt = params.vehicle_type;
                }
                if (params.vehicle_id) {
                    __params.vi = params.vehicle_id;
                }
                // Person's IDs.
                if (params.person_type) {
                    __params.pt = params.person_type;
                }
                if (params.tax_condition) {
                    __params.tc = params.tax_condition;
                }
                // Loan Keys.
                if (params.loan_amount) {
                    __params.la = params.loan_amount;
                }
                if (params.canon) {
                    __params.ca = params.canon;
                }
                // Plan IDs.
                if (params.plan) {
                    __params.ip = params.plan;
                }
                // Subplan IDs.
                if (params.plazo) {
                    __params.pl = params.plazo;
                }
                if (params.subplan) {
                    __params.is = params.subplan;
                }
                // Insurance IDs.
                if (params.insurance_id) {
                    __params.ii = params.insurance_id;
                }
                if (params.insurance_company_name) {
                    __params.icn = params.insurance_company_name;
                }
                if (params.insurance_pack_name) {
                    __params.ipn = params.insurance_pack_name;
                }
                if (params.insurance_zone_name) {
                    __params.izn = params.insurance_zone_name;
                }
                if (params.insurance_prov_name) {
                    __params.izpn = params.insurance_prov_name;
                }
                if (params.insurance_installments) {
                    __params.iic = params.insurance_installments;
                }
                if (params.insurance_company_id) {
                    __params.ici = params.insurance_company_id;
                }
                if (params.insurance_pack_code) {
                    __params.ipi = params.insurance_pack_code;
                }
                if (params.insurance_zone_cp) {
                    __params.izcp = params.insurance_zone_cp;
                }
                if (params.insurance_prov_id) {
                    __params.izpi = params.insurance_prov_id;
                }
                if (params.insurance_detail) {
                    __params.idet = $window.btoa(params.insurance_detail);
                }
                return __url + "?" + $httpParamSerializer(__params);
            },
            /** Redirects to anonymous channel on missing info scenarios. */
            redirectToAnonymousChannel: function () {
                $location.path("/cotizador").search({}).hash(null);
                $route.reload();
            }
        };
    };
    CotizadorService.$inject = moduleConfig.CotizadorService.deps;
    module.factory(moduleConfig.CotizadorService.name, CotizadorService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.DependencyInjectionService",
            deps: [],
            DependencyInjectionService: {
                name: "DependencyInjectionService",
                deps: ["$q", "TCFAAppConfigService"]
            }
        },
        module, DependencyInjectionService;
    module = angular.module("TCFAutos");
    DependencyInjectionService = function ($q, TCFAAppConfigService) {
        var loadPromises = {},
            loadScript = function (src, apiName, delayMs) {
                loadPromises[apiName] = $q.defer();
                $.getScript(src).done(function () {
                    if (delayMs) {
                        setTimeout(function () {
                            loadPromises[apiName].resolve();
                        }, delayMs);
                    } else {
                        loadPromises[apiName].resolve();
                    }
                }).fail(function () {
                    loadPromises[apiName].reject();
                });
                return loadPromises[apiName].promise;
            },
            externalApis = [{
                url: "https://maps.googleapis.com/maps/api/js?key=" + TCFAAppConfigService.GOOGLE_MAPS.KEY + "&libraries=places,geometry",
                apiName: "GoogleMaps",
                exports: "google.maps",
                delayMs: 0
            }, {
                url: "https://apis.google.com/js/client:platform.js",
                apiName: "GooglePlus",
                exports: "gapi",
                delayMs: 0
            }, {
                url: "https://connect.facebook.net/en_US/sdk.js",
                apiName: "Facebook",
                exports: "FB",
                delayMs: 0
            }, {
                url: "https://www.google.com/recaptcha/api.js?onload=vcRecaptchaApiLoaded&render=explicit",
                apiName: "GoogleRecaptcha",
                exports: "",
                delayMs: 2000
            }, {
                url: "https://codeicus.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/popjk2/b/c/3d70dff4c40bd20e976d5936642e2171/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=e8867540",
                apiName: "IssueCollector",
                exports: "",
                delayMs: 0
            }, {
                url: TCFAAppConfigService.SEGUROS.URL,
                apiName: "TCFASeguros",
                exports: "TCFASegurosSDK",
                delayMs: 0
            }],
            loadedApis = {},
            loadApis = function () {
                var i;
                for (i = externalApis.length - 1; i >= 0; i--) {
                    loadedApis[externalApis[i].apiName] = {};
                    loadedApis[externalApis[i].apiName].url = externalApis[i].url;
                    loadedApis[externalApis[i].apiName].exports = externalApis[i].exports;
                    loadedApis[externalApis[i].apiName].delayMs = externalApis[i].delayMs;
                }
            };
        loadApis();
        return {
            loadApi: function (apiName) {
                if (!apiName) {
                    throw "Invalid api name";
                }
                if (!loadPromises[apiName]) {
                    return loadScript(loadedApis[apiName].url, apiName, loadedApis[apiName].delayMs);
                } else {
                    return loadPromises[apiName].promise;
                }
            }
        };
    };
    DependencyInjectionService.$inject = moduleConfig.DependencyInjectionService.deps;
    module.factory(moduleConfig.DependencyInjectionService.name, DependencyInjectionService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.ExternalLoginService",
            deps: ["authDaoModule", "termsConditionCheckerModule", "TCFAutos.services.ParametrosService", "TCFAutos.services.PageNavigationService"],
            ExternalLoginService: {
                name: "ExternalLoginService",
                deps: ["$location", "AuthDAO", "$window", "$rootScope", "$uibModal", "termsAndConditionChecker", "ParametrosService",
                    "PageNavigationService", "TCFAModalService", "TCFAAppConfigService"]
            }
        },
        module, ExternalLoginService,
        /** Constants */
        PICTURE_URL = "../images/user/defaultProfile.png",
        _USER_IMAGES_URL_PATH_SUFFIX = "/user_images/",
        USER_TYPE_ADMIN = 'admin',
        USER_TYPE_CLIENT = 'cliente',
        USER_TYPE_DEALER = 'dealer',
        USER_TYPE_TASA = 'tasa';
    module = angular.module("TCFAutos");
    ExternalLoginService = function ($location, AuthDAO, $window, $rootScope, $uibModal, termsAndConditionChecker,
                                     ParametrosService, PageNavigationService, TCFAModalService, TCFAAppConfigService) {
        var seccion_login = ParametrosService.get({key: "seccion_login"}),
            validateAndSetUserType = function (userType) {
                switch (userType) {
                    case USER_TYPE_ADMIN: // Fallthrough.
                    case USER_TYPE_DEALER:
                        $rootScope.ShowMiPerfil = false;
                        $rootScope.ShowABM = true;
                        $rootScope.ShowMensajes = false;
                        $rootScope.showFavoritos = false;
                        break;
                    case USER_TYPE_TASA:
                        $rootScope.ShowMiPerfil = true;
                        $rootScope.ShowABM = true;
                        $rootScope.ShowMensajes = false;
                        $rootScope.showFavoritos = false;
                        break;
                    case USER_TYPE_CLIENT:
                        $rootScope.ShowMiPerfil = true;
                        $rootScope.ShowABM = false;
                        $rootScope.ShowMensajes = true;
                        $rootScope.showFavoritos = true;
                        break;
                    default:
                        $rootScope.ShowMiPerfil = false;
                        $rootScope.ShowABM = false;
                        $rootScope.ShowMensajes = false;
                        $rootScope.showFavoritos = false;
                }
            },
            goToAbm = function (token, redirect) {
                if ($rootScope.ShowABM) {
                    $window.location.href = TCFAAppConfigService.ABM.URL + "/#/?t=" + token +
                        "&r=" + $window.encodeURIComponent(redirect);
                }
            },
            validateSessionResponse = function (sessionResponse) {
                var invalidSession = (sessionResponse.data.length === 0);
                PageNavigationService.showSplash = false;
                if (invalidSession) {
                    $window.console.debug("ExternalLoginService - El Token utilizado no corresponde a ningún usuario.");
                    TCFAModalService.showError({
                        error: {
                            showModal: true,
                            message: "Ups algo inesperado ha sucedido, vuelva a ingresar o contáctese con el administrador. Le pedimos disculpas.",
                            title: "¡Ha ocurrido un error!"
                        }
                    });
                } else {
                    $window.console.debug("ExternalLoginService - Token valido ->", sessionResponse);
                }
                return !invalidSession;
            },
            validateSessionTasa = function (token) {
                AuthDAO.ValidateSessionTasa({token: token}, function (resp) {
                        if (validateSessionResponse(resp)) {
                            $rootScope.userInfo.user = resp.data;
                            $rootScope.userInfo.token = token;
                            $rootScope.socialProfile = {pictureUrl: PICTURE_URL};
                            $rootScope.is_tasa = true;
                            goToAbm($rootScope.userInfo.token, "aprobaciones/");
                        }
                    }
                );
            },
            validateSessionAdminDealerOrClient = function (token, userType) {
                AuthDAO.validateSession({token: token, user_type: userType}, function (resp) {
                        var pictureUrl, user;
                        if (validateSessionResponse(resp)) {
                            user = resp.data.user;
                            $rootScope.userInfo.token = resp.data.token;
                            // Usuario Administrador.
                            if ((user.admin !== undefined) && (user.admin !== null)) {
                                $rootScope.userInfo.user = user;
                                if (user.profile_image) {
                                    pictureUrl = TCFAAppConfigService.API.IMG_SRC + _USER_IMAGES_URL_PATH_SUFFIX + user.profile_image.url;
                                } else {
                                    pictureUrl = PICTURE_URL;
                                }
                                $rootScope.socialProfile = {pictureUrl: pictureUrl};
                                goToAbm($rootScope.userInfo.token, "publicaciones/");
                            }
                            // Usuario Dealer.
                            if ((user.dealer !== undefined) && (user.dealer !== null)) {
                                $rootScope.userInfo.user = user;
                                if (user.profile_image) {
                                    pictureUrl = TCFAAppConfigService.API.IMG_SRC + _USER_IMAGES_URL_PATH_SUFFIX + user.profile_image.url;
                                } else {
                                    pictureUrl = "../images/user/defaultProfileDealer.png";
                                }
                                $rootScope.socialProfile = {pictureUrl: pictureUrl};
                                // Lógica para login sin unificar.
                                $rootScope.userInfo.user.dealer.concesionario = resp.data.concesionario;
                                if (seccion_login.valor) {
                                    termsAndConditionChecker.checkAndRequest(seccion_login.valor);
                                }
                                goToAbm($rootScope.userInfo.token, "publicaciones/");
                            }
                            // Usuario Cliente.
                            if ((user.cliente !== undefined) && (user.cliente !== null)) {
                                $rootScope.userInfo.user = user;
                                if (user.profile_image) {
                                    pictureUrl = TCFAAppConfigService.API.IMG_SRC + _USER_IMAGES_URL_PATH_SUFFIX + user.profile_image.url;
                                } else {
                                    pictureUrl = PICTURE_URL;
                                }
                                $rootScope.socialProfile = {pictureUrl: pictureUrl};
                                buildSelector(resp.data.clientes_asociados, function (cuit) {
                                    AuthDAO.navigateAs({cuit: cuit}, function (resp) {
                                            $window.console.debug("ExternalLoginService - navigateAs success ->", resp);
                                        }, function (resp) {
                                            $window.console.debug("ExternalLoginService - navigateAs Error ->", resp);
                                        }
                                    );
                                });
                            }
                        }
                    }, function (resp) {
                        PageNavigationService.showSplash = false;
                        $window.console.debug("ExternalLoginService - validateSession Error ->", resp);
                    }
                );
            },
            buildSelector = function (clientesAsociados, callback) {
                if (clientesAsociados.length < 1) {
                    $window.console.debug("ExternalLoginService - buildSelector - Para poder operar como Dealer, debe tener asociado un concesionario.");
                    TCFAModalService.showWarning({
                        error: {
                            showModal: true,
                            message: "Para poder operar como Dealer, debe tener asociado un concesionario.",
                            title: "Usuario no válido"
                        }
                    });
                } else {
                    var selectorScope = $rootScope.$new(true);
                    selectorScope.clientes = clientesAsociados;
                    var clientSelectorModal = $uibModal.open({
                        animation: true,
                        windowClass: "tcfa-modal",
                        templateUrl: "./views/login/login_cliente_selector.html",
                        controller: "LoginClienteSelector",
                        controllerAs: "ctrl",
                        scope: selectorScope,
                        parent: angular.element("#modalComponent"),
                        appendTo: angular.element("#modalComponent"),
                        bindToController: true,
                        backdrop: "static",
                        keyboard: false
                    });
                    clientSelectorModal.result.then(function (result) {
                        $window.console.debug("ExternalLoginService - buildSelector - Se selecciona entidad", result);
                        clientSelectorModal.dismiss("userlogged");
                        callback(result);
                    });
                }
            },
            externalLogin = function (userType) {
                var token = $location.search().token;
                $location.path("/");
                if (token === undefined) {
                    $window.console.debug("ExternalLoginService - Token no ingresado.");
                    TCFAModalService.showWarning({
                        error: {
                            showModal: true,
                            message: "Token de sesión vacío o inválido. Se necesita un token para ingresar a TCFAutos. Muchas gracias.",
                            title: "Aviso:"
                        }
                    });
                } else { // Token is defined.
                    $window.console.debug("ExternalLoginService - Se esta ingresando desde un sistema externo como...", userType);
                    PageNavigationService.showSplash = true;
                    validateAndSetUserType(userType);
                    if (userType === USER_TYPE_TASA) {
                        validateSessionTasa(token);
                    } else {
                        validateSessionAdminDealerOrClient(token, userType);
                    }
                }
            };
        // Integración Apps Externas con TCFAutos.
        return {
            goToAbm: function (redirect) {
                goToAbm($rootScope.userInfo.token, redirect || "");
            },
            externalLogin: externalLogin
        };
    };
    ExternalLoginService.$inject = moduleConfig.ExternalLoginService.deps;
    module.factory(moduleConfig.ExternalLoginService.name, ExternalLoginService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "socialModule",
            deps: ["ngResource", "TCFAutos.services.PageNavigationService", "TCFAutos.services.NotificationsService"],
            facebookService: {
                require: ["//connect.facebook.net/en_US/sdk.js"],
                fallbacks: ["scripts_fallbacks/facebook"],
                name: "facebookService",
                deps: ["$window", "$rootScope", "AuthSocial", "PageNavigationService", "NotificationsService", "DependencyInjectionService",
                    "TCFAAppConfigService", "PublicationDAO", "TCFAModalService"]
            }
        },
        module, facebookService,
        /** Constants */
        MSG_USER_CANCELLED_LOGIN = "User cancelled login or did not fully authorize.",
        FACEBOOK_LOGIN_RESULT = "Facebook Login result",
        SOCIAL_PROFILE_INFO = "Social Profile INFO";
    module = angular.module("TCFAutos");
    // Facebook Service.
    facebookService = function ($window, $rootScope, AuthSocial, PageNavigationService, NotificationsService,
                                DependencyInjectionService, TCFAAppConfigService, PublicationDAO, TCFAModalService) {
        var myself = this;
        // Check for Firewalls blocking Facebook.
        DependencyInjectionService.loadApi("Facebook").then(function () {
            FB.init({
                appId: TCFAAppConfigService.FACEBOOK.APP_ID, //The app id of the web app
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true,  // parse social plugins on this page
                version: TCFAAppConfigService.FACEBOOK.VERSION // use graph api version 2.5
            });
        }, function () {
            myself.tcfautos_fallback_check = true;
        });
        myself.login = function (success) {
            FB.login(function (response) {
                $window.console.debug(FACEBOOK_LOGIN_RESULT, response);
                if (response.status === "connected") {
                    $window.console.debug("Welcome!  Fetching your information.... ");
                    // Calling Auth
                    $window.console.debug("Calling TCFAutos API Social Auth");
                    var data = {
                        network: "facebook",
                        access_token: response.authResponse.accessToken
                    };
                    PageNavigationService.showSplash = true;
                    AuthSocial.login(data).$promise.then(function (resp1) {
                        $window.console.debug("AuthSocial INFO", resp1);
                        FB.api("/me?fields=first_name,last_name,picture.type(large)", function (resp2) {
                            $window.console.debug(SOCIAL_PROFILE_INFO, resp2);
                            var socialProfile = {
                                firstName: resp2.first_name,
                                lastName: resp2.last_name,
                                pictureUrl: resp2.picture.data.url
                            };
                            if (resp1.data.user.profile_image) {
                                socialProfile.pictureUrl = TCFAAppConfigService.API.IMG_SRC + "/user_images/" + resp1.data.user.profile_image.url;
                            }
                            $window.console.debug("Social Profile INFO parsed", socialProfile);
                            $rootScope.userInfo = resp1.data;
                            NotificationsService.markAsUnread(resp1.data.user.cliente.mensajes);
                            $rootScope.socialProfile = socialProfile;
                            $rootScope.loggedSocial = "facebook";
                            PageNavigationService.showSplash = false;
                            success();
                        });
                    }, function (resp) {
                        PageNavigationService.showSplash = false;
                        $window.console.debug(resp);
                    });
                } else {
                    $window.console.debug(MSG_USER_CANCELLED_LOGIN);
                }
            }, {scope: "email"});
        };
        myself.getData = function () {
            FB.getLoginStatus(function (response) {
                if (response.status === "connected") {
                    // getData
                    FB.api("/me?fields=email,first_name,last_name", function (response) {
                        $window.console.debug(response);
                    });
                } else {
                    $window.console.debug("Something bad happened with Facebook");
                    $window.console.debug(response);
                }
            });
        };
        myself.share = function (url, callback) {
            FB.login(function (response) {
                $window.console.debug(FACEBOOK_LOGIN_RESULT, response);
                if (response.status === "connected") {
                    FB.api("/me?fields=name,email", function (resp) {
                        $window.console.debug(SOCIAL_PROFILE_INFO, resp);
                        var socialProfile = {
                            nombre: resp.name,
                            email: resp.email
                        };
                        FB.ui({
                            method: "share",
                            href: url
                        }, function (response) {
                            $window.console.debug("Share on Facebook response:", response);
                            if (callback && typeof callback === "function") {
                                callback(socialProfile);
                            }
                        });
                    });
                } else {
                    $window.console.debug(MSG_USER_CANCELLED_LOGIN);
                }
            }, {scope: "email"});
        };
        myself.loginShare = function (url, publicacion_id, callback) {
            FB.login(function (response) {
                $window.console.debug(FACEBOOK_LOGIN_RESULT, response);
                if (response.status === "connected") {
                    FB.api("/me?fields=name,email", function (resp) {
                        $window.console.debug(SOCIAL_PROFILE_INFO, resp);
                        var socialProfile = {
                            publicacion_id: publicacion_id,
                            nombre: resp.name,
                            email: resp.email
                        };
                        FB.ui({
                            method: "share",
                            href: url
                        }, function (response) {
                            PublicationDAO.sharePublicationByFacebook(socialProfile).$promise.then(function () {
                                var info = {
                                    warning: {
                                        showModal: true,
                                        message: ["¡Muchas Gracias!"],
                                        title: "Publicacion compartida con éxito."
                                    },
                                    showBtn: false
                                };
                                TCFAModalService.showModal(info, "./views/detalle_publicacion/info/modal/success_modal_share_with_friends.html");
                            }, function () {
                                TCFAModalService.showError({
                                    error: {
                                        showModal: true,
                                        message: "Ha ocurrido un error al procesar la solicitud, por favor intente nuevamente en unos instantes.",
                                        title: "¡Ha ocurrido un error!"
                                    }
                                });
                            });
                            $window.console.debug("Share on Facebook response:", response);
                            if (callback && typeof callback === "function") {
                                callback(response);
                            }
                        });
                    });
                } else {
                    $window.console.debug(MSG_USER_CANCELLED_LOGIN);
                }
            }, {scope: "email"});
        };
        return myself;
    };
    facebookService.$inject = moduleConfig.facebookService.deps;
    module.factory(moduleConfig.facebookService.name, facebookService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.GeoLocation",
            deps: [],
            GeoLocationService: {
                name: "GeoLocationService",
                deps: ["$q"]
            }
        },
        module, GeoLocationService;
    module = angular.module("TCFAutos");
    GeoLocationService = function ($q) {
        var geoLocation = {}, options = {
            timeout: 10000,
            enableHighAccuracy: true
        }, promesa = $q.defer();
        if (!navigator.geolocation) {
            promesa.reject("El navegador no soporta la geo localización.");
            return geoLocation;
        }
        navigator.geolocation.getCurrentPosition(function (position) {
            promesa.resolve(position);
        }, function (error) {
            promesa.reject(error);
        }, options);
        geoLocation.location = promesa.promise;
        return geoLocation;
    };
    GeoLocationService.$inject = moduleConfig.GeoLocationService.deps;
    module.factory(moduleConfig.GeoLocationService.name, GeoLocationService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "socialModule",
            deps: ["ngResource", "TCFAutos.services.PageNavigationService", "TCFAutos.services.NotificationsService"],
            googleService: {
                require: ["https://apis.google.com/js/client:platform.js"],
                fallbacks: ["scripts_fallbacks/google-api"],
                name: "googleService",
                deps: ["$window", "$rootScope", "$q", "AuthSocial", "usSpinnerService", "PageNavigationService", "NotificationsService",
                    "DependencyInjectionService", "TCFAAppConfigService"]
            }
        },
        module, googleService;
    module = angular.module("TCFAutos");
    // Google+ Service.
    googleService = function ($window, $rootScope, $q, AuthSocial, usSpinnerService, PageNavigationService,
                              NotificationsService, DependencyInjectionService, TCFAAppConfigService) {
        var myself = this,
            auth2;
        DependencyInjectionService.loadApi("GooglePlus").then(function () {
            $window.console.debug("GooglePlus API fully loaded");

            gapi.load('auth2', function() {
                auth2 = gapi.auth2.init({
                    client_id: TCFAAppConfigService.GOOGLE.CLIENT_ID,
                    scope: TCFAAppConfigService.GOOGLE.SCOPES
                });
            });
        }, function () {
            myself.tcfautos_fallback_check = true;
        });
        // Check for Firewalls blocking Google.
        myself.login = function (success) {
            var handleAuthResult = function (googleUser) {
                $window.console.debug("googleUser G+", googleUser);
                if (googleUser && !googleUser.error) {
                    // Calling Auth
                    $window.console.debug("Calling TCFAutos API Social Auth");
                    var data = {
                        network: "googleplus",
                        access_token: googleUser.getAuthResponse().id_token
                    };

                    PageNavigationService.showSplash = true;
                    AuthSocial.login(data).$promise.then(function (resp1) {
                        $window.console.debug("AuthSocial INFO", resp1);
                        var googleProfile = googleUser.getBasicProfile();
                        var socialProfile = {
                            firstName: googleProfile.getGivenName(),
                            lastName: googleProfile.getFamilyName(),
                            pictureUrl: googleProfile.getImageUrl() + "0" // img size * 10px
                        };
                        if (resp1.data.user.profile_image) {
                            socialProfile.pictureUrl = TCFAAppConfigService.API.IMG_SRC + "/user_images/" + resp1.data.user.profile_image.url;
                        }

                        $window.console.debug("Social Profile INFO parsed", socialProfile);
                        $rootScope.userInfo = resp1.data;
                        NotificationsService.markAsUnread(resp1.data.user.cliente.mensajes);
                        $rootScope.socialProfile = socialProfile;
                        $rootScope.loggedSocial = "google+";
                        PageNavigationService.showSplash = false;
                        success();
                    },
                        function (resp) {
                            PageNavigationService.showSplash = false;
                            $window.console.debug(resp);
                        }
                    );
                } else {
                    $window.console.debug("something went wrong with Google");
                }
            },
                checkAuth = function () {
                    auth2.signIn().then(function (googleUser) {
                        handleAuthResult(googleUser);
                    }, function (error) {
                        $window.console.debug("Google Error: " + error.message);
                    });
                };

            $window.setTimeout(checkAuth, 1);
        };
        return myself;
    };
    googleService.$inject = moduleConfig.googleService.deps;
    module.factory(moduleConfig.googleService.name, googleService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.HomeFilterService",
            deps: [],
            HomeFilterService: {
                name: "HomeFilterService",
                deps: []
            }
        },
        module, HomeFilterService;
    module = angular.module("TCFAutos");
    HomeFilterService = function () {
        return {
            filters: {}
        };
    };
    HomeFilterService.$inject = moduleConfig.HomeFilterService.deps;
    module.factory(moduleConfig.HomeFilterService.name, HomeFilterService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "errorHandlerModule",
            deps: ["TCFAutos.services.PageNavigationService"],
            tcfaErrorHandler: {
                name: "tcfaErrorHandler",
                deps: ["$q", "$injector", "$rootScope", "PageNavigationService", "TCFAAppConfigService"]
            }
        },
        module, tcfaErrorHandler;
    module = angular.module("TCFAutos");
    tcfaErrorHandler = function ($q, $injector, $rootScope, PageNavigationService, TCFAAppConfigService) {
        var showErrorCodes = [400],
            showErrorInternalCodes = [101],
            showSuccessCodes = [201, 204],
            isRestService = function (url) {
                return url.indexOf(TCFAAppConfigService.API.HOST) > -1;
            },
            isCotizadorService = function (url) {
                return (isRestService(url) && (url.indexOf("/cotizador") > -1));
            },
            isYouTubeService = function (url) {
                return (url.indexOf("https://www.googleapis.com/youtube") > -1);
            };
        return {
            response: function (response) {
                var timeout;
                if (isRestService(response.config.url) && (showSuccessCodes.indexOf(response.status) > -1)) {
                    PageNavigationService.showSuccess = true;
                    timeout = $injector.get("$timeout");
                    timeout(function () {
                        PageNavigationService.showSuccess = false;
                    }, 3500);
                }
                return response;
            },
            responseError: function (rejection) {
                var errors, i, $location, $route,
                    info = {error: {}},
                    TCFAModalService = $injector.get("TCFAModalService");
                if (rejection.config && isRestService(rejection.config.url) &&
                    !isCotizadorService(rejection.config.url) &&
                    ((showErrorCodes.indexOf(rejection.status) > -1) ||
                        (rejection.data && rejection.data.errors && rejection.data.errors.code &&
                            (showErrorInternalCodes.indexOf(rejection.data.errors.code) > -1)))) {
                    if (rejection.data.errors) {
                        errors = [];
                        if (!(rejection.data.errors.message instanceof Array)) {
                            errors.push(rejection.data.errors.message);
                        } else {
                            for (i = 0; i < rejection.data.errors.message.length; i++) {
                                errors.push(rejection.data.errors.message[i]);
                            }
                        }
                        info.error.showModal = true;
                        info.error.message = errors;
                        info.error.title = "Aviso:";
                        TCFAModalService.showWarning(info);
                    }
                } else if (rejection.config && rejection.config.url && isYouTubeService(rejection.config.url)) {
                    info.error.showModal = true;
                    info.error.message = "Existe un problema con la cuenta de YouTube.";
                    info.error.title = "Aviso:";
                    TCFAModalService.showWarning(info);
                } else if ((rejection.status === 401) || (rejection.status === 403)) {
                    $rootScope.userInfo = {};
                    $rootScope.is_tasa = false;
                    $location = $injector.get("$location");
                    $route = $injector.get("$route");
                    $location.path("/");
                    $route.reload();
                    info.error.showModal = true;
                    info.error.message = "Su sesión ha expirado. Por favor, vuelva a loguearse.";
                    info.error.title = "¡Ha ocurrido un error!";
                    TCFAModalService.showError(info);
                }
                return $q.reject(rejection);
            }
        };
    };
    tcfaErrorHandler.$inject = moduleConfig.tcfaErrorHandler.deps;
    module.factory(moduleConfig.tcfaErrorHandler.name, tcfaErrorHandler);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "tokenInkectionModule",
            deps: [],
            tcfaTokenInjection: {
                name: "tcfaTokenInjection",
                deps: ["$q", "$rootScope", "TCFAAppConfigService"]
            }
        },
        module, tcfaTokenInjection;
    module = angular.module("TCFAutos");
    tcfaTokenInjection = function ($q, $rootScope, TCFAAppConfigService) {
        var isRestService = function (url) {
                return url.indexOf(TCFAAppConfigService.API.HOST) > -1;
            },
            userIsLogged = function () {
                return $rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.token;
            };
        return {
            request: function (config) {
                if (!isRestService(config.url) || (config.avoidInterceptor === true)) {
                    return config;
                } else {
                    if (userIsLogged()) {
                        config.headers.Authorization = "Bearer " + $rootScope.userInfo.token;
                    }
                    if ($rootScope.session_token) {
                        var deferred = $q.defer();
                        $rootScope.session_token.$promise.then(function (response) {
                            config.headers["X-Session-Token"] = response.data.session_token;
                            deferred.resolve(config);
                        }, function () {
                            deferred.reject("No session token!");
                        });
                        return deferred.promise;
                    }
                }
            }
        };
    };
    tcfaTokenInjection.$inject = moduleConfig.tcfaTokenInjection.deps;
    module.factory(moduleConfig.tcfaTokenInjection.name, tcfaTokenInjection);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "socialModule",
            deps: ["ngResource", "TCFAutos.services.PageNavigationService", "TCFAutos.services.NotificationsService"],
            linkedinService: {
                name: "linkedinService",
                deps: ["$window", "$rootScope", "$q", "$injector", "AuthSocial", "PageNavigationService", "TCFAModalService", "NotificationsService", "TCFAAppConfigService"]
            }
        },
        module, linkedinService;
    module = angular.module("TCFAutos");
    // LinkedIn Service.
    linkedinService = function ($window, $rootScope, $q, $injector, AuthSocial, PageNavigationService, TCFAModalService, NotificationsService, TCFAAppConfigService) {
        var myself = this,
            $location = $injector.get('$location'),
            UtilsHelper = $injector.get("UtilsHelper");

        myself.login = function () {
            var $route = $injector.get('$route'),
                code = $window.decodeURIComponent($route.current.params.code),
                data = {
                    network: "linkedin",
                    access_token: code,
                    redirectURI: UtilsHelper.getBaseURI()
                };

            PageNavigationService.showSplashScreen();

            AuthSocial.login(data).$promise.then(function (resp) {
                var socialProfile = {
                    firstName: resp.data.user.nombre,
                    lastName: resp.data.user.apellido,
                    pictureUrl: resp.data.social_picture
                };

                $window.console.debug("Social Profile INFO", socialProfile);
                $rootScope.userInfo = resp.data;
                NotificationsService.markAsUnread(resp.data.user.cliente.mensajes);
                $rootScope.socialProfile = socialProfile;
                $rootScope.loggedSocial = "linkedin";
                if (resp.data.user.profile_image) {
                    $rootScope.socialProfile.pictureUrl = TCFAAppConfigService.API.IMG_SRC + "/user_images/" + resp.data.user.profile_image.url;
                }

                PageNavigationService.hideSplashScreen();

                $location.path("/");
            },
            function (resp) {
                PageNavigationService.hideSplashScreen();
                $window.console.debug(resp);
            });
        };
        myself.login_error = function () {
            var $route = $injector.get('$route'),
                error = $window.decodeURIComponent($route.current.params.error),
                error_desc = $window.decodeURIComponent($route.current.params.error_desc);

            error_desc = $window.atob(error_desc);

            $window.console.debug("LinkedinLoginService - Error: " + error + " - Mensaje: " + error_desc);
            TCFAModalService.showError({
                error: {
                    showModal: true,
                    message: "Ocurrió un error al iniciar sesión con Linkedin, disculpe las molestias.",
                    title: "Error de Inicio de Sesión:"
                }
            });
            $location.path("/");
        };
        myself.openAuthTab = function () {
            var linkedinLoginURI = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code';
            linkedinLoginURI += '&client_id=' + TCFAAppConfigService.LINKEDIN.CLIENT_ID;
            linkedinLoginURI += '&redirect_uri=' + $window.encodeURIComponent(UtilsHelper.getBaseURI());
            linkedinLoginURI += '&scope=r_liteprofile%20r_emailaddress';

            $window.open(linkedinLoginURI, "_self");
        };
        return myself;
    };
    linkedinService.$inject = moduleConfig.linkedinService.deps;
    module.factory(moduleConfig.linkedinService.name, linkedinService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.LogInUser",
            deps: ["TCFAutos.controllers.LoginClienteSelector", "TCFAutos.controllers.LoginClienteCtrl", "TCFAutos.services.ParametrosService",
                "termsConditionCheckerModule"],
            LogInUser: {
                name: "LogInUser",
                deps: ["$rootScope", "$uibModal", "$q", "AuthDAO", "ParametrosService", "termsAndConditionChecker"]
            }
        },
        module, LogInUser,
        /** Constants */
        TCFA_MODAL = "tcfa-modal",
        MODAL_COMPONENT = "#modalComponent";
    module = angular.module("TCFAutos");
    LogInUser = function ($rootScope, $uibModal, $q, AuthDAO, ParametrosService, termsAndConditionChecker) {
        return {
            showUsuariosAsociados: function (user) {
                var selectorScope, clientSelectorModal;
                if (user.clientes_asociados && (user.clientes_asociados.length > 0)) {
                    selectorScope = $rootScope.$new(true);
                    selectorScope.clientes = user.clientes_asociados;
                    clientSelectorModal = $uibModal.open({
                        animation: true,
                        templateUrl: "./views/login/login_cliente_selector.html",
                        controller: "LoginClienteSelector",
                        windowClass: TCFA_MODAL,
                        controllerAs: "ctrl",
                        scope: selectorScope,
                        bindToController: true,
                        appendTo: angular.element(MODAL_COMPONENT),
                        backdrop: "static",
                        keyboard: false
                    });
                }
                return clientSelectorModal;
            },
            showConcesionariosAsociados: function (user) {
                var selectorScope, concesionarioSelectorModal;
                if (user.concesionarios_asociados && (user.concesionarios_asociados.length > 0)) {
                    selectorScope = $rootScope.$new(true);
                    selectorScope.concesionarios = user.concesionarios_asociados;
                    concesionarioSelectorModal = $uibModal.open({
                        animation: true,
                        templateUrl: "./views/login/login_concesionario_selector.html",
                        controller: "LoginConcesionarioSelector",
                        windowClass: TCFA_MODAL,
                        controllerAs: "ctrl",
                        scope: selectorScope,
                        bindToController: true,
                        appendTo: angular.element(MODAL_COMPONENT),
                        backdrop: "static",
                        keyboard: false
                    });
                }
                return concesionarioSelectorModal;
            },
            showLogin: function () {
                return $uibModal.open({
                    animation: true,
                    templateUrl: "./views/login/login_cliente_modal.html",
                    windowClass: TCFA_MODAL,
                    controller: "LoginClienteCtrl",
                    controllerAs: "loginCliente",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            loginCliente: function () {
                var that = this,
                    defer = $q.defer();
                that.showLogin().result.then(function (user) {
                    var clientSelectorModal = that.showUsuariosAsociados(user);
                    if (clientSelectorModal) {
                        clientSelectorModal.result.then(function (result) {
                            // Usuario seleccionado.
                            if (parseInt(result, 10) > 0) {
                                AuthDAO.navigateAs({cuit: result}).$promise.then(function (/*promise*/) {
                                    $rootScope.userInfo.navigate_as = result;
                                    defer.resolve("userlogged");
                                });
                            } else {
                                defer.resolve("userlogged");
                            }
                        });
                    } else {
                        defer.resolve("userlogged");
                    }
                }, function () {
                    defer.reject();
                });
                return defer.promise;
            },
            showLoginConcesionario: function () {
                return $uibModal.open({
                    animation: true,
                    windowClass: TCFA_MODAL,
                    templateUrl: "./views/login/login_concesionario_modal.html",
                    controller: "LoginConcesionarioCtrl",
                    controllerAs: "loginConcesionario",
                    parent: angular.element(MODAL_COMPONENT),
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            loginConcesionario: function () {
                var that = this,
                    defer = $q.defer();
                that.showLoginConcesionario().result.then(function (userData) {
                    var concesionarioSelectorModal;
                    if (userData.concesionarios_asociados.length === 1) {
                        that.loginConcesionarioProcesarSeleccionado(userData.concesionarios_asociados[0].cuit, userData).then(function () {
                            defer.resolve("userlogged");
                        });
                    } else {
                        concesionarioSelectorModal = that.showConcesionariosAsociados(userData);
                        if (concesionarioSelectorModal) {
                            concesionarioSelectorModal.result.then(function (result) {
                                that.loginConcesionarioProcesarSeleccionado(result, userData).then(function () {
                                    defer.resolve("userlogged");
                                });
                            });
                        } else {
                            defer.resolve("userlogged");
                        }
                    }
                }, function () {
                    defer.reject();
                });
                return defer.promise;
            },
            loginConcesionarioProcesarSeleccionado: function (result, userData) {
                var defer = $q.defer();
                ParametrosService.getAllParameters().$promise.then(function (params) {
                    var seccion_login;
                    if (params.some(function (element) {
                        var check = (element.clave === 'seccion_login');
                        if (check) {
                            seccion_login = element;
                        }
                        return check;
                    })) {
                        if (parseInt(result, 10) > 0) {
                            AuthDAO.navigateAs({cuit: result}).$promise.then(function ( /*promise*/) {
                                $rootScope.userInfo.navigate_as = result;
                                if (seccion_login.valor) {
                                    var termsAndConditionsPromise = termsAndConditionChecker.checkAndRequest(seccion_login.valor);
                                    termsAndConditionsPromise.then(function () {
                                        $rootScope.userInfo.user.dealer.concesionario = userData.concesionarios_asociados.find(function (concesionario) {
                                            return concesionario.cuit === result;
                                        });
                                        defer.resolve();
                                    });
                                }
                            });
                        } else {
                            defer.resolve();
                        }
                    }
                });
                return defer.promise;
            }
        };
    };
    LogInUser.$inject = moduleConfig.LogInUser.deps;
    module.factory(moduleConfig.LogInUser.name, LogInUser);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.NotificationsService",
            deps: ["TCFAutos.services.UserDAOService"],
            NotificationsService: {
                name: "NotificationsService",
                deps: ["$interval", "$rootScope", "UserDAO"]
            }
        },
        module, NotificationsService;
    module = angular.module("TCFAutos");
    NotificationsService = function ($interval, $rootScope, UserDAO) {
        var unreadMessages = {},
            readMessages = {},
            unreadCallbacks = [],
            unreadCount = function () {
                var i,
                    count = 0;
                for (i in unreadMessages) {
                    if (unreadMessages.hasOwnProperty(i)) {
                        count = count + 1;
                    }
                }
                return count;
            },
            notifyUnreads = function () {
                var i,
                    count = unreadCount();
                for (i = unreadCallbacks.length - 1; i >= 0; i--) {
                    unreadCallbacks[i](count);
                }
            },
            unread = function (messagesArray) {
                var i,
                    notify = false;
                for (i = messagesArray.length - 1; i >= 0; i--) {
                    if (!readMessages.hasOwnProperty(messagesArray[i])) {
                        unreadMessages[messagesArray[i]] = true;
                        notify = true;
                    }
                }
                if (notify) {
                    notifyUnreads();
                }
            },
            read = function (messagesArray) {
                var i,
                    notify = false;
                for (i = messagesArray.length - 1; i >= 0; i--) {
                    readMessages[messagesArray[i]] = true;
                    if (unreadMessages.hasOwnProperty(messagesArray[i])) {
                        delete unreadMessages[messagesArray[i]];
                        notify = true;
                    }
                }
                if (notify) {
                    notifyUnreads();
                }
            };
        // Endpoint de actualizacion de mensajes del usuario.
        $interval(function () {
            if ($rootScope.userInfo && $rootScope.userInfo.user && !$rootScope.is_tasa) {
                if ($rootScope.userInfo.user.dealer || $rootScope.userInfo.user.admin) {
                    return false;
                }
                UserDAO.getMensajesSinLeer({}, function (response) {
                    unread(response.data.mensajes);
                });
            }
        }, 60 * 1000);
        return {
            markAsUnread: function (messagesArray) {
                unread(messagesArray);
            },
            markAsRead: function (messagesArray) {
                read(messagesArray);
            },
            watchUnreads: function (callback) {
                unreadCallbacks.push(callback);
            },
            clear: function () {
                var notify = unreadCount();
                unreadMessages = {};
                readMessages = {};
                if (notify) {
                    notifyUnreads();
                }
            }
        };
    };
    NotificationsService.$inject = moduleConfig.NotificationsService.deps;
    module.factory(moduleConfig.NotificationsService.name, NotificationsService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.PageNavigationService",
            deps: [],
            PageNavigationService: {
                name: "PageNavigationService",
                deps: []
            }
        },
        module, PageNavigationService;
    module = angular.module("TCFAutos");
    PageNavigationService = function () {
        var __navigationParameters = {};
        return {
            showSplash: false,
            showSuccess: false,
            showLoginMenu: true,
            showNewsletterSubscription: false,
            showingSplash: 0,
            getParameter: function (key, defaultValue, preserve) {
                var parameter;
                if (__navigationParameters[key] === undefined) {
                    if (defaultValue === undefined) {
                        return null;
                    }
                    return defaultValue;
                } else {
                    parameter = __navigationParameters[key];
                    if (!preserve) {
                        delete __navigationParameters[key];
                    }
                    return parameter;
                }
            },
            deleteParameter: function (key) {
                delete __navigationParameters[key];
            },
            showSplashScreen: function () {
                this.showingSplash++;
            },
            hideSplashScreen: function () {
                if (this.showingSplash > 0) {
                    this.showingSplash--;
                }
            },
            shouldShowSplashScreen: function () {
                return this.showingSplash > 0 || this.showSplash;
            }
        };
    };
    PageNavigationService.$inject = moduleConfig.PageNavigationService.deps;
    module.factory(moduleConfig.PageNavigationService.name, PageNavigationService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.ParametrosService",
            deps: ["angular-cache"],
            ParametrosService: {
                name: "ParametrosService",
                deps: ["$resource", "CacheFactory", "TCFAAppConfigService"]
            }
        },
        module, ParametrosService;
    module = angular.module("TCFAutos");
    ParametrosService = function ($resource, CacheFactory, TCFAAppConfigService) {
        var transformBasicGetResponse,
            cacheParametros = CacheFactory.get("cacheParametros"),
            __cacheAllParameters = CacheFactory.get("cacheAllParameters");
        if (!cacheParametros) {
            cacheParametros = CacheFactory("cacheParametros", {
                maxAge: 1800000, // =(30m * 60s * 1000ms).
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive"
            });
        }
        if (!__cacheAllParameters) {
            __cacheAllParameters = CacheFactory("cacheAllParameters", {
                maxAge: (8 * 60 * 60 * 1000), // MS.
                cacheFlushInterval: (24 * 60 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        transformBasicGetResponse = function (response) {
            // NOTA: el servicio devuelve un array (vac�o, si no encuentra el par�metro).
            var data = (angular.fromJson(response)).data;
            if (data.length > 0) {
                return data[0];
            }
            return {
                error: true
            };
        };
        return $resource(TCFAAppConfigService.API.HOST + "/parametros/:key/", {key: "@key"}, {
            get: {
                method: "GET",
                params: {},
                transformResponse: transformBasicGetResponse,
                isArray: false,
                cache: cacheParametros
            },
            getWithoutCache: {
                method: "GET",
                params: {},
                transformResponse: transformBasicGetResponse,
                isArray: false
            },
            getAllParameters: {
                method: "GET",
                params: {},
                url: TCFAAppConfigService.API.HOST + "/parametros/",
                cache: __cacheAllParameters,
                transformResponse: function (response) {
                    var __json_response = angular.fromJson(response);
                    if (__json_response && __json_response.data &&
                        (!!__json_response.data.length || (__json_response.data.length === 0))) {
                        return __json_response.data;
                    }
                    return [];
                },
                isArray: true
            }
        });
    };
    ParametrosService.$inject = moduleConfig.ParametrosService.deps;
    module.factory(moduleConfig.ParametrosService.name, ParametrosService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "indexModule",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.ParametrosService", "authDaoModule", "termsConditionCheckerModule",
                "socialModule", "TCFAutos.services.BannerDAO", "TCFAutos.services.SessionValidationService", "TCFAutos.services.TCFANewsletter"],
            searchService: {
                name: "searchService",
                deps: []
            }
        },
        module, searchService;
    module = angular.module("TCFAutos");
    searchService = function () {
        return {
            params: {},
            addParameter: function (paramName, paramValue) {
                if (paramValue) {
                    this.params[paramName] = {
                        name: paramName,
                        value: paramValue
                    };
                    if (paramName !== "page") {
                        delete this.params.page;
                    }
                }
            },
            addFromRequest: function (params) {
                var res = {};
                angular.forEach(params, function (value, key) {
                    this[key] = {
                        name: key,
                        value: value
                    };
                }, res);
                this.params = res;
            },
            removeParameter: function (paramName) {
                delete this.params[paramName];
                delete this.params.page;
            },
            navigationValues: function () {
                var response = {};
                angular.forEach(this.params, function (value, key) {
                    this[key] = value.value;
                }, response);
                return response;
            }
        };
    };
    searchService.$inject = moduleConfig.searchService.deps;
    module.factory(moduleConfig.searchService.name, searchService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.SessionValidationService",
            deps: ["TCFAutos.services.LogInUser"],
            SessionValidationService: {
                name: "SessionValidationService",
                deps: ["$injector", "$rootScope", "LogInUser"]
            }
        },
        module, SessionValidationService;
    module = angular.module("TCFAutos");
    SessionValidationService = function ($injector, $rootScope, LogInUser) {
        var $location = $injector.get("$location"),
            $route = $injector.get("$route");
        return {
            validators: {
                CheckUserType: function (user, value) {
                    return this.usersTypesValidators[value](user);
                },
                usersTypesValidators: {
                    cliente: function (user) {
                        return (typeof user !== "undefined") && (user !== null) && (typeof user.cliente !== "undefined") && (user.cliente !== null);
                    },
                    dealer: function (user) {
                        return (typeof user !== "undefined") && (user !== null) && (typeof user.dealer !== "undefined") && (user.dealer !== null);
                    },
                    admin: function (user) {
                        return (typeof user !== "undefined") && (user !== null) && (typeof user.admin !== "undefined") && (user.admin !== null);
                    },
                    tasa: function (user) {
                        return (typeof user !== "undefined") && (user !== null) && !!$rootScope.is_tasa;
                    },
                    anon: function (user) {
                        return typeof user === "undefined";
                    }
                },
                showLoginByUserType: {
                    cliente: function (callback) {
                        LogInUser.loginCliente().then(function () {
                            if (callback) {
                                callback();
                            } else {
                                $route.reload();
                            }
                        });
                    },
                    dealer: function (callback) {
                        LogInUser.loginConcesionario().result.then(function (result) {
                            if (result === "userlogged") {
                                if (callback) {
                                    callback();
                                } else {
                                    $route.reload();
                                }
                            }
                        });
                    },
                    admin: function () {
                    },
                    tasa: function () {
                    },
                    anon: function () {
                    }
                }
            },
            validateStrictPermissions: function (permissions, showLogin, callback) {
                var innerRole, x, i, values, permiso, hasInnerPermission,
                    user,
                    hasPermission = false;
                if ($rootScope.userInfo) {
                    user = $rootScope.userInfo.user;
                }
                if (showLogin === undefined || showLogin === null) {
                    showLogin = false;
                }
                for (i = 0; i < permissions.length; i++) {
                    permiso = permissions[i];
                    if (permiso instanceof Array) {
                        hasInnerPermission = true;
                        for (x = 0; x < permiso.length; x++) {
                            innerRole = permiso[x];
                            values = innerRole.split(":");
                            if (this.validators[values[0]]) {
                                hasInnerPermission = hasInnerPermission && this.validators[values[0]](user, values[1]);
                            }
                        }
                        hasPermission = hasPermission || hasInnerPermission;
                    } else {
                        values = permiso.split(":");
                        if (this.validators[values[0]]) {
                            hasPermission = hasPermission || this.validators[values[0]](user, values[1]);
                        }
                    }
                }
                if (!hasPermission && showLogin && this.validators.CheckUserType(user, "anon")) { // Anon user.
                    this.validators.showLoginByUserType[showLogin](callback);
                } else if (!hasPermission && showLogin) {
                    $location.path("/not_allowed/");
                    $route.reload();
                }
                return hasPermission;
            }
        };
    };
    SessionValidationService.$inject = moduleConfig.SessionValidationService.deps;
    module.factory(moduleConfig.SessionValidationService.name, SessionValidationService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "authModule",
            deps: [],
            sessionValidator: {
                name: "sessionValidator",
                deps: ["$location", "$route", "$rootScope"]
            }
        },
        module, sessionValidator;
    module = angular.module("TCFAutos");
    sessionValidator = function ($location, $route, $rootScope) {
        return {
            validateSession: function (/*permisos*/) {
                if ($rootScope.userInfo &&
                    $rootScope.userInfo.user &&
                    $rootScope.userInfo.token) {
                    return true;
                }
                $location.path("/not_allowed/");
                return false;
            }
        };
    };
    sessionValidator.$inject = moduleConfig.sessionValidator.deps;
    module.factory(moduleConfig.sessionValidator.name, sessionValidator);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.TCFAModalService",
            deps: [],
            TCFAModalService: {
                name: "TCFAModalService",
                deps: ["$uibModal"]
            }
        },
        module, TCFAModalService,
        /** Constants */
        TCFA_MODAL = "tcfa-modal",
        UIB_MODAL_INSTANCE = "$uibModalInstance",
        MODAL_COMPONENT = "#modalComponent";
    module = angular.module("TCFAutos");
    TCFAModalService = function ($uibModal) {
        return {
            showError: function (scope) {
                var myScope = scope;
                return $uibModal.open({
                    animation: true,
                    templateUrl: "./views/component/modal/error_modal.html",
                    windowClass: TCFA_MODAL,
                    openedClass: "tcfa-error-modal-wrapper",
                    // FIXME: mover esta definicion a la carpeta controllers.
                    controller: ["$scope", UIB_MODAL_INSTANCE,
                        function ($scope, $uibModalInstance) {
                            this.info = myScope;
                            this.showClose = true;
                            if (!(this.info.error.message instanceof Array)) {
                                this.info.error.message = [this.info.error.message];
                            }
                            this.close = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                        }
                    ],
                    controllerAs: "ctrl",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            showWarning: function (scope) {
                var myScope = scope;
                return $uibModal.open({
                    animation: true,
                    templateUrl: "./views/component/modal/warning_modal.html",
                    windowClass: TCFA_MODAL,
                    // FIXME: mover esta definicion a la carpeta controllers.
                    controller: ["$scope", UIB_MODAL_INSTANCE,
                        function ($scope, $uibModalInstance) {
                            this.info = myScope;
                            if (!(this.info.error.message instanceof Array)) {
                                this.info.error.message = [this.info.error.message];
                            }
                            $scope.close = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                        }
                    ],
                    controllerAs: "ctrl",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            showAdvice: function (scope) {
                var myScope = scope;
                return $uibModal.open({
                    animation: true,
                    templateUrl: "./views/component/modal/advice_modal.html",
                    windowClass: TCFA_MODAL,
                    controller: ["$scope", UIB_MODAL_INSTANCE,
                        function ($scope, $uibModalInstance) {
                            this.info = myScope;
                            if (!(this.info.warning.message instanceof Array)) {
                                this.info.warning.message = [this.info.warning.message];
                            }
                            $scope.close = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                            $scope.btnClick = function () {
                                $uibModalInstance.dismiss("cancel");
                                if (myScope.warning.warningFn) {
                                    myScope.warning.warningFn();
                                }
                            };
                        }
                    ],
                    controllerAs: "ctrl",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            showConfirm: function (scope) {
                return $uibModal.open({
                    animation: true,
                    templateUrl: "./views/component/modal/confirm_modal.html",
                    windowClass: TCFA_MODAL,
                    controller: ["$scope", UIB_MODAL_INSTANCE,
                        function ($scope, $uibModalInstance) {
                            var vm = this;
                            vm.info = scope;
                            $scope.aceptar = function () {
                                $uibModalInstance.dismiss("accept");
                                if (vm.info.aceptar && angular.isFunction(
                                    vm.info.aceptar)) {
                                    vm.info.aceptar();
                                }
                            };
                            $scope.cancelar = function () {
                                $uibModalInstance.dismiss("cancel");
                                if (vm.info.cancelar && angular.isFunction(
                                    vm.info.cancelar)) {
                                    vm.info.cancelar();
                                }
                            };
                        }
                    ],
                    controllerAs: "ctrl",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            showOk: function (modal) {
                var modalInfo = modal;
                return $uibModal.open({
                    animation: true,
                    templateUrl: "./views/component/modal/ok_modal.html",
                    windowClass: TCFA_MODAL,
                    controller: ["$scope", UIB_MODAL_INSTANCE,
                        function ($scope, $uibModalInstance) {
                            this.info = modalInfo;
                            if (!(this.info.warning.message instanceof Array)) {
                                this.info.warning.message = [this.info.warning.message];
                            }
                            $scope.close = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                            $scope.btnClick = function () {
                                $uibModalInstance.dismiss("cancel");
                                if (modalInfo.warning.warningFn) {
                                    modalInfo.warning.warningFn();
                                }
                            };
                        }
                    ],
                    controllerAs: "ctrl",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            },
            showModal: function (modal, template) {
                var modalInfo = modal, templateUrl = "./views/component/modal/ok_modal.html";
                if (template) {
                    templateUrl = template;
                }
                return $uibModal.open({
                    animation: true,
                    templateUrl: templateUrl,
                    windowClass: TCFA_MODAL,
                    controller: ["$scope", UIB_MODAL_INSTANCE,
                        function ($scope, $uibModalInstance) {
                            this.info = modalInfo;
                            if (!(this.info.warning.message instanceof Array)) {
                                this.info.warning.message = [this.info.warning.message];
                            }
                            $scope.close = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                            $scope.btnClick = function () {
                                $uibModalInstance.dismiss("cancel");
                                if (modalInfo.warning.warningFn) {
                                    modalInfo.warning.warningFn();
                                }
                            };
                        }
                    ],
                    controllerAs: "ctrl",
                    appendTo: angular.element(MODAL_COMPONENT),
                    bindToController: true
                });
            }
        };
    };
    TCFAModalService.$inject = moduleConfig.TCFAModalService.deps;
    module.factory(moduleConfig.TCFAModalService.name, TCFAModalService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.TCFANewsletter",
            deps: ["TCFAutos.services.TCFANewsletterDAO"],
            TCFANewsletter: {
                name: "TCFANewsletter",
                deps: ["$rootScope", "TCFAModalService", "TCFANewsletterDAO"]
            }
        },
        module, TCFANewsletter;
    module = angular.module('TCFAutos');
    TCFANewsletter = function ($rootScope, TCFAModalService, TCFANewsletterDAO) {
        return {
            adherirNewsletter: function (email, canal) {
                var info = {
                        warning: {},
                        error: {}
                    },
                    promesa = TCFANewsletterDAO.adherir({
                        canal: canal || $rootScope.loggedSocial || "native",
                        email: email,
                        nombre: "",
                        apellido: "",
                        plataforma: "TCFAutos"
                    }).$promise;
                promesa.then(function () {
                    info.warning.showModal = true;
                    info.warning.message = ["Te suscribiste al newsletter de TCFAUTOS.COM. Recibirás las mejores novedades para financiar tu próximo Toyota 0km - Usado y usados de otras marcas.", " ¡Muchas gracias!"];
                    info.warning.title = 'Suscripción exitosa';
                    info.warning.showBtn = true;
                    info.warning.btnText = 'Cerrar';
                    TCFAModalService.showOk(info);
                }, function (response) {
                    if (response.status === 400) {
                        info.error.message = ["Se encontro el siguiente problema: Ya se encuentra subscripto al newsletter.", "Muchas gracias."];
                        info.error.title = 'Suscripción inválida';
                        TCFAModalService.showWarning(info);
                    } else {
                        info.error.message = "Ha ocurrido un error al realizar la subscripción.";
                        info.error.title = 'Error en la suscripción';
                        TCFAModalService.showError(info);
                    }
                });
                return promesa;
            },
            desadherirNewsletter: function (email) {
                var info = {
                        warning: {},
                        error: {}
                    },
                    promesa = TCFANewsletterDAO.desadherir({
                        email: email,
                        plataforma: "TCFAutos"
                    }).$promise;
                promesa.then(function () {
                    info.warning.showModal = true;
                    info.warning.message = ["Usted se ha desadherido del newsletter.", "Por favor revise su casilla de correo", " Muchas gracias."];
                    info.warning.title = 'Desuscripción exitosa';
                    info.warning.showBtn = true;
                    info.warning.btnText = 'Cerrar';
                    TCFAModalService.showOk(info);
                }, function (response) {
                    if (response.status === 400) {
                        info.error.message = ["Se encontró el siguiente problema: No se encuentra subscripto al newsletter.", "Muchas gracias."];
                        info.error.title = 'Suscripción inválida';
                        TCFAModalService.showWarning(info);
                    } else {
                        info.error.message = "Ha ocurrido un error al cancelar la suscripción.";
                        info.error.title = 'Error en la suscripción';
                        TCFAModalService.showError(info);
                    }
                });
                return promesa;
            },
            validarEmailNewsletter: function (id_persona, hash) {
                var info = {
                        warning: {},
                        error: {}
                    },
                    promesa = TCFANewsletterDAO.verificacionDeEmail({
                        id_persona: id_persona,
                        hash: hash
                    }).$promise;
                promesa.then(function () {
                    info.warning.showModal = true;
                    info.warning.message = ["La operación ha sido completada.", " Muchas gracias."];
                    info.warning.title = 'Operacíon completada';
                    info.warning.showBtn = true;
                    info.warning.btnText = 'Cerrar';
                    TCFAModalService.showOk(info);
                }, function (response) {
                    if (response.status === 400) {
                        info.error.message = ["Se encontró el siguiente problema: No se encuentra la operación especificada.", "Muchas gracias."];
                        info.error.title = 'Operacion fallida';
                        TCFAModalService.showWarning(info);
                    } else {
                        info.error.message = "Ha ocurrido un error al validar el email.";
                        info.error.title = 'Error en la operación';
                        TCFAModalService.showError(info);
                    }
                });
                return promesa;
            }
        };
    };
    TCFANewsletter.$inject = moduleConfig.TCFANewsletter.deps;
    module.factory(moduleConfig.TCFANewsletter.name, TCFANewsletter);
}());

(function () {
    "use strict";
    var moduleConfig = {
            TCFAAppConfigService: {
                name: "TCFAAppConfigService",
                deps: ["$window"]
            }
        },
        module, TCFAAppConfigService,
        /** Constants. */
        GOOGLE_KEY = "AIzaSyDsnM-k76MHzztHTcGJuwBS_Nvb6wMRMxE",
        CONFIG_SHARED_GOOGLE_API_KEY = "AIzaSyC6iVdXBel8N5SNrps-vDuu81nESZvbXuY",
        CONFIG_SHARED_GOOGLE_CLIENT_ID = "1046438162897-pelpjv7s64mqi00i6looevtniptmqn00.apps.googleusercontent.com",
        CONFIG_SHARED_GOOGLE_RECAPTCHA_KEY = "6LdQ3UwUAAAAAF4B1gmft8m-YmYrjW6BVDGBZoEE",
        CONFIG_SHARED_LINKS_ASK_LOAN = "http://190.216.29.83/ToyotaAppForm/Login/TCFAutos",
        CONFIG_SHARED_LINKS_ASK_LOAN_COTIZADOR = "http://190.216.29.83/ToyotaAppForm/Login/MultiCanal",
        CONFIG_SHARED_LINKS_SIMULADOR_CREDITOS = "http://test1.toyotacfa.com.ar/simulador-creditos";
    module = angular.module("TCFAutos");
    TCFAAppConfigService = function ($window) {
        var HOST = $window.location.hostname,
            ENVS = {
                DEV_LOCAL: "local.www.tcfautos.com.ar",
                DEV_QA: "qa.www.tcfautos.codeicus",
                DEV_QA2: "qa2.www.tcfautos.codeicus",
                TEST1: "test1www.tcfautos.com.ar",
                TEST2: "test2www.tcfautos.com.ar",
                TEST3: "test3www.tcfautos.com.ar",
                TEST4: "test4www.tcfautos.com.ar",
                TEST_QA: "testqawww.tcfautos.com.ar",
                PROD: "www.tcfautos.com.ar"
            },
            CONFIG_SHARED = {
                SHOW_ISSUE_COLLECTOR: false,
                API: {
                    HOST: "",
                    MOCK: "",
                    IMG_SRC: "",
                    AUTO_IMAGE_FOLDER: "/auto_images",
                    BANNER_IMAGE_FOLDER: "/banner_images"
                },
                ABM: {URL: ""},
                CUOTAS: {
                    LOANS: [60, 48, 32, 24, 18, 12, 6],
                    MIN_VALUE: 50000
                },
                FACEBOOK: {
                    APP_ID: "681761016377309",
                    APP_SECRET: "922c541513399a40dfba4c6137454c91",
                    VERSION: "v2.6"
                },
                GOOGLE: {
                    API_KEY: GOOGLE_KEY,
                    CLIENT_ID: "823949072627-769p880beq6f731su74nf9v9h3qi2ss9.apps.googleusercontent.com",
                    CLIENT_SECRET: "o50ppWyluLYDpGC4QPnMK-2h",
                    SCOPES: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.profile",
                    SHARE_URL: "https://plus.google.com/share?url=",
                    ANALITYCS: "UA-54199384-2",
                    RECAPTCHA_KEY: "6LeQZxYUAAAAAD3RxJhd-lfDJA7WwGuEKLhdXCHw"
                },
                GOOGLE_MAPS: {KEY: GOOGLE_KEY},
                YOUTUBE: {
                    KEY: "AIzaSyDaAe2IGaTsw7eHP5sVi-abOt6coocqwYE",
                    IMAGE_REPO: "//i1.ytimg.com/vi/"
                },
                LINKEDIN: {
                    CLIENT_ID: "77afduyxtja3k9",
                    CLIENT_SECRET: "rMG9IEJ6MxUIlYVq"
                },
                LINKS: {
                    ASK_LOAN: "https://www.toyotacfa.com.ar/ToyotaAppForm/Login/TCFAutos",
                    ASK_LOAN_COTIZADOR: "https://www.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal",
                    SIMULADOR_CREDITOS: "https://www.toyotacfa.com.ar/simulador-creditos",
                    REGISTER_URL: "https://www.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/registration.xhtml",
                    FACEBOOK_PAGE: "https://www.facebook.com/ToyotaCFA",
                    GPLUS_PAGE: "https://plus.google.com/u/0/102230450905286800297",
                    LINKEDIN_PAGE: "http://www.linkedin.com/company/toyota-compa-ia-financiera",
                    TWITTER_PAGE: "https://www.twitter.com",
                    IOS_APP_LINK: "https://itunes.apple.com/app/id1214404213",
                    ANDROID_APP_LINK: "https://play.google.com/store/apps/details?id=com.ar.toyotacfa.tcfautosmobile",
                    TCFA_LOST_PASSWORD: "https://www.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/forgotUserPassword.xhtml",
                    NEW_REGISTER_URL: "/clientregister/",
                    LOST_PASSWORD: "/user/password/missed/"
                },
                TCFA_NEWSLETTER: "//www.toyotacfa.com.ar/api/",
                SEGUROS: {
                    ENV: "PROD",
                    URL: "https://widgetseguros.tcfautos.com.ar/sdk/sdk.js"
                }
            };
        // Development Local.
        if (HOST === ENVS.DEV_LOCAL) {
            CONFIG_SHARED.API.HOST = "//local.api.tcfautos.com.ar/index.php";
            CONFIG_SHARED.API.IMG_SRC = "http://local.resources.tcfautos.com.ar";
            CONFIG_SHARED.ABM.URL = "//local.abm.tcfautos.com.ar";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1745266399076401";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "085e61471f6f0181c0ae9066495b4e2d";
            CONFIG_SHARED.GOOGLE.API_KEY = CONFIG_SHARED_GOOGLE_API_KEY;
            CONFIG_SHARED.GOOGLE.CLIENT_ID = CONFIG_SHARED_GOOGLE_CLIENT_ID;
            CONFIG_SHARED.GOOGLE.CLIENT_SECRET = "M_Um09ttTKIeJrtyGPSKtoIc";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = CONFIG_SHARED_GOOGLE_RECAPTCHA_KEY;
            CONFIG_SHARED.LINKEDIN.CLIENT_ID = "78gsej3ow80j56";
            CONFIG_SHARED.LINKEDIN.CLIENT_SECRET = "dUqp6OH043DMN40G";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://qa.www.toyotacfa.com.ar/api/";
            CONFIG_SHARED.LINKS.ASK_LOAN = CONFIG_SHARED_LINKS_ASK_LOAN;
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = CONFIG_SHARED_LINKS_ASK_LOAN_COTIZADOR;
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = CONFIG_SHARED_LINKS_SIMULADOR_CREDITOS;
            CONFIG_SHARED.SEGUROS.ENV = "DEV";
            CONFIG_SHARED.SEGUROS.URL = "http://local.widgetseguros.tcfautos.codeicus.com.ar/sdk/sdk.js";
        }
        // Development QA.
        if (HOST === ENVS.DEV_QA) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "//qa.api.tcfautos.codeicus/index.php";
            CONFIG_SHARED.ABM.URL = "//qa.abm.tcfautos.codeicus";
            CONFIG_SHARED.API.IMG_SRC = "http://qa.resources.tcfautos.codeicus";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1779429392292463";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "295e950403db38d00ddf58e730efdfb6";
            CONFIG_SHARED.GOOGLE.API_KEY = CONFIG_SHARED_GOOGLE_API_KEY;
            CONFIG_SHARED.GOOGLE.CLIENT_ID = CONFIG_SHARED_GOOGLE_CLIENT_ID;
            CONFIG_SHARED.GOOGLE.CLIENT_SECRET = "M_Um09ttTKIeJrtyGPSKtoIc";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = CONFIG_SHARED_GOOGLE_RECAPTCHA_KEY;
            CONFIG_SHARED.LINKEDIN.CLIENT_ID = "78gsej3ow80j56";
            CONFIG_SHARED.LINKEDIN.CLIENT_SECRET = "dUqp6OH043DMN40G";
            CONFIG_SHARED.TCFA_NEWSLETTER = "http://qa.www.toyotacfa.codeicus/api/";
            CONFIG_SHARED.LINKS.ASK_LOAN = CONFIG_SHARED_LINKS_ASK_LOAN;
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = CONFIG_SHARED_LINKS_ASK_LOAN_COTIZADOR;
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = CONFIG_SHARED_LINKS_SIMULADOR_CREDITOS;
            CONFIG_SHARED.SEGUROS.ENV = "QA";
            CONFIG_SHARED.SEGUROS.URL = "http://qa.widgetseguros.tcfautos.codeicus/sdk/sdk.js";
        }
        // Development QA-2.
        if (HOST === ENVS.DEV_QA2) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "//qa2.api.tcfautos.codeicus/index.php";
            CONFIG_SHARED.ABM.URL = "//qa2.abm.tcfautos.codeicus";
            CONFIG_SHARED.API.IMG_SRC = "//qa2.resources.tcfautos.codeicus";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1745266399076401";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "085e61471f6f0181c0ae9066495b4e2d";
            CONFIG_SHARED.GOOGLE.API_KEY = CONFIG_SHARED_GOOGLE_API_KEY;
            CONFIG_SHARED.GOOGLE.CLIENT_ID = CONFIG_SHARED_GOOGLE_CLIENT_ID;
            CONFIG_SHARED.GOOGLE.CLIENT_SECRET = "M_Um09ttTKIeJrtyGPSKtoIc";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = CONFIG_SHARED_GOOGLE_RECAPTCHA_KEY;
            CONFIG_SHARED.LINKEDIN.CLIENT_ID = "78gsej3ow80j56";
            CONFIG_SHARED.LINKEDIN.CLIENT_SECRET = "dUqp6OH043DMN40G";
            CONFIG_SHARED.TCFA_NEWSLETTER = "http://test1.toyotacfa.com.ar/api/";
            CONFIG_SHARED.LINKS.ASK_LOAN = CONFIG_SHARED_LINKS_ASK_LOAN;
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = CONFIG_SHARED_LINKS_ASK_LOAN_COTIZADOR;
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = CONFIG_SHARED_LINKS_SIMULADOR_CREDITOS;
            CONFIG_SHARED.SEGUROS.ENV = "QA";
            CONFIG_SHARED.SEGUROS.URL = "http://qa.widgetseguros.tcfautos.codeicus/sdk/sdk.js";
        }
        // Testing 1.
        if (HOST === ENVS.TEST1) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "https://test1api.tcfautos.com.ar/index.php";
            CONFIG_SHARED.ABM.URL = "https://test1abm.tcfautos.com.ar";
            CONFIG_SHARED.API.IMG_SRC = "https://test1resources.tcfautos.com.ar";
            CONFIG_SHARED.LINKS.ASK_LOAN = "http://test1.toyotacfa.com.ar/ToyotaAppForm/Login/TCFAutos";
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = "http://test1.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal";
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = "http://test1.toyotacfa.com.ar/simulador-creditos";
            CONFIG_SHARED.LINKS.REGISTER_URL = "http://test1.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/registration.xhtml";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://test1.toyotacfa.com.ar/api/";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = "6Le7VBYUAAAAALIeBbiT2t3IHpYQjSZtVu8o_H7a";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1862730357276773";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "8d6879942780892a21ae81e27a1809fe";
            CONFIG_SHARED.SEGUROS.ENV = "TEST1";
            CONFIG_SHARED.SEGUROS.URL = "https://test1widgetseguros.tcfautos.com.ar/sdk/sdk.js";
        }
        // Testing2.
        if (HOST === ENVS.TEST2) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "https://test2api.tcfautos.com.ar/index.php";
            CONFIG_SHARED.ABM.URL = "https://test2abm.tcfautos.com.ar";
            CONFIG_SHARED.API.IMG_SRC = "https://test2resources.tcfautos.com.ar";
            CONFIG_SHARED.LINKS.ASK_LOAN = "http://test2.toyotacfa.com.ar/ToyotaAppForm/Login/TCFAutos";
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = "http://test2.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal";
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = "http://test2.toyotacfa.com.ar/simulador-creditos";
            CONFIG_SHARED.LINKS.REGISTER_URL = "http://test2.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/registration.xhtml";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://test2.toyotacfa.com.ar/api/";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = "6Le7VBYUAAAAALIeBbiT2t3IHpYQjSZtVu8o_H7a";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1862730357276773";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "8d6879942780892a21ae81e27a1809fe";
            CONFIG_SHARED.SEGUROS.ENV = "TEST2";
            CONFIG_SHARED.SEGUROS.URL = "https://test2widgetseguros.tcfautos.com.ar/sdk/sdk.js";
        }
        // Testing3.
        if (HOST === ENVS.TEST3) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "https://test3api.tcfautos.com.ar/index.php";
            CONFIG_SHARED.ABM.URL = "https://test3abm.tcfautos.com.ar";
            CONFIG_SHARED.API.IMG_SRC = "https://test3resources.tcfautos.com.ar";
            CONFIG_SHARED.LINKS.ASK_LOAN = "http://test3.toyotacfa.com.ar/ToyotaAppForm/Login/TCFAutos";
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = "http://test3.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal";
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = "http://test3.toyotacfa.com.ar/simulador-creditos";
            CONFIG_SHARED.LINKS.REGISTER_URL = "http://test3.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/registration.xhtml";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://test3.toyotacfa.com.ar/api/";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = "6Le7VBYUAAAAALIeBbiT2t3IHpYQjSZtVu8o_H7a";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1862730357276773";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "8d6879942780892a21ae81e27a1809fe";
            CONFIG_SHARED.SEGUROS.ENV = "TEST3";
            CONFIG_SHARED.SEGUROS.URL = "https://test3widgetseguros.tcfautos.com.ar/sdk/sdk.js";
        }
        // Testing4.
        if (HOST === ENVS.TEST4) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "https://test4api.tcfautos.com.ar/index.php";
            CONFIG_SHARED.ABM.URL = "https://test4abm.tcfautos.com.ar";
            CONFIG_SHARED.API.IMG_SRC = "https://test4resources.tcfautos.com.ar";
            CONFIG_SHARED.LINKS.ASK_LOAN = "http://test4.toyotacfa.com.ar/ToyotaAppForm/Login/TCFAutos";
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = "http://test4.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal";
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = "http://test4.toyotacfa.com.ar/simulador-creditos";
            CONFIG_SHARED.LINKS.REGISTER_URL = "http://test4.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/registration.xhtml";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://test4.toyotacfa.com.ar/api/";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = "6Le7VBYUAAAAALIeBbiT2t3IHpYQjSZtVu8o_H7a";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1862730357276773";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "8d6879942780892a21ae81e27a1809fe";
            CONFIG_SHARED.SEGUROS.ENV = "TEST4";
            CONFIG_SHARED.SEGUROS.URL = "https://test4widgetseguros.tcfautos.com.ar/sdk/sdk.js";
        }
        // TestingQA.
        if (HOST === ENVS.TEST_QA) {
            CONFIG_SHARED.SHOW_ISSUE_COLLECTOR = true;
            CONFIG_SHARED.API.HOST = "https://testqaapi.tcfautos.com.ar/index.php";
            CONFIG_SHARED.ABM.URL = "https://testqaabm.tcfautos.com.ar";
            CONFIG_SHARED.API.IMG_SRC = "https://testqaresources.tcfautos.com.ar";
            CONFIG_SHARED.LINKS.ASK_LOAN = "http://testqa.toyotacfa.com.ar/ToyotaAppForm/Login/TCFAutos";
            CONFIG_SHARED.LINKS.ASK_LOAN_COTIZADOR = "http://testqa.toyotacfa.com.ar/ToyotaAppForm/Login/MultiCanal";
            CONFIG_SHARED.LINKS.SIMULADOR_CREDITOS = "http://testqa.toyotacfa.com.ar/simulador-creditos";
            CONFIG_SHARED.LINKS.REGISTER_URL = "http://testqa.toyotacfa.com.ar/toyota-sop-web-cliente/faces/pages/login/registration.xhtml";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://testqa.toyotacfa.com.ar/api/";
            CONFIG_SHARED.GOOGLE.RECAPTCHA_KEY = "6Le7VBYUAAAAALIeBbiT2t3IHpYQjSZtVu8o_H7a";
            CONFIG_SHARED.FACEBOOK.APP_ID = "1862730357276773";
            CONFIG_SHARED.FACEBOOK.APP_SECRET = "8d6879942780892a21ae81e27a1809fe";
            CONFIG_SHARED.SEGUROS.ENV = "TESTQA";
            CONFIG_SHARED.SEGUROS.URL = "https://testqawidgetseguros.tcfautos.com.ar/sdk/sdk.js";
        }
        // Production.
        if (HOST === ENVS.PROD) {
            CONFIG_SHARED.API.HOST = "https://api.tcfautos.com.ar/index.php";
            CONFIG_SHARED.ABM.URL = "https://abm.tcfautos.com.ar";
            CONFIG_SHARED.API.MOCK = "https://www.tcfautos.com.ar/mock";
            CONFIG_SHARED.API.IMG_SRC = "https://resources.tcfautos.com.ar";
            // Analytics' Configuration.
            CONFIG_SHARED.GOOGLE.ANALITYCS = "UA-80208331-1";
            CONFIG_SHARED.TCFA_NEWSLETTER = "https://www.toyotacfa.com.ar/api/";
        }
        return CONFIG_SHARED;
    };
    TCFAAppConfigService.$inject = moduleConfig.TCFAAppConfigService.deps;
    module.factory(moduleConfig.TCFAAppConfigService.name, TCFAAppConfigService);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "termsConditionCheckerModule",
            deps: ['termsConditionsDaoModule'],
            termsAndConditionChecker: {
                name: "termsAndConditionChecker",
                deps: ['$uibModal', 'TCFAModalService', 'TermsConditionsDAO', '$q']
            }
        },
        module, termsAndConditionChecker;
    module = angular.module('TCFAutos');
    termsAndConditionChecker = function ($uibModal, TCFAModalService, TermsConditionsDAO, $q) {
        return {
            checkAndRequest: function (seccion) {
                return $q(function (resolve, reject) {
                    TermsConditionsDAO.isSeccionApproved({id: seccion}, function (data) {
                            if (data.aprobado) {
                                resolve({approved: true});
                                return;
                            }
                            $uibModal.open({
                                animation: true,
                                backdrop: 'static',
                                keyboard: false,
                                templateUrl: './views/component/modal/approve_term_modal.html',
                                controller: ['$rootScope', '$scope', '$location', '$route', '$uibModalInstance',
                                    function ($rootScope, $scope, $location, $route, $uibModalInstance) {
                                        $scope.termino = data.termino_condicion;
                                        $scope.fn = {};
                                        $scope.fn.aceptar = function () {
                                            TermsConditionsDAO.approveTerm({id: data.termino_condicion.id}, function () {
                                                $uibModalInstance.dismiss('aceptar');
                                                resolve({approved: true});
                                            });
                                        };
                                        $scope.fn.cancelar = function () {
                                            $uibModalInstance.dismiss('cancelar');
                                            $rootScope.userInfo = {};
                                            reject({approved: false});
                                            $location.path('/').search({});
                                            $route.reload();
                                        };
                                    }
                                ],
                                controllerAs: 'ctrl',
                                appendTo: angular.element('#modalComponent'),
                                bindToController: true
                            });
                        },
                        function (error) {
                            console.log(error);
                            TCFAModalService.showError({
                                error: {
                                    showModal: true,
                                    message: "No se han cargado los términos y condiciones por favor intente mas tarde.",
                                    title: "¡Ha ocurrido un error!"
                                }
                            });
                        });
                });
            }
        };
    };
    termsAndConditionChecker.$inject = moduleConfig.termsAndConditionChecker.deps;
    module.factory(moduleConfig.termsAndConditionChecker.name, termsAndConditionChecker);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "utilsModule",
            deps: [],
            UtilsHelper: {
                name: "UtilsHelper",
                deps: ['$window', '$location']
            }
        },
        module, UtilsHelper;
    module = angular.module('TCFAutos');
    UtilsHelper = function ($window, $location) {
        return {
            idCheckerWithTypeCoercion: function (id1, id2) {
                return parseInt(id1) === parseInt(id2);
            },
            isMobile: (function () {
                var mobileObj = {
                    windows: function () {
                        return /IEMobile/i.test($window.navigator.userAgent);
                    },
                    android: function () {
                        return /Android/i.test($window.navigator.userAgent);
                    },
                    blackBerry: function () {
                        return /BlackBerry/i.test($window.navigator.userAgent);
                    },
                    iOS: function () {
                        return /iPhone|iPad|iPod/i.test($window.navigator.userAgent);
                    },
                    any: function () {
                        return (mobileObj.android() || mobileObj.blackBerry() || mobileObj.iOS() || mobileObj.windows());
                    }
                };
                return mobileObj;
            }()),
            isOldIEVersion: function () {
                return /MSIE ([0-9]{1,}[\.0-9]{0,})/i.test($window.navigator.userAgent) || new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").test($window.navigator.userAgent);
            },
            getParamFromURL: function (paramName) {
                var searchStr = $window.location.search.substring(1);
                var keyValues = searchStr.split('&');
                for (var i = 0; i < keyValues.length; i++) {
                    var split = keyValues[i].split('=');
                    if (split[0] === paramName) {
                        return decodeURIComponent(split[1]);
                    }
                }
                return null;
            },
            getBaseURI: function () {
                var baseURI = $location.absUrl();
    
                // Remove the # and ? substrings
                baseURI = baseURI.replace(/(#.*|\?.*)/g, "");
    
                // Remove the trailing slash
                baseURI = baseURI.replace(/\/$/g, "");
    
                return baseURI;
            }
        };
    };
    UtilsHelper.$inject = moduleConfig.UtilsHelper.deps;
    module.factory(moduleConfig.UtilsHelper.name, UtilsHelper);
}());

(function () {
    "use strict";
    var moduleConfig = {
        name: "verificationCodeHandlerModule",
        deps: ['TCFAutos.services.UserDAOService'],
        verificationCodeHandler: {
            name: "verificationCodeHandler",
            deps: ['$uibModal', '$window', '$q', 'TCFAModalService', 'UserDAO', 'usSpinnerService']
        }
    },
        module, verificationCodeHandler;
    module = angular.module('TCFAutos');
    verificationCodeHandler = function ($uibModal, $window, $q, TCFAModalService, UserDAO, usSpinnerService) {
        return {
            showVerificationModal: function (scope) {
                return $q(function (resolve, reject) {
                    $uibModal.open({
                        animation: true,
                        backdrop: 'static',
                        keyboard: false,
                        templateUrl: "./views/component/modal/phone_verify_modal.html",
                        windowClass: "tcfa-modal tcfa-phone-modal",
                        controller: ["$scope", "$uibModalInstance",
                            function ($scope, $uibModalInstance) {
                                var vm = this;
                                vm.info = scope,
                                    $scope.verificationCode = "",
                                    $scope.codeSent = false,
                                    $scope.verificationId = null,
                                    $scope.resendEnabled = false,
                                    $scope.attempts = 0;

                                $scope.showErrorModal = function (message) {
                                    return TCFAModalService.showError({
                                        "error": {
                                            "showModal": true,
                                            "title": "Error al verificar teléfono",
                                            "message": message
                                        }
                                    });
                                };

                                $scope.sendCode = function () {
                                    usSpinnerService.spin("verify_code_spinner");

                                    UserDAO.sendVerificationCode({
                                        email: vm.info.email,
                                        telefono: vm.info.cellphone
                                    }, function (response) {
                                        usSpinnerService.stop("verify_code_spinner");
                                        $scope.codeSent = true;
                                        $scope.verificationId = response.data.id;
        
                                        $window.setTimeout(function () {
                                            $scope.resendEnabled = true;
                                        }, 1000 * 60 * 5);
                                    }, function (error) {
                                        $window.console.debug("Error al enviar SMS: ", error.message);
                                        usSpinnerService.stop("verify_code_spinner");
                                        $uibModalInstance.dismiss("cancel");
     
                                        reject({"sent": false, "verified": false, "error": error});
                                    });
                                };

                                $scope.resendCode = function () {
                                    if ($scope.resendEnabled) {
                                        $scope.resendEnabled = false;
                                        $scope.sendCode();
                                    } else {
                                        TCFAModalService.showWarning({
                                            "error": {
                                                "showModal": true,
                                                "title": "Reenviar código de verificación",
                                                "message": "Debe esperar 5 minutos entre cada envío para solicitar un nuevo código."
                                            }
                                        });
                                    }
                                };

                                $scope.close = function () {
                                    $uibModalInstance.dismiss("cancel");

                                    if (vm.info.closed && angular.isFunction(vm.info.closed)) {
                                        vm.info.closed();
                                    }
                                };

                                $scope.onSubmit = function () {
                                    if ($scope.verificationCode.trim().length != 4) {
                                        $scope.showErrorModal("Debe ingresar un código de verificación válido.");
                                        return false;
                                    }

                                    $scope.attempts++;
                                    usSpinnerService.spin("verify_code_spinner");

                                    UserDAO.checkVerificationCode({
                                        sms_id: $scope.verificationId,
                                        verification_code: $scope.verificationCode
                                    }, function (response) {
                                        usSpinnerService.stop("verify_code_spinner");

                                        if (response.data.verified) {
                                            $uibModalInstance.dismiss("cancel");
                                            resolve({ "sent": true, "verified": true });
                                        } else if ($scope.attempts == 3) {
                                            $scope.showErrorModal("Código de verificación incorrecto. Se ha alcanzado el máximo de intentos, " +
                                                "en breve recibirá un nuevo código de verificación.");
                                            $scope.attempts = 0;
                                            $scope.verificationCode = "";
                                            $scope.sendCode();
                                        } else {
                                            $scope.showErrorModal("Código de verificación incorrecto.");
                                        }
                                    }, function (error) {
                                        $window.console.debug("Error al verificar SMS:", error);
                                        usSpinnerService.stop("verify_code_spinner");
                                        $uibModalInstance.dismiss("cancel");

                                        reject({ "sent": true, "verified": false, "error": error });
                                    });
                                };

                                $scope.sendCode();
                            }
                        ],
                        controllerAs: "ctrl",
                        appendTo: angular.element("#modalComponent"),
                        bindToController: true
                    });
                });
            }
        };
    };
    verificationCodeHandler.$inject = moduleConfig.verificationCodeHandler.deps;
    module.factory(moduleConfig.verificationCodeHandler.name, verificationCodeHandler);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.ActionDAO",
            deps: ["ngResource"],
            ActionDAO: {
                name: "ActionDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, ActionDAO;
    module = angular.module("TCFAutos");
    ActionDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/action/", {}, {
            createAction: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/action/",
                transformRequest: function(data) {
                    data.tipo_aplicacion_id = 1;
                    return JSON.stringify(data);
                }
            },
        });
    };
    ActionDAO.$inject = moduleConfig.ActionDAO.deps;
    module.factory(moduleConfig.ActionDAO.name, ActionDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "authDaoModule",
            deps: ["ngResource"],
            AuthDAO: {
                name: "AuthDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, AuthDAO;
    module = angular.module("TCFAutos");
    AuthDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/auth/", {}, {
            login: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auth/login/"
            },
            loginConcesionario: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auth/concesionario/login"
            },
            navigateAs: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auth/navigateas/"
            },
            validateSession: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auth/validateSession/"
            },
            ValidateSessionTasa: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/toyotausados/abm/eval/tasa/"
            },
            passwordReset: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/user/password/reset/"
            },
            passwordChange: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/user/password/change/"
            }
        });
    };
    AuthDAO.$inject = moduleConfig.AuthDAO.deps;
    module.factory(moduleConfig.AuthDAO.name, AuthDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.BannerDAO",
            deps: ["ngResource", "ngFileUpload"],
            BannerDAO: {
                name: "BannerDAO",
                deps: ["$resource", "$q", "Upload", "CacheFactory", "TCFAAppConfigService"]
            }
        },
        module, BannerDAO,
        _SECCION_BANNERS_URL_PATH_SUFFIX = "/seccionbanner/";
    module = angular.module("TCFAutos");
    BannerDAO = function ($resource, $q, Upload, CacheFactory, TCFAAppConfigService) {
        var cacheBanners = CacheFactory.get("cacheBanners");
        if (!cacheBanners) {
            cacheBanners = CacheFactory("cacheBanners", {
                maxAge: 1800000, // =(30m * 60s * 1000ms).
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive" // Delete expired items.
            });
        }
        return $resource(TCFAAppConfigService.API.HOST + _SECCION_BANNERS_URL_PATH_SUFFIX, {}, {
            getPublicatedBanners: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/seccionbanner/publicated/",
                cache: cacheBanners
            },
            getBanner: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/seccionbanner/:id/",
                params: {id: "@id"}
            }
        });
    };
    BannerDAO.$inject = moduleConfig.BannerDAO.deps;
    module.factory(moduleConfig.BannerDAO.name, BannerDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "carDaoModule",
            deps: ["ngResource", "angular-cache"],
            CarDAO: {
                name: "CarDAO",
                deps: ["$window", "$http", "$resource", "TCFAAppConfigService", "CacheFactory"]
            }
        },
        module, CarDAO;
    module = angular.module("TCFAutos");
    CarDAO = function ($window, $http, $resource, TCFAAppConfigService, CacheFactory) {
        var __cacheCuotasData = CacheFactory.get("cacheCuotasData");
        if (!__cacheCuotasData) {
            __cacheCuotasData = CacheFactory("cacheCuotasData", {
                maxAge: (8 * 60 * 60 * 1000), // MS.
                cacheFlushInterval: (24 * 60 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        return $resource(TCFAAppConfigService.API.HOST + '/auto/', {}, {
            getAutoById: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/auto/:id/',
                params: {id: '@id'}
            },
            getOutstandingBrands: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/marca/destacadas/'
            },
            getModelsByBrand: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/modelo/:id/',
                params: {id: '@id'}
            },
            getVersionByModel: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/version/:id/',
                params: {id: '@id'}
            },
            searchCarByTitle: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/auto/search/'
            },
            autocompleteSearch: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/opciones_autocomplete/'
            },
            getSearchFilters: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/auto/search/filters/'
            },
            getCuotas: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/auto/:id/cuotas/',
                params: {id: '@id'}
            },
            getRelatedByDistributor: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/concesionario/:concesionario/related/',
                params: {concesionario: '@concesionario'}
            },
            getDatosCalculoCuotas: {
                method: 'GET',
                url: TCFAAppConfigService.API.HOST + '/cotizador/posicion_fiscal/',
                cache: __cacheCuotasData
            }
        });
    };
    CarDAO.$inject = moduleConfig.CarDAO.deps;
    module.factory(moduleConfig.CarDAO.name, CarDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.ConcesionariosDAO",
            deps: ["angular-cache", "ngFileUpload"],
            ConcesionariosDAO: {
                name: "ConcesionariosDAO",
                deps: ["$resource", "CacheFactory", "Upload", "TCFAAppConfigService"]
            }
        },
        module, ConcesionariosDAO;
    module = angular.module("TCFAutos");
    ConcesionariosDAO = function ($resource, CacheFactory, Upload, TCFAAppConfigService) {
        var baseURL = TCFAAppConfigService.API.HOST + "/concesionario",
            __cacheConcesionarios = CacheFactory.get("cacheConcesionarios");
        if (!__cacheConcesionarios) {
            __cacheConcesionarios = CacheFactory("cacheConcesionarios", {
                maxAge: (24 * 60 * 60 * 1000), // MS.
                cacheFlushInterval: (3 * 24 * 60 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        return $resource(baseURL, {id: "@id"}, {
            get: {
                method: "GET",
                params: {},
                transformResponse: function (response) {
                    return (angular.fromJson(response)).data || [];
                },
                isArray: true,
                cache: __cacheConcesionarios
            },
            getDestacados: {
                method: "GET",
                url: baseURL + "/map/:cuit",
                transformResponse: function (response) {
                    var data = angular.fromJson(response);
                    return data.data;
                },
                isArray: true
            }
        });
    };
    ConcesionariosDAO.$inject = moduleConfig.ConcesionariosDAO.deps;
    module.factory(moduleConfig.ConcesionariosDAO.name, ConcesionariosDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "contactsDaoModule",
            deps: ["ngResource"],
            ContactsDAO: {
                name: "ContactsDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, ContactsDAO;
    module = angular.module("TCFAutos");
    ContactsDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/consulta", {}, {
            consultaAuto: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auto/:auto_id/consulta/",
                params: {auto_id: "@auto_id"}
            },
            confirmarConsulta: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/consulta/confirmar/"
            }
        });
    };
    ContactsDAO.$inject = moduleConfig.ContactsDAO.deps;
    module.factory(moduleConfig.ContactsDAO.name, ContactsDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.CotizadorDAO",
            deps: ["ngResource"],
            CotizadorDAO: {
                name: "CotizadorDAO",
                deps: ["$resource", "TCFAAppConfigService", "CacheFactory"]
            }
        },
        module, CotizadorDAO;
    module = angular.module("TCFAutos");
    CotizadorDAO = function ($resource, TCFAAppConfigService, CacheFactory) {
        var __base_url = TCFAAppConfigService.API.HOST + "/cotizador/",
            __cacheCampaign = CacheFactory.get("cacheCampaign"),
            __cacheVehicleLoanInfoTCFAutos = CacheFactory.get("cacheVehicleLoanInfoTCFAutos"),
            __cacheAllTooltips = CacheFactory.get("cacheAllTooltips"),
            __cacheAllVehicleLogos = CacheFactory.get("cacheAllVehicleLogos"),
            __cacheVehicleImage = CacheFactory.get("cacheVehicleImage"),
            __cacheAllVehicleImages = CacheFactory.get("cacheAllVehicleImages"),
            __cacheCanalAnonimo = CacheFactory.get("cacheCanalAnonimo"),
            __cacheAllSections = CacheFactory.get("cacheAllSections");
        if (!__cacheCampaign) {
            __cacheCampaign = CacheFactory("cacheCampaign", {
                maxAge: (2 * 60 * 1000), // MS.
                cacheFlushInterval: (6 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive"
            });
        }
        if (!__cacheVehicleLoanInfoTCFAutos) {
            __cacheVehicleLoanInfoTCFAutos = CacheFactory("cacheVehicleLoanInfoTCFAutos", {
                maxAge: (10 * 60 * 1000), // MS.
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive"
            });
        }
        if (!__cacheAllTooltips) {
            __cacheAllTooltips = CacheFactory("cacheAllTooltips", {
                maxAge: (8 * 60 * 60 * 1000), // MS.
                cacheFlushInterval: (3 * 24 * 60 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        if (!__cacheAllVehicleLogos) {
            __cacheAllVehicleLogos = CacheFactory("cacheAllVehicleLogos", {
                maxAge: (10 * 60 * 1000), // MS.
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        if (!__cacheVehicleImage) {
            __cacheVehicleImage = CacheFactory("cacheVehicleImage", {
                maxAge: (10 * 60 * 1000), // MS.
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive"
            });
        }
        if (!__cacheAllVehicleImages) {
            __cacheAllVehicleImages = CacheFactory("cacheAllVehicleImages", {
                maxAge: (10 * 60 * 1000), // MS.
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        if (!__cacheCanalAnonimo) {
            __cacheCanalAnonimo = CacheFactory("cacheCanalAnonimo", {
                maxAge: (2 * 60 * 1000), // MS.
                cacheFlushInterval: (6 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive"
            });
        }
        if (!__cacheAllSections) {
            __cacheAllSections = CacheFactory("cacheAllSections", {
                maxAge: (16 * 60 * 60 * 1000), // MS.
                cacheFlushInterval: (2 * 24 * 60 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive",
                storageMode: "localStorage"
            });
        }
        return $resource(__base_url, {}, {
            getCampaign: {
                method: "GET",
                url: __base_url + "campania/:id/",
                cache: __cacheCampaign
            },
            getVehicleLoanInfoTCFAutos: {
                method: "POST",
                url: __base_url + "auto_tcfautos/",
                cache: __cacheVehicleLoanInfoTCFAutos,
                timeout: (3 * 1000 * 60)
            },
            newMessage: {
                method: "POST",
                url: __base_url + "consulta/"
            },
            getAllTooltips: {
                method: "GET",
                url: __base_url + "tooltip/",
                cache: __cacheAllTooltips,
                transformResponse: function (response) {
                    var __json_response = angular.fromJson(response);
                    if (__json_response && __json_response.data &&
                        (!!__json_response.data.length || (__json_response.data.length === 0))) {
                        return __json_response.data;
                    }
                    return [];
                },
                isArray: true
            },
            cotizar: {
                method: "POST",
                url: __base_url + "auto/cotizar"
            },
            cuadroDeMarcha: {
                method: "POST",
                url: __base_url + "auto/cuadro_de_marcha"
            },
            pdf: {
                method: "POST",
                url: __base_url + "pdf"
            },
            shareWithFriends: {
                method: "POST",
                url: __base_url + "compartir/amigo"
            },
            favoriteDelete: {
                method: "DELETE",
                url: __base_url + "favorito"
            },
            favoriteAdd: {
                method: "POST",
                url: __base_url + "favorito"
            },
            favoriteCheck: {
                method: "POST",
                url: __base_url + "favorito/check"
            },
            favoriteGetAll: {
                method: "GET",
                url: __base_url + "favorito"
            },
            getAllVehicleLogos: {
                method: "POST",
                url: __base_url + "imagen_logo_unidad/",
                cache: __cacheAllVehicleLogos,
                transformResponse: function (response) {
                    var __json_response = angular.fromJson(response);
                    if (__json_response && __json_response.data &&
                        (!!__json_response.data.length || (__json_response.data.length === 0))) {
                        return __json_response.data;
                    }
                    return [];
                },
                isArray: true
            },
            getAllVehicleImages: {
                method: "POST",
                url: __base_url + "imagen_unidad/",
                cache: __cacheAllVehicleImages,
                transformResponse: function (response) {
                    var __json_response = angular.fromJson(response);
                    if (__json_response && __json_response.data &&
                        (!!__json_response.data.length || (__json_response.data.length === 0))) {
                        return __json_response.data;
                    }
                    return [];
                },
                isArray: true
            },
            getCanalAnonimo: {
                method: "GET",
                url: __base_url + "canal_anonimo/:cuit",
                cache: __cacheCanalAnonimo
            },
            dateroLog: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/datero/log"
            },
            getAllSections: {
                method: "GET",
                url: __base_url + "seccion/",
                cache: __cacheAllSections,
                transformResponse: function (response) {
                    var __json_response = angular.fromJson(response);
                    if (__json_response && __json_response.data &&
                        (!!__json_response.data.length || (__json_response.data.length === 0))) {
                        return __json_response.data;
                    }
                    return [];
                },
                isArray: true
            },
            shareWithFriendOnFacebook: {
                method: "POST",
                url: __base_url + "compartir/facebook"
            },
            getVehicleVersionImage: {
                method: "GET",
                url: __base_url + "imagen_logo_unidad_version/:brand_id/:model_id/:version_id",
                cache: __cacheVehicleImage
            },
            getVersionXModelo: {
                method: "GET",
                url: __base_url + "vehiculo/versiones"
            },
            getPlazoDetalle: {
                method: "GET",
                url: __base_url + "plazos/:plazo/detalle"
            },
            getMarcaModeloByTipo: {
                method: "GET",
                url: __base_url + "vehiculo/marca_modelo"
            },
            getPlazos: {
                method: "GET",
                url: __base_url + "plazos"
            }
        });
    };
    CotizadorDAO.$inject = moduleConfig.CotizadorDAO.deps;
    module.factory(moduleConfig.CotizadorDAO.name, CotizadorDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.DestacadosDAO",
            deps: ["TCFAutos.services.PageNavigationService"],
            DestacadosDAO: {
                name: "DestacadosDAO",
                deps: ["$resource", "TCFAAppConfigService", "CacheFactory"]
            }
        },
        module, DestacadosDAO;
    module = angular.module("TCFAutos");
    DestacadosDAO = function ($resource, TCFAAppConfigService, CacheFactory) {
        var cacheDestacados = CacheFactory.get("cacheDestacados");
        if (!cacheDestacados) {
            cacheDestacados = CacheFactory("cacheDestacados", {
                maxAge: (10 * 60 * 1000), // MS.
                cacheFlushInterval: (30 * 60 * 1000), // MS.
                deleteOnExpire: "aggressive"
            });
        }
        return $resource(TCFAAppConfigService.API.HOST + "/auto/destacados/", {}, {
            getDestacados: {
                method: "GET",
                params: {},
                url: TCFAAppConfigService.API.HOST + "/auto/destacados/",
                isArray: false,
                cache: cacheDestacados
            },
            getRelacionados: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/auto/relacionados/",
                params: {},
                isArray: false
            },
            getPublicacionPorToken: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/publicacion/activacion/",
                params: {},
                isArray: false
            },
            activarPublicacion: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/publicacion/activacion/",
                params: {},
                isArray: false
            }
        });
    };
    DestacadosDAO.$inject = moduleConfig.DestacadosDAO.deps;
    module.factory(moduleConfig.DestacadosDAO.name, DestacadosDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "favoritoDaoModule",
            deps: ["ngResource"],
            FavoritoDAO: {
                name: "FavoritoDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, FavoritoDAO;
    module = angular.module("TCFAutos");
    FavoritoDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/favorito", {}, {
            getAll: {method: "GET"},
            delete: {
                method: "DELETE",
                url: TCFAAppConfigService.API.HOST + "/favorito/:id_publicacion/"
            },
            isFavorito: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/favorito/check/:id_publicacion/"
            }
        });
    };
    FavoritoDAO.$inject = moduleConfig.FavoritoDAO.deps;
    module.factory(moduleConfig.FavoritoDAO.name, FavoritoDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "locationDaoModule",
            deps: [],
            LocationDAO: {
                name: "LocationDAO",
                deps: ["$window", "$http", "DependencyInjectionService", "TCFAAppConfigService"]
            }
        },
        module, LocationDAO;
    module = angular.module("TCFAutos");
    LocationDAO = function ($window, $http, DependencyInjectionService, TCFAAppConfigService) {
        return {
            getAllProvincias: function (callback) {
                $http.get(TCFAAppConfigService.API.HOST + "/provincia", {method: "GET"}).success(function (data) {
                    callback(data.data);
                });
            },
            apiGoogleMapsLocalidades: function (address, callback) {
                var doCall = function () {
                    var returnData = [],
                        geocoder = new $window.google.maps.Geocoder();
                    geocoder.geocode({
                        address: address,
                        componentRestrictions: {country: "AR"}
                    }, function (data, status) {
                        var i, m;
                        if (status === $window.google.maps.GeocoderStatus.OK) {
                            m = data.length;
                            for (i = 0; i < m; i = i + 1) {
                                returnData[i] = {
                                    id: data[i].place_id,
                                    formatted_address: data[i].formatted_address,
                                    location: {
                                        lat: data[i].geometry.location.lat(),
                                        lng: data[i].geometry.location.lng()
                                    },
                                    address_components: data[i].address_components
                                };
                            }
                            callback(returnData);
                        } else {
                            $window.console.debug("Geocode was not successful for the following reason: " + status);
                            $window.alert("No se encontraron resultados para la ubicación ingresada. Por favor, ingrese una nueva búsqueda.");
                        }
                    });
                };
                DependencyInjectionService.loadApi("GoogleMaps").then(function () {
                    doCall();
                }, function () {
                    $window.console.log("Error al cargar la api de GMaps");
                });
            }
        };
    };
    LocationDAO.$inject = moduleConfig.LocationDAO.deps;
    module.factory(moduleConfig.LocationDAO.name, LocationDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.PublicationDAO",
            deps: ["ngResource"],
            PublicationDAO: {
                name: "PublicationDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, PublicationDAO;
    module = angular.module("TCFAutos");
    PublicationDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/publicacion", {}, {
            get: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/calendariopublicacion/"
            },
            agregarAFavoritos: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/publicacion/:pubId/favorito/",
                params: {pubId: "@pubId"}
            },
            sharePublication: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auto/:publicacion_id/compartir/amigo",
                params: {publicacion_id: "@publicacion_id"}
            },
            sharePublicationByFacebook: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/auto/:publicacion_id/compartir/facebook",
                params: {publicacion_id: "@publicacion_id"}
            }
        });
    };
    PublicationDAO.$inject = moduleConfig.PublicationDAO.deps;
    module.factory(moduleConfig.PublicationDAO.name, PublicationDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.SessionDAO",
            deps: ["ngResource"],
            SessionDAO: {
                name: "SessionDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, SessionDAO;
    module = angular.module("TCFAutos");
    SessionDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/session/", {}, {
            createSession: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/session/",
                avoidInterceptor: true,
                transformRequest: function(data) {
                    data.tipo_aplicacion_id = 1;
                    return JSON.stringify(data);
                }
            },
            updateSession: {
                method: "PATCH",
                url: TCFAAppConfigService.API.HOST + "/session/",
                transformRequest: function(data) {
                    data.tipo_aplicacion_id = 1;
                    return JSON.stringify(data);
                }
            }
        });
    };
    SessionDAO.$inject = moduleConfig.SessionDAO.deps;
    module.factory(moduleConfig.SessionDAO.name, SessionDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "socialModule",
            deps: ["ngResource", "TCFAutos.services.PageNavigationService", "TCFAutos.services.NotificationsService"],
            AuthSocial: {
                name: "AuthSocial",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, AuthSocial;
    module = angular.module("TCFAutos");
    AuthSocial = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/auth/social/login", {}, {login: {method: "POST"}});
    };
    AuthSocial.$inject = moduleConfig.AuthSocial.deps;
    module.factory(moduleConfig.AuthSocial.name, AuthSocial);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.StatsDAO",
            deps: ["ngResource"],
            StatsDAO: {
                name: "StatsDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, StatsDAO;
    module = angular.module("TCFAutos");
    StatsDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.API.HOST + "/stats/", {}, {
            postPediTuPrestamo: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/stats/ask/loan/"
            }
        });
    };
    StatsDAO.$inject = moduleConfig.StatsDAO.deps;
    module.factory(moduleConfig.StatsDAO.name, StatsDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.TCFANewsletterDAO",
            deps: ["ngResource"],
            TCFANewsletterDAO: {
                name: "TCFANewsletterDAO",
                deps: ["$resource", "TCFAAppConfigService"]
            }
        },
        module, TCFANewsletterDAO;
    module = angular.module("TCFAutos");
    TCFANewsletterDAO = function ($resource, TCFAAppConfigService) {
        return $resource(TCFAAppConfigService.TCFA_NEWSLETTER, {}, {
            adherir: {
                method: "POST",
                url: TCFAAppConfigService.TCFA_NEWSLETTER + "Adherir"
            },
            desadherir: {
                method: "POST",
                url: TCFAAppConfigService.TCFA_NEWSLETTER + "Desadherir"
            },
            verificacionDeEmail: {
                method: "POST",
                url: TCFAAppConfigService.TCFA_NEWSLETTER + "VerificacionEmail"
            }
        });
    };
    TCFANewsletterDAO.$inject = moduleConfig.TCFANewsletterDAO.deps;
    module.factory(moduleConfig.TCFANewsletterDAO.name, TCFANewsletterDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "termsConditionsDaoModule",
            deps: [],
            TermsConditionsDAO: {
                name: "TermsConditionsDAO",
                deps: ["$window", "$http", "TCFAAppConfigService"]
            }
        },
        module, TermsConditionsDAO;
    module = angular.module("TCFAutos");
    TermsConditionsDAO = function ($window, $http, TCFAAppConfigService) {
        return {
            isSeccionApproved: function (sendData, callback, callbackError) {
                $http.get(TCFAAppConfigService.API.HOST + "/seccion/" + sendData.id + "/isapproved/", sendData).success(function (data/*, status*/) {
                    callback(data.data);
                }).error(function (error) {
                    if (callbackError) {
                        callbackError(error);
                    }
                });
            },
            approveTerm: function (sendData, callback) {
                $http.post(TCFAAppConfigService.API.HOST + "/terminocondicion/" + sendData.id + "/", sendData).success(function (data/*, status*/) {
                    callback(data.data);
                });
            },
            getSeccion: function (id, callback, callbackError) {
                $http.get(TCFAAppConfigService.API.HOST + "/seccion/" + id + "/").success(function (data/*, status*/) {
                    callback(data.data);
                }).error(function () {
                    if (callbackError) {
                        callbackError();
                    }
                });
            }
        };
    };
    TermsConditionsDAO.$inject = moduleConfig.TermsConditionsDAO.deps;
    module.factory(moduleConfig.TermsConditionsDAO.name, TermsConditionsDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.services.UserDAOService",
            deps: ["ngResource", "ngFileUpload"],
            UserDAO: {
                name: "UserDAO",
                deps: ["$resource", "$q", "Upload", "TCFAAppConfigService"]
            }
        },
        module, UserDAO;
    module = angular.module("TCFAutos");
    UserDAO = function ($resource, $q, Upload, TCFAAppConfigService) {
        var resource = $resource(TCFAAppConfigService.API.HOST + "/usuario/desuscribir/", {}, {
            unsubcribeCliente: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/desuscribir/"
            },
            checkUserName: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/check/email/"
            },
            checkSMTPEmail: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/check/smtpemail/"
            },
            registerClient: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/register",
                params: {data: "@data"}
            },
            confirmClient: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/confirm",
                params: {token: "@token"}
            },
            confirmEmailAlternativo: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/emailalternativo/confirm",
                params: {token: "@token"}
            },
            patchProfile: {
                method: "PATCH",
                url: TCFAAppConfigService.API.HOST + "/user/me/"
            },
            patchProfileTasa: {
                method: "PATCH",
                url: TCFAAppConfigService.API.HOST + "/toyotausados/usuario/profile/"
            },
            getMensajes: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/user/consulta/"
            },
            mensajeVisto: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/respuesta/:id/vista/",
                params: {id: "@id"}
            },
            getMensajesSinLeer: {
                method: "GET",
                url: TCFAAppConfigService.API.HOST + "/user/messages/"
            },
            validatePhone: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/telefono/validar/"
            },
            sendVerificationCode: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/telefono/verification/send/"
            },
            checkVerificationCode: {
                method: "POST",
                url: TCFAAppConfigService.API.HOST + "/usuario/telefono/verification/check/"
            }
        });
        resource.postProfileImage = function (image) {
            var defered = $q.defer();
            Upload.upload({
                url: TCFAAppConfigService.API.HOST + "/user/me/",
                data: {profile_image: image}, //pass file as data, should be user ng-model
                timeout: 1800000
            }).then(function (resp) { //upload function returns a promise
                defered.resolve(resp);
            }, function (resp) { //catch error
                defered.reject(resp);
            }, function (evt) {
                window.console.log("Img Upl %" + evt);
            });
            return defered.promise;
        };
        return resource;
    };
    UserDAO.$inject = moduleConfig.UserDAO.deps;
    module.factory(moduleConfig.UserDAO.name, UserDAO);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.DefaultImage",
            deps: [],
            defImgSrc: {
                name: "defImgSrc",
                deps: []
            }
        },
        module, defImgSrc;
    module = angular.module("TCFAutos");
    defImgSrc = function () {
        return {
            link: function (scope, element, attrs) {
                element.bind("error", function () {
                    if (attrs.src !== attrs.defImgSrc) {
                        attrs.$set("src", attrs.defImgSrc);
                    }
                });
            }
        };
    };
    defImgSrc.$inject = moduleConfig.defImgSrc.deps;
    module.directive(moduleConfig.defImgSrc.name, defImgSrc);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.BottomBar",
            deps: [],
            TcfaBottomBarController: {
                name: "TcfaBottomBarController",
                deps: ["$scope"]
            },
            tcfaBottomBar: {
                name: "tcfaBottomBar",
                deps: []
            }
        },
        module, TcfaBottomBarController, tcfaBottomBar;
    module = angular.module("TCFAutos");
    TcfaBottomBarController = function ($scope) {
        var vm = this;
        vm.close = function () {
            $scope.showBottomBar = false;
        };
    };
    tcfaBottomBar = function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {showBottomBar: "=showBottomBar"},
            template: "<div class=\"bottom-navbar\" data-ng-if=\"showBottomBar\"><div class=\"col-xs-12 col-sm-12 col-lg-12 col-md-12 margin-cero\" ng-transclude></div></div>",
            controller: "TcfaBottomBarController",
            controllerAs: "ctrl"
        };
    };
    TcfaBottomBarController.$inject = moduleConfig.TcfaBottomBarController.deps;
    module.controller(moduleConfig.TcfaBottomBarController.name, TcfaBottomBarController);
    tcfaBottomBar.$inject = moduleConfig.tcfaBottomBar.deps;
    module.directive(moduleConfig.tcfaBottomBar.name, tcfaBottomBar);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.checkPattern",
            deps: [],
            tcfaCheckPattern: {
                name: "tcfaCheckPattern",
                deps: []
            }
        },
        module, tcfaCheckPattern;
    module = angular.module("TCFAutos");
    tcfaCheckPattern = function () {
        return {
            require: "ngModel",
            transclude: false,
            scope: {tcfaCheckPattern: "=tcfaCheckPattern"},
            link: function (scope, element, attributes, ngModel) {
                var i, regx;
                ngModel.$validators.tcfaCheckPattern = function (modelValue) {
                    if (modelValue) {
                        if (scope.tcfaCheckPattern instanceof Array) {
                            for (i = scope.tcfaCheckPattern.length - 1; i >= 0; i--) {
                                regx = new RegExp(scope.tcfaCheckPattern[i]);
                                if (regx.test(modelValue)) {
                                    return true;
                                }
                            }
                            return false;
                        } else {
                            regx = new RegExp(scope.tcfaCheckPattern);
                            return regx.test(modelValue);
                        }
                    } else {
                        return true;
                    }
                };
            }
        };
    };
    tcfaCheckPattern.$inject = moduleConfig.tcfaCheckPattern.deps;
    module.directive(moduleConfig.tcfaCheckPattern.name, tcfaCheckPattern);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.tcfaCutCopyPastePrevent",
            deps: [],
            tcfaCutCopyPastePrevent: {
                name: "tcfaCutCopyPastePrevent",
                deps: []
            }
        },
        module, tcfaCutCopyPastePrevent;
    module = angular.module("TCFAutos");
    tcfaCutCopyPastePrevent = function () {
        return {
            //scope: {},
            link: function (scope, element) {
                element.on("cut copy paste", function (event) {
                    event.preventDefault();
                });
            }
        };
    };
    tcfaCutCopyPastePrevent.$inject = moduleConfig.tcfaCutCopyPastePrevent.deps;
    module.directive(moduleConfig.tcfaCutCopyPastePrevent.name, tcfaCutCopyPastePrevent);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "directivesModule",
            deps: [],
            directiveA: {
                name: "numericFormatter",
                deps: ["$filter", "$timeout"]
            },
            directiveB: {
                name: "tcfaNumericInput",
                deps: []
            },
            directiveC: {
                name: "ctModel",
                deps: []
            },
            directiveD: {
                name: "ctYoutubeVideo",
                deps: ["$window"]
            },
            directiveF: {
                name: "tcOnKeyPress",
                deps: []
            },
            directiveG: {
                name: "ctYoutubeImage",
                deps: ["TCFAAppConfigService"]
            },
            filterA: {
                name: "camelCase",
                deps: []
            }
        },
        module, directiveA, directiveB, directiveC, directiveD, directiveF, directiveG, filterA;
    module = angular.module("TCFAutos");
    // Numeric formatter.
    directiveA = function ($filter, $timeout) {
        return {
            require: "?ngModel",
            link: function (scope, elem, attrs, controller) {
                var displayAsNumber, saveAsNumber, appendStr, transform, addMiles;
                transform = function (val) {
                    var finalStr;
                    if (attrs.currency) {
                        finalStr = $filter("currency")(val, "$", "0");
                    } else if (attrs.unit) {
                        finalStr = $filter("number")(val);
                        if (attrs.unit === 'km' && attrs.newCar === 'false' && finalStr === "0") {
                            finalStr = "Kilómetros";
                        }
                    } else {
                        finalStr = $filter("number")(val, "0");
                    }
                    if (finalStr && finalStr !== 'Kilómetros' && appendStr) {
                        finalStr = finalStr + appendStr;
                    }
                    return finalStr;
                };
                addMiles = function (value) {
                    if (!value) {
                        return null;
                    }
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                };
                if (attrs.unit) {
                    appendStr = " " + attrs.unit;
                }
                // Formatter. Changes how model values will appear in the view.
                displayAsNumber = function (modelValue) {
                    if (!controller.$isEmpty(modelValue)) {
                        return transform(modelValue);
                    }
                    return transform(0);
                };
                // Parser. Change how view values will be saved in the model.
                saveAsNumber = function (inputValue) {
                    if (!controller.$isEmpty(inputValue)) {
                        return parseInt(inputValue.replace(/[^\d]/g, ""), 10);
                    }
                    return 0;
                };
                controller.$formatters.push(displayAsNumber);
                controller.$parsers.push(saveAsNumber);
                elem.on("focus", function () {
                    controller.$viewValue = addMiles(controller.$modelValue);
                    controller.$render();
                });
                elem.on("blur", function () {
                    controller.$viewValue = transform(controller.$modelValue);
                    controller.$render();
                });
                elem.on("keydown", function (event) {
                    var __key_code = (event.keyCode || event.which);
                    $timeout(function () {
                        controller.$viewValue = addMiles(controller.$modelValue);
                        controller.$render();
                        if (__key_code === 13) { // <Enter> key.
                            elem.blur();
                        }
                    });
                });
            }
        };
    };
    // Directiva para validar NUMEROS.
    directiveB = function () {
        return {
            require: "?ngModel",
            link: function (scope, elem, attrs, controller) {
                var transformedInput, onlyNumbersFunc = function (inputValue) {
                    if (typeof inputValue === "undefined") {
                        return "";
                    }
                    transformedInput = inputValue.replace(/[^0-9]/g, "");
                    if (transformedInput !== inputValue) {
                        controller.$modelValue = parseInt(transformedInput);
                        controller.$commitViewValue();
                        controller.$setViewValue(transformedInput);
                        controller.$render();
                    }
                    return parseInt(transformedInput);
                };
                controller.$parsers.push(onlyNumbersFunc);
            }
        };
    };
    //directiva para comprar inputs
    directiveC = function () {
        return {
            require: "ngModel",
            transclude: false,
            scope: {
                ctModel: "=ctModel",
                ctMethod: "@ctMethod",
                ctValidato: "@ctValidato"
            },
            link: function (scope, element, attributes, ngModel) {
                if (!scope.ctValidato) {
                    scope.ctValidato = "compareTo";
                }
                ngModel.$validators[scope.ctValidato] = function (modelValue) {
                    if (scope.ctMethod) {
                        if (scope.ctMethod === "=") {
                            return modelValue === scope.ctModel;
                        }
                        if (scope.ctMethod === ">") {
                            return modelValue > scope.ctModel;
                        }
                        if (scope.ctMethod === ">=") {
                            return modelValue >= scope.ctModel;
                        }
                        if (scope.ctMethod === "<") {
                            return modelValue < scope.ctModel;
                        }
                        if (scope.ctMethod === "<=") {
                            return modelValue <= scope.ctModel;
                        }
                    } else {
                        return modelValue === scope.ctModel;
                    }
                };
                scope.$watch("ctModel", function () {
                    ngModel.$validate();
                });
            }
        };
    };
    // Directiva YouTube.
    directiveD = function ($window) {
        return {
            restrict: "E",
            scope: {
                height: "@",
                width: "@",
                videoid: "=",
                yturl: "=",
                ytvideo: "="
            },
            template: "<div></div>",
            link: function (scope, element, attrs) {
                var __yt_player, __current_video_id,
                    __first_script_tag = $window.document.getElementsByTagName("script")[0],
                    __new_script_tag = $window.document.createElement("script"),
                    /** YouTube player onError event handler. */
                    __onPlayerError = function (event) { // NOTE: this event is not firing due to a current bug in YouTube embed API.
                        var __error_code = event.data;
                        if ((__error_code === 100) || (__error_code === 101) || (__error_code === 150)) {
                            scope.ytvideo.valid = false;
                        }
                    },
                    /** Loads a video on the directive element. */
                    __videoInit = function () {
                        if (!$window.YT || !$window.YT.Player) {
                            return;
                        } // Skip init until YouTube API is loaded.
                        __yt_player = new $window.YT.Player(element.children()[0], {
                            playerVars: {
                                autoplay: 0,
                                html5: 1,
                                theme: "light",
                                modesbranding: 0,
                                color: "white",
                                iv_load_policy: 3,
                                showinfo: 1,
                                controls: 1,
                                rel: 0 // No related videos after video playback.
                            },
                            height: "375px",
                            width: "100%",
                            videoId: __current_video_id,
                            events: {onError: __onPlayerError}
                        });
                    },
                    /** Parses and validates a YouTube URL. Returns the inner YT video ID or NULL. */
                    __getVideoIdFromYTUrl = function (yt_url) {
                        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
                            match = yt_url.match(regExp);
                        if (match && (match[7].length === 11)) {
                            return match[7];
                        }
                        return null;
                    },
                    /** Updates YouTube embed Player video ID. */
                    __updatePlayerVideoId = function (video_id) {
                        // Only update video initial ID if there is no player loaded yet.
                        if (!__yt_player) {
                            __current_video_id = video_id;
                            __videoInit();
                            return;
                        }
                        // Player loaded and "video_id" is NULL. Clear video player reproduction.
                        if (!video_id) {
                            __yt_player.stopVideo();
                            __yt_player.clearVideo();
                            element.css("display", "none");
                            scope.ytvideo.valid = false;
                            return;
                        }
                        // Player loaded and video_id not NULL. Update video only if "video_id" has changed.
                        if (video_id !== __current_video_id) {
                            __current_video_id = video_id;
                            __yt_player.cueVideoById(__current_video_id);
                        }
                        // Always display the video if "video_id" is not NULL and correct length.
                        if (video_id.length === 11) {
                            scope.ytvideo.valid = true;
                            element.css("display", "block");
                        }
                    };
                // Add YouTube script to main window.
                __new_script_tag.src = "//www.youtube.com/iframe_api";
                __first_script_tag.parentNode.insertBefore(__new_script_tag, __first_script_tag);
                // Watch video ID changes on scope.
                if (attrs.yturl) {
                    scope.$watch("yturl", function (new_yturl) {
                        __updatePlayerVideoId(__getVideoIdFromYTUrl(new_yturl));
                    });
                } else {
                    scope.$watch("videoid", function (new_video_id) {
                        __updatePlayerVideoId(new_video_id);
                    });
                }
                // Call YouTube player embed API.
                $window.onYouTubeIframeAPIReady = function () {
                    __videoInit();
                };
                // Cleanup.
                scope.$on("$destroy", function () {
                    if (__yt_player) {
                        __yt_player.destroy();
                        __yt_player = null;
                        __current_video_id = null;
                    }
                });
            }
        };
    };
    directiveF = function () {
        return {
            require: "^form",
            link: function (scope, element, attrs, ctrl) {
                element.bind("keydown keypress", function (event) {
                    var keyCode = event.which || event.keyCode,
                        code = 13; //Default is ENTER
                    if (attrs.keyCode) {
                        code = attrs.keyCode;
                    }
                    // If enter key is pressed
                    if (keyCode === code && ctrl.$valid) {
                        scope.$apply(function () {
                            // call blur in the input
                            element[0].blur();
                            // Evaluate the expression
                            scope.$eval(attrs.tcOnKeyPress);
                        });
                        event.preventDefault();
                    }
                });
            }
        };
    };
    //directiva imagen youtube
    directiveG = function (TCFAAppConfigService) {
        return {
            restrict: "AC",
            scope: {
                height: "@",
                width: "@",
                videoid: "=",
                yturl: "="
            },
            link: function (scope, element, attrs) {
                var finalvideoid,
                    getIdFromUrl = function (url) {
                        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
                            match = url.match(regExp);

                        if (match && match[7].length === 11) {
                            return match[7];
                        }
                        return null;
                    },
                    youtubeImageRepo = TCFAAppConfigService.YOUTUBE.IMAGE_REPO,
                    defImage = 1; // Default google thumbnails go from 1 to 4.
                if (scope.yturl) {
                    finalvideoid = getIdFromUrl(scope.yturl);
                } else {
                    finalvideoid = scope.videoid;
                }
                attrs.$set("src", youtubeImageRepo + finalvideoid + "/" + defImage + ".jpg");
            }
        };
    };
    // Filtros.
    filterA = function () {
        return function (input) {
            input = input || "";
            return input.replace(/\w*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };
    };
    directiveA.$inject = moduleConfig.directiveA.deps;
    module.directive(moduleConfig.directiveA.name, directiveA);
    directiveB.$inject = moduleConfig.directiveB.deps;
    module.directive(moduleConfig.directiveB.name, directiveB);
    directiveC.$inject = moduleConfig.directiveC.deps;
    module.directive(moduleConfig.directiveC.name, directiveC);
    directiveD.$inject = moduleConfig.directiveD.deps;
    module.directive(moduleConfig.directiveD.name, directiveD);
    directiveF.$inject = moduleConfig.directiveF.deps;
    module.directive(moduleConfig.directiveF.name, directiveF);
    directiveG.$inject = moduleConfig.directiveG.deps;
    module.directive(moduleConfig.directiveG.name, directiveG);
    filterA.$inject = moduleConfig.filterA.deps;
    module.filter(moduleConfig.filterA.name, filterA);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.Dropdown",
            deps: [],
            tcfaDropdownController: {
                name: "tcfaDropdownController",
                deps: ["$scope"]
            },
            directive: {
                name: "tcfaDropdownDirective",
                deps: []
            }
        },
        module, tcfaDropdownController, directive;
    module = angular.module("TCFAutos");
    tcfaDropdownController = function ($scope) {
        var vm = this;
        vm.changeValue = function (value) {
            $scope.modelValue = value;
            if ($scope.onValueChange) {
                $scope.onValueChange(value);
            }
        };
        vm.getDescription = function (value) {
            if (value) {
                return $scope.showDescription(value);
            }
            return null;
        };
        vm.getTooltip = function (value) {
            if (value && $scope.showTooltip) {
                return $scope.showTooltip(value);
            }
            return null;
        };
        vm.enableTooltip = typeof $scope.showTooltip !== "undefined";
    };
    directive = function () {
        return {
            restrict: "E",
            require: "ngModel",
            transclude: true,
            scope: {
                modelValue: "=ngModel",
                onValueChange: "=onValueChange",
                showDescription: "=showDescription",
                showTooltip: "=showTooltip",
                list: "=list",
                ngDisabled: "=ngDisabled"
            },
            templateUrl: "/views/component/tcfadropdown.html",
            controller: "tcfaDropdownController",
            controllerAs: "ctrl"
        };
    };
    tcfaDropdownController.$inject = moduleConfig.tcfaDropdownController.deps;
    module.controller(moduleConfig.tcfaDropdownController.name, tcfaDropdownController);
    directive.$inject = moduleConfig.directive.deps;
    module.directive(moduleConfig.directive.name, directive);
    return module;
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.TCFAElementFadeOn",
            deps: [],
            directive: {
                name: "tcfaElementFadeOn",
                deps: []
            }
        },
        module, tcfaElementFadeOn;
    module = angular.module("TCFAutos");
    tcfaElementFadeOn = function () {
        return {
            restrict: "A",
            transclude: true,
            scope: {
                fadeOnElement: "@",
                fadeOnAction: "@"
            },
            template: '<div data-ng-class="{\'hide-component\':!tcfaElementFadeOn.showElement , \'show-component\':tcfaElementFadeOn.showElement}"><ng-transclude></ng-transclude></div>',
            controller: ["$scope", "$element", "$window", "$timeout",
                function ($scope, $element, $window, $timeout) {
                    var vm = this,
                        _s = $scope,
                        showOn = _s.fadeOnAction || "after",
                        inView = function (elem) {
                            var docViewTop, docViewBottom, elemTop, elemBottom,
                                belowElement, upElement, response = false;
                            docViewTop = $window.pageYOffset;
                            docViewBottom = docViewTop + $(window).height();
                            elemTop = elem.offsetTop;
                            elemBottom = elemTop + elem.offsetHeight + $element[0].clientHeight;
                            belowElement = (elemBottom <= docViewBottom);
                            upElement = (elemTop >= docViewTop);
                            switch (showOn) {
                                case "after":
                                    response = belowElement;
                                    break;
                                case "before":
                                    response = upElement;
                                    break;
                                case "between":
                                    response = belowElement && upElement;
                                    break;
                            }
                            return response;
                        },
                        showObject = function () {
                            var fadeOnElement = angular.element("#" + _s.fadeOnElement)[0];
                            if (fadeOnElement) {
                                vm.showElement = !!inView(fadeOnElement);
                                $timeout(function () {
                                    _s.$apply();
                                });
                            }
                        };
                    vm.showElement = false;
                    if (_s.fadeOnElement && $window.getComputedStyle($element[0]).display !== "none") {
                        $timeout(function () {
                            var win = angular.element($window), elem = angular.element("#" + _s.fadeOnElement)[0];
                            win.bind("scroll", function () {
                                showObject();
                            });
                            $scope.$watch(function () {
                                return elem.offsetTop + elem.offsetHeight;
                            }, function () {
                                showObject();
                            });
                        });
                    }
                }
            ],
            controllerAs: "tcfaElementFadeOn"
        };
    };
    tcfaElementFadeOn.$inject = moduleConfig.directive.deps;
    module.directive(moduleConfig.directive.name, tcfaElementFadeOn);
    return module;
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.tcfaPagination",
            deps: ["TCFAutos.controllers.TcfaPaginationCtrl"],
            tcfaPagination: {
                name: "tcfaPagination",
                deps: []
            }
        },
        module, tcfaPagination;
    module = angular.module("TCFAutos");
    tcfaPagination = function () {
        return {
            restrict: "A",
            replace: false,
            templateUrl: "./views/component/pagination/tcfapagination.html",
            scope: {
                disablePagination: "<",
                firstPageIndex: "<",
                lastPageIndex: "<",
                currentPageIndex: "<",
                maxPageDisplay: "<",
                onPageChange: "<"
            },
            controller: "TcfaPaginationCtrl",
            controllerAs: "tcfaPagination"
        };
    };
    tcfaPagination.$inject = moduleConfig.tcfaPagination.deps;
    module.directive(moduleConfig.tcfaPagination.name, tcfaPagination);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.TopBarCarResume",
            deps: [],
            directive: {
                name: "tcfaTopBarCarResume",
                deps: []
            }
        },
        module, directive;
    directive = function () {
        return {
            restrict: "A",
            replace: false,
            scope: {
                image: "=",
                quota: "=",
                info: "=",
                url: "=",
                showQuotaBlock: "@",
                askLoan: "=",
                _image: "@",
                _url: "@",
                _info: "@",
                _quota: "@"
            },
            templateUrl: "./views/detalle_publicacion/component/tcfatopbarcarresume.html",
            controller: ["$scope", "$attrs", "$element", "$window", "$timeout",
                function ($scope, $attrs, $element, $window, $timeout) {
                    var _s = $scope;
                    var vm = this;
                    vm._image = _s.image;
                    vm._url = _s.url;
                    vm._info = _s.info;
                    vm.showQuotaBlock = false;
                    vm._quota = _s.quota;
                    $timeout(function () {
                        angular.element($window).bind("scroll", function () {
                            var quotaElement = angular.element("#quotaBlock")[0],
                                quotaBlockPositionY;
                            if (quotaElement) {
                                quotaBlockPositionY = parseInt(quotaElement.scrollHeight);
                                if (parseInt(this.pageYOffset) >= quotaBlockPositionY) {
                                    vm.showQuotaBlock = true;
                                    vm._quota = _s.quota;
                                } else {
                                    vm.showQuotaBlock = false;
                                }
                                _s.$apply();
                            }
                        });
                    });
                    vm.askLoan = function () {
                        _s.askLoan(vm._quota);
                    };
                }
            ],
            controllerAs: "tcfaTopBarCarResume"
        };
    };
    module = angular.module("TCFAutos");
    directive.$inject = moduleConfig.directive.deps;
    module.directive(moduleConfig.directive.name, directive);
    return module;
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.ZoomImage",
            deps: ["utilsModule"],
            directive: {
                name: "tcfaZoomImage",
                deps: ["UtilsHelper"]
            }
        },
        module, directive;
    directive = function (UtilsHelper) {
        return {
            restrict: 'AC',
            scope: {showZoom: "=showZoom"},
            link: function (scope, element, attrs) {
                if (UtilsHelper.isOldIEVersion()) {
                    //For older versions of IE, we create the is Integer function.
                    Number.isInteger = Number.isInteger || function (value) {
                        return typeof value === "number" &&
                            isFinite(value) &&
                            Math.floor(value) === value;
                    };
                }
                var zoomElement;
                element[0].src = attrs.ngSrc;
                angular.element(element[0]).bind('load', function () {
                    scope.$watch(function () {
                        return scope.showZoom;
                    }, function () {
                        if (scope.showZoom === true && !zoomElement) {
                            zoomElement = $(element).ezPlus({
                                zoomWindowWidth: element[0].width / 2,
                                zoomWindowHeight: element[0].height / 2,
                                lenszoom: true,
                                lensSize: 200,
                                easing: true,
                                minZoomLevel: 2,
                                preloading: 0,
                                zIndex: 1001
                                //attrImageZoomSrc: attrs.ngSrc
                            });
                        }
                    });
                });
            }
        };
    };
    module = angular.module("TCFAutos");
    directive.$inject = moduleConfig.directive.deps;
    module.directive(moduleConfig.directive.name, directive);
    return module;
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.directives.tcSlider",
            deps: ["TCFAutos.controllers.CarSliderCtrl"],
            tcSlider: {
                name: "tcSlider",
                deps: []
            }
        },
        module, tcSlider;
    module = angular.module("TCFAutos");
    tcSlider = function () {
        return {
            restrict: "E",
            priority: 0,
            transclude: true,
            scope: {
                carsFilter: "=filter",
                onLoad: "=onSliderItemsLoad",
                loadItems: "=loadItems",
                onItemClick: "=onItemClick",
                goToItem: "=goToItem",
                fullView: "=fullView",
                showMore: "=showMore",
                maxListItems: "=maxListItems",
                sliderSpinnerKey: "@sliderSpinnerKey"
            },
            templateUrl: "./views/publication/component/filtered_cars_slider.html",
            controller: "CarSliderCtrl",
            controllerAs: "carSlider"
        };
    };
    tcSlider.$inject = moduleConfig.tcSlider.deps;
    module.directive(moduleConfig.tcSlider.name, tcSlider);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.modals.NewsletterUnsubscribeCtrl",
            deps: [],
            NewsletterUnsubscribeCtrl: {
                name: "NewsletterUnsubscribeCtrl",
                deps: ["$scope", "$uibModalInstance", "TCFANewsletter"]
            }
        },
        module, NewsletterUnsubscribeCtrl;
    module = angular.module("TCFAutos");
    NewsletterUnsubscribeCtrl = function ($scope, $uibModalInstance, TCFANewsletter) {
        var controller = this;
        controller.emailPattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
        controller.aceptar = function () {
            $uibModalInstance.close(controller.newsletterEmail);
            TCFANewsletter.desadherirNewsletter(controller.newsletterEmail);
        };
        controller.close = function () {
            $uibModalInstance.dismiss('close');
        };
    };
    NewsletterUnsubscribeCtrl.$inject = moduleConfig.NewsletterUnsubscribeCtrl.deps;
    module.controller(moduleConfig.NewsletterUnsubscribeCtrl.name, NewsletterUnsubscribeCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.modals.ShareWithFriendsCtrl",
            deps: [],
            ShareWithFriendsCtrl: {
                name: "ShareWithFriendsCtrl",
                deps: ["$window", "$rootScope", "$scope", "$location", "$route", "$uibModalInstance", "TCFAAppConfigService",
                    "PublicationDAO", "TCFAModalService", "UtilsHelper", "CotizadorDAO", "UserDAO"]
            }
        },
        module, ShareWithFriendsCtrl;
    module = angular.module("TCFAutos");
    ShareWithFriendsCtrl = function ($window, $rootScope, $scope, $location, $route, $uibModalInstance, TCFAAppConfigService,
                                     PublicationDAO, TCFAModalService, UtilsHelper, CotizadorDAO, UserDAO) {
        var controller = this;
        controller.errors = {
            emailsmtpcheckfailed: null,
            emailamigosmtpcheckfailed: null
        };
        controller.captchaSize = (UtilsHelper.isMobile.any() ? "compact" : "normal");
        controller.model = {};
        if ($rootScope.userInfo.user) {
            controller.model.nombre = $rootScope.userInfo.user.nombre;
            controller.model.email = $rootScope.userInfo.user.email;
        }
        controller.close = function () {
            $uibModalInstance.dismiss("cerrar");
        };
        controller.checkEmailInput = function () {
            UserDAO.checkSMTPEmail({email: controller.model.email}).$promise.then(function () {
                controller.errors.emailsmtpcheckfailed = false;
            }, function () {
                controller.errors.emailsmtpcheckfailed = true;
            });
        };
        controller.checkEmailAmigoInput = function () {
            UserDAO.checkSMTPEmail({email: controller.model.emailAmigo}).$promise.then(function () {
                controller.errors.emailamigosmtpcheckfailed = false;
            }, function () {
                controller.errors.emailamigosmtpcheckfailed = true;
            });
        };
        controller.onFail = function () {
            controller.disableSend = false;
            TCFAModalService.showError({
                error: {
                    showModal: true,
                    message: "Ha ocurrido un error al procesar la solicitud, por favor intente nuevamente en unos instantes.",
                    title: "¡Ha ocurrido un error!"
                }
            });
        };
        controller.send = function () {
            controller.model.publicacion_id = $scope.publication_id;
            controller.disableSend = true;
            PublicationDAO.sharePublication(controller.model).$promise.then(function () {
                controller.disableSend = false;
                $uibModalInstance.dismiss("cerrar");
                var info = {
                    warning: {
                        showModal: true,
                        message: ["¡Muchas Gracias!"],
                        title: "El mensaje se envío con éxito."
                    },
                    showBtn: false
                };
                TCFAModalService.showModal(info, "./views/detalle_publicacion/info/modal/success_modal_share_with_friends.html");
            }, controller.onFail);
        };
        /** Sends the insurance data by e-mail. */
        controller.sendInsurance = function () {
            var __data = $scope.insurance_data;
            controller.disableSend = true;
            CotizadorDAO.shareWithFriends({
                nombre: controller.model.nombre,
                email: controller.model.email,
                nombreAmigo: controller.model.nombreAmigo,
                emailAmigo: controller.model.emailAmigo,
                mensaje: controller.model.mensaje,
                marca: __data.vehicle_brand_name,
                marcaId: __data.vehicle_brand,
                modelo: __data.vehicle_model_name,
                modeloId: __data.vehicle_model,
                version: __data.vehicle_version_name,
                year: __data.vehicle_year,
                kms: 0,
                is0km: (__data.vehicle_condition === 1),
                price: __data.vehicle_price,
                link: $scope.share_url
            }).$promise.then(function () {
                controller.disableSend = false;
                $uibModalInstance.dismiss("cerrar");
                TCFAModalService.showModal({
                    warning: {
                        showModal: true,
                        message: ["¡Muchas Gracias!"],
                        title: "El mensaje se envío con éxito."
                    },
                    showBtn: false
                }, "./views/detalle_publicacion/info/modal/success_modal_share_with_friends.html");
                // Datero Log event 11: "Compartir"
                CotizadorDAO.dateroLog({
                    lt: 11,
                    vb: __data.vehicle_brand,
                    vm: __data.vehicle_model,
                    vv: __data.vehicle_version,
                    ce: controller.model.email,
                    App: "Cotizador",
                    EsCertificado: false
                });
            }, controller.onFail);
        };
        controller.grecaptchaKey = TCFAAppConfigService.GOOGLE.RECAPTCHA_KEY;
    };
    ShareWithFriendsCtrl.$inject = moduleConfig.ShareWithFriendsCtrl.deps;
    module.controller(moduleConfig.ShareWithFriendsCtrl.name, ShareWithFriendsCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.CarSliderCtrl",
            deps: ["TCFAutos.services.DestacadosDAO"],
            CarSliderCtrl: {
                name: "CarSliderCtrl",
                deps: ["$scope", "$window", "$location", "usSpinnerService", "DestacadosDAO", "CarSliderService", "TCFAAppConfigService"]
            }
        },
        module, CarSliderCtrl;
    module = angular.module("TCFAutos");
    CarSliderCtrl = function ($scope, $window, $location, usSpinnerService, DestacadosDAO, CarSliderService, TCFAAppConfigService) {
        var vm = this, promise, loadData;

        vm.sliderSpinnerKey = $scope.sliderSpinnerKey;
        vm.startSpinner = !angular.isUndefined($scope.sliderSpinnerKey);
        usSpinnerService.spin(vm.sliderSpinnerKey);

        vm.interval = -1;
        vm.no_wrap = false;
        vm.active = 0;
        vm.slides = [];
        vm.imagesSource = TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.AUTO_IMAGE_FOLDER;
        vm.data = [];
        vm.cars = [];
        vm.fullView = !(($scope.fullView === undefined) || ($scope.fullView === false));
        vm.isSearching = false;

        CarSliderService.updateRes(vm, $window, vm.fullView);

        var defaultSearch = function (filters) {
            return DestacadosDAO.getDestacados(filters).$promise;
        };

        loadData = function () {
            usSpinnerService.spin(vm.sliderSpinnerKey);
            vm.isSearching = true;
            if ($scope.loadItems) {
                promise = $scope.loadItems($scope.carsFilter);
            } else {
                promise = defaultSearch($scope.carsFilter);
            }

            promise.then(function (response) {
                CarSliderService.prepareSlide(vm, response.data, vm.fullView);
                if ($scope.showMore && $scope.showMore === true) {
                    if ($scope.maxListItems) {
                        vm.showMore = $scope.maxListItems === response.data.length;
                    } else {
                        vm.showMore = true;
                    }
                } else {
                    vm.showMore = false;
                }
                vm.isSearching = false;
                usSpinnerService.stop(vm.sliderSpinnerKey);
                vm.onLoad(response);
            });
        };

        $scope.$watch(function () {
            return $scope.loadItems;
        }, function () {
            loadData();
        });

        $window.onresize = function () {
            CarSliderService.updateRes(vm, this, vm.fullView);
        };

        vm.mouseEnterItem = function (item) {
            item.hover = true;
        };
        vm.mouseLeaveItem = function (item) {
            item.hover = false;
        };
        vm.onLoad = function (data) {
            if ($scope.onLoad) {
                $scope.onLoad(data);
            }
        };

        vm.goToItem = function (obj) {
            $location.path("/cars/" + obj.id + "/").search({concesionario: obj.publicacion.concesionario.nombre});
        };
        if ($scope.goToItem) {
            vm.goToItem = $scope.goToItem;
        }
        vm.goTo = function (obj) {
            if ($scope.onItemClick) {
                $scope.onItemClick(obj);
            }
            vm.goToItem(obj);
        };
        vm.showMoreLink = function () {
            delete $scope.carsFilter.removeId;
            $location.path("/search/").search($scope.carsFilter);
        };
    };
    CarSliderCtrl.$inject = moduleConfig.CarSliderCtrl.deps;
    module.controller(moduleConfig.CarSliderCtrl.name, CarSliderCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.ClientConfirmCtrl",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.UserDAOService"],
            ClientConfirmCtrl: {
                name: "ClientConfirmCtrl",
                deps: ["$window", "$rootScope", "$injector", "AuthDAO", "PageNavigationService", "TCFAModalService", "UserDAO", "$routeParams"]
            }
        },
        module, ClientConfirmCtrl;
    module = angular.module("TCFAutos");
    ClientConfirmCtrl = function ($window, $rootScope, $injector, AuthDAO, PageNavigationService, TCFAModalService, UserDAO, $routeParams) {
        var vm = this, userDAOMethod, modalInfo, showSuccessModal,
            token = $routeParams.tokenid,
            $location = $injector.get("$location"),
            $timeout = $injector.get("$timeout"),
            usSpinnerService = $injector.get("usSpinnerService");

        vm.errors = {
            success: false,
            alreadyConfirmed: false,
            error: false
        };

        showSuccessModal = function () {
            modalInfo = {warning: {}};
            modalInfo.warning.showModal = true;
            modalInfo.warning.message = ["¡Exito! Tu cuenta ha sido confirmada, ya puedes entrar y disfrutar de nuestros servicios.", "Muchas gracias."];
            modalInfo.warning.title = "Confirmación exitosa!";
            modalInfo.warning.showBtn = true;
            modalInfo.warning.btnText = "Cerrar";
            TCFAModalService.showOk(modalInfo);
        };

        usSpinnerService.spin("confirm_user");

        if ($location.path().indexOf("/client/emailalternativo/confirm") === 0) {
            userDAOMethod = UserDAO.confirmEmailAlternativo;
        } else {
            userDAOMethod = UserDAO.confirmClient;
        }

        userDAOMethod({token: token}).$promise.then(function (res) {
            usSpinnerService.stop("confirm_user");
            if (res.data.done === true) {
                $location.path("/");
                showSuccessModal();
            } else {
                vm.errors.error = true;
                $timeout(function () {
                    $location.path("/");
                }, 10000);
            }
        }).catch(function (response) {
            usSpinnerService.stop("confirm_user");
            if (response.status === 500) {
                vm.errors.error = true;
            } else {
                vm.errors.alreadyConfirmed = true;
            }
            $timeout(function () {
                $location.path("/");
            }, 10000);
        });
    };
    ClientConfirmCtrl.$inject = moduleConfig.ClientConfirmCtrl.deps;
    module.controller(moduleConfig.ClientConfirmCtrl.name, ClientConfirmCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.ClientRegisterCtrl",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.UserDAOService", "verificationCodeHandlerModule"],
            ClientRegisterCtrl: {
                name: "ClientRegisterCtrl",
                deps: ["$window", "$rootScope", "$route", "AuthDAO", "PageNavigationService", "TCFAModalService", "verificationCodeHandler", "UserDAO", "$injector", "$uibModal"]
            }
        },
        module, ClientRegisterCtrl;
    module = angular.module("TCFAutos");
    ClientRegisterCtrl = function ($window, $rootScope, $route, AuthDAO, PageNavigationService, TCFAModalService, verificationCodeHandler, UserDAO, $injector, $uibModal) {
        var vm = this, $location = $injector.get("$location"),
            showSuccessModal,
            modalInfo,
            usSpinnerService = $injector.get("usSpinnerService"),
            TCFAAppConfigService = $injector.get("TCFAAppConfigService");
        PageNavigationService.showNavBar = true;
        PageNavigationService.showNewsletterSubscription = false;

        vm.nameRegex = /(?=.\d)?(?=.*[A-zñÑ])(?!.*[\$\&\%\#\"\/\\\?\¡\¿\!\=\+\*\{\}\-_,\.\'\[\]\^]).{1,}$/;
        vm.register = {
            name: "",
            last: "",
            email: "",
            reemail: "",
            cellphone_prefix: "",
            cellphone: "",
            full_cellphone: "",
            pass: "",
            repass: "",
            error: "",
            user_type: "cliente"
        };
        vm.errors = {
            emailexistnative: false,
            emailexisttcfa: false,
            formError: false,
            loading: false,
            success: false,
            emailsmtpcheckfailed: false,
            cellphonecheckfailed: false
        };

        var copyOfRegister = angular.copy(vm.register);
        var copyOfErrors = angular.copy(vm.errors);

        vm.tcfauserlostpassword = TCFAAppConfigService.LINKS.TCFA_LOST_PASSWORD;

        vm.resetValues = function () {
            vm.register = copyOfRegister;
            vm.errors = copyOfErrors;
            vm.form_register.$setPristine();
            vm.form_register.$setUntouched();
        };

        vm.detectLoadingError = function (k) {
            if (k !== "loading") {
                vm.errors[k] = false;
            }
        };

        vm.doCheckSmtp = function () {
            usSpinnerService.spin("register_client");
            Object.keys(vm.errors).forEach(vm.detectLoadingError);
            vm.disableAceptar = true;
            UserDAO.checkSMTPEmail({email: vm.register.email}).$promise.then(function () {
                vm.errors.emailsmtpcheckfailed = false;
                usSpinnerService.stop("register_client");
                vm.disableAceptar = false;
                vm.doCheckClient();
            }, function () {
                vm.errors.emailsmtpcheckfailed = true;
                usSpinnerService.stop("register_client");
                vm.disableAceptar = false;
            });
        };

        vm.doCheckClient = function () {
            usSpinnerService.spin("register_client");
            Object.keys(vm.errors).forEach(function (k) {
                if ("form" !== k) {
                    vm.errors[k] = false;
                }
            });
            vm.disableAceptar = true;
            UserDAO.checkUserName({email: window.base64.encode(vm.register.email)}).$promise.then(function (res) {
                if (res.data.email.done === false) {
                    if (res.data.email.native) {
                        vm.errors.emailexistnative = true;
                    } else {
                        vm.errors.emailexisttcfa = true;
                    }
                    vm.disableAceptar = false;
                    usSpinnerService.stop("register_client");
                } else {
                    vm.errors.loading = true;
                    vm.disableAceptar = false;
                    vm.doCheckCellphone();
                }
            }, function () {
                usSpinnerService.stop("register_client");
                vm.errors.formError = true;
                vm.disableAceptar = false;
            });
        };

        vm.doCheckCellphone = function () {
            UserDAO.validatePhone({
                telefono: vm.register.full_cellphone
            }, function (response) {
                vm.register.cellphone_prefix = "0" + response.data.codigo_area;
                vm.register.cellphone = response.data.telefono;
                vm.register.full_cellphone = $window.parseInt("" + response.data.codigo_area + response.data.telefono);
                usSpinnerService.stop("register_client");
                var verificationCodePromise = verificationCodeHandler.showVerificationModal({
                    "email": vm.register.email,
                    "cellphone": vm.register.full_cellphone
                });
                
                verificationCodePromise.then(function () {
                    vm.doRegisterClient();
                }).catch(function (error) {
                    vm.errors.cellphonecheckfailed = true;
                    vm.disableAceptar = false;
                    usSpinnerService.stop("register_client");
                    TCFAModalService.showError({
                        "error": {
                            "showModal": true,
                            "title": "Error al verificar teléfono",
                            "message": "Por favor intente nuevamente."
                        }
                    });
                });
            }, function () {
                vm.errors.cellphonecheckfailed = true;
                vm.disableAceptar = false;
                usSpinnerService.stop("register_client");
            });
        };

        showSuccessModal = function () {
            modalInfo = {warning: {}};
            modalInfo.warning.showModal = true;
            modalInfo.warning.message = ["Su registro se efectuó satisfactoriamente, en breve recibirá un correo electrónico," +
            " con la información de confirmación de su registro.", " Muchas gracias."];
            modalInfo.warning.title = "Registro exitoso!";
            modalInfo.warning.showBtn = true;
            modalInfo.warning.btnText = "Cerrar";
            TCFAModalService.showOk(modalInfo).result.then(function () {
                $location.path("/");
            }, function () {
                $location.path("/");
            });
        };

        vm.doCancel = function () {
            $location.path("/");
        };

        vm.doRegisterClient = function () {
            Object.keys(vm.errors).forEach(vm.detectLoadingError);
            usSpinnerService.spin("register_client");
            vm.register.pass = window.base64.encode(vm.register.pass);
            vm.register.repass = window.base64.encode(vm.register.repass);
            vm.disableAceptar = true;
            UserDAO.registerClient({data: vm.register}).$promise.then(function (res) {
                usSpinnerService.stop("register_client");
                if (res.data.done === true) {
                    vm.resetValues();
                    showSuccessModal();
                    vm.register.user_type = "cliente";
                    vm.disableAceptar = false;
                } else {
                    vm.resetValues();
                }
            }, function () {
                usSpinnerService.stop("register_client");
                vm.errors.success = false;
                vm.errors.formError = true;
                vm.errors.loading = false;
                vm.register.pass = "";
                vm.register.repass = "";
                vm.disableAceptar = false;
            });
        };

        vm.showAgainForm = function () {
            Object.keys(vm.errors).forEach(function (k) {
                vm.errors[k] = false;
            });
        };
    };
    ClientRegisterCtrl.$inject = moduleConfig.ClientRegisterCtrl.deps;
    module.controller(moduleConfig.ClientRegisterCtrl.name, ClientRegisterCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.CotizadorCtrl",
            deps: ["TCFAutos.controllers.Cotizador_SeccionCotizadorCtrl", "TCFAutos.controllers.Cotizador_SeccionPlazosCtrl",
                "TCFAutos.controllers.Cotizador_SeccionVideoCtrl", "TCFAutos.controllers.Cotizador_SeccionConsultaCtrl",
                "TCFAutos.services.PageNavigationService", "TCFAutos.directives.BottomBar", "TCFAutos.directives.tcSlider"],
            CotizadorCtrl: {
                name: "CotizadorCtrl",
                deps: ["PageNavigationService"]
            }
        },
        module, CotizadorCtrl;
    module = angular.module("TCFAutos");
    CotizadorCtrl = function (PageNavigationService) {
        PageNavigationService.showNavBar = false;
        PageNavigationService.showNewsletterSubscription = true;
        PageNavigationService.showLoginMenu = true;
    };
    CotizadorCtrl.$inject = moduleConfig.CotizadorCtrl.deps;
    module.controller(moduleConfig.CotizadorCtrl.name, CotizadorCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Cotizador_SeccionConsultaCtrl",
            deps: ["vcRecaptcha", "TCFAutos.services.CotizadorDAO"],
            Cotizador_SeccionConsultaCtrl: {
                name: "Cotizador_SeccionConsultaCtrl",
                deps: ["$rootScope", "$scope", "TCFAAppConfigService", "UtilsHelper", "DependencyInjectionService", "CotizadorDAO",
                    "$uibModal", "UserDAO", "$timeout"]
            }
        },
        module, Cotizador_SeccionConsultaCtrl;
    module = angular.module("TCFAutos");
    Cotizador_SeccionConsultaCtrl = function ($rootScope, $scope, TCFAAppConfigService, UtilsHelper, DependencyInjectionService, CotizadorDAO,
                                              $uibModal, UserDAO, $timeout) {
        var vm = this,
            __modal_consulta = null;

        vm.errors = {
            emailsmtpcheckfailed: null
        };

        vm.onNewMessage = function () {
            var __scope_modal_consulta = $scope.$new(true),
                __recaptcha_promise = DependencyInjectionService.loadApi("GoogleRecaptcha"),
                __openNewMessageModal = function () {
                    $rootScope.fixScreen();
                    __modal_consulta = $uibModal.open({
                        animation: true,
                        windowClass: "tcfa-modal",
                        openedClass: "modal-open cotizador-modal-consulta",
                        backdrop: true,
                        keyboard: true,
                        templateUrl: "./views/cotizador/modal/modal_consulta.html",
                        controller: ["$scope", "$uibModalInstance", "$rootScope", "TCFAAppConfigService", "UtilsHelper",
                            function ($scope, $uibModalInstance, $rootScope, TCFAAppConfigService, UtilsHelper) {
                                $scope.disable_submit = false;
                                $scope.emailsmtpcheckfailed = null;

                                $scope.message = {
                                    name: "",
                                    mail: "",
                                    text: ""
                                };

                                $scope.message_max_length = 400;
                                $scope.message_left_length = $scope.message_max_length;

                                $scope.captcha_key = TCFAAppConfigService.GOOGLE.RECAPTCHA_KEY;
                                $scope.captcha_size = (UtilsHelper.isMobile.any() && "compact" || "normal");

                                if ($rootScope.userInfo && $rootScope.userInfo.user) {
                                    if ($rootScope.userInfo.user.nombre || $rootScope.userInfo.user.apellido) {
                                        __scope_modal_consulta.message.name = ($rootScope.userInfo.user.nombre + " " + $rootScope.userInfo.user.apellido).trim();
                                    }
                                    if ($rootScope.userInfo.user.email) {
                                        __scope_modal_consulta.message.mail = $rootScope.userInfo.user.email;
                                    }
                                }

                                $scope.onChangeMessageText = function () {
                                    $scope.message_left_length = $scope.message_max_length - (($scope.message.text && $scope.message.text.length) || 0);
                                };

                                $scope.checkEmailInput = function () {
                                    UserDAO.checkSMTPEmail({
                                        email: $scope.message.mail
                                    }).$promise.then(function () {
                                        $scope.emailsmtpcheckfailed = false;
                                    }, function () {
                                        $scope.emailsmtpcheckfailed = true;
                                    });
                                };

                                // Close modal.
                                $scope.close = function () {
                                    $uibModalInstance.dismiss("close");
                                };

                                $scope.onSubmit = function () {
                                    var __loading_modal = null,
                                        __error_message = "",
                                        __email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                                    if (($scope.message.name.trim() === "") || ($scope.message.mail.trim() === "") || ($scope.message.text.trim() === "")) {
                                        __error_message = "Todos los campos son obligatorios.";
                                    }
                                    if (!__email_regex.test($scope.message.mail.trim().toLowerCase())) {
                                        __error_message = "Debe ingresar un e-mail válido.";
                                    }
                                    if ($scope.message.text.trim().length > $scope.message_max_length) {
                                        __error_message = "El mensaje no puede exceder los " + $scope.message_max_length + " caracteres.";
                                    }
                                    if (__error_message !== "") {
                                        $rootScope.showErrorModal(__error_message, $scope.$new(true));
                                        return false;
                                    }

                                    $scope.disable_submit = true;
                                    __loading_modal = $rootScope.showLoadingModal("Enviando consulta. Por favor espere.", $scope.$new(true));
                                    CotizadorDAO
                                        .newMessage({
                                            consulta: {
                                                nombre: $scope.message.name.trim(),
                                                texto: $scope.message.text.trim(),
                                                email: $scope.message.mail.trim()
                                            },
                                            operacion: {
                                                tipo_operacion_id: ($rootScope.cotizador.operation_type && $rootScope.cotizador.operation_type.id),
                                                tipo_persona_id: ($rootScope.cotizador.person_type && $rootScope.cotizador.person_type.id),
                                                condicion_impositiva_id: ($rootScope.cotizador.tax_condition && $rootScope.cotizador.tax_condition.id) || null,
                                                cuit_dealer: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit),
                                                vehiculo_nuevo: ($rootScope.cotizador.vehicle_condition_new && 1 || 0),
                                                anio: $rootScope.cotizador.vehicle_year,
                                                tipo_vehiculo_id: $rootScope.cotizador.vehicle_type,
                                                marca_id: ($rootScope.cotizador.vehicle_brand && $rootScope.cotizador.vehicle_brand.id),
                                                marca_descripcion: ($rootScope.cotizador.vehicle_brand && $rootScope.cotizador.vehicle_brand.name),
                                                modelo_id: ($rootScope.cotizador.vehicle_model && $rootScope.cotizador.vehicle_model.id),
                                                modelo_descripcion: ($rootScope.cotizador.vehicle_model && $rootScope.cotizador.vehicle_model.descripcion),
                                                version_id: ($rootScope.cotizador.vehicle_version && $rootScope.cotizador.vehicle_version.id),
                                                version_descripcion: ($rootScope.cotizador.vehicle_version && $rootScope.cotizador.vehicle_version.descripcion),
                                                vehiculo_id: $rootScope.cotizador.vehicle_id,
                                                plan_id: ($rootScope.cotizador.plan && $rootScope.cotizador.plan.id),
                                                plan_descripcion: ($rootScope.cotizador.plan && $rootScope.cotizador.plan.name),
                                                subplan_id: ($rootScope.cotizador.installment && $rootScope.cotizador.installment.id),
                                                subplan_descripcion: ($rootScope.cotizador.installment && $rootScope.cotizador.installment.descripcion),
                                                canon_anticipado: ($rootScope.cotizador.canon && $rootScope.cotizador.canon.key),
                                                monto_financiar: $rootScope.cotizador.loan_amount
                                            }
                                        }, function () {
                                            var __success_modal;

                                            __loading_modal.dismiss("close");
                                            $scope.disable_submit = false;

                                            $scope.message.text = "";
                                            $scope.onChangeMessageText();

                                            __success_modal = $rootScope.showAlertModal("Su consulta se ha enviado exitosamente.", $scope.$new(true));
                                            __success_modal.closed.then(function () {
                                                __modal_consulta.dismiss("success");
                                            });
                                        }, function (error) {
                                            var __error_message = "Ha ocurrido un error. Intente nuevamente en unos minutos.";

                                            __loading_modal.dismiss("error");
                                            $scope.disable_submit = false;

                                            if (error.data.errors) {
                                                if (!(error.data.errors.message instanceof Array)) {
                                                    __error_message = error.data.errors.message;
                                                } else {
                                                    __error_message = error.data.errors.message.join(" ");
                                                }
                                            }

                                            $rootScope.showErrorModal(__error_message, $scope.$new(true));
                                        });
                                };

                                // MODAL INITIALIZATION.
                                $scope.onChangeMessageText();
                            }],
                        controllerAs: "modalConsulta",
                        bindToController: true,
                        scope: __scope_modal_consulta,
                        appendTo: angular.element("#modalComponent")
                    });

                    __modal_consulta.closed.then(function () {
                        $rootScope.unfixScreen();
                    });
                };

            __recaptcha_promise.then(function () {
                $timeout(function () {
                    __openNewMessageModal();
                }, 100);
            });
        };
    };
    Cotizador_SeccionConsultaCtrl.$inject = moduleConfig.Cotizador_SeccionConsultaCtrl.deps;
    module.controller(moduleConfig.Cotizador_SeccionConsultaCtrl.name, Cotizador_SeccionConsultaCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Cotizador_SeccionCotizadorCtrl",
            deps: ["TCFAutos.services.CotizadorDAO", "TCFAutos.services.CotizadorService", "carDaoModule", "TCFAutos.services.ParametrosService",
                "directivesModule"],
            Cotizador_SeccionCotizadorCtrl: {
                name: "Cotizador_SeccionCotizadorCtrl",
                deps: ["$window", "$timeout", "$location", "$q", "$routeParams", "$rootScope", "$scope", "$uibModal", "$route",
                    "CotizadorDAO", "CarDAO", "ParametrosService", "CotizadorService", "TCFAAppConfigService", "facebookService",
                    "DependencyInjectionService", "ConcesionariosDAO", "$filter"]
            }
        },
        module, Cotizador_SeccionCotizadorCtrl,
        _LOG_TAG = "Cotizador_SeccionCotizadorCtrl - CotizadorDAO.getCanalAnonimo ->",
        _OPTION_INVALID_ERROR_MSG = "Esta opción ya no se encuentra disponible.",
        _LOADING_INFO_MSG = "Cargando información...",
        _LOADING_INFO_PLEASE_WAIT_MSG = "Cargando información. Por favor espere.";
    module = angular.module("TCFAutos");
    Cotizador_SeccionCotizadorCtrl = function ($window, $timeout, $location, $q, $routeParams, $rootScope, $scope, $uibModal, $route,
                                               CotizadorDAO, CarDAO, ParametrosService, CotizadorService, TCFAAppConfigService, facebookService,
                                               DependencyInjectionService, ConcesionariosDAO, $filter) {
        var vm = this,
            _CUIT_DEALER_DEFAULT = "22222222222",
            // Query Params.
            __id_share_link = (parseInt($routeParams.il, 10) || 0),
            __id_campaign = parseInt($routeParams.ic, 10),
            __id_vehicle_brand = parseInt($routeParams.vb, 10),
            __name_vehicle_brand = $routeParams.vbn,
            __id_vehicle_model = parseInt($routeParams.vm, 10),
            __id_vehicle_version = parseInt($routeParams.vv, 10),
            __vehicle_condition = parseInt($routeParams.vc, 10),
            __vehicle_year = parseInt($routeParams.vy, 10),
            __vehicle_type = parseInt($routeParams.vt, 10),
            __id_person_type = parseInt($routeParams.pt, 10),
            __key_tax_condition = $routeParams.tc,
            __loan_amount = parseInt($routeParams.la, 10),
            __key_canon = $routeParams.ca,
            __key_operation = $routeParams.io,
            __id_vehicle = parseInt($routeParams.vi, 10),
            // QueryString param "ip" ($routeParams.ip) = ID Plan. Not used currently.
            // Google Analytics Campaign Params
            __ga_utm_source = $routeParams.utm_source,
            __ga_utm_medium = $routeParams.utm_medium,
            __ga_utm_campaign = $routeParams.utm_campaign,
            // Inner navigation scheme.
            __nav_step_model = null,
            __nav_state = $location.absUrl(),
            __nav_prev_state = null,
            __nav_back_state_enable = false,
            __campaign_data_promise = null,
            __initializing_modal = null,
            __loan_amount_alert_modal = null,
            __person_types_data_promise = $q.defer(),
            __person_types_data_fire_req = (function () {
                var __req_fired = false;
                return function () {
                    if (__req_fired) {
                        return;
                    }
                    __req_fired = true;
                    // Get Person types & tax conditions.
                    CarDAO.getDatosCalculoCuotas().$promise.then(function (response) {
                        var i, j, __taxes_conditions, __person_type,
                            __person_types_data = response.data;
                        $window.console.debug("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.getDatosCalculoCuotas ->", response);
                        if (__person_types_data.tipos_persona && __person_types_data.tipos_persona.length) {
                            for (i = 0; i < __person_types_data.tipos_persona.length; i = i + 1) {
                                __taxes_conditions = [];
                                if (__person_types_data.tipos_persona[i].condicion_impositiva && __person_types_data.tipos_persona[i].condicion_impositiva.length) {
                                    for (j = 0; j < __person_types_data.tipos_persona[i].condicion_impositiva.length; j = j + 1) {
                                        __taxes_conditions.push({
                                            id: __person_types_data.tipos_persona[i].condicion_impositiva[j].id,
                                            name: __person_types_data.tipos_persona[i].condicion_impositiva[j].descripcion,
                                            interest: __person_types_data.tipos_persona[i].condicion_impositiva[j].iva_interes,
                                            key: __person_types_data.tipos_persona[i].condicion_impositiva[j].codigo,
                                            is_default: __person_types_data.tipos_persona[i].condicion_impositiva[j].default
                                        });
                                    }
                                }

                                __person_type = {
                                    id: __person_types_data.tipos_persona[i].id,
                                    name: __person_types_data.tipos_persona[i].descripcion,
                                    key: __person_types_data.tipos_persona[i].codigo,
                                    key_number: __person_types_data.tipos_persona[i].codigo_numerico,
                                    loan_code: __person_types_data.tipos_persona[i].codigo_prestamo,
                                    taxes_conditions: __taxes_conditions,
                                    is_default: __person_types_data.tipos_persona[i].default,
                                    canons: []
                                };

                                if ((__id_person_type && (__id_person_type === __person_type.id)) ||
                                    (!__id_person_type && __person_type.is_default)) {
                                    vm.person_type = __person_type;
                                    vm.onChangePersonType();
                                }

                                (function (__p_type) {
                                    var __param = "cotizador_canon_ant_" + __p_type.loan_code.toLowerCase();
                                    // Get canons.
                                    ParametrosService.getAllParameters().$promise.then(function (resp_params) {
                                        resp_params.some(function (param_item) {
                                            var __i, __canons;
                                            if (param_item.clave === __param) {
                                                __canons = param_item.valor.split(";");
                                                __p_type.canons = [];
                                                for (__i = 0; __i < __canons.length; __i = __i + 1) {
                                                    __p_type.canons.push({
                                                        label: __canons[__i] + "%",
                                                        key: __canons[__i]
                                                    });
                                                }
                                                return true;
                                            }
                                            return false;
                                        });

                                        if (__p_type.id === vm.person_type.id) {
                                            vm.canon = null;
                                            if (__key_canon) {
                                                vm.person_type.canons.some(function (canon) {
                                                    if (canon.key === __key_canon) {
                                                        vm.canon = canon;
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                            } else {
                                                if (vm.person_type.canons.length > 0) {
                                                    vm.canon = vm.person_type.canons[0];
                                                }
                                            }
                                            vm.onChangeCanon();
                                        }
                                    });
                                }(__person_type));

                                vm.person_types.push(__person_type);
                            }

                            if ((vm.person_types.length > 0) && !vm.person_type) {
                                vm.person_type = vm.person_types[0];
                                vm.onChangePersonType();
                            }
                        }
                        __person_types_data_promise.resolve();
                    }, function (error) {
                        $window.console.error("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.getDatosCalculoCuotas ->", error);
                        if (__initializing_modal) {
                            __initializing_modal.dismiss("error");
                        }
                        $rootScope.showErrorModal(_OPTION_INVALID_ERROR_MSG, $scope.$new(true));
                        __person_types_data_promise.reject("error");
                    });
                };
            }()),

            __tooltip_error_text = "No se pudo obtener la información solicitada. Por favor intente nuevamente más tarde. Disculpe las molestias ocasionadas.",

            /** Loads vehicle images from API, Returns promise. */
            __loadVehicleImagesPromise = null,
            __loadVehicleLogosPromise = null,
            __loadVehicleLogoImages = function (vehicle_brand, vehicle_model) {
                __loadVehicleLogosPromise.promise
                    .then(function (all_images_response) {
                        if (!all_images_response.some(function (image_item) {
                            if (image_item.file_exists && (parseInt(image_item.brand_id, 10) === vehicle_brand.id) &&
                                (parseInt(image_item.model_id, 10) === vehicle_model.id)) {
                                vehicle_model.logo_image = image_item.image;
                                vehicle_model.logo_image_loading.resolve(vehicle_model.logo_image);
                                vehicle_model.error_logo_image = false;
                                return true;
                            }
                            return false;
                        })) {
                            vehicle_model.logo_image_loading.reject("not_exist");
                            vehicle_model.error_logo_image = true;
                        }

                    })
                    .catch(function () {
                        vehicle_model.error_logo_image = true;
                        vehicle_model.logo_image_loading.reject("error");
                    });
            },
            __loadVehicleImages = function (vehicle_brand, vehicle_model, get_logo) {
                if (!__loadVehicleImagesPromise) {
                    __loadVehicleImagesPromise = CotizadorDAO
                        .getAllVehicleImages({
                            marca_id: (vehicle_brand && vehicle_brand.id),
                            modelos_id: vm.vehicle_models.map(function (model_item) {
                                return (model_item && model_item.id);
                            })
                        })
                        .$promise;
                    __loadVehicleLogosPromise = null;
                }

                if (!__loadVehicleLogosPromise) {
                    __loadVehicleLogosPromise = $q.defer();
                    if (!get_logo) {
                        __loadVehicleImagesPromise.then(function (all_images_response) {
                            var __missing_images = all_images_response.filter(function (image_item) {
                                return !image_item.file_exists;
                            });
                            if (__missing_images.length > 0) {
                                CotizadorDAO.getAllVehicleLogos({
                                    marca_id: (vehicle_brand && vehicle_brand.id),
                                    modelos_id: __missing_images.map(function (image_item) {
                                        return (image_item && parseInt(image_item.model_id, 10));
                                    })
                                }).$promise.then(function (response) {
                                    __loadVehicleLogosPromise.resolve(response);
                                }).catch(function () {
                                    __loadVehicleLogosPromise.reject();
                                });
                            } else {
                                __loadVehicleLogosPromise.resolve([]);
                            }
                        }).catch(function () {
                            CotizadorDAO.getAllVehicleLogos({
                                marca_id: (vehicle_brand && vehicle_brand.id),
                                modelos_id: vm.vehicle_models.map(function (model_item) {
                                    return (model_item && model_item.id);
                                })
                            }).$promise.then(function (response) {
                                __loadVehicleLogosPromise.resolve(response);
                            }).catch(function () {
                                __loadVehicleLogosPromise.reject();
                            });
                        });
                    } else {
                        CotizadorDAO.getAllVehicleLogos({
                            marca_id: (vehicle_brand && vehicle_brand.id),
                            modelos_id: [vehicle_model.id]
                        }).$promise.then(function (response) {
                            if (__loadVehicleLogosPromise.promise.$$state.status === 0) {
                                __loadVehicleLogosPromise.resolve(response);
                            } else {
                                __loadVehicleLogosPromise = $q.defer();
                                __loadVehicleLogoImages(vehicle_brand, vehicle_model);
                                __loadVehicleLogosPromise.resolve(response);
                            }
                        }).catch(function () {
                            __loadVehicleLogosPromise.reject();
                        });
                    }
                }

                if (!vehicle_model.logo_image_loading) {
                    vehicle_model.logo_image_loading = $q.defer();
                } else {
                    if (get_logo) {
                        vehicle_model.logo_image_loading.reject();
                        vehicle_model.logo_image_loading = $q.defer();
                        __loadVehicleLogoImages(vehicle_brand, vehicle_model);
                    }
                }
                if (!vehicle_model.image_loading) {
                    vehicle_model.image_loading = $q.defer();
                    __loadVehicleImagesPromise.then(function (all_images_response) {
                        if (!all_images_response.some(function (image_item) {
                            if (image_item.file_exists && (parseInt(image_item.brand_id, 10) === vehicle_brand.id) &&
                                (parseInt(image_item.model_id, 10) === vehicle_model.id)) {
                                vehicle_model.image = image_item.image;
                                vehicle_model.image_loading.resolve(vehicle_model.image);
                                vehicle_model.error_image = false;
                                if (get_logo) {
                                    __loadVehicleLogoImages(vehicle_brand, vehicle_model);
                                } else {
                                    vehicle_model.logo_image_loading.resolve(null);
                                    vehicle_model.error_logo_image = false;
                                }
                                return true;
                            }
                            return false;
                        })) {
                            vehicle_model.image_loading.reject("not_exist");
                            vehicle_model.error_image = true;
                            __loadVehicleLogoImages(vehicle_brand, vehicle_model);
                        }
                    }).catch(function () {
                        vehicle_model.error_image = true;
                        vehicle_model.image_loading.reject("error");
                        __loadVehicleLogoImages(vehicle_brand, vehicle_model);
                    });
                }
                return $q.all([vehicle_model.logo_image_loading.promise, vehicle_model.image_loading.promise]);
            },

            __parseVehiclesCollection = function (collection) {
                return {
                    brand: {
                        id: (parseInt(collection.id || collection.p_mar_id_i, 10) || null),
                        name: (collection.descripcion || collection.p_mar_desc_c || null)
                    },
                    models: (collection.modelo || collection.modelos || []).map(function (modelo_item) {
                        return {
                            id: parseInt(modelo_item.id || modelo_item.p_mod_id_i, 10),
                            descripcion: modelo_item.descripcion || modelo_item.p_mod_desc_c
                        };
                    })
                };
            },

            __loadVehicleLoanInfo = function (vehicle_version) {
                if (!vehicle_version.__$vehicle_loan_info) {
                    vehicle_version.__$vehicle_loan_info = $q.defer();
                    __person_types_data_promise.promise.then(function () {
                        var __loanInfo = { // Generated as an API Response.
                            data: {
                                financiacion_sugerida: vehicle_version.financiacion_sugerida || 5000,
                                max_financiacion: vehicle_version.max_financiacion,
                                vehiculo_id: vehicle_version.p_veh_id_i,
                                vehiculo_precio: vehicle_version.p_veh_precio_n
                            }
                        };
                        vehicle_version.loan_suggested_amount = Math.floor(parseFloat(__loanInfo.data.financiacion_sugerida));
                        vehicle_version.__$vehicle_loan_info.resolve(__loanInfo);
                        vehicle_version.__$vehicle_loan_info_finished = true;
                    }, function () {
                        vehicle_version.__$vehicle_loan_info.reject("error");
                        vehicle_version.__$vehicle_loan_info_finished = true;
                    });
                }
                return vehicle_version.__$vehicle_loan_info.promise;
            };

        // DECLARATIONS.
        // Dealer.
        vm.dealer = null;
        // Vehicle.
        vm.vehicle_models = [];
        vm.vehicle_model = null;
        vm.vehicle_version = null;
        vm.vehicle_price = 0;
        vm.vehicle_condition_new = true;
        vm.vehicle_year = new Date().getFullYear();
        vm.vehicle_type = 1;
        vm.vehicle_is_car = true;
        vm.vehicle_is_truck = false;
        vm.vehicle_is_forklift = false;
        vm.vehicle_brand = null;
        vm.vehicle_id = null;
        vm.vehicle_iva = null;
        vm.show_vehicle_price = false;
        // Anonymous channel.
        vm.anonymous_channel = {};
        vm.used_anonymous_channel = false;
        // Person type.
        vm.person_types = [];
        vm.person_type = null;
        // Tax condition.
        vm.tax_condition = null;
        // Canon & Loan amount.
        vm.canon = null;
        vm.loan_amount = 0;
        vm.loan_max_amount = 0;
        vm.loan_min_amount = 0;
        // Form.
        vm.form_cotizar_changed = false;
        vm.form_submit_in_progress = false;
        // Operation types.
        vm.operations = [{
            id: 2,
            key: "RE",
            name: "PRENDARIO",
            extra: {label: "MONTO A FINANCIAR"}
        }, {
            id: 1,
            key: "LE",
            name: "LEASING",
            extra: {label: "CANON ANTICIPADO"}
        }];
        vm.operation = vm.operations[0];
        // Plans & installments.
        vm.plans = [];
        vm.plan = null;
        vm.installment = null;
        // RootScope cotizador.
        if (!$rootScope.cotizador) {
            $rootScope.cotizador = {};
        }
        $rootScope.publicacion = null;
        if ($rootScope.cotizador.publicacion) {
            $rootScope.cotizador.publicacion = {};
        }
        // Tooltips.
        vm.tooltip_operation_retail = _LOADING_INFO_MSG;
        vm.tooltip_operation_leasing = _LOADING_INFO_MSG;
        vm.tooltip_canon = _LOADING_INFO_MSG;
        // Slider controls.
        vm.show_used_cars = false;
        vm.show_new_cars = true;
        vm.show_vehicle_type_selectors = true;
        vm.swiper_instance = null;
        vm.swiper_options = {
            initialSlide: (vm.show_used_cars ? 3 : 2),
            slidesPerView: 3,
            centeredSlides: true,
            breakpoints: {
                "767": {
                    slidesPerView: 1,
                    initialSlide: (vm.show_used_cars ? 2 : 1)
                }
            },
            observer: true,
            autoHeight: true,
            on: {
                transitionEnd: function () {
                    var __slide_to = -1;
                    if ((this.activeIndex === 0) && (this.previousIndex === 2)) {
                        __slide_to = 1;
                    } else {
                        if (this.isBeginning) {
                            __slide_to = this.slides.length - (vm.show_used_cars ? 3 : 2);
                        }
                        if (this.isEnd) {
                            __slide_to = (vm.show_used_cars ? 2 : 1);
                        }
                    }
                    if (__slide_to !== -1) {
                        this.slideTo(__slide_to);
                        this.update();
                    }
                }
            }
        };
        vm.swiper_to_initial_slide = true;
        vm.swiper_dummy_slide_first = null;
        vm.swiper_dummy_slide_last = null;
        vm.disable_vehicle_type_forklift = true;
        vm.disable_vehicle_type_car = true;
        vm.disable_vehicle_type_truck = true;
        vm.vehicle_version_model = null;
        vm.toyotaCiclo = false;

        vm.getVehicleVersions = function () {
            return CotizadorDAO.getVersionXModelo({
                p_tve_id_i: vm.vehicle_type,
                p_mar_id_i: vm.vehicle_brand.id,
                p_mod_id_i: vm.vehicle_model.id
            }).$promise.catch(function (error) {
                $window.console.log(error);
            });
        };

        /** Re-enables all inputs on Cotizador Form. */
        vm.liftCampaignRestrictions = function () {
            vm.disable_operation_change = false;
            vm.disable_person_type_change = false;
            vm.disable_tax_condition_change = false;
            vm.disable_vehicle_model_change = false;
            vm.disable_vehicle_version_change = false;
        };

        // Returns true if the selected vehicle type is CAR.
        vm.isVehicleTypeCarActive = function () {
            return ((vm.vehicle_type === 1) && vm.vehicle_is_car);
        };

        // Returns true if the selected vehicle type is FORKLIFT.
        vm.isVehicleTypeForkliftActive = function () {
            return ((vm.vehicle_type === 2) && vm.vehicle_is_forklift);
        };

        // Returns true if the selected vehicle type is TRUCK.
        vm.isVehicleTypeTruckActive = function () {
            return ((vm.vehicle_type === 3) && vm.vehicle_is_truck);
        };

        // Sorting models: following method performs a custom sort of vehicles. Remove when ABM for such purpose is created.
        vm.vehiclesCustomSortComparator = function (v1, v2) {
            var predefined = ["ETIOS", "COROLLA", "HILUX"];
            var v1pos = predefined.indexOf(v1.value);
            var v2pos = predefined.indexOf(v2.value);
            if (v1pos === -1 && v2pos === -1) {
                return v1.value.localeCompare(v2.value);
            } else if (v1pos !== -1 && v2pos === -1) {
                return -1;
            } else if (v1pos === -1 && v2pos !== -1) {
                return 1;
            } else {
                return (v1pos < v2pos) ? -1 : 1;
            }
        };

        // Filters versions available in campaign
        vm.filterCampaignVehicleVersions = function (vehicle_versions) {
            return vehicle_versions.filter(function (vehicle_version) {
                var __campaign_vehicle_versions = vm.vehicle_model.versiones;
                return __campaign_vehicle_versions.some(function (campaign_vehicle_version) {
                    return (campaign_vehicle_version.id === vehicle_version.p_ver_id_i);
                });
            });
        };

        // HANDLERS.
        /** Slides swiper to initial slide. */
        vm.swiperSlideToInitial = function () {
            if (!vm.swiper_instance) {
                vm.onSwiperContainerInit();
            }
            if (vm.swiper_instance.currentBreakpoint === "max") {
                vm.swiper_instance.slideTo(vm.swiper_options && vm.swiper_options.initialSlide);
            } else {
                vm.swiper_instance.slideTo(vm.swiper_options && vm.swiper_options.breakpoints["767"] && vm.swiper_options.breakpoints["767"].initialSlide);
            }
        };
        /** Swiper init handler. Initializes Swiper component on its container. */
        vm.onSwiperContainerInit = function () {
            vm.swiper_instance = new $window.Swiper(".swiper-container", vm.swiper_options);
            if (!vm.swiper_instance.slideTo && (vm.swiper_instance.length >= 1)) {
                vm.swiper_instance = vm.swiper_instance[0];
            }
        };
        /** Slides initialization handler. Gets vehicle images and update swiper initial slide. */
        vm.onSlideInit = function (vehicle_model) {
            __loadVehicleImages(vm.vehicle_brand, vehicle_model).finally(function () {
                if (vm.swiper_to_initial_slide && (vm.swiper_instance.activeIndex !== (vm.swiper_instance.params && vm.swiper_instance.params.initialSlide)) &&
                    (vm.swiper_instance.slides.length > (vm.swiper_instance.params && vm.swiper_instance.params.initialSlide))) {
                    vm.swiper_to_initial_slide = false;
                    vm.swiperSlideToInitial();
                }
                vm.swiper_instance.update();
            });
        };
        /** Swiper slide to left handler. */
        vm.onSwiperPrev = function () {
            var __index;
            if (vm.swiper_instance) {
                __index = vm.swiper_instance.activeIndex - 1;
                if (__index === 0) {
                    __index = vm.swiper_instance.slides.length - (vm.show_used_cars ? 3 : 2);
                }
                vm.swiper_instance.slideTo(__index);
            }
        };
        /** Swiper slide to right handler. */
        vm.onSwiperNext = function () {
            var __index;
            if (vm.swiper_instance) {
                __index = vm.swiper_instance.activeIndex + 1;
                if (__index === vm.swiper_instance.slides.length - 1) {
                    __index = (vm.show_used_cars ? 2 : 1);
                }
                vm.swiper_instance.slideTo(__index);
            }
        };
        // Select slide handler.
        vm.onSelectSlide = function (vehicle_model) {
            vm.vehicle_model = vehicle_model;
            // Set step
            if (__nav_back_state_enable) {
                __nav_step_model = vehicle_model;
                vm.hashUpdate(vehicle_model.id);
                __nav_back_state_enable = false;
            }
            vm.onChangeVehicleModel();
        };
        // Select special slide "usados seleccionados" handler.
        vm.onSelectSlideUsedCar = function () {
            $location.path("/").search({});
            $route.reload();
        };
        // Select vehicle type car (slider) handler.
        vm.onSelectVehicleTypeCar = function () {
            if (!vm.isVehicleTypeCarActive()) {
                vm.vehicle_type = 1;
                vm.getMarcaModelo();
            }
        };
        // Select vehicle type forklift (slider) handler.
        vm.onSelectVehicleTypeForklift = function () {
            if (!vm.isVehicleTypeForkliftActive()) {
                vm.vehicle_type = 2;
                vm.getMarcaModelo();
            }
        };
        // Select vehicle type truck (slider) handler.
        vm.onSelectVehicleTypeTruck = function () {
            if (!vm.isVehicleTypeTruckActive()) {
                vm.vehicle_type = 3;
                vm.getMarcaModelo();
            }
        };
        // Changes on form Cotizar inputs handler.
        vm.onFormCotizarChange = function () {
            vm.form_cotizar_changed = true;
            CotizadorService.changeCotizarForm();
        };
        // Operation type selection change handler.
        vm.onChangeOperationType = function () {
            vm.onFormCotizarChange();
            // Switch Canon anticipado/Monto a financiar.
            //  - LE (Leasing) -> Canon anticipado.
            //  - RE (Prendario) -> Monto a financiar.
            vm.operation_is_retail = (vm.operation.key === "RE");
            $rootScope.cotizador.operation_type = vm.operation;
            $rootScope.cotizador.operation_is_retail = vm.operation_is_retail;
        };
        // Vehicle price change handler.
        vm.onChangeVehiclePrice = function () {
            $rootScope.cotizador.vehicle_price = vm.vehicle_price;
        };
        // Vehicle ID change handler.
        vm.onChangeVehicleId = function () {
            $rootScope.cotizador.vehicle_id = vm.vehicle_id;
        };
        vm.onChangeVehicleVersionImage = function () {
            CotizadorDAO.getVehicleVersionImage({
                brand_id: (vm.vehicle_brand && vm.vehicle_brand.id),
                model_id: (vm.vehicle_model && vm.vehicle_model.id),
                version_id: (vm.vehicle_version && vm.vehicle_version.p_ver_id_i)
            }).$promise.then(function (response) {
                if (response.data.length > 0) {
                    var dataImagen = response.data[0];
                    if (dataImagen.file_exists) {
                        vm.vehicle_version.image = dataImagen.image;
                    }
                }
            }).catch(function (error) {
                $window.console.log(error);
            });
        };
        // Vehicle IVA change handler.
        vm.onChangeVehicleIva = function () {
            $rootScope.cotizador.vehicle_iva = vm.vehicle_iva;
        };
        // Vehicle version selection change handler.
        vm.onChangeVehicleVersion = function (quiet) {
            var __loading_modal,
                __vehicle_loan_info_promise = null,
                __vehicle_loan_info_promise_resolve_func = function __vehicle_loan_info_promise_resolve_func() {
                    __vehicle_loan_info_promise.then(function () {
                        vm.vehicle_price = vm.vehicle_version.p_veh_precio_n;
                        vm.onChangeVehiclePrice();
                        vm.loan_amount = vm.vehicle_version.loan_suggested_amount;
                        vm.loan_max_amount = vm.vehicle_version.max_financiacion;
                        vm.onChangeLoanAmount(true);
                        vm.vehicle_id = vm.vehicle_version.p_veh_id_i;
                        vm.onChangeVehicleId();
                        vm.onChangeVehicleVersionImage();
                        // Datero Log event 7: "Selección Vehículo"
                        CotizadorDAO.dateroLog({
                            lt: 7,
                            vb: vm.vehicle_brand.id,
                            vm: vm.vehicle_model.id,
                            vv: vm.vehicle_version.p_ver_id_i,
                            vt: vm.vehicle_type,
                            vy: vm.vehicle_year,
                            vc: vm.vehicle_condition_new,
                            la: vm.loan_amount,
                            tc: vm.tax_condition.id,
                            io: vm.operation.key,
                            dcu: (vm.dealer && vm.dealer.cuit), // Dealer's CUIT.
                            ic: vm.campaign_id,
                            co: (vm.campaign_data && vm.campaign_data.campania && 
                                vm.campaign_data.campania.origen), // Campaign origin.
                            App: "Cotizador",
                            EsCertificado: false
                        });
                    }).catch(function (error) {
                        if (error.status === 404) {
                            // Retry with next version on error
                            var length = vm.vehicle_version_model.length,
                                pos = vm.vehicle_version_model.findIndex(function (version) {
                                    return (version.p_ver_id_i === vm.vehicle_version.p_ver_id_i);
                                });
                            if (pos + 1 < length) {
                                $window.console.debug("Retrying with next version available, index: " + (pos + 1) + ", length: " + length);
                                vm.vehicle_version = vm.vehicle_version_model[pos + 1];
                                __vehicle_loan_info_promise = __loadVehicleLoanInfo(vm.vehicle_version);
                                __vehicle_loan_info_promise_resolve_func();
                            } else {
                                $window.console.debug("No more retries are available. Redirecting to anonymous channel");
                                CotizadorService.restoreSlider();
                            }
                        } else {
                            if (!quiet) {
                                $rootScope.showErrorModal(_OPTION_INVALID_ERROR_MSG, $scope.$new(true));
                                $window.console.debug("Missing vehicle loan info.");
                            }
                        }
                    }).finally(function () {
                        if (__loading_modal) {
                            __loading_modal.rendered.then(function () {
                                __loading_modal.dismiss("close");
                            });
                        }
                        vm.vehicle_version.finished_loading_data = true;
                    });
                };
            vm.onFormCotizarChange();
            // Get vehicle loan info.
            if (vm.vehicle_version) {
                vm.vehicle_version_model.some(function (version) {
                    if (version.p_ver_id_i === vm.vehicle_version.p_ver_id_i) {
                        vm.vehicle_version = version;
                        __vehicle_loan_info_promise = __loadVehicleLoanInfo(version);
                        return true;
                    }
                    return false;
                });
                vm.vehicle_version.finished_loading_data = false;

                if (!vm.vehicle_version.__$vehicle_loan_info_finished && !quiet) {
                    __loading_modal = $rootScope.showLoadingModal(_LOADING_INFO_PLEASE_WAIT_MSG, $scope.$new(true));
                }
                if (__loading_modal) {
                    __loading_modal.rendered.then(function () {
                        __vehicle_loan_info_promise_resolve_func();
                    });
                } else {
                    __vehicle_loan_info_promise_resolve_func();
                }
                
                vm.vehicle_version.id = vm.vehicle_version.p_ver_id_i;
                vm.vehicle_version.descripcion = vm.vehicle_version.p_ver_desc_c;
                $rootScope.cotizador.vehicle_version = vm.vehicle_version;
            } else {
                $window.console.debug("No versions available. Redirecting to anonymous channel");
                CotizadorService.redirectToAnonymousChannel();
            }
        };
        // Vehicle models array update handler.
        vm.onUpdatedVehicleModels = function (reset_images) {
            var __loading_modal = $rootScope.showLoadingModal("Cargando imágenes. Por favor espere.", $scope.$new(true));
            // Sorting models: following line performs a custom sort of vehicles. Remove this line and vm.vehiclesCustomSortComparator()
            //  when ABM for such purpose is created.
            vm.vehicle_models = $filter("orderBy")(vm.vehicle_models, "descripcion", false, vm.vehiclesCustomSortComparator);
            if (vm.vehicle_models.length > 1) {
                vm.swiper_dummy_slide_first = vm.vehicle_models[vm.vehicle_models.length - 1];
                vm.swiper_dummy_slide_last = vm.vehicle_models[0];
                vm.swiper_options.initialSlide = (vm.show_used_cars ? 3 : 2);
                vm.swiper_options.breakpoints["767"].initialSlide = (vm.show_used_cars ? 2 : 1);
            } else {
                vm.swiper_dummy_slide_first = null;
                vm.swiper_dummy_slide_last = null;
                vm.swiper_options.initialSlide = 0;
                vm.swiper_options.breakpoints["767"].initialSlide = 0;
            }
            if (vm.swiper_instance) {
                if (!vm.swiper_instance.params) {
                    vm.onSwiperContainerInit();
                    vm.swiper_instance.params = (vm.swiper_instance.params || vm.swiper_options);
                }
                vm.swiper_instance.params.initialSlide = vm.swiper_options.initialSlide;
                vm.swiper_instance.params.breakpoints["767"].initialSlide = vm.swiper_options.breakpoints["767"].initialSlide;
                $timeout(function () {
                    vm.swiperSlideToInitial();
                    vm.swiper_instance.update();
                }, 100);
            }
            if (reset_images) {
                __loadVehicleImagesPromise = null;
                __loadVehicleLogosPromise = null;
            }
            // Show loading for the first 3 images of the slider.
            $q.all(vm.vehicle_models.slice(0, 3).map(function (v_model) {
                return __loadVehicleImages(vm.vehicle_brand, v_model);
            })).finally(function () {
                $timeout(function () {
                    __loading_modal.dismiss("close");
                    __loading_modal.rendered.then(function () {
                        __loading_modal.dismiss("close");
                    });
                }, 500);
            });
        };
        // Vehicle model selection change handler.
        vm.onChangeVehicleModel = function (pre_selected_version_id, quiet) {
            var __loading_modal, __loading_modal2, __version_modelo_promise,
                __getVehicleImages = function () {
                    __loadVehicleLogosPromise = null;
                    __loadVehicleImages(vm.vehicle_brand, vm.vehicle_model, true).then(function () {
                        if (__loading_modal) {
                            __loading_modal.dismiss("close");
                        }
                    }).catch(function () {
                        if (__loading_modal) {
                            __loading_modal.dismiss("error");
                        }
                    });
                };

            vm.onFormCotizarChange();
            vm.vehicle_version = null;

            if (vm.vehicle_model) {
                if (!quiet) {
                    __loading_modal2 = $rootScope.showLoadingModal(_LOADING_INFO_PLEASE_WAIT_MSG, $scope.$new(true));
                }
                __version_modelo_promise = vm.getVehicleVersions();
                __person_types_data_fire_req();

                vm.vehicle_models.some(function (v_model) {
                    if (v_model.id === vm.vehicle_model.id) {
                        vm.vehicle_model = v_model;
                        return true;
                    }
                    return false;
                });

                __version_modelo_promise.then(function (response) {
                    if (__campaign_data_promise) {
                        vm.vehicle_version_model = vm.filterCampaignVehicleVersions(response.data);
                    } else {
                        vm.vehicle_version_model = response.data;
                    }
                    if (pre_selected_version_id) {
                        vm.vehicle_version_model.some(function (version) {
                            if (version.id === pre_selected_version_id) {
                                vm.vehicle_version = version;
                                return true;
                            }
                            return false;
                        });
                    } else {
                        if (vm.vehicle_version_model) {
                            vm.vehicle_version = vm.vehicle_version_model[0];
                        }
                    }
                    vm.onChangeVehicleVersion(quiet);
                    if (__loading_modal2) {
                        __loading_modal2.dismiss("close");
                    }
                }).catch(function () {
                    if (__loading_modal2) {
                        __loading_modal2.dismiss("error");
                    }
                });
                // Get vehicle images.
                if ((!vm.vehicle_model.logo_image || !vm.vehicle_model.logo_image_loading) || (!vm.vehicle_model.image && !vm.vehicle_model.image_loading)) {
                    if (!quiet) {
                        __loading_modal = $rootScope.showLoadingModal("Cargando imágenes. Por favor espere.", $scope.$new(true));
                    }
                    if (__loading_modal) {
                        __loading_modal.rendered.then(function () {
                            __getVehicleImages();
                        });
                    } else {
                        __getVehicleImages();
                    }
                }
            }
            $rootScope.cotizador.vehicle_model = vm.vehicle_model;
        };
        // Tax condition selection change handler.
        vm.onChangeTaxCondition = function () {
            vm.onFormCotizarChange();
            $rootScope.cotizador.tax_condition = vm.tax_condition;
            vm.vehicle_iva = vm.tax_condition.interest;
            vm.onChangeVehicleIva();
        };
        // Canon selection change handler.
        vm.onChangeCanon = function () {
            vm.onFormCotizarChange();
            $rootScope.cotizador.canon = vm.canon;
        };
        // Loan amount input focus & keydown handler.
        (function () {
            var __last_input = -1;
            vm.onFocusLoanAmount = function (event) {
                __last_input = event.target.value.replace("$", "");
            };
            vm.onKeydownLoanAmount = function (event) {
                $timeout(function () {
                    if (event.target.value !== __last_input) {
                        __last_input = event.target.value.replace("$", "");
                        vm.onFormCotizarChange();
                    }
                }, 1);
            };
        }());
        // Loan amount input change handler.
        vm.__previous_loan_amount = -1;
        vm.onChangeLoanAmount = function (quiet) {
            var __showModal = function (message) {
                if (!__loan_amount_alert_modal) {
                    __loan_amount_alert_modal = $rootScope.showAlertModal(message, $scope.$new(true));
                    __loan_amount_alert_modal.closed.then(function () {
                        __loan_amount_alert_modal = null;
                    });
                }
            };
            if (vm.loan_amount > vm.loan_max_amount) {
                if (!quiet) {
                    __showModal("El valor excede el máximo permitido.");
                }
                vm.loan_amount = vm.loan_max_amount;
            }
            if (vm.loan_amount < vm.loan_min_amount) {
                if (!quiet) {
                    __showModal("El valor es menor al mínimo permitido.");
                }
                vm.loan_amount = vm.loan_min_amount;
            }
            $rootScope.cotizador.loan_amount = vm.loan_amount;

            // Trigger change event only on real changes (the validations can change the final value).
            if (vm.__previous_loan_amount === vm.loan_amount) {
                return;
            }
            vm.__previous_loan_amount = vm.loan_amount;
            vm.onFormCotizarChange();
        };
        // Person type selection change handler.
        vm.onChangePersonType = function () {
            var i;
            vm.onFormCotizarChange();
            if (vm.person_type) {
                vm.canon = null;
                if (vm.person_type.canons.length > 0) {
                    vm.canon = vm.person_type.canons[0];
                }
                vm.onChangeCanon();

                vm.tax_condition = null;
                for (i = 0; i < vm.person_type.taxes_conditions.length; i = i + 1) {
                    if (vm.person_type.taxes_conditions[i].selected) {
                        vm.tax_condition = vm.person_type.taxes_conditions[i];
                        break; // Only break on pre-selections.
                    }
                    if (vm.person_type.taxes_conditions[i].is_default) {
                        vm.tax_condition = vm.person_type.taxes_conditions[i]; // No break.
                    }
                }
                if (!vm.tax_condition && (vm.person_type.taxes_conditions.length > 0)) {
                    vm.tax_condition = vm.person_type.taxes_conditions[0];
                }
                vm.onChangeTaxCondition();
            }
            $rootScope.cotizador.person_type = vm.person_type;
        };
        // Installment change handler.
        vm.onChangeInstallment = function () {
            $rootScope.cotizador.installment = vm.installment;
        };
        // Plan change handler.
        vm.onChangePlan = function () {
            if (vm.plan && (vm.plan.installments.length === 1)) {
                vm.installment = vm.plan.installments[0];
            } else {
                vm.installment = null;
            }
            vm.onChangeInstallment();

            $rootScope.cotizador.plan = vm.plan;
        };
        // Vehicle condition change handler.
        vm.onChangeVehicleCondition = function () {
            $rootScope.cotizador.vehicle_condition_new = vm.vehicle_condition_new;
        };
        // Vehicle year change handler.
        vm.onChangeVehicleYear = function () {
            $rootScope.cotizador.vehicle_year = vm.vehicle_year;
        };
        // Vehicle type change handler.
        vm.onChangeVehicleType = function () {
            $rootScope.cotizador.vehicle_type = vm.vehicle_type;
            $rootScope.cotizador.vehicle_is_car = vm.vehicle_is_car;
            $rootScope.cotizador.vehicle_is_truck = vm.vehicle_is_truck;
            $rootScope.cotizador.vehicle_is_forklift = vm.vehicle_is_forklift;
        };
        // Vehicle brand change handler.
        vm.onChangeVehicleBrand = function () {
            $rootScope.cotizador.vehicle_brand = vm.vehicle_brand;
        };
        // Share.
        vm.onShare = function () {
            var __modal_share,
                __modal_scope = $scope.$new(true),
                __share_data = {
                    link_type: 1,
                    campaign: __id_campaign,
                    operation_type: vm.operation.key,
                    vehicle_brand: vm.vehicle_brand.id,
                    vehicle_brand_name: vm.vehicle_brand.name,
                    vehicle_model: vm.vehicle_model.id,
                    vehicle_model_name: vm.vehicle_model.descripcion,
                    vehicle_version: vm.vehicle_version.p_ver_id_i,
                    vehicle_version_name: vm.vehicle_version.p_ver_desc_c,
                    vehicle_condition: ((vm.vehicle_condition_new && 1) || 0),
                    vehicle_year: vm.vehicle_year,
                    vehicle_type: vm.vehicle_type,
                    vehicle_id: vm.vehicle_id,
                    vehicle_price: vm.vehicle_price,
                    person_type: vm.person_type.id,
                    loan_amount: vm.loan_amount,
                    tax_condition: vm.tax_condition.key,
                    canon: vm.canon.key,
                    plan: (vm.plan && vm.plan.id)
                },
                __share_url = CotizadorService.getUrlToSocialShare(__share_data);

            if (facebookService.tcfautos_fallback_check) {
                __modal_scope.disableFacebookShare = true;
            }
            /** Share loan on Facebook by API URL to allow OpenGraph to get the HTML tags. */
            __modal_scope.onShareFacebook = function () {
                facebookService.share(__share_url, function (response) {
                    if (angular.isObject(response)) {
                        $window.console.log("se compartió en FB");
                        // Datero Log event 11: "Compartir"
                        CotizadorDAO.dateroLog({
                            lt: 11,
                            vb: vm.vehicle_brand.id,
                            vm: vm.vehicle_model.id,
                            vv: vm.vehicle_version.p_ver_id_i,
                            App: "Cotizador",
                            EsCertificado: false
                        });
                        CotizadorDAO.shareWithFriendOnFacebook(response);
                    } else {
                        $window.console.log("no se compartió en FB");
                    }
                });
                if (__modal_share) {
                    __modal_share.dismiss("close");
                }
            };
            /** Share loan on GooglePlus by API URL to allow OpenGraph to get the HTML tags. */
            __modal_scope.onShareGooglePlus = function () {
                $window.open(TCFAAppConfigService.GOOGLE.SHARE_URL + $window.encodeURIComponent(__share_url), "",
                    "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
                // Datero Log event 11: "Compartir"
                CotizadorDAO.dateroLog({
                    lt: 11,
                    vb: vm.vehicle_brand.id,
                    vm: vm.vehicle_model.id,
                    vv: vm.vehicle_version.p_ver_id_i,
                    App: "Cotizador",
                    EsCertificado: false
                });
                if (__modal_share) {
                    __modal_share.dismiss("close");
                }
            };
            /** Share loan with friends by sending an e-mail. */
            __modal_scope.onShareWithFriends = function () {
                var __share_with_friends_modal_scope = $scope.$new(true),
                    __recaptcha_promise = DependencyInjectionService.loadApi("GoogleRecaptcha"),
                    __openShareWithFriendsModal = function () {
                        $uibModal.open({
                            animation: true,
                            windowClass: "tcfa-modal",
                            backdrop: true,
                            keyboard: true,
                            templateUrl: "./views/cotizador/modal/modal_share_with_friends.html",
                            controller: "ShareWithFriendsCtrl",
                            controllerAs: "ctrl",
                            bindToController: true,
                            scope: __share_with_friends_modal_scope,
                            appendTo: angular.element("#modalComponent"),
                            openedClass: "modal-share-with-friends-wrapper"
                        });
                    };

                __share_with_friends_modal_scope.insurance_data = __share_data;
                __share_with_friends_modal_scope.share_url = __share_url;

                if (__modal_share) {
                    __modal_share.dismiss("close");
                    __recaptcha_promise.then(function () {
                        __modal_share.closed.then(__openShareWithFriendsModal);
                    });
                } else {
                    __recaptcha_promise.then(__openShareWithFriendsModal);
                }
            };

            __modal_share = $uibModal.open({
                animation: true,
                windowClass: "tcfa-modal",
                backdrop: true,
                keyboard: true,
                templateUrl: "./views/cotizador/modal/modal_share.html",
                controller: ["$scope", function ($scope) {
                    $scope.close = function () {
                        $scope.$dismiss("close");
                    };
                }],
                controllerAs: "modalShare",
                bindToController: true,
                scope: __modal_scope,
                appendTo: angular.element("#modalComponent"),
                openedClass: "modal-share-wrapper"
            });
        };

        // Cotizar (getPlazos()).
        vm.onCotizar = function () {
            var __loading_modal = $rootScope.showLoadingModal("Cotizando. Por favor espere.", $scope.$new(true));
            vm.form_cotizar_changed = false;
            vm.form_submit_in_progress = true;
            CotizadorDAO.getPlazos({
                p_tipo_de_persona_c: (vm.person_type && vm.person_type.key),
                p_tipo_operacion_c: (vm.operation && vm.operation.key),
                p_id_veh_i: vm.vehicle_id,
                p_monto_a_financiar_n: (vm.operation && vm.operation.key === 'LE' ? vm.canon.key : vm.loan_amount),
                campania_id: (__id_campaign || null)
            }).$promise.then(function (cotizar_response) {
                $window.console.debug("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.cotizar ->", cotizar_response);
                vm.form_submit_in_progress = false;
                __loading_modal.dismiss("close");
                CotizadorService.updatePlanesAndPlazos(cotizar_response.data, false, false);
            }, function (error) {
                $window.console.error("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.cotizar ->", error);
                vm.form_submit_in_progress = false;
                __loading_modal.dismiss("error");
                vm.onFormCotizarChange();
                $rootScope.showErrorModal("No se pudo completar la operación.", $scope.$new(true));
            });
        };

        // Cotizar form reset state handler.
        CotizadorService.onCotizarFormChanged($scope, function () {
            vm.form_cotizar_changed = true;
        });

        // Promo loan amount change handler.
        CotizadorService.onLoanAmountUpdated($scope, function (event, loan_amount) {
            vm.loan_amount = loan_amount;
            vm.onChangeLoanAmount(true);
            vm.onCotizar();
        });

        // Vehicle models change broadcast event handler.
        CotizadorService.onVehicleModelsUpdated($scope, function () {
            var __loading_modal = $rootScope.showLoadingModal(_LOADING_INFO_PLEASE_WAIT_MSG, $scope.$new(true));
            __loading_modal.rendered.then(function () {
                vm.used_anonymous_channel = true;
                vm.liftCampaignRestrictions();
                CotizadorDAO.getCanalAnonimo({
                    cuit: (vm.dealer && vm.dealer.cuit),
                    ga_utm_source: __ga_utm_source,
                    ga_utm_medium: __ga_utm_medium,
                    ga_utm_campaign: __ga_utm_campaign
                }).$promise.then(function (response) {
                    var __finish_get_anon_channel = function () {
                        vm.show_vehicle_type_selectors = true;
                        vm.vehicle_model = null;
                        vm.onChangeVehicleModel();
                        __loading_modal.rendered.then(function () {
                            __loading_modal.dismiss("close");
                        });
                    };
                    $window.console.debug(_LOG_TAG, response);
                    if (response.data) {
                        // Parse anonymous channel.
                        vm.anonymous_channel.forklift = __parseVehiclesCollection(response.data.autoelevadores);
                        vm.anonymous_channel.car = __parseVehiclesCollection(response.data.autos);
                        vm.anonymous_channel.truck = __parseVehiclesCollection(response.data.camiones);
                        // Vehicle condition.
                        vm.vehicle_condition_new = true;
                        vm.onChangeVehicleCondition();
                        // Vehicle year.
                        vm.vehicle_year = new Date().getFullYear();
                        vm.onChangeVehicleYear();
                        // Vehicle brand
                        if (vm.isVehicleTypeCarActive()) {
                            vm.vehicle_brand = vm.anonymous_channel.car.brand;
                        }
                        if (vm.isVehicleTypeForkliftActive()) {
                            vm.vehicle_brand = vm.anonymous_channel.forklift.brand;
                        }
                        if (vm.isVehicleTypeTruckActive()) {
                            vm.vehicle_brand = vm.anonymous_channel.truck.brand;
                        }
                        vm.onChangeVehicleBrand();
                        // Vehicle type.
                        if (vm.vehicle_brand) {
                            vm.vehicle_is_car = ((vm.vehicle_type === 1) && (vm.vehicle_brand.id === 2)); // Check by Toyota brand ID.
                            vm.vehicle_is_truck = ((vm.vehicle_type === 3) && (vm.vehicle_brand.id !== 2)); // Check by Toyota brand ID.
                            vm.vehicle_is_forklift = (vm.vehicle_type === 2);
                        }
                        vm.onChangeVehicleType();
                        vm.show_new_cars = true;
                        $rootScope.cotizador.show_used_cars = vm.show_used_cars;
                        $rootScope.cotizador.show_new_cars = vm.show_new_cars;
                        // Vehicle brand & models & versions.
                        if (vm.isVehicleTypeCarActive()) {
                            vm.vehicle_brand = vm.anonymous_channel.car.brand;
                            vm.vehicle_models = vm.anonymous_channel.car.models;
                        }
                        if (vm.isVehicleTypeForkliftActive()) {
                            vm.show_used_cars = false;
                            vm.vehicle_brand = vm.anonymous_channel.forklift.brand;
                            vm.vehicle_models = vm.anonymous_channel.forklift.models;
                        }
                        if (vm.isVehicleTypeTruckActive()) {
                            vm.show_used_cars = false;
                            vm.vehicle_brand = vm.anonymous_channel.truck.brand;
                            vm.vehicle_models = vm.anonymous_channel.truck.models;
                        }
                        vm.onUpdatedVehicleModels();
                        vm.onChangeVehicleBrand();
                        vm.onUpdatedVehicleModels(true);
                        if (vm.vehicle_version && vm.vehicle_version.__$vehicle_loan_info) {
                            vm.vehicle_version.__$vehicle_loan_info.promise.finally(__finish_get_anon_channel);
                        } else {
                            __finish_get_anon_channel();
                        }
                    }
                }).catch(function (error) {
                    $window.console.error(_LOG_TAG, error);
                    __loading_modal.rendered.then(function () {
                        __loading_modal.dismiss("error");
                    });
                    $rootScope.showErrorModal(_OPTION_INVALID_ERROR_MSG, $scope.$new(true));
                });
            });
        });
        // Plan change broadcast event handler.
        CotizadorService.onPlanUpdated($scope, function (event, plan) {
            var __filter_plans = true;
            vm.liftCampaignRestrictions();
            if (plan) {
                vm.plan = plan;
                vm.onChangePlan();
                __filter_plans = false;
            }
            vm.onCotizar(__filter_plans);
        });
        // Inner navigation change handler.
        vm.onHashChange = function () {
            var __state = $location.absUrl();
            if (__state === __nav_state) {
                $window.console.debug("Route update detected.", !!vm.vehicle_model);
                if (!vm.vehicle_model) {
                    __nav_back_state_enable = false;
                    vm.vehicle_model = __nav_step_model;
                    vm.onChangeVehicleModel();
                }
            } else {
                if (__state === __nav_prev_state) {
                    $window.console.debug("Route back detected.");
                    __nav_back_state_enable = true;
                    vm.vehicle_model = null;
                    vm.onChangeVehicleModel();
                } else {
                    $route.reload();
                }
            }
        };
        // Inner navigation updater.
        vm.hashUpdate = function (new_hash) {
            var __current_hash = $location.hash(),
                __current_url = $location.absUrl();
            if (!new_hash) {
                new_hash = Math.random().toString(36).substr(2, 5);
            }
            __nav_prev_state = __current_url;
            if (__current_hash) {
                __nav_state = __current_url.replace(__current_hash, new_hash);
            } else {
                __nav_state = __current_url + "#" + new_hash;
            }
            $location.hash(new_hash);
        };
        vm.updateVehicleModel = function (veh_mod) {
            if (veh_mod.id === __id_vehicle_model) {
                vm.vehicle_model = veh_mod;
                return true;
            }
            return false;
        };
        vm.getMarcaModelo = function () {
            CotizadorDAO.getMarcaModeloByTipo({
                p_tve_id_i: vm.vehicle_type,
                tipo_aplicacion: "TCFAutos"
            }).$promise.then(function (response) {
                var __finish_get_anon_channel = function () {
                    // Loan.
                    if (__loan_amount) {
                        vm.loan_amount = __loan_amount;
                        vm.onChangeLoanAmount(true);
                    }
                    // Vehicle ID.
                    if (__id_vehicle && (!vm.vehicle_id || (vm.vehicle_id !== __id_vehicle))) {
                        vm.vehicle_id = __id_vehicle;
                        vm.onChangeVehicleId();
                    }
                    if (vm.vehicle_model) {
                        __person_types_data_promise.promise.then(function () {
                            __initializing_modal.dismiss("close");
                        });
                    } else {
                        __initializing_modal.dismiss("close");
                        // Ser nav step.
                        __nav_back_state_enable = true;
                    }
                    vm.show_vehicle_type_selectors = true;
                    // Auto submit form on loads from shared links.
                    if (vm.vehicle_id && (__id_share_link > 0)) {
                        __person_types_data_promise.promise.then(function () {
                            vm.onCotizar();
                        });
                    }
                    if (vm.vehicle_version && !vm.vehicle_id) {
                        $rootScope.showErrorModal(_OPTION_INVALID_ERROR_MSG, $scope.$new(true));
                        $window.console.debug("Vehicle version without vehicle ID.");
                    }
                };

                $window.console.debug("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.getMarcaModeloByType ->", response);
                if (response.data) {
                    // Parse anonymous channel.
                    vm.anonymous_channel.vehicle = __parseVehiclesCollection(response.data.marcas);
                    // Dealer.
                    vm.dealer = {
                        cuit: _CUIT_DEALER_DEFAULT,
                        is_default: true
                    };
                    $rootScope.cotizador.dealer = vm.dealer;
                    __person_types_data_promise.promise.then(function () {
                        // Person type.
                        if (__id_person_type && (!vm.person_type || (vm.person_type.key !== __id_person_type))) {
                            vm.person_types.some(function (p_type) {
                                if (p_type.key === __id_person_type) {
                                    vm.person_type = p_type;
                                    return true;
                                }
                                return false;
                            });
                        }
                        vm.onChangePersonType();
                        // Tax condition.
                        if (__key_tax_condition && (!vm.tax_condition || (vm.tax_condition.key !== __key_tax_condition))) {
                            vm.person_type.taxes_conditions.some(function (tax_cond) {
                                if (tax_cond.key === __key_tax_condition) {
                                    tax_cond.selected = true;
                                    vm.tax_condition = tax_cond;
                                    return true;
                                }
                                return false;
                            });
                        }
                        vm.onChangeTaxCondition();
                    });
                    // Vehicle condition.
                    if (__vehicle_condition || (__vehicle_condition === 0)) {
                        vm.vehicle_condition_new = (__vehicle_condition === 1);
                    }
                    vm.onChangeVehicleCondition();
                    // Vehicle year.
                    vm.vehicle_year = (__vehicle_year || (new Date().getFullYear()));
                    vm.onChangeVehicleYear();
                    // Vehicle brand
                    if (__id_vehicle_brand) {
                        vm.vehicle_brand = {
                            id: __id_vehicle_brand,
                            name: __name_vehicle_brand
                        };
                    } else {
                        vm.vehicle_brand = vm.anonymous_channel.vehicle.brand;
                    }
                    vm.onChangeVehicleBrand();
                    // Vehicle type.
                    if (vm.vehicle_brand) {
                        if (__vehicle_type || (__vehicle_type === 0)) {
                            vm.vehicle_type = __vehicle_type;
                        }
                        vm.vehicle_is_car = ((vm.vehicle_type === 1) && (vm.vehicle_brand.id === 2)); // Check by Toyota brand ID.
                        vm.vehicle_is_truck = ((vm.vehicle_type === 3) && (vm.vehicle_brand.id !== 2)); // Check by Toyota brand ID.
                        vm.vehicle_is_forklift = (vm.vehicle_type === 2);
                    }
                    vm.onChangeVehicleType();
                    vm.disable_vehicle_type_forklift = false;
                    vm.disable_vehicle_type_car = false;
                    vm.disable_vehicle_type_truck = false;
                    vm.show_new_cars = true;
                    $rootScope.cotizador.show_used_cars = vm.show_used_cars;
                    $rootScope.cotizador.show_new_cars = vm.show_new_cars;
                    vm.vehicle_brand = vm.anonymous_channel.vehicle.brand;
                    vm.vehicle_models = vm.anonymous_channel.vehicle.models;
                    // Vehicle brand & models & versions.
                    if (vm.isVehicleTypeCarActive()) {
                        vm.show_used_cars = true;
                    }
                    if (vm.isVehicleTypeForkliftActive() || vm.isVehicleTypeTruckActive()) {
                        vm.show_used_cars = false;
                    }
                    vm.onUpdatedVehicleModels(true);
                    vm.onChangeVehicleBrand();
                    if (__id_vehicle_model) {
                        vm.vehicle_models.some(vm.updateVehicleModel);
                    }
                    vm.onChangeVehicleModel(__id_vehicle_version, true);
                    vm.onUpdatedVehicleModels();
                    // Plans.
                    vm.plans = [];
                    vm.plan = null;
                    vm.onChangePlan();
                    // Operation type.
                    if (__key_operation) {
                        vm.operations.some(function (operation) {
                            if (operation.key === __key_operation) {
                                vm.operation = operation;
                                return true;
                            }
                            return false;
                        });
                    }
                    vm.onChangeOperationType();
                    if (vm.vehicle_version && vm.vehicle_version.__$vehicle_loan_info) {
                        vm.vehicle_version.__$vehicle_loan_info.promise.finally(__finish_get_anon_channel);
                    } else {
                        __finish_get_anon_channel();
                    }
                }
            }).catch(function (error) {
                $window.console.error(_LOG_TAG, error);
                __initializing_modal.dismiss("error");
                $rootScope.showErrorModal(_OPTION_INVALID_ERROR_MSG, $scope.$new(true));
            });
        };
        // Deselects model, reactivates slider of current vehicle type
        vm.deselectModel = function () {
            vm.vehicle_model = null;
            __nav_step_model = null;
            $location.hash(null);
        };
        // Restore slider handler
        CotizadorService.onSliderRestored($scope, function () {
            vm.deselectModel();
        });

        // INITIALIZATION.
        // Detect hash changes (inner navigation scheme).
        $scope.$on("$routeUpdate", function () {
            vm.onHashChange();
        });
        // Show loading modal.
        __initializing_modal = $rootScope.showLoadingModal(_LOADING_INFO_PLEASE_WAIT_MSG, $scope.$new(true));
        if (__id_campaign) {
            $rootScope.cotizador.campaign_id = __id_campaign;
            // Send the API request ASAP. Saved as promise to delay the use of the response.
            __campaign_data_promise = CotizadorDAO.getCampaign({
                id: __id_campaign,
                ga_utm_source: __ga_utm_source,
                ga_utm_medium: __ga_utm_medium,
                ga_utm_campaign: __ga_utm_campaign
            }).$promise;
        }
        // Min loan amount.
        ParametrosService.get({key: "minimo_valor_financiable"}).$promise.then(function(param) {
            vm.loan_min_amount = parseInt(param.valor, 10);
            vm.onChangeLoanAmount(true);
        });
        // Show vehicle price.
        ParametrosService.get({key: "ver_precio_unidad"}).$promise.then(function(param) {
            vm.show_vehicle_price = ((param.valor || "").toLowerCase() === "true");
        });
        // Toyota ciclo.
        ParametrosService.get({key: "id_toyota_ciclo"}).$promise.then(function(param) {
            vm.toyotaCiclo = (parseInt(param.valor, 10) === __id_campaign);
        });
        // Get tooltips.
        CotizadorDAO.getAllTooltips().$promise.then(function (tooltips) {
            tooltips.forEach(function (tooltip_item) {
                switch (tooltip_item.code) {
                    case "OPERACION_LEASING":
                        vm.tooltip_operation_leasing = tooltip_item.text;
                        break;
                    case "OPERACION_PRENDARIO":
                        vm.tooltip_operation_retail = tooltip_item.text;
                        break;
                    case "CANON_ANTICIPADO":
                        vm.tooltip_canon = tooltip_item.text;
                        break;
                    default:
                        return null;
                }
            });
        }, function () {
            vm.tooltip_operation_leasing = __tooltip_error_text;
            vm.tooltip_operation_retail = __tooltip_error_text;
            vm.tooltip_canon = __tooltip_error_text;
        });
        // Get PAO campaign data.
        if (__campaign_data_promise) {
            vm.used_anonymous_channel = false;
            __campaign_data_promise.then(function (response) {
                var i, __image, __vehicle_models, __vehicle_versions, __plans, __installments, __aux_id,
                    __campaign_data = response.data,
                    __finish_get_campaign = function () {
                        // Vehicle price.
                        if (!vm.vehicle_price && __campaign_data.operacion && __campaign_data.operacion.valor_vehiculo) {
                            vm.vehicle_price = Math.round(parseFloat(__campaign_data.operacion.valor_vehiculo));
                            vm.onChangeVehiclePrice();
                        }
                        // Max loan.
                        if (!vm.loan_max_amount && __campaign_data.operacion && __campaign_data.operacion.maximo_financiar) {
                            vm.loan_max_amount = Math.floor(parseFloat(__campaign_data.operacion.maximo_financiar));
                            vm.onChangeLoanAmount(true);
                        }
                        // Loan.
                        __aux_id = (__loan_amount || (__campaign_data.operacion && __campaign_data.operacion.monto_financiar));
                        if (__aux_id) {
                            vm.loan_amount = parseFloat(__aux_id);
                            vm.onChangeLoanAmount(true);
                        }
                        // Vehicle ID.
                        if (__id_vehicle && (!vm.vehicle_id || (vm.vehicle_id !== __id_vehicle))) {
                            vm.vehicle_id = __id_vehicle;
                            vm.onChangeVehicleId();
                        }
                        if (vm.vehicle_model) {
                            __person_types_data_promise.promise.then(function () {
                                __initializing_modal.dismiss("close");
                            });
                        } else {
                            __initializing_modal.dismiss("close");
                            // Set nav step.
                            __nav_back_state_enable = true;
                        }
                        // Auto submit form on loads from shared links.
                        if (vm.vehicle_id && (__id_share_link > 0)) {
                            __person_types_data_promise.promise.then(function () {
                                vm.onCotizar();
                            });
                        }
                    };
                $window.console.debug("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.getCampaign ->", response);
                // Campaign.
                $rootScope.cotizador.campaign_data = __campaign_data;
                // Campaign Parameters
                var showTipoVehiculo = !!__campaign_data && !!__campaign_data.campania &&
                    !!__campaign_data.campania.show_tipo_vehiculo
                    ? Boolean(parseInt(__campaign_data.campania.show_tipo_vehiculo)) : true;
                var showLoginConcesionario = !!__campaign_data && !!__campaign_data.campania &&
                    !!__campaign_data.campania.show_login_concesionario
                    ? Boolean(parseInt(__campaign_data.campania.show_login_concesionario)) : true;
                vm.hideTipoVehiculo = !showTipoVehiculo;
                $rootScope.hideLoginConcesionario = !showLoginConcesionario;
                // Dealer.
                if (__campaign_data.concesionario) {
                    vm.dealer = {
                        id: (__campaign_data.concesionario.cuit || 0),
                        cuit: __campaign_data.concesionario.cuit,
                        logo: TCFAAppConfigService.API.IMG_SRC + "/dealer_images/" + __campaign_data.concesionario.cuit + "/logo.png",
                        logo_pdf: TCFAAppConfigService.API.IMG_SRC + "/dealer_images/" + __campaign_data.concesionario.cuit + "/logo_pdf.png",
                        name: __campaign_data.concesionario.nombre,
                        is_default: (__campaign_data.concesionario.cuit && (__campaign_data.concesionario.cuit === _CUIT_DEALER_DEFAULT))
                    };
                    __image = new Image();
                    __image.src = vm.dealer.logo;
                    __image.onload = function () {
                        vm.dealer.logo_loaded = true;
                    };
                    __image.onerror = function () {
                        vm.dealer.logo_error = true;
                    };
                    $rootScope.cotizador.dealer = vm.dealer;
                    $rootScope.cotizador.from_campaign = true;
                    // Get Dealer ID + Branches. (It Should be returned by GetCampaign.)
                    ConcesionariosDAO.get({sucursales: true}, function (concesionarios_response) {
                        (concesionarios_response || []).some(function (conc_item) {
                            if (conc_item.cuit === vm.dealer.cuit) {
                                vm.dealer.id = conc_item.id;
                                vm.dealer.branches = conc_item.sucursales;
                                $rootScope.cotizador.dealer = vm.dealer;
                                return true;
                            }
                            return false;
                        });
                    });
                }
                // Operation.
                if (__campaign_data.operacion) {
                    __person_types_data_promise.promise.then(function () {
                        // Person type.
                        __aux_id = (__id_person_type || __campaign_data.operacion.tipo_persona);
                        if (__aux_id && (!vm.person_type || (vm.person_type.key !== __aux_id))) {
                            vm.person_types.some(function (p_type) {
                                if (p_type.key === __aux_id) {
                                    vm.person_type = p_type;
                                    vm.onChangePersonType();
                                    return true;
                                }
                                return false;
                            });
                        }
                        // Tax condition.
                        __aux_id = (__key_tax_condition || __campaign_data.operacion.condicion_impositiva);
                        if (__aux_id && (!vm.tax_condition || (vm.tax_condition.key !== __aux_id))) {
                            vm.person_type.taxes_conditions.some(function (tax_cond) {
                                if (tax_cond.key === __aux_id) {
                                    tax_cond.selected = true;
                                    vm.tax_condition = tax_cond;
                                    return true;
                                }
                                return false;
                            });
                            vm.onChangeTaxCondition();
                        }
                    });
                    // Vehicle condition.
                    __aux_id = __campaign_data.operacion.vehiculo_nuevo;
                    if (__vehicle_condition || (__vehicle_condition === 0)) {
                        __aux_id = __vehicle_condition;
                    }
                    vm.vehicle_condition_new = (__aux_id === 1);
                    vm.onChangeVehicleCondition();
                    // Vehicle year.
                    vm.vehicle_year = (__vehicle_year || __campaign_data.operacion.anio || (new Date().getFullYear()));
                    vm.onChangeVehicleYear();
                    // Vehicle brand.
                    vm.vehicle_brand = {
                        id: (__id_vehicle_brand || __campaign_data.operacion.id_marca),
                        name: (__name_vehicle_brand || __campaign_data.operacion.marca_desc)
                    };
                    vm.onChangeVehicleBrand();
                    // Vehicle type.
                    __aux_id = __campaign_data.operacion.tipo_vehiculo;
                    if (__vehicle_type || (__vehicle_type === 0)) {
                        __aux_id = __vehicle_type;
                    }
                    vm.vehicle_type = __aux_id;
                    vm.vehicle_is_car = ((vm.vehicle_type === 1) && (vm.vehicle_brand.id === 2)); // Check by Toyota brand ID.
                    vm.vehicle_is_truck = ((vm.vehicle_type === 3) && (vm.vehicle_brand.id !== 2)); // Check by Toyota brand ID.
                    vm.vehicle_is_forklift = (vm.vehicle_type === 2);
                    vm.onChangeVehicleType();
                    // Vehicle types.
                    vm.disable_vehicle_type_forklift = !vm.vehicle_is_forklift;
                    vm.disable_vehicle_type_car = !vm.vehicle_is_car;
                    vm.disable_vehicle_type_truck = !vm.vehicle_is_truck;
                    // Vehicle models & versions.
                    __vehicle_models = [];
                    if (__campaign_data.operacion.modelos && __campaign_data.operacion.modelos.modelo) { // "modelo" can be an array or an object.
                        if (__campaign_data.operacion.modelos.modelo.length) {
                            __vehicle_models = __campaign_data.operacion.modelos.modelo;
                        } else {
                            __vehicle_models = [__campaign_data.operacion.modelos.modelo];
                        }
                    }
                    for (i = 0; i < __vehicle_models.length; i = i + 1) {
                        __vehicle_versions = [];
                        if (__vehicle_models[i].versiones && __vehicle_models[i].versiones.version) {
                            if (__vehicle_models[i].versiones.version.length) {
                                __vehicle_versions = __vehicle_models[i].versiones.version;
                            } else {
                                __vehicle_versions = [__vehicle_models[i].versiones.version];
                            }
                        }
                        __vehicle_models[i].versiones = __vehicle_versions;
                    }
                    vm.vehicle_models = __vehicle_models;
                    if (__id_vehicle_model) {
                        vm.vehicle_models.some(vm.updateVehicleModel);
                    } else {
                        if (vm.vehicle_models.length === 1) {
                            vm.vehicle_model = vm.vehicle_models[0];
                        }
                    }
                    vm.onChangeVehicleModel(__id_vehicle_version, true);
                    vm.show_used_cars = false;
                    vm.show_new_cars = true;
                    if (__campaign_data.operacion.vehiculos_seleccionados) {
                        vm.show_used_cars = ((__campaign_data.operacion.vehiculos_seleccionados.codigo === 0) ||
                            (__campaign_data.operacion.vehiculos_seleccionados.codigo === 2));
                        vm.show_new_cars = ((__campaign_data.operacion.vehiculos_seleccionados.codigo === 1) ||
                            (__campaign_data.operacion.vehiculos_seleccionados.codigo === 2));
                    }
                    vm.onUpdatedVehicleModels(true);
                    $rootScope.cotizador.show_used_cars = vm.show_used_cars;
                    $rootScope.cotizador.show_new_cars = vm.show_new_cars;
                    // Plans.
                    if (__campaign_data.operacion.planes) {
                        __plans = [];
                        if (__campaign_data.operacion.planes && __campaign_data.operacion.planes.plan) {
                            if (__campaign_data.operacion.planes.plan.length) {
                                __plans = __campaign_data.operacion.planes.plan;
                            } else {
                                __plans = [__campaign_data.operacion.planes.plan];
                            }
                        }
                        vm.plans = [];
                        for (i = 0; i < __plans.length; i = i + 1) {
                            __installments = [];
                            if (__plans[i].subplanes && __plans[i].subplanes.subplan) {
                                if (__plans[i].subplanes.subplan.length) {
                                    __installments = __plans[i].subplanes.subplan;
                                } else {
                                    __installments = [__plans[i].subplanes.subplan];
                                }
                            }
                            vm.plans.push({
                                id: __plans[i].id,
                                name: __plans[i].descripcion,
                                installments: __installments
                            });
                        }
                        vm.plan = null;
                        // "__id_plan" is not currently used to filter the campaign plans.
                        if (vm.plans.length === 1) {
                            vm.plan = vm.plans[0];
                        }
                        vm.onChangePlan();
                    }
                    // Operation type.
                    __aux_id = (__key_operation || __campaign_data.operacion.tipo_operacion);
                    if (__aux_id) {
                        vm.operations.some(function (operation) {
                            if (operation.key === __aux_id) {
                                operation.id = __campaign_data.operacion.tipo_operacion_id;
                                vm.operation = operation;
                                vm.onChangeOperationType();
                                return true;
                            }
                            return false;
                        });
                    }
                    // Enforce campaign restrictions.
                    vm.disable_operation_change = !__campaign_data.operacion.tipo_operacion_todos;
                    vm.disable_person_type_change = !__campaign_data.operacion.tipo_persona_todos;
                    vm.disable_tax_condition_change = !__campaign_data.operacion.condicion_impositiva_todos;
                }
                if (vm.vehicle_version && vm.vehicle_version.__$vehicle_loan_info) {
                    vm.vehicle_version.__$vehicle_loan_info.promise.finally(__finish_get_campaign);
                } else {
                    __finish_get_campaign();
                }
            }, function (error) {
                $window.console.error("Cotizador_SeccionCotizadorCtrl - CotizadorDAO.getCampaign ->", error);
                if (error.status === 404) {
                    CotizadorService.redirectToAnonymousChannel();
                } else {
                    __initializing_modal.dismiss("error");
                    $rootScope.showErrorModal(_OPTION_INVALID_ERROR_MSG, $scope.$new(true));
                }
            });
        } else { // Get Anonymous Channel.
            $rootScope.cotizador.campaign_id = null;
            $rootScope.cotizador.campaign_data = null;
            $rootScope.cotizador.from_campaign = false;
            vm.liftCampaignRestrictions();
            vm.used_anonymous_channel = true;
            $timeout(function () {
                vm.getMarcaModelo();
            }, 100);
        }
        vm.liftCampaignRestrictions();
    };
    Cotizador_SeccionCotizadorCtrl.$inject = moduleConfig.Cotizador_SeccionCotizadorCtrl.deps;
    module.controller(moduleConfig.Cotizador_SeccionCotizadorCtrl.name, Cotizador_SeccionCotizadorCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Cotizador_SeccionPlazosCtrl",
            deps: ["TCFAutos.services.CotizadorDAO", "TCFAutos.services.CotizadorService", "TCFAutos.services.ParametrosService",
                "TCFAutos.services.DependencyInjectionService"],
            Cotizador_SeccionPlazosCtrl: {
                name: "Cotizador_SeccionPlazosCtrl",
                deps: ["$rootScope", "$scope", "$window", "$httpParamSerializer", "$location", "$anchorScroll", "$uibModal", "$q",
                    "$filter", "$routeParams", "$timeout", "$route", "CotizadorDAO", "CotizadorService", "TCFAAppConfigService",
                    "ParametrosService", "TermsConditionsDAO", "facebookService", "DependencyInjectionService", "LogInUser",
                    "StatsDAO", "UtilsHelper"]
            }
        },
        module, Cotizador_SeccionPlazosCtrl,
        _WINDOW_MODAL_CLASS = "tcfa-modal",
        _MODAL_COMPONENT_HTML_ID_SELECTOR = "#modalComponent",
        _MESSAGE_LOADING_DATA = "Cargando información...";
    module = angular.module("TCFAutos");
    Cotizador_SeccionPlazosCtrl = function ($rootScope, $scope, $window, $httpParamSerializer, $location, $anchorScroll, $uibModal, $q,
                                            $filter, $routeParams, $timeout, $route, CotizadorDAO, CotizadorService, TCFAAppConfigService,
                                            ParametrosService, TermsConditionsDAO, facebookService, DependencyInjectionService, LogInUser,
                                            StatsDAO, UtilsHelper) {
        var vm = this,
            __ID_PROV_TDF = "23", // Hardcoded ID provincia Tierra del Fuego.
            // Get QueryString params.
            __id_share_link = (parseInt($routeParams.il, 10) || 0),
            __id_plazo = (parseInt($routeParams.pl, 10) || 0),
            __id_subplan = (parseInt($routeParams.is, 10) || 0),
            __id_insurance = $routeParams.ii,
            __name_insurance_company = $routeParams.icn,
            __id_insurance_company = (parseInt($routeParams.ici, 10) || 0),
            __code_insurance_pack = $routeParams.ipi,
            __name_insurance_pack = $routeParams.ipn,
            __name_insurance_zone = $routeParams.izn,
            __cp_insurance_zone = $routeParams.izcp,
            __name_insurance_prov = $routeParams.izpn,
            __id_insurance_prov = $routeParams.izpi,
            __insurance_installment = (parseInt($routeParams.iic, 10) || 0),
            __insurance_detail = $window.atob($routeParams.idet || ""),
            __used_shared_link = false,
            __tooltip_error_text = "No se pudo obtener la información solicitada. Por favor intente nuevamente más tarde. Disculpe las molestias ocasionadas.",
            __getPlanPDF,
            /** Summarizes an array of Floats. Designed to be used by the "reduce" method of an Array object. */
            __sumReducer = function (accumulated, current) {
                return parseFloat(accumulated) + parseFloat(current);
            },
            getInstallmentsDataFiltered = function (installments, subplan_id) {
                var __data = (installments.cuadro_de_marcha || []);
                return __data.filter(function (installments_data) {
                    return (installments_data.p_sup_id_i === subplan_id);
                });
            },
            __legal_text = "",
            __legal_text_promo = "",
            __legal_text_uva = "",
            __legal_text_promise = ParametrosService.getAllParameters().$promise,
            __legal_text_promise_promo = ParametrosService.getAllParameters().$promise,
            __legal_text_promise_uva = ParametrosService.getAllParameters().$promise,
            __q_legal_text = $q.defer(),
            __q_legal_text_promo = $q.defer(),
            __q_legal_text_uva = $q.defer(),
            __error_legal_text = false,
            __error_legal_text_promo = false,
            __error_legal_text_uva = false,
            __errorLoadingLegalTextComplete = function (plan) {
                if (!plan) {
                    return;
                }
                plan.error_legal_text = true;
                plan.loading_legal_text = false;
                plan.legal_text_defer.reject();
            },
            __replace_dynamic_vars = function (plan) {
                $q.all([
                    ParametrosService.getAllParameters().$promise,
                    plan.installments_info_promise
                ]).then(function (data) {
                    var __hora_limite_liquidacion, __costo_mensual, __costo_mensual_param_key, __fecha_inicio,
                        __fecha_fin,
                        __time_format = "DD/MM/YYYY",
                        __params_data = data[0],
                        __installments_data = getInstallmentsDataFiltered(data[1].data || {}, plan.subplan_id);
                    __hora_limite_liquidacion = __params_data.filter(function (param_item) {
                        return (param_item.clave === "cotizador_hora_limite_liquidacion");
                    });
                    if (__hora_limite_liquidacion.length > 0) {
                        __hora_limite_liquidacion = __hora_limite_liquidacion[0].valor;
                    } else {
                        __errorLoadingLegalTextComplete(plan);
                    }
                    __costo_mensual_param_key = "cotizador_costo_mensual_";
                    if (vm.operation_is_retail) {
                        __costo_mensual_param_key = __costo_mensual_param_key + "re";
                    } else {
                        __costo_mensual_param_key = __costo_mensual_param_key + "le";
                    }
                    __costo_mensual = __params_data.filter(function (param_item) {
                        return (param_item.clave === __costo_mensual_param_key);
                    });
                    if (__costo_mensual.length > 0) {
                        __costo_mensual = __costo_mensual[0].valor;
                    } else {
                        __errorLoadingLegalTextComplete(plan);
                    }
                    if (__installments_data.length === 1) {
                        __fecha_inicio = window.moment(__installments_data[0].p_fecha_vig_desde, "YYYY-MM-DDTHH:mm:ss");
                        __fecha_fin = window.moment(__installments_data[0].p_fecha_vig_hasta, "YYYY-MM-DDTHH:mm:ss");
                        plan.legal_text = plan.legal_text
                            .replace("%TEXTO_GRANDE_INICIO%", "<span class=\"texto-legal-grande\">")
                            .replace("%TEXTO_GRANDE_FIN%", "</span>")
                            .replace("%TNA%", $filter("number")(__installments_data[0].tna, 2))
                            .replace("%TEA%", $filter("number")(__installments_data[0].tea, 2))
                            .replace("%CFT%", $filter("number")(__installments_data[0].cft, 2))
                            .replace("%CFTEF%", $filter("number")(__installments_data[0].cft_efectivo, 2))
                            .replace("%COSTO_MENSUAL_LEASING_PRENDARIO%", $filter("number")(__costo_mensual, 2))
                            .replace("%PROMO_MODELO%", ((($rootScope.cotizador && $rootScope.cotizador.vehicle_model &&
                                $rootScope.cotizador.vehicle_model.descripcion) || "") + " " + (($rootScope.cotizador &&
                                $rootScope.cotizador.vehicle_version && $rootScope.cotizador.vehicle_version.descripcion) || "")))
                            .replace("%PROMO_MONTO_MAXIMO_FINANCIAR%", $filter("number")(plan.promo_amount, 2))
                            .replace("%PROMO_FECHA_INICIO%", (__fecha_inicio.isValid() && __fecha_inicio.format(__time_format)) || "ERROR")
                            .replace("%FROMO_FECHA_FIN%", (__fecha_fin.isValid() && __fecha_fin.format(__time_format)) || "ERROR")
                            .replace("%PROMO_FECHA_LIMITE_LIQUIDACION%", (__fecha_fin.isValid() && __fecha_fin.add(30, "days").format(__time_format)) || "ERROR")
                            .replace("%PROMO_HORA_LIMITE_LIQUIDACION%", __hora_limite_liquidacion);
                        plan.legal_text_defer.resolve(plan.legal_text);
                    } else {
                        __errorLoadingLegalTextComplete(plan);
                    }
                }, function () {
                    __errorLoadingLegalTextComplete(plan);
                });
            },
            __getLegalTextsForPlan = function (plan) {
                if (!plan || plan.legal_text_defer) {
                    return;
                }
                plan.legal_text_defer = $q.defer();
                plan.loading_legal_text = true;
                plan.error_legal_text = false;
                if (plan.promo) {
                    $q.all([
                        __q_legal_text.promise,
                        __q_legal_text_promo.promise,
                        plan.installments_info_promise
                    ]).then(function () {
                        if (!__error_legal_text_promo && !__error_legal_text) {
                            plan.legal_text = __legal_text.termino_condicion.content + __legal_text_promo.termino_condicion.content;
                            plan.loading_legal_text = false;
                            __replace_dynamic_vars(plan);
                        } else {
                            __errorLoadingLegalTextComplete(plan);
                        }
                    }, function () {
                        __errorLoadingLegalTextComplete(plan);
                    });
                } else if (plan.uva) {
                    $q.all([
                        __q_legal_text_uva.promise,
                        plan.installments_info_promise
                    ]).then(function () {
                        if (!__error_legal_text_uva) {
                            plan.legal_text = __legal_text.termino_condicion.content + __legal_text_uva.termino_condicion.content;
                            plan.loading_legal_text = false;
                            __replace_dynamic_vars(plan);
                        } else {
                            __errorLoadingLegalTextComplete();
                        }
                    }, __errorLoadingLegalTextComplete);
                } else {
                    $q.all([
                        __q_legal_text.promise,
                        plan.installments_info_promise
                    ]).then(function () {
                        if (!__error_legal_text) {
                            plan.legal_text = __legal_text.termino_condicion.content;
                            plan.loading_legal_text = false;
                            __replace_dynamic_vars(plan);
                        } else {
                            __errorLoadingLegalTextComplete();
                        }
                    }, __errorLoadingLegalTextComplete);
                }
            },
            calculateInstallments = function (plan, auto_select_plan) {
                var __loading_modal;
                if (auto_select_plan) {
                    __loading_modal = $rootScope.showLoadingModal("Aguarde unos instantes mientras cotizamos el seguro del vehículo.", $scope.$new(true));
                }
                plan.disable_installments = true;
                plan.installments = [];
                plan.installments_info_promise.then(function (data) {
                    var i,
                        __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);
                    if (__installments_data.length === 1) {
                        plan.tna = __installments_data[0].tna;
                        plan.cft = __installments_data[0].cft;
                        plan.cft_efectivo = __installments_data[0].cft_efectivo;
                        plan.cft_seguro = __installments_data[0].cft_seguro;
                        plan.cft_seguro_iva = __installments_data[0].cft_seguro_iva;
                        plan.primera_cuota_con_seguro = parseFloat(__installments_data[0].primera_cuota_con_seguro);
                        plan.ultima_cuota_con_seguro = parseFloat(__installments_data[0].ultima_cuota_con_seguro);
                        for (i = 0; i < plan.installments_count; i = i + 1) {
                            plan.installments.push({
                                number: i + 1,
                                // Cuota Pura = Capital + Interés.
                                cuota_pura: (parseFloat(__installments_data[0].capital[i] || 0) +
                                    parseFloat(__installments_data[0].interes[i] || 0)),
                                // Canon = Capital + Interés.
                                canon: (parseFloat(__installments_data[0].capital[i] || 0) +
                                    parseFloat(__installments_data[0].interes[i] || 0)),
                                // Costo Mensual = Gasto.
                                costo_mensual: parseFloat(__installments_data[0].gasto || 0),
                                // IVA = IVA Interés + Gasto IVA.
                                iva: (parseFloat(__installments_data[0].iva_interes[i] || 0) +
                                    parseFloat(__installments_data[0].gasto_iva || 0))
                            });
                        }
                        // Si el plan es de tipo UVA, solamente me quedo con la primera installment
                        if (plan.uva) {
                            plan.installments = plan.installments.slice(0, 1);
                        }
                        // Columna de ordenamiento retail
                        plan.order_retail = plan.uva ? plan.primera_cuota_con_seguro : 0;
                        plan.disable_installments = false;
                        if (__loading_modal) {
                            __loading_modal.dismiss("close");
                        }
                        if (auto_select_plan) {
                            vm.onSelectPlan(plan, auto_select_plan);
                        }
                    } else {
                        if (__loading_modal) {
                            __loading_modal.dismiss("error");
                        }

                        plan.error_installments = true;
                    }
                }, function () {
                    if (__loading_modal) {
                        __loading_modal.dismiss("error");
                    }
                    plan.error_installments = true;
                });

                // Check favorites if user is logged in.
                if ($rootScope.userInfo.user) {
                    plan.checking_favorite = true;
                    CotizadorDAO.favoriteCheck({
                        id_campania: $rootScope.cotizador.campaign_id,
                        id_plan: plan.plan_id,
                        id_subplan: plan.subplan_id
                    }).$promise.then(function (check_fav_response) {
                        var __is_favorite = (check_fav_response.data);
                        plan.favorite = ((typeof __is_favorite === "boolean") && __is_favorite);
                        plan.checking_favorite = false;
                    }, function () {
                        plan.checking_favorite_error = true;
                        plan.checking_favorite = false;
                    });
                }
            },
            //genera la informacion de cuadro de marcha para cotizador
            getPlanesLoanInfoCotizador = function (plazo, caida_de_cuotas) {
                plazo.installments_info_loading = true;
                plazo.installments_info_promise = new Promise(function (resolve) {
                    resolve({"data": {"cuadro_de_marcha": caida_de_cuotas}});
                });
                plazo.installments_info_promise.then(function () {
                    plazo.installments_info_loading = false;
                }, function () {
                    plazo.installments_info_error = true;
                    plazo.installments_info_loading = false;
                });
                plazo.planes.forEach(function (plan_item) {
                    var __auto_select_plan = ((__id_share_link >= 2) && !__used_shared_link && __id_subplan && (plan_item.subplan_id === __id_subplan));
                    if (__auto_select_plan) {
                        __used_shared_link = true;
                    }
                    plan_item.installments_info_promise = plazo.installments_info_promise;
                    calculateInstallments(plan_item, __auto_select_plan);
                });
            },
            //genera la informacion de cuadro de marcha para publicaciones
            getPlanesLoanInfoPublicaciones = function (plazo) {
                if (!plazo.installments_info_promise) {
                    vm.getCuadroMarcha(plazo);
                }
            },
            // "checkCotizacionSeguro" helper vars.
            _seguro_cotizado = false,
            _seguro_cotizado_con_restriccion_provincias = false,
            _cotizacion_seguro = {},
            /** Resets the user selected loan insurance. */
            resetCotizacionSeguro = function () {
                _seguro_cotizado = false;
                _cotizacion_seguro = {};
                vm.insurance = null;
                vm.plazos.forEach(function (plazo) {
                    if (plazo.planes) {
                        plazo.planes.forEach(function (plan) {
                            plan.collapsed = true;
                        });
                    }
                });
            },
            /** Checks if the user selected a loan insurance. Returns promise that resolves when the insurance is selected. */
            checkCotizacionSeguro = function (reset, plan) {
                var __modal, __operation_data,
                    __restrict_provincia = !!(plan && plan.es_TDF),
                    __seguro_promise = $q.defer(),
                    __modal_scope = $scope.$new(true),
                    __tcfaSegurosSDKPromise = DependencyInjectionService.loadApi("TCFASeguros");
                // Reset insurance.
                reset = !!reset;
                __seguro_promise.promise.then(function (cotizacion_seguro) {
                    CotizadorDAO.dateroLog({
                        lt: 7, // Log type.
                        sid: JSON.stringify(cotizacion_seguro),
                        App: "Cotizador"
                    });
                    vm.onChangeLoanInsuranceData(cotizacion_seguro);
                });
                if ((_seguro_cotizado && !reset) &&
                    ((_seguro_cotizado_con_restriccion_provincias && __restrict_provincia) ||
                        (!_seguro_cotizado_con_restriccion_provincias && !__restrict_provincia))) {
                    __seguro_promise.resolve(_cotizacion_seguro);
                    return __seguro_promise.promise;
                }
                if (!$rootScope.cotizador) {
                    __seguro_promise.reject("Información faltante.");
                    return __seguro_promise.promise;
                }
                // Register Log event only if plan specified (not on onChangeInsurance)
                if (plan) {
                    // Datero Log event 5: "VerCuotas (cotiza seguro)"
                    // user clicks [ver cuotas] to select insurance (insurance not selected yet).
                    CotizadorDAO.dateroLog({
                        lt: 5, // Log type.
                        pl: plan.installments_count,
                        ipl: plan.plan_id, // Plan ID.
                        isl: plan.subplan_id, // Subplan ID.
                        ic: $rootScope.cotizador.campaign_id, // Campaign ID.
                        co: ($rootScope.cotizador.campaign_data && $rootScope.cotizador.campaign_data.campania &&
                            $rootScope.cotizador.campaign_data.campania.origen), // Campaign origin.
                        dcu: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit), // Dealer's CUIT.
                        ccu: ($rootScope.userInfo && $rootScope.userInfo.navigate_as), // Client's CUIT.
                        ce: ($rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.user.email), // Client's e-mail.
                        ip: null, // Publication ID.
                        App: "Cotizador",
                        EsCertificado: false
                    });
                }
                // Required params.
                __operation_data = {
                    p_tipo_operacion: ($rootScope.cotizador.operation_type && $rootScope.cotizador.operation_type.id),
                    p_cuit_dealer: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit),
                    p_id_vehiculo_i: ($rootScope.cotizador && $rootScope.cotizador.vehicle_id),
                    id_publicacion: (($rootScope.cotizador.publicacion && $rootScope.cotizador.publicacion.id) || 0)
                };
                // Check vehicle type.
                if (!vm.vehicle_is_car) { // Trucks and Forklifts
                    _seguro_cotizado_con_restriccion_provincias = false;
                } else { // Vehicle type = car. --> Open TCFA Seguros widget integration to get insurance value.
                    if (_cotizacion_seguro && __restrict_provincia && (!_cotizacion_seguro.provincia || _cotizacion_seguro.provincia.id !== __ID_PROV_TDF)) {
                        resetCotizacionSeguro();
                    }
                    _seguro_cotizado_con_restriccion_provincias = __restrict_provincia;
                    __operation_data.pre_cotizacion = _cotizacion_seguro;
                }
                // Optional params.
                // Se omiten las propiedades id_cotizacion, p_edad, y p_cod_uso, dado que no existen en este contexto.
                __operation_data.p_cod_cond_fiscal = ($rootScope.cotizador.tax_condition && $rootScope.cotizador.tax_condition.id);
                __operation_data.p_es_usado = !!!$rootScope.cotizador.vehicle_condition_new;
                __operation_data.tipo_vehiculo = ($rootScope.cotizador.vehicle_type);
                __operation_data.p_es_auto = vm.vehicle_is_car;
                __operation_data.p_es_persona_fisica = (($rootScope.cotizador.person_type && ($rootScope.cotizador.person_type.key === "F")) || false);
                __operation_data.p_anio = ((__operation_data.p_es_usado && $rootScope.cotizador.vehicle_year) || (new Date()).getFullYear());
                if (_seguro_cotizado_con_restriccion_provincias) {
                    __operation_data.restrict_provincia_id = __ID_PROV_TDF;
                }
                // Set origin properties: application and session_id.
                __operation_data.origin_session_id = $rootScope.session_token.data.id;
                if ($rootScope.cotizador.campaign_id) {
                    __operation_data.origin_application = "TCFAutos Cotizador Campaña " + $rootScope.cotizador.campaign_id;
                } else if ($rootScope.cotizador.publicacion && $rootScope.cotizador.publicacion.id) {
                    __operation_data.origin_application = "TCFAutos Publicacion " + $rootScope.cotizador.publicacion.id;
                } else {
                    __operation_data.origin_application = "Cotizador Toyota";
                }
                $rootScope.fixScreen();
                __modal = $uibModal.open({
                    animation: true,
                    windowClass: _WINDOW_MODAL_CLASS,
                    backdrop: true,
                    keyboard: false,
                    templateUrl: "./views/cotizador/modal/modal_seguro.html",
                    controller: ["$scope", "$window", function ($scope, $window) {
                        $scope.onInit = function () {
                            __modal.rendered.then(function () {
                                __tcfaSegurosSDKPromise.then(function () {
                                    $window.TCFASegurosSDK.init({
                                        environment: TCFAAppConfigService.SEGUROS.ENV,
                                        container: "iframe-modal-seguros-container",
                                        operation: __operation_data,
                                        done: function (operation_result) {
                                            if (operation_result.success) {
                                                $scope.$dismiss("success");
                                                _cotizacion_seguro = operation_result;
                                                _seguro_cotizado = true;
                                                __seguro_promise.resolve(_cotizacion_seguro);
                                            } else {
                                                $scope.$dismiss("fail");
                                                __seguro_promise.reject(operation_result.reason);
                                            }
                                        },
                                        fail: function (error) {
                                            __modal.closed.then(function () {
                                                __seguro_promise.reject(error.message);
                                            });
                                            $scope.$dismiss("error");
                                        },
                                        update: function (status) {
                                            var __modal_container = angular.element(_MODAL_COMPONENT_HTML_ID_SELECTOR);
                                            if (__modal_container.length > 0) {
                                                __modal_container = __modal_container[0].querySelector(".modal-dialog");
                                                if (__modal_container && __modal_container.classList && status.width) {
                                                    if (status.width === "full") {
                                                        __modal_container.classList.add("full");
                                                        __modal_container.classList.remove("half");
                                                    } else { // "half".
                                                        __modal_container.classList.add("half");
                                                        __modal_container.classList.remove("full");
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }, function () { // Load SDK Seguros failed.
                                    __modal.closed.then(function () {
                                        __seguro_promise.reject("Cannot load TCFA Seguros SDK.");
                                    });
                                    $scope.$dismiss("error");
                                });
                            });
                        };
                    }],
                    controllerAs: "modalSeguro",
                    bindToController: true,
                    scope: __modal_scope,
                    appendTo: angular.element(_MODAL_COMPONENT_HTML_ID_SELECTOR),
                    openedClass: "modal-seguro-wrapper"
                });
                __modal.closed.then(function () {
                    $rootScope.unfixScreen();
                });
                __modal.result.then(function () {
                    __seguro_promise.reject("close");
                }, function (reason) {
                    if (reason.indexOf("backdrop") > -1) {
                        __seguro_promise.reject("close");
                    }
                });
                return __seguro_promise.promise;
            },
            loading_choose_plan = false;

        window.moment.locale("es");

        // Init Cotizador.
        if (!$rootScope.cotizador) {
            $rootScope.cotizador = {};
        }

        // DECLARATIONS.
        vm.showPlazos = false;
        vm.planes_plazos = null;
        vm.plazos = [];
        vm.show_detail_plazo = false;
        vm.operation_is_retail = true;
        vm.pre_selected_plazos = {};
        if (__id_plazo) {
            vm.pre_selected_plazos[__id_plazo.toString()] = true;
        }
        vm.tooltip_cuota_final = _MESSAGE_LOADING_DATA;
        vm.tooltip_cuota_final_uva = _MESSAGE_LOADING_DATA;
        vm.tooltip_canon_final = _MESSAGE_LOADING_DATA;
        vm.tooltip_canon_anticipado = _MESSAGE_LOADING_DATA;
        vm.insurance = null;
        vm.plan = null;
        vm.vehicle_version = null;
        vm.vehicle_iva = null;
        vm.getLoanInsuranceInstallment = function () {
            return ((vm.insurance && parseFloat(vm.insurance.installments)) || 0);
        };
        vm.getLegalSeguro = function () {
            // Legal text for Cars.
            var __insurance_text = "El seguro corresponde a " + ((vm.insurance && vm.insurance.name) || "") +
                ", pack " + ((vm.insurance && vm.insurance.pack) || "") +
                ", zona " + ((vm.insurance && vm.insurance.zone) || "") +
                ", " + ((vm.insurance && vm.insurance.prov) || ""),
                __additional_legal_text = ". El premio del seguro podrá registrar variaciones si se selecciona otra aseguradora y/u otra zona de guarda del vehículo" +
                    ". El valor del seguro es estimado y depende de las variaciones de la aseguradora elegida.";
            if (!vm.vehicle_is_car) {
                // Legal text for Trucks.
                if (($rootScope.cotizador.vehicle_type === 3)) {
                    __insurance_text = "Seguro correspondiente a " + ((vm.insurance && vm.insurance.name) || "") + ", " + ((vm.insurance && vm.insurance.pack) || "");
                    __additional_legal_text = ". El valor del seguro dependerá de la aseguradora seleccionada, uso, lugar de guarda, cobertura y adicionales" +
                        ". El valor del seguro es meramente estimativo y no corresponde una oferta por parte de Toyota Compañía Financiera de Argentina S.A. (TCFA).";
                }
                // Legal text dor Forklifts.
                if ($rootScope.cotizador.vehicle_type === 2) {
                    __insurance_text = "El seguro corresponde a " + ((vm.insurance && vm.insurance.name) || "") + ", " + ((vm.insurance && vm.insurance.pack) || "");
                    __additional_legal_text = ". El valor del seguro dependerá de la aseguradora seleccionada, uso, lugar de guarda, cobertura y adicionales" +
                        ". El valor del seguro es meramente estimativo y no corresponde una oferta por parte de Toyota Compañía Financiera de Argentina S.A. (TCFA).";
                }
            }
            return __insurance_text + __additional_legal_text;
        };
        vm.getAclaracionCaidaDeCuota = function () {
            return "La cuota pura fue calculada con la cotización de UVA utilizada por TCFA al día de hoy, " +
                "por lo cual podrá diferir al momento del otorgamiento del crédito.";
        };
        // Cuota Final = Cuota Pura + Seguro Vehículo + Costo Mensual + IVA.
        // 1° Cuota Final = Cuota Final + ITF.
        vm.getCuotaFinal = function (installment, uva) {
            return (installment.cuota_pura || 0) +
                (vm.getLoanInsuranceInstallment() || 0) +
                (installment.costo_mensual || 0) +
                (installment.iva || 0) +
                ((!uva && installment.number === 1) ? (vm.loan_amount * 0.012) : 0);
        };
        // Canon Final = Canon + Seguro del Vehículo + Costo Mensual + IVA
        vm.getCanonFinal = function (installment) {
            return (installment.canon || 0) +
                (vm.getLoanInsuranceInstallment() || 0) +
                (installment.costo_mensual || 0) +
                (installment.iva || 0);
        };
        vm.getPrimeraCuota = function (plan) {
            if (!vm.operation_is_retail) {
                return null;
            }
            if (plan.primera_cuota_con_seguro || (plan.primera_cuota_con_seguro === 0)) {
                return plan.primera_cuota_con_seguro;
            }
            if (!plan.__primera_cuota_promise && plan.installments_info_promise) {
                plan.__primera_cuota_promise = true;
                plan.installments_info_promise.then(function (data) {
                    var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);
                    if (__installments_data.length === 1) {
                        plan.primera_cuota_con_seguro = parseFloat(__installments_data[0].primera_cuota_con_seguro);
                    } else {
                        plan.error_primera_cuota = true;
                    }
                }, function () {
                    plan.error_primera_cuota = true;
                });
            }
            return null;
        };
        // Última Cuota = ultima_cuota_con_seguro(*) + Cuota Seguro. (*)Cálculo realizado en el BackEnd.
        vm.getUltimaCuota = function (plan) {
            if (!vm.operation_is_retail) {
                return null;
            }
            if (plan.ultima_cuota_con_seguro || (plan.ultima_cuota_con_seguro === 0)) {
                return plan.ultima_cuota_con_seguro;
            }
            if (!plan.__ultima_cuota_promise && plan.installments_info_promise) {
                plan.__ultima_cuota_promise = true;
                plan.installments_info_promise.then(function (data) {
                    var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);
                    if (__installments_data.length === 1) {
                        plan.ultima_cuota_con_seguro = parseFloat(__installments_data[0].ultima_cuota_con_seguro) + (vm.getLoanInsuranceInstallment() || 0);
                    } else {
                        plan.error_ultima_cuota = true;
                    }
                }, function () {
                    plan.error_ultima_cuota = true;
                });
            }
            return null;
        };
        vm.recalculatePrimeraCuota = function (plan) {
            delete plan.primera_cuota_con_seguro;
            plan.__primera_cuota_promise = false;
            vm.getPrimeraCuota(plan);
        };
        // Erases previous Última Cuota calculus.
        vm.recalculateUltimaCuota = function (plan) {
            delete plan.ultima_cuota_con_seguro;
            plan.__ultima_cuota_promise = false;
            vm.getUltimaCuota(plan);
        };
        // Cuota Promedio = (*)Se calcula distinto según cada tipo de plan.
        vm.getCuotaPromedio = function (plan) {
            if (!vm.operation_is_retail) {
                return null;
            }
            if (plan.cuota_promedio || (plan.cuota_promedio === 0)) {
                if (!plan.uva) {
                    plan.order_retail = plan.cuota_promedio;
                }
                return plan.cuota_promedio;
            }
            if (!plan.__cuota_promedio_promise && plan.installments_info_promise) {
                plan.__cuota_promedio_promise = true;
                plan.installments_info_promise.then(function (data) {
                    var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);
                    if (__installments_data.length === 1) {
                        // Plan Tradicional: Sumatoria(Cuota Final) / Plazo.
                        plan.cuota_promedio = plan.installments.map(function (installment) {
                            return vm.getCuotaFinal(installment);
                        }).reduce(__sumReducer, 0) / plan.installments_count;
                        // Plan Agro: (Sumatoria(Cuota Final) - Capital a Financiar) / Plazo.
                        if (plan.es_agro) {
                            plan.cuota_promedio = (plan.installments.map(function (installment) {
                                return vm.getCuotaFinal(installment);
                            }).reduce(__sumReducer, 0) - vm.loan_amount) / plan.installments_count;
                        }
                        // Plan Aguinaldo: (Sumatoria(Cuota Final) - (p_sup_cant_cuotas_ext_n * p_sup_impo_cuotas_ext_n)) / Plazo.
                        if (plan.es_aguinaldo) {
                            plan.cuota_promedio = (plan.installments.map(function (installment) {
                                return vm.getCuotaFinal(installment);
                            }).reduce(__sumReducer, 0) - (plan.p_sup_cant_cuotas_ext_n * plan.p_sup_impo_cuotas_ext_n)) / plan.installments_count;
                        }
                        // Plan Baloon: (Sumatoria(Cuota Final) - (Monto a Financiar * p_ref_porcentaje_n / 100)) / Plazo.
                        if (plan.es_baloon) {
                            plan.cuota_promedio = (plan.installments.map(function (installment) {
                                return vm.getCuotaFinal(installment);
                            }).reduce(__sumReducer, 0) - (vm.loan_amount * plan.p_ref_porcentaje_n / 100)) / plan.installments_count;
                        }
                        if (!plan.uva) {
                            plan.order_retail = plan.cuota_promedio;
                        }
                    } else {
                        plan.error_cuota_promedio = true;
                    }
                }, function () {
                    plan.error_cuota_promedio = true;
                });
            }
            return null;
        };
        // Erases previous Cuota Promedio calculus.
        vm.recalculateCuotaPromedio = function (plan) {
            delete plan.cuota_promedio;
            plan.__cuota_promedio_promise = false;
            vm.getCuotaPromedio(plan);
        };
        // Cuota Refuerzo = (*)Se calcula distinto según cada tipo de plan.
        vm.getCuotaRefuerzo = function (plan) {
            if (!vm.operation_is_retail) {
                return null;
            }
            if (plan.cuota_refuerzo || (plan.cuota_refuerzo === 0)) {
                return plan.cuota_refuerzo;
            }
            if (!plan.__cuota_refuerzo_promise && plan.installments_info_promise) {
                plan.__cuota_refuerzo_promise = true;
                plan.installments_info_promise.then(function (data) {
                    var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);
                    if (__installments_data.length === 1) {
                        // Plan Tradicional y Otros = N/A
                        plan.cuota_refuerzo = -1; // No aplica.
                        // Plan Agro: Monto a Financiar / p_sup_cant_cuotas_ext_n.
                        if (plan.es_agro && (plan.p_sup_cant_cuotas_ext_n > 0)) {
                            plan.cuota_refuerzo = vm.loan_amount / plan.p_sup_cant_cuotas_ext_n;
                        }
                        // Plan Aguinaldo: propiedad "p_sup_impo_cuotas_ext_n".
                        if (plan.es_aguinaldo && (plan.p_sup_impo_cuotas_ext_n > 0)) {
                            plan.cuota_refuerzo = plan.p_sup_impo_cuotas_ext_n;
                        }
                        // Plan Baloon: Monto a Financiar * p_ref_porcentaje_n / 100.
                        if (plan.es_baloon && (plan.p_ref_porcentaje_n > 0)) {
                            plan.cuota_refuerzo = vm.loan_amount * plan.p_ref_porcentaje_n / 100;
                        }
                    } else {
                        plan.error_cuota_refuerzo = true;
                    }
                }, function () {
                    plan.error_cuota_refuerzo = true;
                });
            }
            return null;
        };
        /** Returns the vehicle price by operation type. */
        vm.getVehiclePrice = function () {
            // RETAIL / PRENDARIO --> Vehicle Price.
            if (vm.operation_is_retail) {
                return vm.vehicle_price;
            }
            // LEASING --> Vehicle Price without IVA (uses Vehicle IVA to perform calculations).
            return vm.vehicle_price / (($rootScope.cotizador.vehicle_version.p_veh_iva_n + 100) / 100);
        };
        // Canon Anticipado = p_sup_porcentaje_anticipo_n(%) * Valor de la Unidad.
        vm.getCanonAnticipado = function (plan) {
            if (vm.operation_is_retail) {
                return null;
            }
            if (!plan.canon_anticipado && (plan.canon_anticipado !== 0)) {
                plan.canon_anticipado = ((parseInt(plan.p_sup_anticipo_porcentaje_n, 10) || 0) * vm.getVehiclePrice() / 100);
            }
            return plan.canon_anticipado;
        };
        // Opción de Compra (sin IVA) = Valor de la Unidad * p_ref_porcentaje_n / 100.
        vm.getOpcionDeCompra = function (plan) {
            if (vm.operation_is_retail) {
                return null;
            }
            if (!plan.opcion_de_compra && (plan.opcion_de_compra !== 0)) {
                plan.opcion_de_compra = vm.getVehiclePrice() * plan.p_ref_porcentaje_n / 100;
            }
            return plan.opcion_de_compra;
        };
        // Canon Promedio Mensual = [Sumatoria(Capital_Menos_Ultimo_Valor + Interés) / Plazo] + Gasto + Cuota Seguro Vehículo.
        vm.getCanonPromedioMensual = function (plan) {
            if (vm.operation_is_retail) {
                return null;
            }
            if (plan.canon_promedio_mensual || (plan.canon_promedio_mensual === 0)) {
                return plan.canon_promedio_mensual;
            }
            if (!plan.__canon_promedio_mensual_promise && plan.installments_info_promise) {
                plan.__canon_promedio_mensual_promise = true;
                plan.installments_info_promise.then(function (data) {
                    var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id),
                        __capital_menos_ultimo_valor, __interes_menos_ultimo_valor;
                    if (__installments_data.length === 1) {
                        __capital_menos_ultimo_valor = (__installments_data[0].capital || []).map(function (cap) {
                            return cap;
                        });
                        __capital_menos_ultimo_valor.splice(-1);
                        __interes_menos_ultimo_valor = (__installments_data[0].interes || []).map(function (interes) {
                            return interes;
                        });
                        __interes_menos_ultimo_valor.splice(-1);
                        plan.canon_promedio_mensual = ((__capital_menos_ultimo_valor.reduce(__sumReducer, 0) +
                            (__interes_menos_ultimo_valor).reduce(__sumReducer, 0)) / parseInt(__installments_data[0].plazo || 1, 10)) +
                            parseFloat(__installments_data[0].gasto || 0) + (vm.getLoanInsuranceInstallment() || 0);
                    } else {
                        plan.error_canon_promedio_mensual = true;
                    }
                }, function () {
                    plan.error_canon_promedio_mensual = true;
                });
            }
            return null;
        };
        // Erases previous Canon Promedio Mensual calculus.
        vm.recalculateCanonPromedioMensual = function (plan) {
            delete plan.canon_promedio_mensual;
            plan.__canon_promedio_mensual_promise = false;
            vm.getCanonPromedioMensual(plan);
        };
        vm.getCapitalAFinanciarEnUVAs = function (plan) {
            return vm.loan_amount / plan.uva_coefficient;
        };
        vm.getCuotaPuraEnUVAs = function (plan) {
            if (plan.installments_info_promise && plan.installments[0]) {
                return plan.installments[0].cuota_pura / plan.uva_coefficient;
            }
            return null;
        };
        // Returns Sorting Field for Resumen de Planes
        vm.getPlanesSortField = function () {
            if (vm.operation_is_retail) {
                return '+order_retail';
            } else {
                return '+canon_promedio_mensual';
            }
        };
        /** Returns true when the Secondary Bar needs to be fixed positioned. */
        vm.secondaryBarFixed = (function () {
            var __$window_el = angular.element($window),
                /** Returns the bottom of the 1st plan section. */
                __getUpperBound = function (offset) {
                    var __$plan_element = angular.element(".contenedor-principal-plancuadro.collapse.in");
                    if (__$plan_element.length >= 2) {
                        return (__$plan_element.eq(1).offset().top + (offset || 0) || undefined);
                    }
                    return undefined;
                },
                /** Returns the top of the video section. */
                __getLowerBound = function () {
                    var __$video_element = angular.element("#seccion-plazos-secondary-bar-fixed-lower-bound");
                    if (__$video_element.length === 1) {
                        return (__$video_element.offset().top || undefined);
                    }
                    return undefined;
                };
            return function () {
                var __viewport_bottom = __$window_el.scrollTop() + __$window_el.height();
                return ((__viewport_bottom >= __getUpperBound(angular.element("#seccion-plazos-secondary-bar").height())) &&
                    (__viewport_bottom <= __getLowerBound()));
            };
        }());

        // HANDLERS.
        /** Page scroll handler. */
        vm.onPageScroll = function () {
            var __$el = angular.element("#seccion-plazos-secondary-bar");
            if (vm.secondaryBarFixed()) {
                __$el.addClass("contenedor-botones-ver-otrasopciones-fixed");
            } else {
                __$el.removeClass("contenedor-botones-ver-otrasopciones-fixed");
            }
        };
        // Insurance loan change handler.
        vm.onChangeLoanInsuranceData = function (new_insurance) {
            vm.insurance = {
                name: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_compania_desc_c) || "Error"),
                pack: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_cobertura_desc_c) || "Error"),
                zone: ((new_insurance && new_insurance.localidad && new_insurance.localidad.name) || "Error"),
                prov: ((new_insurance && new_insurance.provincia && new_insurance.provincia.name) || "Error"),
                installments: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_cuota_n) || 0),
                id: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_cotizacion_id_c) || 0),
                company_id: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_compania_id_i) || "Error"),
                pack_code: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_familia_cod_c) || "Error"),
                zone_cp: ((new_insurance && new_insurance.localidad && new_insurance.localidad.cp) || "Error"),
                prov_id: ((new_insurance && new_insurance.provincia && new_insurance.provincia.id) || "Error"),
                detail: ((new_insurance && new_insurance.seguro && new_insurance.seguro.p_cob_desc_ampliada_c) || "Error")
            };
            vm.plazos.forEach(function (plazo_item) {
                if (plazo_item.planes) {
                    vm.getCuadroMarcha(plazo_item);

                    plazo_item.planes.forEach(function (plan_item) {
                        vm.recalculateCuotaPromedio(plan_item);
                        vm.recalculatePrimeraCuota(plan_item);
                        vm.recalculateUltimaCuota(plan_item);
                        vm.recalculateCanonPromedioMensual(plan_item);
                    });
                }
            });
        };
        // Plazo selection change handler.
        vm.onChangePlazoSelect = function (selected) {
            vm.show_detail_plazo = (selected || vm.plazos.some(function (plazo_item) {
                return plazo_item.selected;
            }));
        };
        // Cotizar form reset state handler.
        CotizadorService.onCotizarFormChanged($scope, function () {
            vm.showPlazos = false;
            vm.show_detail_plazo = false;
        });
        // Change plans and plazos handler.
        CotizadorService.onPlanesAndPlazosUpdated($scope, function (event, planes_plazos, is_publication, quiet) {
            if (is_publication) {
                vm.onPlanesAndPlazosUpdatedPublicaciones(planes_plazos, quiet);
            } else {
                vm.onPlanesAndPlazosUpdatedCotizador(planes_plazos, quiet);
            }
        });
        // Change plans and plazos handler para publicaciones TCFA.
        vm.onPlanesAndPlazosUpdatedPublicaciones = function (planes_plazos, quiet) {
            var __hash_back,
                __plazos = {};
            vm.planes_plazos = planes_plazos;
            // Update operation data.
            vm.operation_is_retail = $rootScope.cotizador.operation_is_retail;
            vm.loan_amount = $rootScope.cotizador.loan_amount;
            vm.vehicle_price = $rootScope.cotizador.vehicle_price;
            vm.vehicle_iva = $rootScope.cotizador.vehicle_iva;
            vm.vehicle_is_car = (($rootScope.cotizador.vehicle_type === 1) && $rootScope.cotizador.vehicle_is_car);
            vm.plan = $rootScope.cotizador.plan;
            vm.publicacion = $rootScope.publicacion;
            // Reset insurance on vehicle changes.
            if (((__id_share_link < 2) || __used_shared_link) && (!vm.vehicle_version || (vm.vehicle_version.id !== $rootScope.cotizador.vehicle_version.id))) {
                vm.vehicle_version = $rootScope.cotizador.vehicle_version;
                resetCotizacionSeguro();
            }
            // Extract plazos from planes/subplanes.
            vm.planes_plazos.forEach(function (plan) {
                if (plan.p_subplanes_o) {
                    plan.p_subplanes_o.forEach(function (subplan) {
                        var __plazo_id = subplan.p_sup_plazo_n.toString();
                        __plazos[__plazo_id] = (__plazos[__plazo_id] || {});
                        __plazos[__plazo_id].disable_plazo = (__plazos[__plazo_id].disable_plazo || subplan.disable_plazo);
                        __plazos[__plazo_id].promo = (__plazos[__plazo_id].promo || (plan.p_es_plan_subvencionado_i === 1));
                        __plazos[__plazo_id].planes = (__plazos[__plazo_id].planes || []);
                        __plazos[__plazo_id].planes.push({
                            plan_id: plan.p_pla_id_i,
                            subplan_id: subplan.p_sup_id_i,
                            name: plan.p_pla_descripcion_c,
                            description: plan.p_pla_comentario_c,
                            es_TDF: (plan.p_plan_destino_c || "").includes(" TDF"),
                            collapsed: true,
                            installments_count: parseInt(__plazo_id, 10),
                            promo: (plan.p_es_plan_subvencionado_i === 1),
                            promo_adjust: (plan.p_importe_max_fin_n < $rootScope.cotizador.loan_amount),
                            promo_amount: plan.p_importe_max_fin_n,
                            es_agro: !!(subplan.p_sup_anticipo_porcentaje_n && subplan.p_sup_cant_cuotas_ext_n &&
                                subplan.p_sup_peri_cuotas_ext_n && subplan.p_sup_porc_cuotas_ext_n),
                            es_aguinaldo: !!(subplan.p_sup_anticipo_porcentaje_n && subplan.p_sup_cant_cuotas_ext_n &&
                                subplan.p_sup_impo_cuotas_ext_n),
                            es_baloon: !!(subplan.p_sup_anticipo_porcentaje_n && subplan.p_ref_porcentaje_n),
                            uva: (plan.p_factor_c === "UVA"),
                            uva_coefficient: plan.p_coeficiente_n,
                            p_sup_peri_cuotas_ext_n: subplan.p_sup_peri_cuotas_ext_n,
                            p_sup_impo_cuotas_ext_n: subplan.p_sup_impo_cuotas_ext_n,
                            p_ref_porcentaje_n: subplan.p_ref_porcentaje_n,
                            p_sup_anticipo_porcentaje_n: subplan.p_sup_anticipo_porcentaje_n,
                            p_sup_cant_cuotas_ext_n: subplan.p_sup_cant_cuotas_ext_n,
                            tna: 0,
                            cft: 0,
                            cft_efectivo: 0,
                            cft_seguro: 0,
                            cft_seguro_iva: 0,
                            incremental: subplan.p_tipo_gen_caida_c
                        });

                    });
                }
            });
            // Parse & sort plazos.
            vm.plazos = [];
            Object.keys(__plazos).forEach(function (key) {
                vm.plazos.push({
                    id: key,
                    installments: parseInt(key, 10),
                    is_promo: __plazos[key].promo,
                    selected: (vm.pre_selected_plazos[key] || false),
                    collapsed: false,
                    disable_plazo: __plazos[key].disable_plazo,
                    planes: __plazos[key].planes.sort(function (a, b) {
                        var __a = (a.promo) ? 0 : 1,
                            __b = (b.promo) ? 0 : 1;
                        return __a - __b;
                    }),
                    tax_condition: $rootScope.cotizador.tax_condition.name,
                    has_values: function () {
                        return !!this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                    },
                    tna: function () {
                        var plan = this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                        return plan ? plan.tna : 0;
                    },
                    cft: function () {
                        var plan = this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                        return plan ? plan.cft : 0;
                    },
                    cft_seguro_iva: function () {
                        var plan = this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                        return plan ? plan.cft_seguro_iva : 0;
                    }
                });
            });
            vm.plazos.sort(function (a, b) {
                return (a.installments - b.installments);
            });
            vm.pre_selected_plazos = {};
            vm.plazos.forEach(function (plazo_item) {
                if (plazo_item.selected) {
                    getPlanesLoanInfoPublicaciones(plazo_item);
                }
            });
            // Scroll to seccion plazos.
            if (vm.plazos.length > 0) {
                vm.showPlazos = true;
                __hash_back = $location.hash();
                $timeout(function () {
                    $location.hash("quinto-detalle");
                    $anchorScroll();
                    $location.hash(__hash_back);
                }, 500);
            } else {
                vm.showPlazos = false;
                vm.show_detail_plazo = false;
                if (!quiet) {
                    $window.console.debug("No plazos. Redirecting to anonymous channel");
                    CotizadorService.restoreSlider();
                }
                CotizadorService.changeCotizarForm();
            }
        };
        // Change plans and plazos handler para Cotizador.
        vm.onPlanesAndPlazosUpdatedCotizador = function (plazos, quiet) {
            var __hash_back;
            // Update operation data.
            vm.operation_is_retail = $rootScope.cotizador.operation_is_retail;
            vm.loan_amount = $rootScope.cotizador.loan_amount;
            vm.vehicle_price = $rootScope.cotizador.vehicle_price;
            vm.vehicle_iva = $rootScope.cotizador.vehicle_iva;
            vm.vehicle_is_car = (($rootScope.cotizador.vehicle_type === 1) && $rootScope.cotizador.vehicle_is_car);
            vm.plan = $rootScope.cotizador.plan;
            vm.publicacion = $rootScope.publicacion;
            // Reset insurance on vehicle changes.
            if (((__id_share_link < 2) || __used_shared_link) && (!vm.vehicle_version || (vm.vehicle_version.id !== $rootScope.cotizador.vehicle_version.id))) {
                vm.vehicle_version = $rootScope.cotizador.vehicle_version;
                resetCotizacionSeguro();
            }
            vm.plazos = (plazos || []).map(function (plazo_item) {
                return {
                    id: plazo_item.plazo,
                    installments: parseInt(plazo_item.plazo, 10),
                    selected: (vm.pre_selected_plazos[plazo_item.plazo] || false),
                    collapsed: false,
                    is_promo: plazo_item.es_promo
                };
            });
            vm.plazos.sort(function (a, b) {
                return (a.installments - b.installments);
            });
            vm.pre_selected_plazos = {};
            vm.plazos.forEach(function (plazo_item) {
                if (plazo_item.selected) {
                    plazo_item.selected = false;
                    vm.onSelectPlazo(plazo_item);
                }
            });
            // Scroll to seccion plazos.
            if (vm.plazos.length > 0) {
                vm.showPlazos = true;
                __hash_back = $location.hash();
                $timeout(function () {
                    $location.hash("quinto-detalle");
                    $anchorScroll();
                    $location.hash(__hash_back);
                }, 500);
            } else {
                vm.showPlazos = false;
                vm.show_detail_plazo = false;
                if (!quiet) {
                    $window.console.debug("No plazos. Redirecting to anonymous channel");
                    CotizadorService.redirectToAnonymousChannel();
                }
                CotizadorService.changeCotizarForm();
            }
        };
        // Plazo select handler.
        vm.onSelectPlazo = function (plazo) {
            if (plazo.disable_plazo) {
                return;
            }
            if (!plazo.planes) {
                var __loading_modal = $rootScope.showLoadingModal("Cotizando. Por favor espere.", $scope.$new(true));
                CotizadorDAO.getPlazoDetalle({
                    plazo: plazo.id,
                    p_tipo_de_persona_c: ($rootScope.cotizador && $rootScope.cotizador.person_type && $rootScope.cotizador.person_type.key),
                    p_tipo_operacion_c: ($rootScope.cotizador && $rootScope.cotizador.operation_type && $rootScope.cotizador.operation_type.key),
                    p_id_veh_i: ($rootScope.cotizador && $rootScope.cotizador.vehicle_id),
                    p_monto_a_financiar_n: ($rootScope.cotizador && $rootScope.cotizador.loan_amount),
                    condicion_impositiva_id: ($rootScope.cotizador && $rootScope.cotizador.tax_condition && $rootScope.cotizador.tax_condition.id),
                    p_veh_iva_n: $rootScope.cotizador.vehicle_version.p_veh_iva_n,
                    seguro_auto_prima: vm.getLoanInsuranceInstallment(),
                    seguro_auto_premio: vm.getLoanInsuranceInstallment(),
                    campania_id: ($rootScope.cotizador.campaign_id || null)
                }).$promise.then(function (response) {
                    var __planes, __caida_de_cuota;
                    __planes = response.data.cuadro_de_marcha.map(function (item) {
                        return {
                            plan_id: item.p_pla_id_i,
                            subplan_id: item.p_sup_id_i,
                            name: item.p_pla_descripcion_c,
                            description: item.p_pla_comentario_c,
                            es_TDF: (item.p_plan_destino_c || "").includes(" TDF"),
                            collapsed: true,
                            installments_count: item.plazo,
                            promo: (item.p_es_plan_subvencionado_i === 1),
                            promo_adjust: (item.p_importe_max_fin_n < $rootScope.cotizador.loan_amount),
                            promo_amount: item.p_importe_max_fin_n,
                            es_agro: !!(item.p_sup_anticipo_porcentaje_n && item.p_sup_cant_cuotas_ext_n &&
                                item.p_sup_peri_cuotas_ext_n && item.p_sup_porc_cuotas_ext_n),
                            es_aguinaldo: !!(item.p_sup_anticipo_porcentaje_n && item.p_sup_cant_cuotas_ext_n &&
                                item.p_sup_impo_cuotas_ext_n),
                            es_baloon: !!(item.p_sup_anticipo_porcentaje_n && item.p_ref_porcentaje_n),
                            uva: (item.p_factor_c === "UVA"),
                            uva_coefficient: item.p_coeficiente_n,
                            p_sup_peri_cuotas_ext_n: item.p_sup_peri_cuotas_ext_n,
                            p_sup_impo_cuotas_ext_n: item.p_sup_impo_cuotas_ext_n,
                            p_ref_porcentaje_n: item.p_ref_porcentaje_n,
                            p_sup_anticipo_porcentaje_n: item.p_sup_anticipo_porcentaje_n,
                            p_sup_cant_cuotas_ext_n: item.p_sup_cant_cuotas_ext_n,
                            cft: item.cft,
                            cft_efectivo: item.cft_efectivo,
                            cft_seguro: item.cft_seguro,
                            cft_seguro_iva: item.cft_seguro_iva,
                            tea: item.tea,
                            tna: item.tna,
                            incremental: item.p_tipo_gen_caida_c
                        };
                    });
                    __caida_de_cuota = response.data.cuadro_de_marcha.map(function (item) {
                        return {
                            p_sup_id_i: item.p_sup_id_i,
                            plazo: item.plazo,
                            deuda_capital: item.deuda_capital,
                            fecha_cuota: item.fecha_cuota,
                            capital: item.capital,
                            interes: item.interes,
                            iva_interes: item.iva_interes,
                            cuotas_con_seguro: item.cuotas_con_seguro,
                            ultima_cuota_con_seguro: item.ultima_cuota_con_seguro,
                            seguro: item.seguro,
                            cft: item.cft,
                            cft_efectivo: item.cft_efectivo,
                            cft_seguro: item.cft_seguro,
                            cft_seguro_iva: item.cft_seguro_iva,
                            tea: item.tea,
                            tna: item.tna,
                            gasto: item.gasto,
                            gasto_iva: item.gasto_iva,
                            p_fecha_vig_desde: item.p_fecha_vig_desde,
                            p_fecha_vig_hasta: item.p_fecha_vig_hasta,
                            primera_cuota_con_seguro: item.primera_cuota_con_seguro
                        };
                    });
                    plazo.planes = __planes;
                    plazo.tax_condition = $rootScope.cotizador.tax_condition.name;
                    plazo.has_values = function () {
                        return !!this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                    };
                    plazo.tna = function () {
                        var plan = this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                        return plan ? plan.tna : 0;
                    };
                    plazo.cft = function () {
                        var plan = this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                        return plan ? plan.cft : 0;
                    };
                    plazo.cft_seguro_iva = function () {
                        var plan = this.planes.find(function (plan_item) {
                            return (!plan_item.collapsed);
                        });
                        return plan ? plan.cft_seguro_iva : 0;
                    };
                    getPlanesLoanInfoCotizador(plazo, __caida_de_cuota);
                    plazo.selected = !plazo.selected;
                    vm.onChangePlazoSelect(plazo.selected);
                    if (plazo.selected) {
                        vm.plazos.forEach(function (plazo_item) {
                            plazo_item.collapsed = true;
                        });
                        plazo.collapsed = false;
                    }
                    // Datero Log event 2: "Selección de Plazo"
                    // user opens plazo.
                    if (plazo.selected && $rootScope.cotizador && !$rootScope.cotizador.publicacion) {
                        CotizadorDAO.dateroLog({
                            lt: 2, // Log type.
                            pl: plazo.installments,
                            ic: $rootScope.cotizador.campaign_id, // Campaign ID.
                            co: ($rootScope.cotizador.campaign_data && $rootScope.cotizador.campaign_data.campania &&
                                $rootScope.cotizador.campaign_data.campania.origen), // Campaign origin.
                            dcu: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit), // Dealer's CUIT.
                            ccu: ($rootScope.userInfo && $rootScope.userInfo.navigate_as), // Client's CUIT.
                            ce: ($rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.user.email), // Client's e-mail.
                            ip: null, // Publication ID.
                            la: $rootScope.cotizador.loan_amount,
                            tc: $rootScope.cotizador.tax_condition.id,
                            io: $rootScope.cotizador.operation_type.key,
                            App: "Cotizador",
                            EsCertificado: false
                        });
                    }
                    __loading_modal.dismiss("close");
                }, function (error) {
                    $window.console.log("Error on get plazo detalle ->", error);
                    __loading_modal.dismiss("close");
                    $rootScope.showErrorModal("No se pudo completar la operación.", $scope.$new(true));
                });
            } else {
                getPlanesLoanInfoPublicaciones(plazo);
                plazo.selected = !plazo.selected;
                vm.onChangePlazoSelect(plazo.selected);
                if (plazo.selected) {
                    vm.plazos.forEach(function (plazo_item) {
                        plazo_item.collapsed = true;
                    });
                    plazo.collapsed = false;
                }
            }
        };
        // Plazo collapse toggle handler.
        vm.onTogglePlazoCollapse = function (plazo) {
            plazo.collapsed = !plazo.collapsed;
            if (!plazo.collapsed) {
                vm.plazos.forEach(function (plazo_item) {
                    if (!plazo_item.collapsed && plazo_item.id !== plazo.id) {
                        plazo_item.collapsed = true;
                    }
                });
            }
        };
        // Plan "ver cuotas" handler.
        vm.onSelectPlan = function (plan, auto_select_plan) {
            if (plan.disable_installments || plan.error_installments || plan.error_ultima_cuota ||
                plan.error_cuota_promedio || plan.error_cuota_refuerzo || plan.error_canon_anticipado ||
                plan.error_opcion_de_compra || plan.error_canon_promedio_mensual) {
                return null;
            }
            // Ocultar otros planes seleccionados al seleccionar uno nuevo
            var plazo = vm.plazos.find(function (plazo_item) {
                return UtilsHelper.idCheckerWithTypeCoercion(plazo_item.installments, plan.installments_count);
            });
            plazo.planes.forEach(function (plan_item) {
                if (!plan_item.collapsed && !UtilsHelper.idCheckerWithTypeCoercion(plan_item.plan_id, plan.plan_id)) {
                    plan_item.collapsed = true;
                }
            });
            // Validacion seguro seleccionado.
            checkCotizacionSeguro(false, plan).then(function () {
                var __hash_back;
                __getLegalTextsForPlan(plan);
                plan.collapsed = !plan.collapsed;
                // Scroll to installments table.
                if (!plan.collapsed) {
                    __hash_back = $location.hash();
                    $timeout(function () {
                        $location.hash("plan" + plan.subplan_id);
                        $anchorScroll();
                        $location.hash(__hash_back);
                    }, 800);
                }
                if (auto_select_plan) {
                    vm.onChangePlazoSelect(true);
                }
                if (!plan.collapsed && $rootScope.cotizador && !$rootScope.cotizador.publicacion) {
                    plan.installments_info_promise.then(function (data) {
                        var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);
                        if (__installments_data.length === 1) {
                            // Datero Log event 3: "VerSegSi (seguro si)"
                            // insurance selected.
                            CotizadorDAO.dateroLog({
                                lt: 3, // Log type.
                                pl: plan.installments_count,
                                ipl: plan.plan_id, // Plan ID.
                                isl: plan.subplan_id, // Subplan ID.
                                ic: $rootScope.cotizador.campaign_id, // Campaign ID.
                                co: ($rootScope.cotizador.campaign_data && $rootScope.cotizador.campaign_data.campania &&
                                    $rootScope.cotizador.campaign_data.campania.origen), // Campaign origin.
                                dcu: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit), // Dealer's CUIT.
                                ccu: ($rootScope.userInfo && $rootScope.userInfo.navigate_as), // Client's CUIT.
                                ce: ($rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.user.email), // Client's e-mail.
                                ip: null, // Publication ID.
                                tasa: __installments_data[0].tna, // Tasa
                                App: "Cotizador",
                                EsCertificado: false
                            });
                        } else {
                            $window.console.log("Datero error on filter installments info.");
                        }
                    }).catch(function (error) {
                        $window.console.log("Datero error on get installments info ->", error);
                    });
                }
            }, function (error) {
                // Datero Log event 6: "VerSegNo (seguro no)"
                // insurance not selected.
                CotizadorDAO.dateroLog({
                    lt: 6, // Log type.
                    pl: plan.installments_count,
                    ipl: plan.plan_id, // Plan ID.
                    isl: plan.subplan_id, // Subplan ID.
                    ic: $rootScope.cotizador.campaign_id, // Campaign ID.
                    co: ($rootScope.cotizador.campaign_data && $rootScope.cotizador.campaign_data.campania &&
                        $rootScope.cotizador.campaign_data.campania.origen), // Campaign origin.
                    dcu: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit), // Dealer's CUIT.
                    ccu: ($rootScope.userInfo && $rootScope.userInfo.navigate_as), // Client's CUIT.
                    ce: ($rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.user.email), // Client's e-mail.
                    ip: null, // Publication ID.
                    App: "Cotizador",
                    EsCertificado: false
                });
                var __error_message = "No se pudo obtener la cotización del seguro. Este dato es necesario a fin de calcular la financiación.";
                if (error === "close") {
                    __error_message = "Es necesario seleccionar un seguro a fin de calcular la financiación.";
                }
                $rootScope.showErrorModal(__error_message, $scope.$new(true));
            });
        };
        // Plan "Pedí tu aprobado" handler.
        vm.onChoosePlan = function (plan) {
            if (vm.loading_choose_plan) {
                return null;
            }

            vm.loading_choose_plan = true;

            var __pao_params = {
                    cuit_cliente: 0,
                    cuit_dealer: (($rootScope.cotizador && $rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit) || 0),
                    id_publicacion: 0,
                    id_campania_pao: 0,
                    tipo_persona: ($rootScope.cotizador && $rootScope.cotizador.person_type && $rootScope.cotizador.person_type.loan_code),
                    seguro: 0,
                    EsTC: (plan.incremental === 'E' ? 1 : 0)
                },
                __stats_params = {};
            // Skip admin and dealer users.
            if ($rootScope.userInfo && $rootScope.userInfo.user && ($rootScope.userInfo.user.admin || $rootScope.userInfo.user.dealer)) {
                return null;
            }
            // Validar seguro seleccionado.
            checkCotizacionSeguro(false, plan).then(function () {
                // Add insurance data.
                if (vm.insurance && vm.insurance.id) {
                    __pao_params.id_cotizacion_seg = vm.insurance.id;
                }
                // Add PAO Params (Publicacion).
                if ($rootScope.cotizador && $rootScope.cotizador.publicacion && $rootScope.cotizador.publicacion.id) {
                    __pao_params.canal = "TCFAUTOS";
                    __pao_params.id_publicacion = ($rootScope.cotizador.publicacion.publicacion.id || __pao_params.id_publicacion);
                    // Stats.
                    __stats_params.marca_id = $rootScope.cotizador.publicacion.version.modelo.marca.id;
                    __stats_params.modelo_id = $rootScope.cotizador.publicacion.version.modelo.id;
                    __stats_params.version_id = $rootScope.cotizador.publicacion.version.id;
                    __stats_params.monto_total = $rootScope.cotizador.publicacion.price_calculated;
                    __stats_params.publicacion_id = $rootScope.cotizador.publicacion.publicacion.id;
                    __stats_params.auto_id_vehiculo_fu = $rootScope.cotizador.publicacion.vehiculo_id_fu;
                    __stats_params.auto_anio = $rootScope.cotizador.publicacion.year;
                    __stats_params.auto_nuevo = $rootScope.cotizador.publicacion.is0km;
                    __stats_params.publicacion_titulo = $rootScope.cotizador.publicacion.tittle;
                    __stats_params.tipo_operacion = ($rootScope.cotizador && $rootScope.cotizador.operation_type && $rootScope.cotizador.operation_type.id);
                    __stats_params.plazo = plan.installments_count;
                    __stats_params.monto_a_financiar = vm.loan_amount;
                    __stats_params.auto_plan_id = plan.plan_id; // Default Plan ID (external, FU).
                    $rootScope.cotizador.publicacion.planes.some(function (plan_item) { // Search for internal Plan ID and replace.
                        if (plan_item.p_pla_id_i === plan.plan_id) {
                            __stats_params.auto_plan_id = plan_item.id;
                            return true;
                        }
                        return false;
                    });
                    __stats_params.tipo_persona_id = ($rootScope.cotizador && $rootScope.cotizador.person_type && $rootScope.cotizador.person_type.id);
                    __stats_params.condicion_impositiva_id = ($rootScope.cotizador && $rootScope.cotizador.tax_condition && $rootScope.cotizador.tax_condition.id);
                    __stats_params.external_plan_id = plan.plan_id;
                    __stats_params.external_sub_plan_id = plan.subplan_id;
                    StatsDAO.postPediTuPrestamo(__stats_params).$promise.then(function (stats_response) {
                        __pao_params.id_sesion = stats_response.data.id;
                    }).finally(function () {
                        $window.open(TCFAAppConfigService.LINKS.ASK_LOAN_COTIZADOR + "?" + $httpParamSerializer(__pao_params), "_self");
                    });
                } else { // Cotizador Dinámico (NO publicación).
                    __pao_params.canal = "COT";
                    __pao_params.id_campania_pao = (($rootScope.cotizador && $rootScope.cotizador.campaign_id) || __pao_params.id_campania_pao);
                    plan.installments_info_promise
                        .then(function (data) {
                            var __installments_data = getInstallmentsDataFiltered(data.data || {}, plan.subplan_id);

                            if (__installments_data.length === 1) {
                                // Datero Log event 4: "Pedí tu Aprobado"
                                // user clicks PAO.
                                CotizadorDAO.dateroLog({
                                    lt: 4, // Log type.
                                    pl: plan.installments_count,
                                    ipl: plan.plan_id, // Plan ID.
                                    isl: plan.subplan_id, // Subplan ID.
                                    ic: $rootScope.cotizador.campaign_id, // Campaign ID.
                                    co: ($rootScope.cotizador.campaign_data && $rootScope.cotizador.campaign_data.campania &&
                                        $rootScope.cotizador.campaign_data.campania.origen), // Campaign origin.
                                    dcu: ($rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit), // Dealer's CUIT.
                                    ccu: ($rootScope.userInfo && $rootScope.userInfo.navigate_as), // Client's CUIT.
                                    ce: ($rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.user.email), // Client's e-mail.
                                    ip: null, // Publication ID.
                                    tasa: __installments_data[0].tna, // Tasa
                                    App: "Cotizador",
                                    EsCertificado: false
                                })
                                    .$promise
                                    .then(function (datero_response) {
                                        __pao_params.id_sesion = datero_response.data.IdDateroTrackingData;
                                    })
                                    .finally(function () {
                                        $window.open(TCFAAppConfigService.LINKS.ASK_LOAN_COTIZADOR + "?" + $httpParamSerializer(__pao_params), "_self");
                                    });

                            } else {
                                vm.loading_choose_plan = false;
                                $window.console.log("Datero error on filter installments info.");
                            }
                        })
                        .catch(function (error) {
                            vm.loading_choose_plan = false;
                            $window.console.log("Datero error on get installments info ->", error);
                        });
                }
            }, function (error) {
                vm.loading_choose_plan = false;
                var __error_message = "No se pudo obtener la cotización del seguro. Este dato es necesario a fin de solicitar el aprobado.";
                if (error === "close") {
                    __error_message = "Es necesario seleccionar un seguro a fin de solicitar el aprobado.";
                }
                $rootScope.showErrorModal(__error_message, $scope.$new(true));
            });
        };
        vm.closeModalScopeFunc = function ($scope) {
            $scope.close = function () {
                $scope.$dismiss("close");
            };
        };
        // "Ver texto legal" handler.
        vm.onShowLegalText = function (plan) {
            var __legal_modal_scope = $scope.$new(true);
            __getLegalTextsForPlan(plan);
            __legal_modal_scope.loading_legal_text = true;
            __legal_modal_scope.error_legal_text = false;
            plan.legal_text_defer.promise.then(function () {
                __legal_modal_scope.legal_text = plan.legal_text;
                __legal_modal_scope.loading_legal_text = false;
            }, function () {
                __legal_modal_scope.error_legal_text = true;
                __legal_modal_scope.loading_legal_text = false;
            });
            __legal_modal_scope.scrollbarsConfig = {
                autoHideScrollbar: false,
                theme: "rounded",
                advanced: {updateOnContentResize: true},
                scrollButtons: {enable: false},
                setHeight: 330,
                mouseWheel: {
                    enable: true,
                    preventDefault: true
                },
                scrollInertia: 0
            };
            $uibModal.open({
                animation: true,
                windowClass: _WINDOW_MODAL_CLASS,
                backdrop: true,
                keyboard: false,
                templateUrl: "./views/cotizador/modal/modal_legal.html",
                controller: ["$scope", vm.closeModalScopeFunc],
                controllerAs: "ctrl",
                bindToController: true,
                scope: __legal_modal_scope,
                appendTo: angular.element(_MODAL_COMPONENT_HTML_ID_SELECTOR),
                openedClass: "modal-texto-legal-wrapper"
            });
        };
        /** Returns the generated PDF for the input plan as a promise. */
        __getPlanPDF = function (plan) {
            var __q = $q.defer();
            __getLegalTextsForPlan(plan);
            plan.legal_text_defer.promise.then(function () {
                var __filteredCapitalAFinanciarEnUVAs = $filter("number")(vm.getCapitalAFinanciarEnUVAs(plan), 0),
                    __cuotaRefuerzo = ((vm.getCuotaRefuerzo(plan) >= 0) ? $filter("currency")(vm.getCuotaRefuerzo(plan), "$", 0) : "No posee");
                CotizadorDAO.pdf({
                    tipo_operacion: ($rootScope.cotizador && $rootScope.cotizador.operation_type && $rootScope.cotizador.operation_type.name),
                    plan_promocion: plan.promo,
                    url_img: ($rootScope.cotizador && $rootScope.cotizador.vehicle_model && $rootScope.cotizador.vehicle_model.image),
                    modelo: ($rootScope.cotizador && $rootScope.cotizador.vehicle_brand && $rootScope.cotizador.vehicle_brand.name) + " " +
                        ($rootScope.cotizador && $rootScope.cotizador.vehicle_model && $rootScope.cotizador.vehicle_model.descripcion) + " " +
                        ($rootScope.cotizador && $rootScope.cotizador.vehicle_version && $rootScope.cotizador.vehicle_version.descripcion),
                    plan: plan.name,
                    condicion_impositiva: ($rootScope.cotizador && $rootScope.cotizador.tax_condition && $rootScope.cotizador.tax_condition.name),
                    tipo_persona: ($rootScope.cotizador && $rootScope.cotizador.person_type && $rootScope.cotizador.person_type.name),
                    // es_campania: es campania o publicacion tcfautos
                    es_campania: ($rootScope.cotizador && $rootScope.cotizador.from_campaign),
                    dealer_logo: ($rootScope.cotizador && $rootScope.cotizador.dealer &&
                        !$rootScope.cotizador.dealer.is_default && $rootScope.cotizador.dealer.logo),
                    dealer_logo_pdf: ($rootScope.cotizador && $rootScope.cotizador.dealer &&
                        !$rootScope.cotizador.dealer.is_default && $rootScope.cotizador.dealer.logo_pdf),
                    dealer_sucursales: ($rootScope.cotizador && $rootScope.cotizador.dealer &&
                        !$rootScope.cotizador.dealer.is_default && $rootScope.cotizador.dealer.branches),
                    plazo: plan.installments_count + " Meses",
                    legal_seguro: vm.getLegalSeguro(),
                    descripcion_plazo: [{
                        titulo: "",
                        valor: plan.name
                    }, {
                        titulo: ((vm.operation_is_retail && (plan.uva ? "CUOTA INICIAL\nEN $" : "Cuota promedio")) || "Valor de la unidad"),
                        valor: ((vm.operation_is_retail && (plan.uva ?
                            $filter("currency")(vm.getPrimeraCuota(plan), "$", 0) :
                            $filter("currency")(vm.getCuotaPromedio(plan), "$", 0))) ||
                            $filter("currency")(vm.getVehiclePrice(), "$", 0))
                    }, {
                        titulo: ((vm.operation_is_retail && (plan.uva ? "CAPITAL A\nFINANCIAR EN $" : "Capital a financiar")) || "Canon anticipado"),
                        valor: ((vm.operation_is_retail && $filter("currency")(vm.loan_amount, "$", 0)) ||
                            $filter("currency")(vm.getCanonAnticipado(plan), "$", 0))
                    }, {
                        titulo: ((vm.operation_is_retail && (plan.uva ? "CAPITAL A\nFINANCIAR EN UVAs" : "Cuota refuerzo")) || "Canon promedio mensual"),
                        valor: ((vm.operation_is_retail && (plan.uva ? __filteredCapitalAFinanciarEnUVAs : __cuotaRefuerzo)) ||
                            $filter("currency")(vm.getCanonPromedioMensual(plan), "$", 0))
                    }, {
                        titulo: ((vm.operation_is_retail && (plan.uva ? "CUOTA PURA EN\nUVAs" : "Última cuota")) || "Opción de compra"),
                        valor: ((vm.operation_is_retail && (plan.uva ?
                            $filter("number")(vm.getCuotaPuraEnUVAs(plan), 0) :
                            $filter("currency")(vm.getUltimaCuota(plan), "$", 0))) ||
                            $filter("currency")(vm.getOpcionDeCompra(plan), "$", 0))
                    }],
                    titulo_cuadro_marcha: [((vm.operation_is_retail && "Cuota pura") || "Canon"),
                        "Seguro del vehículo",
                        "Costo mensual",
                        "IVA",
                        (((vm.operation_is_retail && "Cuota") || "Canon") + " final")],
                    cuadro_marcha: plan.installments.map(function (installment) {
                        return [installment.number,
                            ((vm.operation_is_retail && $filter("currency")(installment.cuota_pura, "$", 0)) ||
                                $filter("currency")(installment.canon, "$", 0)),
                            $filter("currency")(vm.getLoanInsuranceInstallment(), "$", 0),
                            $filter("currency")(installment.costo_mensual, "$", 0),
                            $filter("currency")(installment.iva, "$", 0),
                            ((vm.operation_is_retail && $filter("currency")(vm.getCuotaFinal(installment, plan.uva), "$", 0)) ||
                                $filter("currency")(vm.getCanonFinal(installment), "$", 0))];
                    }),
                    texto_legal: plan.legal_text,
                    opcion_compra: (vm.operation_is_retail ? null : (plan.p_ref_porcentaje_n + "% O.C.")),
                    plan_uva: plan.uva,
                    aclaracion_caida_de_cuota: vm.getAclaracionCaidaDeCuota()
                }).$promise.then(function (pdf_response) {
                    __q.resolve(pdf_response);
                }, function (error) {
                    __q.reject(error);
                });
            }, function (error) {
                __q.reject(error);
            });
            return __q.promise;
        };
        // Download installments handler.
        vm.onDownloadInstallments = function (plan) {
            var __loading_modal;
            if (plan.__loading_pdf) {
                return;
            }
            plan.__loading_pdf = true;
            __loading_modal = $rootScope.showLoadingModal("Generando el documento. Por favor espere.", $scope.$new(true));
            __getPlanPDF(plan).then(function (pdf_response) {
                var i, __download_url, __blob,
                    __decoded_pdf = $window.atob(pdf_response.data.file_pdf),
                    __pdf_len = __decoded_pdf.length,
                    __bytes = new Uint8Array(__pdf_len),
                    __pdf_link = $window.document.createElement("a"),
                    __URL = ($window.URL || $window.webkitURL);
                for (i = 0; i < __pdf_len; i = i + 1) {
                    __bytes[i] = __decoded_pdf.charCodeAt(i);
                }
                __blob = new Blob([__bytes], {type: "application/pdf"});
                __pdf_link.download = (pdf_response.data.titulo || "Cuadro de marcha") + ".pdf";
                __download_url = __URL.createObjectURL(__blob);
                __pdf_link.href = __download_url;
                $window.document.body.appendChild(__pdf_link);
                __pdf_link.click();
                $window.document.body.removeChild(__pdf_link);
                __URL.revokeObjectURL(__download_url);
                __loading_modal.dismiss("close");
                plan.__loading_pdf = false;
            }, function () {
                __loading_modal.dismiss("error");
                $rootScope.showErrorModal("No se pudo obtener el documento.", $scope.$new(true));
                plan.__loading_pdf = false;
            });
        };
        // Print installments handler.
        vm.onPrintInstallments = function (plan) {
            var __loading_modal, __pdf_window;
            if (plan.__loading_pdf) {
                return;
            }
            plan.__loading_pdf = true;
            // New windows must be allowed.
            __pdf_window = $window.open("");
            if (__pdf_window) {
                __loading_modal = $rootScope.showLoadingModal("Generando el documento. Por favor espere.", $scope.$new(true));
                __pdf_window.document.title = "TCFAutos - Imprimir cotización: Cargando documento, Por favor espere.";
                __pdf_window.document.body.innerText = "Cargando documento, Por favor espere.";
                __getPlanPDF(plan).then(function (pdf_response) {
                    var __iframe = $window.document.createElement("iframe");
                    __iframe.src = "data:application/pdf;base64," + pdf_response.data.file_pdf;
                    __iframe.style.width = "100%";
                    __iframe.style.height = "100%";
                    __iframe.style.border = "0";
                    __iframe.style.margin = "0";
                    __iframe.style.padding = "0";
                    __pdf_window.document.body.innerText = "";
                    __pdf_window.document.body.style.margin = "0";
                    __pdf_window.document.body.style.padding = "0";
                    __pdf_window.document.documentElement.style.margin = "0";
                    __pdf_window.document.documentElement.style.padding = "0";
                    __pdf_window.document.body.appendChild(__iframe);
                    __pdf_window.document.title = "TCFAutos - Imprimir cotización: " + pdf_response.data.titulo;
                    __pdf_window.focus();
                    __loading_modal.dismiss("close");
                    plan.__loading_pdf = false;
                }, function () {
                    __loading_modal.dismiss("error");
                    $rootScope.showErrorModal("No se pudo obtener el documento.", $scope.$new(true));
                    plan.__loading_pdf = false;
                });
            } else {
                $rootScope.showAlertModal("Debe permitir la apertura de ventanas emergentes para visualizar el documento.", $scope.$new(true));
            }
        };
        // Toggle favorite installments handler.
        vm.onFavoriteToggleInstallments = function (plan) {
            var __checkFavorito = function () {
                if (plan.favorite) {
                    CotizadorDAO.favoriteDelete({
                        id_campania: $rootScope.cotizador.campaign_id,
                        id_plan: plan.plan_id,
                        id_subplan: plan.subplan_id
                    }).$promise.then(function () {
                        plan.favorite = false;
                        plan.checking_favorite = false;
                    }, function () {
                        plan.checking_favorite = false;
                    });
                } else {
                    CotizadorDAO
                        .favoriteAdd({
                            id_publicacion: (($rootScope.cotizador && $rootScope.cotizador.publicacion && $rootScope.cotizador.publicacion.id) || null),
                            id_campania: $rootScope.cotizador.campaign_id,
                            id_cotizacion_seguro: vm.insurance.id,
                            cotizacion_seguro_compania: vm.insurance.name,
                            cotizacion_seguro_pack: vm.insurance.pack,
                            cotizacion_seguro_zona: vm.insurance.zone,
                            cotizacion_seguro_prov: vm.insurance.prov,
                            cotizacion_seguro_cuota: vm.insurance.installments,
                            cotizacion_seguro_id_compania: vm.insurance.company_id,
                            cotizacion_seguro_codigo_pack: vm.insurance.pack_code,
                            cotizacion_seguro_cp_zona: vm.insurance.zone_cp,
                            cotizacion_seguro_id_prov: vm.insurance.prov_id,
                            cotizacion_seguro_detalle: vm.insurance.detail,
                            condicion_impositiva: $rootScope.cotizador.tax_condition.key,
                            id_marca: $rootScope.cotizador.vehicle_brand.id,
                            id_modelo: $rootScope.cotizador.vehicle_model.id,
                            id_vesion: $rootScope.cotizador.vehicle_version.id,
                            id_plan: plan.plan_id,
                            id_subplan: plan.subplan_id,
                            monto_financiar: $rootScope.cotizador.loan_amount,
                            tipo_operacion_id: $rootScope.cotizador.operation_type.id,
                            tipo_operacion_clave: $rootScope.cotizador.operation_type.key,
                            tipo_persona: $rootScope.cotizador.person_type.key,
                            tipo_persona_id: $rootScope.cotizador.person_type.id,
                            tipo_vehiculo: $rootScope.cotizador.vehicle_type,
                            vehiculo_nuevo: $rootScope.cotizador.vehicle_condition_new,
                            vehiculo_anio: $rootScope.cotizador.vehicle_year,
                            vehiculo_id: $rootScope.cotizador.vehicle_id,
                            vehiculo_precio: $rootScope.cotizador.vehicle_price,
                            url_img: ($rootScope.cotizador && $rootScope.cotizador.vehicle_model && $rootScope.cotizador.vehicle_model.image),
                            marca: $rootScope.cotizador.vehicle_brand.name,
                            modelo: $rootScope.cotizador.vehicle_model.descripcion,
                            version: $rootScope.cotizador.vehicle_version.descripcion,
                            cuotas: plan.installments_count,
                            canon: ($rootScope.cotizador.canon && $rootScope.cotizador.canon.key)
                        })
                        .$promise
                        .then(function () {
                            plan.favorite = true;
                            plan.checking_favorite = false;

                            // Datero Log event 10: "Favoritos"
                            CotizadorDAO.dateroLog({
                                lt: 10,
                                vb: $rootScope.cotizador.vehicle_brand.id,
                                vm: $rootScope.cotizador.vehicle_model.id,
                                vv: $rootScope.cotizador.vehicle_version.id,
                                vt: $rootScope.cotizador.vehicle_type,
                                ipl: plan && plan.plan_id,
                                isl: plan && plan.subplan_id,
                                pl: plan && plan.installments_count,
                                App: "Cotizador",
                                EsCertificado: false
                            });

                        }, function () {
                            plan.checking_favorite = false;
                        });
                }
            };
            if (plan.checking_favorite) {
                return null;
            }
            plan.checking_favorite = true;
            if (!$rootScope.userInfo.user) {
                LogInUser.loginCliente().then(function (result) {
                    if (result === "userlogged") {
                        __checkFavorito();
                    } else {
                        plan.checking_favorite = false;
                    }
                }).catch(function () {
                    plan.checking_favorite = false;
                });
            } else {
                __checkFavorito();
            }
        };
        // Share installments handler.
        vm.onShareInstallments = function (plan) {
            var __modal_share,
                __modal_scope = $scope.$new(true),
                __share_data = {
                    link_type: 2,
                    campaign: $rootScope.cotizador.campaign_id,
                    operation_type: $rootScope.cotizador.operation_type.key,
                    vehicle_brand: $rootScope.cotizador.vehicle_brand.id,
                    vehicle_brand_name: $rootScope.cotizador.vehicle_brand.name,
                    vehicle_model: $rootScope.cotizador.vehicle_model.id,
                    vehicle_model_name: $rootScope.cotizador.vehicle_model.descripcion,
                    vehicle_version: $rootScope.cotizador.vehicle_version.id,
                    vehicle_version_name: $rootScope.cotizador.vehicle_version.descripcion,
                    vehicle_condition: (($rootScope.cotizador.vehicle_condition_new && 1) || 0),
                    vehicle_year: $rootScope.cotizador.vehicle_year,
                    vehicle_type: $rootScope.cotizador.vehicle_type,
                    vehicle_id: $rootScope.cotizador.vehicle_id,
                    vehicle_price: $rootScope.cotizador.vehicle_price,
                    person_type: $rootScope.cotizador.person_type.id,
                    loan_amount: $rootScope.cotizador.loan_amount,
                    tax_condition: $rootScope.cotizador.tax_condition.key,
                    canon: ($rootScope.cotizador.canon && $rootScope.cotizador.canon.key),
                    plan: ($rootScope.cotizador.plan && $rootScope.cotizador.plan.id),
                    plazo: plan.installments_count,
                    subplan: plan.subplan_id,
                    insurance_id: vm.insurance.id,
                    insurance_company_name: vm.insurance.name,
                    insurance_pack_name: vm.insurance.pack,
                    insurance_zone_name: vm.insurance.zone,
                    insurance_prov_name: vm.insurance.prov,
                    insurance_installments: vm.insurance.installments,
                    insurance_company_id: vm.insurance.company_id,
                    insurance_pack_code: vm.insurance.pack_code,
                    insurance_zone_cp: vm.insurance.zone_cp,
                    insurance_prov_id: vm.insurance.prov_id,
                    insurance_detail: vm.insurance.detail
                },
                __share_url = CotizadorService.getUrlToSocialShare(__share_data);
            if (facebookService.tcfautos_fallback_check) {
                __modal_scope.disableFacebookShare = true;
            }
            /** Share loan on Facebook by API URL to allow OpenGraph to get the HTML tags. */
            __modal_scope.onShareFacebook = function () {
                facebookService.share(__share_url, function (response) {
                    if (angular.isArray(response)) {
                        $window.console.log("se compartió en FB");
                        // Datero Log event 11: "Compartir"
                        CotizadorDAO.dateroLog({
                            lt: 11,
                            vb: $rootScope.cotizador.vehicle_brand.id,
                            vm: $rootScope.cotizador.vehicle_model.id,
                            vv: $rootScope.cotizador.vehicle_version.id,
                            vt: $rootScope.cotizador.vehicle_type,
                            ipl: plan && plan.plan_id,
                            isl: plan && plan.subplan_id,
                            pl: plan && plan.installments_count,
                            App: "Cotizador",
                            EsCertificado: false
                        });
                    } else {
                        $window.console.log("no se compartió en FB");
                    }
                });
                if (__modal_share) {
                    __modal_share.dismiss("close");
                }
            };
            /** Share loan on GooglePlus by API URL to allow OpenGraph to get the HTML tags. */
            __modal_scope.onShareGooglePlus = function () {
                $window.open(TCFAAppConfigService.GOOGLE.SHARE_URL + $window.encodeURIComponent(__share_url), "",
                    "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
                // Datero Log event 11: "Compartir"
                CotizadorDAO.dateroLog({
                    lt: 11,
                    vb: $rootScope.cotizador.vehicle_brand.id,
                    vm: $rootScope.cotizador.vehicle_model.id,
                    vv: $rootScope.cotizador.vehicle_version.id,
                    vt: $rootScope.cotizador.vehicle_type,
                    ipl: plan && plan.plan_id,
                    isl: plan && plan.subplan_id,
                    pl: plan && plan.installments_count,
                    App: "Cotizador",
                    EsCertificado: false
                });
                if (__modal_share) {
                    __modal_share.dismiss("close");
                }
            };
            /** Share loan with friends by sending an e-mail. */
            __modal_scope.onShareWithFriends = function () {
                var __share_with_friends_modal_scope = $scope.$new(true),
                    __recaptcha_promise = DependencyInjectionService.loadApi("GoogleRecaptcha"),
                    __openShareWithFriendsModal = function () {
                        $uibModal.open({
                            animation: true,
                            windowClass: _WINDOW_MODAL_CLASS,
                            backdrop: true,
                            keyboard: true,
                            templateUrl: "./views/cotizador/modal/modal_share_with_friends.html",
                            controller: "ShareWithFriendsCtrl",
                            controllerAs: "ctrl",
                            bindToController: true,
                            scope: __share_with_friends_modal_scope,
                            appendTo: angular.element(_MODAL_COMPONENT_HTML_ID_SELECTOR),
                            openedClass: "modal-share-with-friends-wrapper"
                        });
                    };
                __share_with_friends_modal_scope.insurance_data = __share_data;
                __share_with_friends_modal_scope.share_url = __share_url;
                if (__modal_share) {
                    __modal_share.dismiss("close");
                    __recaptcha_promise.then(function () {
                        __modal_share.closed.then(__openShareWithFriendsModal);
                    });
                } else {
                    __recaptcha_promise.then(__openShareWithFriendsModal);
                }
            };
            __modal_share = $uibModal.open({
                animation: true,
                windowClass: _WINDOW_MODAL_CLASS,
                backdrop: true,
                keyboard: true,
                templateUrl: "./views/cotizador/modal/modal_share.html",
                controller: ["$scope", vm.closeModalScopeFunc],
                controllerAs: "modalShare",
                bindToController: true,
                scope: __modal_scope,
                appendTo: angular.element(_MODAL_COMPONENT_HTML_ID_SELECTOR),
                openedClass: "modal-share-wrapper"
            });
        };

        // Change modal handler.
        vm.onChangeVehicleModel = function () {
            // Datero Log event 8: "Ver Otros Vehiculos"
            CotizadorDAO.dateroLog({
                lt: 8,
                App: "Cotizador",
                EsCertificado: false
            });
            var __hash_back;
            if (vm.publicacion) {
                // Go to Home and filter by Dealer.
                $location.path("/").search({});
                $route.reload();
            } else {
                // Reset vehicle model to display the vehicles slider.
                CotizadorService.updatePlanesAndPlazos([], false, true);
                CotizadorService.updateVehicleModels();
                // Scroll top.
                vm.showPlazos = true;
                __hash_back = $location.hash();
                $timeout(function () {
                    $location.hash("cotizador-wrapper");
                    $anchorScroll();
                    $location.hash(__hash_back);
                }, 100);
            }
        };
        // Change plan handler.
        vm.onChangePlan = function () {
            // Datero Log event 9: "Ver Otros Planes"
            CotizadorDAO.dateroLog({
                lt: 9,
                App: "Cotizador",
                EsCertificado: false
            });

            CotizadorService.updatePlan(null);
        };

        vm.getCuadroMarcha = function (plazo) {
            // Volver a llamar cuadro de marcha
            var __planes = [],
                __planes_subplanes_ids = {};
            plazo.planes.forEach(function (plan_item) {
                var __key = plan_item.plan_id.toString();
                __planes_subplanes_ids[__key] = (__planes_subplanes_ids[__key] || {
                    flag_TDF: plan_item.es_TDF,
                    subplanes: [],
                    incremental: plan_item.incremental
                });
                __planes_subplanes_ids[__key].subplanes.push(plan_item.subplan_id);
            });

            Object
                .keys(__planes_subplanes_ids)
                .forEach(function (key) {
                    __planes.push({
                        id: parseInt(key, 10),
                        subplanes: __planes_subplanes_ids[key].subplanes,
                        flag_TDF: __planes_subplanes_ids[key].flag_TDF,
                        incremental: __planes_subplanes_ids[key].incremental
                    });
                });

            plazo.installments_info_loading = true;
            plazo.installments_info_promise = (CotizadorDAO
                .cuadroDeMarcha({
                    p_veh_id_i: ($rootScope.cotizador && $rootScope.cotizador.vehicle_id),
                    p_cuit_dealer_c: ($rootScope.cotizador && $rootScope.cotizador.dealer && $rootScope.cotizador.dealer.cuit),
                    planes: __planes,
                    p_tipo_persona_c: ($rootScope.cotizador && $rootScope.cotizador.person_type && $rootScope.cotizador.person_type.key),
                    p_veh_nuevo_i: (($rootScope.cotizador && $rootScope.cotizador.vehicle_condition_new && 1) || 0),
                    p_tipo_operacion_c: ($rootScope.cotizador && $rootScope.cotizador.operation_type && $rootScope.cotizador.operation_type.key),
                    p_precio_usado_n: ($rootScope.cotizador && $rootScope.cotizador.vehicle_price),
                    monto_financiar: ($rootScope.cotizador && $rootScope.cotizador.loan_amount),
                    condicion_impositiva_id: ($rootScope.cotizador && $rootScope.cotizador.tax_condition && $rootScope.cotizador.tax_condition.id),
                    x_veh_iva: ($rootScope.cotizador && $rootScope.cotizador.tax_condition.interest),
                    seguro_auto_prima: vm.getLoanInsuranceInstallment(),
                    seguro_auto_premio: vm.getLoanInsuranceInstallment()
                }).$promise);

            plazo.installments_info_promise
                .then(function () {
                    plazo.installments_info_loading = false;
                }, function () {
                    plazo.installments_info_error = true;
                    plazo.installments_info_loading = false;
                });

            plazo.planes.forEach(function (plan_item) {
                var __auto_select_plan = ((__id_share_link >= 2) && !__used_shared_link && __id_subplan && (plan_item.subplan_id === __id_subplan));
                if (__auto_select_plan) {
                    __used_shared_link = true;
                }
                plan_item.installments_info_promise = plazo.installments_info_promise;
                calculateInstallments(plan_item, __auto_select_plan);
            });
        };
        // Change insurance company handler.
        vm.onChangeInsurance = function () {
            checkCotizacionSeguro(true);
        };
        // Change loan amount handler.
        vm.onChangeLoanAmount = function (plan) {
            var __loan_amount_alert_modal = $rootScope.showAlertModal("Se ajustará el capital a financiar en todos los planes.", $scope.$new(true));
            __loan_amount_alert_modal.result.then(function () {
                // Save pre selected plazos to render them selected again after the cotizar POST.
                vm.pre_selected_plazos = {};
                vm.plazos.forEach(function (plazo_item) {
                    if (plazo_item.selected) {
                        vm.pre_selected_plazos[plazo_item.installments.toString()] = true;
                    }
                });
                vm.plazos = [];
                vm.onChangePlazoSelect();
                CotizadorService.updateLoanAmount(plan.promo_amount);
            });
        };

        // INITIALIZATION.
        // Bind scroll handler.
        angular.element($window).bind("scroll", vm.onPageScroll);
        if (__id_insurance && __name_insurance_company && __name_insurance_pack && __name_insurance_zone &&
            __cp_insurance_zone && __id_insurance_prov && __name_insurance_prov && __insurance_installment &&
            __id_insurance_company && __code_insurance_pack && __insurance_detail && (__id_share_link >= 2)) {
            _cotizacion_seguro = {
                seguro: {
                    p_compania_id_i: __id_insurance_company,
                    p_familia_cod_c: __code_insurance_pack,
                    p_compania_desc_c: __name_insurance_company,
                    p_cobertura_desc_c: __name_insurance_pack,
                    p_cuota_n: __insurance_installment,
                    p_cotizacion_id_c: __id_insurance,
                    p_cob_desc_ampliada_c: __insurance_detail
                },
                localidad: {
                    cp: __cp_insurance_zone,
                    name: __name_insurance_zone
                },
                provincia: {
                    id: __id_insurance_prov,
                    name: __name_insurance_prov
                }
            };
            _seguro_cotizado = true;
        }

        // Get tooltips.
        CotizadorDAO.getAllTooltips().$promise.then(function (tooltips) {
            tooltips.forEach(function (tooltip_item) {
                switch (tooltip_item.code) {
                    case "CANON_FINAL":
                        vm.tooltip_canon_final = tooltip_item.text;
                        break;
                    case "CUOTA_FINAL":
                        vm.tooltip_cuota_final = tooltip_item.text;
                        break;
                    case "CUOTA_FINAL_UVA":
                        vm.tooltip_cuota_final_uva = tooltip_item.text;
                        break;
                    case "CANON_ANTICIPADO":
                        vm.tooltip_canon_anticipado = tooltip_item.text;
                        break;
                    default:
                        return null;
                }
            });
        }, function () {
            vm.tooltip_canon_final = __tooltip_error_text;
            vm.tooltip_cuota_final = __tooltip_error_text;
            vm.tooltip_cuota_final_uva = __tooltip_error_text;
            vm.tooltip_canon_anticipado = __tooltip_error_text;
        });
        // Get plan legal texts (generics).
        (function () {
            var __errorLoadingLegalText = function () {
                    __error_legal_text = true;
                    __q_legal_text.reject();
                },
                __errorLoadingLegalTextPromo = function () {
                    __error_legal_text_promo = true;
                    __q_legal_text_promo.reject();
                },
                __errorLoadingLegalTextUva = function () {
                    __error_legal_text_uva = true;
                    __q_legal_text_uva.reject();
                };
            __legal_text_promise.then(function (resp_params) {
                resp_params.some(function (param_item) {
                    if (param_item.clave === "seccion_terminos_cotizador") {
                        CotizadorDAO.getAllSections().$promise.then(function (all_sections) {
                            if (!all_sections.some(function (section_item) {
                                if (section_item.id === parseInt(param_item.valor, 10)) {
                                    if (section_item.termino_condicion && section_item.termino_condicion.content) {
                                        __legal_text = section_item;
                                        __q_legal_text.resolve();
                                    } else {
                                        __errorLoadingLegalText();
                                    }
                                    return true;
                                }
                                return false;
                            })) {
                                __errorLoadingLegalText();
                            }
                        }, __errorLoadingLegalText);
                        return true;
                    }
                    return false;
                });
            }, __errorLoadingLegalText);

            __legal_text_promise_promo.then(function (resp_params) {
                resp_params.some(function (param_item) {
                    if (param_item.clave === "seccion_terminos_cotizador_promo") {
                        CotizadorDAO.getAllSections().$promise.then(function (all_sections) {
                            if (!all_sections.some(function (section_item) {
                                if (section_item.id === parseInt(param_item.valor, 10)) {
                                    if (section_item.termino_condicion && section_item.termino_condicion.content) {
                                        __legal_text_promo = section_item;
                                        __q_legal_text_promo.resolve();
                                    } else {
                                        __errorLoadingLegalTextPromo();
                                    }
                                    return true;
                                }
                                return false;
                            })) {
                                __errorLoadingLegalTextPromo();
                            }
                        }, __errorLoadingLegalTextPromo);
                        return true;
                    }
                    return false;
                });
            }, __errorLoadingLegalTextPromo);

            __legal_text_promise_uva.then(function (resp_params) {
                resp_params.some(function (param_item) {
                    if (param_item.clave === "seccion_terminos_cotizador_uva") {
                        CotizadorDAO.getAllSections().$promise.then(function (all_sections) {
                            if (!all_sections.some(function (section_item) {
                                if (section_item.id === parseInt(param_item.valor, 10)) {
                                    if (section_item.termino_condicion && section_item.termino_condicion.content) {
                                        __legal_text_uva = section_item;
                                        __q_legal_text_uva.resolve();
                                    } else {
                                        __errorLoadingLegalTextUva();
                                    }
                                    return true;
                                }
                                return false;
                            })) {
                                __errorLoadingLegalTextUva();
                            }
                        }, __errorLoadingLegalTextUva);
                        return true;
                    }
                    return false;
                });
            }, __errorLoadingLegalTextUva);
        }());
    };
    Cotizador_SeccionPlazosCtrl.$inject = moduleConfig.Cotizador_SeccionPlazosCtrl.deps;
    module.controller(moduleConfig.Cotizador_SeccionPlazosCtrl.name, Cotizador_SeccionPlazosCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Cotizador_SeccionVideoCtrl",
            deps: ["TCFAutos.services.ParametrosService"],
            Cotizador_SeccionVideoCtrl: {
                name: "Cotizador_SeccionVideoCtrl",
                deps: ["ParametrosService", "$routeParams"]
            }
        },
        module, Cotizador_SeccionVideoCtrl;
    module = angular.module("TCFAutos");
    Cotizador_SeccionVideoCtrl = function (ParametrosService, $routeParams) {
        var vm = this,
            __id_campaign = parseInt($routeParams.ic, 10);
        // Get Toyota Ciclo.
        ParametrosService.get({key: "id_toyota_ciclo"}).$promise.then(function(param) {
            vm.toyotaCiclo = (parseInt(param.valor, 10) === parseInt($routeParams.ic, 10));
            if (!vm.toyotaCiclo) {
                ParametrosService.get({key: "video_cotizacion_url"}).$promise.then(function(param) {
                    vm.video_url = param.valor;
                });
            }
            else {
                ParametrosService.get({key: "toyota_ciclo_video_cotizacion_url"}).$promise.then(function(param) {
                    vm.video_url = param.valor;
                });
            }
        });
    };
    Cotizador_SeccionVideoCtrl.$inject = moduleConfig.Cotizador_SeccionVideoCtrl.deps;
    module.controller(moduleConfig.Cotizador_SeccionVideoCtrl.name, Cotizador_SeccionVideoCtrl);
}());
(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.DesuscribirClienteController",
            deps: ["TCFAutos.services.UserDAOService", "ui.bootstrap"],
            DesuscribirClienteController: {
                name: "DesuscribirClienteController",
                deps: ["$routeParams", "$location", "TCFAModalService", "UserDAO"]
            }
        },
        module, DesuscribirClienteController;
    module = angular.module("TCFAutos");
    DesuscribirClienteController = function ($routeParams, $location, TCFAModalService, UserDAO) {
        var info = null;
        if (!$routeParams.username || !$routeParams.email) {
            info = {error: {}};
            info.error.showModal = true;
            info.error.message = "Sin email de usuario para cancelar suscripción.";
            info.error.title = "¡Ha ocurrido un error!";
            TCFAModalService.showError(info).then(function () {
                $location.path("/").search({});
            });
        }
        var param = {username: $routeParams.username, email: $routeParams.email};
        UserDAO.unsubcribeCliente(param).$promise.then(function () {
            info = {error: {}};
            info.error.showModal = true;
            info.error.message = ["Ya no recibirás actualizaciones de la aplicación en tu casilla de email.", "Gracias por utilizar TCFAutos."];
            info.error.title = "¡Cancelaste tu suscripción!";
            TCFAModalService.showWarning(info).result.then(function () {
                $location.path("/").search({});
            }, function () {
                $location.path("/").search({});
            });
        }, function () {
            $location.path("/").search({});
        });
    };
    DesuscribirClienteController.$inject = moduleConfig.DesuscribirClienteController.deps;
    module.controller(moduleConfig.DesuscribirClienteController.name, DesuscribirClienteController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.FavoritosListController",
            deps: ["favoritoDaoModule", "TCFAutos.controllers.PerfilMenuController", "TCFAutos.directives.BottomBar"],
            FavoritosListController: {
                name: "FavoritosListController",
                deps: ["$window", "$rootScope", "FavoritoDAO", "TCFAModalService", "$location", "TCFAAppConfigService",
                    "CotizadorDAO", "CotizadorService"]
            }
        },
        module, FavoritosListController;
    module = angular.module("TCFAutos");
    FavoritosListController = function ($window, $rootScope, FavoritoDAO, TCFAModalService, $location, TCFAAppConfigService,
                                        CotizadorDAO, CotizadorService) {
        var vm = this;
        if (!$rootScope.userInfo.user) {
            $location.path("/");
            return;
        }
        vm.data = {
            imagesSource: TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.AUTO_IMAGE_FOLDER,
            sorts: [
                {key: "dest", display: "Más Buscados"},
                {key: "priceAsc", display: "Menor precio"},
                {key: "priceDesc", display: "Mayor precio"},
                {key: "dateAsc", display: "Más viejo"},
                {key: "dateDesc", display: "Más nuevo"}
            ]
        };
        vm.cotizador_data = {};
        vm.data.currentSort = vm.data.sorts[0];
        vm.sort = function (item) {
            FavoritoDAO.getAll({page: vm.data.page.current_page, sort: item.key}, function (response) {
                $window.console.debug("FavoritosListController - FavoritoDAO.getAll ->", response);
                vm.data.page = response.data;
                vm.data.results = response.data.data;
                if (vm.data.page.current_page === 1) {
                    CotizadorDAO.favoriteGetAll({}, function (cotizador_response) {
                        $window.console.debug("FavoritosListController - CotizadorDAO.favoriteGetAll ->", cotizador_response);
                        vm.cotizador_data.results = cotizador_response.data.map(function (item) {
                            item.is_cotizador_result = true;
                            return item;
                        });
                    });
                } else {
                    vm.cotizador_data.results = [];
                }
                $window.console.log("New Sort:", item);
                vm.data.currentSort = item;
            });
        };
        vm.loadPage = function (pageIndex) {
            $window.console.log("Pag. Favoritos:", pageIndex);
            FavoritoDAO.getAll({page: pageIndex, sort: vm.data.currentSort.key}, function (response) {
                $window.console.debug("FavoritosListController - FavoritoDAO.getAll ->", response);
                if (pageIndex === 1) {
                    CotizadorDAO.favoriteGetAll({}, function (cotizador_response) {
                        $window.console.debug("FavoritosListController - CotizadorDAO.favoriteGetAll ->", cotizador_response);
                        vm.cotizador_data.results = cotizador_response.data.map(function (item) {
                            item.is_cotizador_result = true;
                            return item;
                        });
                    });
                } else {
                    vm.cotizador_data.results = [];
                }
                vm.data.page = response.data;
                vm.data.results = response.data.data;
            });
        };
        vm.loadPage(1);
        vm.getTotalItems = function () {
            var __total_pubs = 0,
                __total_loans = 0;
            if (vm.data.page) {
                __total_pubs = (vm.data.page.total || 0);
            }
            if (vm.cotizador_data.results) {
                __total_loans = (vm.cotizador_data.results.length || 0);
            }
            return __total_pubs + __total_loans;
        };
        vm.getAllResults = function () {
            return (vm.data.results || []).concat(vm.cotizador_data.results || []);
        };
        vm.isItemDisabled = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return false;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.estado) {
                return null;
            }
            return (item.publicacion.estado.clave !== "PUBLICADA");
        };
        vm.mouseLeaveItem = function (item) {
            if (vm.modalOpen || vm.isItemDisabled(item)) {
                return null;
            }
            item.hover = false;
        };
        vm.mouseEnterItem = function (item) {
            if (vm.modalOpen || vm.isItemDisabled(item)) {
                return null;
            }
            item.hover = true;
        };
        vm.eliminarDeFavoritos = function (item) {
            var __message = "¿Está seguro que desea eliminar la publicación de su lista?";
            if (item.is_cotizador_result) {
                __message = __message.replace("publicación", "cotización");
            }
            vm.modalOpen = true;
            TCFAModalService.showConfirm({
                text: __message,
                aceptar: function () {
                    if (item.is_cotizador_result) {
                        CotizadorDAO.favoriteDelete({
                            id_campania: item.id_campania,
                            id_plan: item.id_plan,
                            id_subplan: item.id_subplan
                        }).$promise.then(function () {
                            vm.loadPage(vm.data.page.current_page || 1);
                        });
                    } else {
                        FavoritoDAO.delete({
                            id_publicacion: item.publicacion.id
                        }).$promise.then(function () {
                            vm.loadPage(vm.data.page.current_page || 1);
                        });
                    }
                }
            }).closed.then(function () {
                vm.modalOpen = false;
                vm.mouseLeaveItem(item);
            });
        };
        vm.goToItem = function (item) {
            var __path, __search;
            if (!item) {
                return;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                if (item.id_publicacion) {
                    __path = "/cars/" + item.id_publicacion + "/";
                } else {
                    __path = "/cotizador/";
                }
                __search = CotizadorService.getUrlToSocialShare({
                    link_type: 2,
                    campaign: item.id_campania,
                    vehicle_brand: item.id_marca,
                    vehicle_model: item.id_modelo,
                    vehicle_version: item.id_vesion,
                    vehicle_brand_name: item.marca,
                    vehicle_model_name: item.modelo,
                    vehicle_version_name: item.version,
                    vehicle_condition: item.vehiculo_nuevo,
                    vehicle_type: item.tipo_vehiculo,
                    loan_amount: item.monto_financiar,
                    tax_condition: item.condicion_impositiva,
                    plazo: item.cuotas,
                    plan: item.id_plan,
                    subplan: item.id_subplan,
                    insurance_id: item.id_cotizacion_seguro,
                    operation_type: item.tipo_operacion_clave,
                    vehicle_year: item.vehiculo_anio,
                    vehicle_id: item.vehiculo_id,
                    vehicle_price: item.vehiculo_precio,
                    person_type: item.tipo_persona_id,
                    canon: item.canon,
                    insurance_company_name: item.cotizacion_seguro_compania,
                    insurance_pack_name: item.cotizacion_seguro_pack,
                    insurance_zone_name: item.cotizacion_seguro_zona,
                    insurance_prov_name: item.cotizacion_seguro_prov,
                    insurance_installments: item.cotizacion_seguro_cuota,
                    insurance_company_id: item.cotizacion_seguro_id_compania,
                    insurance_pack_code: item.cotizacion_seguro_codigo_pack,
                    insurance_zone_cp: item.cotizacion_seguro_cp_zona,
                    insurance_prov_id: item.cotizacion_seguro_id_prov,
                    insurance_detail: item.cotizacion_seguro_detalle
                }).split("?")[1];
            } else {
                // Item publicación.
                __path = "/cars/" + (item.publicacion && item.publicacion.auto && item.publicacion.auto.id) + "/";
                __search = null;
            }
            $location.path(__path).search(__search);
        };
        vm.itemHasimages = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return item.url_img;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto || !item.publicacion.auto.car_images) {
                return null;
            }
            if (!item.publicacion.auto.car_images.length || (item.publicacion.auto.car_images.length === 0)) {
                return false;
            }
            return item.publicacion.auto.car_images[0];
        };
        vm.getItemImage = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return item.url_img;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto || !item.publicacion.auto.car_images) {
                return null;
            }
            if (!item.publicacion.auto.car_images.length || (item.publicacion.auto.car_images.length === 0)) {
                return null;
            }
            return vm.data.imagesSource + "/" + item.publicacion.auto.id + "/" + item.publicacion.auto.car_images[0].url;
        };
        vm.getItemMarcaModelo = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return item.marca + " " + item.modelo;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto || !item.publicacion.auto.version) {
                return null;
            }
            if (!item.publicacion.auto.version.modelo || !item.publicacion.auto.version.modelo.marca) {
                return null;
            }
            return item.publicacion.auto.version.modelo.marca.mar_descripcion_s + " " + item.publicacion.auto.version.modelo.mod_descripcion_s;
        };
        vm.getItemVersion = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return item.version;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto || !item.publicacion.auto.version) {
                return null;
            }
            return item.publicacion.auto.version.ver_descripcion_s;
        };
        vm.getItemYear = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return item.vehiculo_anio;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto) {
                return null;
            }
            return item.publicacion.auto.year;
        };
        vm.getItemKM = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return 0;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto) {
                return null;
            }
            return item.publicacion.auto.kms;
        };
        vm.getItemProvince = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return "";
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto || !item.publicacion.auto.provincia) {
                return null;
            }
            return item.publicacion.auto.provincia.name;
        };
        vm.getItemPrice = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                if (item.tipo_operacion_clave === "RE") {
                    return item.monto_financiar;
                }
                // LE = leasing.
                return parseInt(item.canon || "0", 10) / 100 * parseInt(item.vehiculo_precio, 10);
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto) {
                return null;
            }
            return item.publicacion.auto.price_calculated / 2;
        };
        vm.getItemInstallments = function (item) {
            if (!item) {
                return null;
            }
            // Item cotización.
            if (item.is_cotizador_result) {
                return item.cuotas;
            }
            // Item publicación.
            if (!item.publicacion || !item.publicacion.auto) {
                return null;
            }
            return item.publicacion.auto.cant_cuotas;
        };
    };
    FavoritosListController.$inject = moduleConfig.FavoritosListController.deps;
    module.controller(moduleConfig.FavoritosListController.name, FavoritosListController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.HomepageCtrl",
            deps: ["TCFAutos.controllers.Homepage_HeaderCtrl", "TCFAutos.controllers.Homepage_SliderCtrl",
                "TCFAutos.controllers.Homepage_CarsAutocompleteCtrl", "TCFAutos.controllers.Homepage_ProvinciasAutoCompleteCtrl",
                "TCFAutos.controllers.Homepage_ConcesionariosDestacadosCtrl", "TCFAutos.controllers.Homepage_BannerMovilCtrl",
                "TCFAutos.services.PageNavigationService", "TCFAutos.directives.BottomBar", "TCFAutos.directives.tcSlider"],
            HomepageCtrl: {
                name: "HomepageCtrl",
                deps: ["$injector", "PageNavigationService"]
            }
        },
        module, HomepageCtrl;
    module = angular.module("TCFAutos");
    HomepageCtrl = function ($injector, PageNavigationService) {
        PageNavigationService.showNavBar = false;
        PageNavigationService.showNewsletterSubscription = true;
        PageNavigationService.showLoginMenu = true;

        var $window = $injector.get("$window"),
            UtilsHelper = $injector.get("UtilsHelper");

        var linkedin_code = UtilsHelper.getParamFromURL('code'),
            linkedin_error = UtilsHelper.getParamFromURL('error');
        if (linkedin_code) {
            linkedin_code = $window.encodeURIComponent(linkedin_code);

            $window.location.href = UtilsHelper.getBaseURI() + '/#/auth/linkedin/code/' + linkedin_code;
        } else if (linkedin_error) {
            linkedin_error = $window.encodeURIComponent(linkedin_error);

            // The recieved error_description parameter is already URI encoded
            var linkedin_error_desc = $window.decodeURIComponent(UtilsHelper.getParamFromURL('error_description'));
            linkedin_error_desc = $window.btoa(linkedin_error_desc);
            linkedin_error_desc = $window.encodeURIComponent(linkedin_error_desc);

            $window.location.href = UtilsHelper.getBaseURI() + '/#/auth/linkedin/error/' + linkedin_error + '/'
                + linkedin_error_desc;
        }
    };
    HomepageCtrl.$inject = moduleConfig.HomepageCtrl.deps;
    module.controller(moduleConfig.HomepageCtrl.name, HomepageCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_BannerMovilCtrl",
            deps: ["TCFAutos.services.ParametrosService"],
            Homepage_BannerMovilCtrl: {
                name: "Homepage_BannerMovilCtrl",
                deps: ["ParametrosService", "$window", "TCFAAppConfigService"]
            }
        },
        module, Homepage_BannerMovilCtrl;
    module = angular.module("TCFAutos");
    Homepage_BannerMovilCtrl = function (ParametrosService, $window, TCFAAppConfigService) {
        var vm = this;
        ParametrosService.get({key: "show_banner_mobile"}).$promise.then(function (response) {
            vm.showNewMobileBanner = response.valor === "true";
        });
        vm.mobileGplusLink = function () {
            $window.open(TCFAAppConfigService.LINKS.ANDROID_APP_LINK);
        };
        vm.mobileIphoneLink = function () {
            $window.open(TCFAAppConfigService.LINKS.IOS_APP_LINK);
        };
    };
    Homepage_BannerMovilCtrl.$inject = moduleConfig.Homepage_BannerMovilCtrl.deps;
    module.controller(moduleConfig.Homepage_BannerMovilCtrl.name, Homepage_BannerMovilCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_CarsAutocompleteCtrl",
            deps: [],
            Homepage_CarsAutocompleteCtrl: {
                name: "Homepage_CarsAutocompleteCtrl",
                deps: ["$scope", "$timeout", "$mdUtil", "$log", "$injector", "$location"]
            }
        },
        module, Homepage_CarsAutocompleteCtrl;
    module = angular.module("TCFAutos");
    Homepage_CarsAutocompleteCtrl = function ($scope, $timeout, $mdUtil, $log, $injector, $location) {
        var vm = this;
        vm.scope = $scope;
        vm.showResults = true;
        return AutoComplete.call(vm, $timeout, $mdUtil, $log, $injector, $location);
    };
    Homepage_CarsAutocompleteCtrl.$inject = moduleConfig.Homepage_CarsAutocompleteCtrl.deps;
    module.controller(moduleConfig.Homepage_CarsAutocompleteCtrl.name, Homepage_CarsAutocompleteCtrl);
    Homepage_CarsAutocompleteCtrl.prototype = new AutoComplete();
    Homepage_CarsAutocompleteCtrl.prototype.selectedItemChange = function (item) {
        if (item && item.display) {
            this.location.path("/search/").search({query: item.display});
            this.md.enableScrolling();
        }
    };
    Homepage_CarsAutocompleteCtrl.prototype.doTextSearch = function (searchQuery) {
        var vm = this;
        if (searchQuery && searchQuery.length > 0) {
            vm.showResults = false;
            vm.md.enableScrolling();
            vm.timeout(function () {
                vm.location.path("/search/").search({query: searchQuery, page: 1});
            }, 500)
            ;
        }
    };
    Homepage_CarsAutocompleteCtrl.prototype.querySearch = function (query) {
        var deferred, CarDAO, states,
            autoComplete = this;
        autoComplete.$q = this.$injector.get("$q");
        deferred = autoComplete.$q.defer();
        CarDAO = autoComplete.$injector.get("CarDAO");
        if (query.length > 2) {
            CarDAO.autocompleteSearch({query: query}, function (response) {
                if (response.data) {
                    states = response.data.map(function (state) {
                        return {
                            value: null,
                            display: state.query
                        };
                    });
                    if (autoComplete.showResults) {
                        deferred.resolve(states);
                    }
                }
            });
        } else {
            return [];
        }
        return deferred.promise;
    };
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_ConcesionariosDestacadosCtrl",
            deps: ["TCFAutos.services.ConcesionariosDAO"],
            Homepage_ConcesionariosDestacadosCtrl: {
                name: "Homepage_ConcesionariosDestacadosCtrl",
                deps: ["$scope", "$compile", "$rootScope", "$window", "ConcesionariosDAO", "$injector", "DependencyInjectionService"]
            }
        },
        module, Homepage_ConcesionariosDestacadosCtrl;
    module = angular.module("TCFAutos");
    Homepage_ConcesionariosDestacadosCtrl = function ($scope, $compile, $rootScope, $window, ConcesionariosDAO, $injector, DependencyInjectionService) {
        var googleMaps,
            vm = this,
            GeoLocationService = $injector.get("GeoLocationService"),
            $anchorScroll = $injector.get("$anchorScroll"),
            $filter = $injector.get("$filter"),
            __showMarker = function () {
                vm.comoLlego(-1);
                $anchorScroll("concesionariosDestacadosMap");
                vm.map.setCenter(this.marker.getPosition());
                vm.map.setZoom(15);
            },
            onSuccess = function (position) {
                var latLng,
                    mapOptions = {
                        scrollwheel: false,
                        zoom: 15,
                        mapTypeId: googleMaps.MapTypeId.ROADMAP
                    };
                if (position) {
                    latLng = new googleMaps.LatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    mapOptions.center = latLng;
                } else {
                    // Obelisco CABA.
                    mapOptions.center = new googleMaps.LatLng(-34.6037345, -58.3837644);
                }
                // Create a map object and specify the DOM element for display.
                vm.map = new googleMaps.Map(document.getElementById("concesionariosDestacadosMap"), mapOptions);
                // Wait until the map is loaded.
                googleMaps.event.addListenerOnce(vm.map, "idle", function () {
                    var __det_destacados_params = null,
                        __filter_by_casa_central = true;
                    // User position.
                    if (latLng) {
                        vm.user_marker = new googleMaps.Marker({
                            map: vm.map,
                            animation: googleMaps.Animation.DROP,
                            icon: "../images/imageWeb/marker.png",
                            position: latLng,
                            draggable: true
                        });
                        vm.user_infoWindow = new googleMaps.InfoWindow({
                            content: $compile(
                                '<div class="text-center">' +
                                "<span>Ud. se encuentra aqu&iacute;.</span>" +
                                "<br>" +
                                "<span><small><em>(Arrastre el marcador para cambiar la ubicaci&oacute;n.)</em></small></span>" +
                                "</div>"
                            )($scope)[0]
                        });
                        googleMaps.event.addListener(vm.user_marker, "click", function () {
                            vm.user_infoWindow.open(vm.map, vm.user_marker);
                        });
                        googleMaps.event.addListener(vm.user_marker, "dragend", function () {
                            if (vm.cambiarComoLlego) {
                                vm.cambiarComoLlego();
                            }
                        });
                    }
                    // Filter destacados by Dealer.
                    if ($rootScope.cotizador && $rootScope.cotizador.from_campaign && $rootScope.cotizador.dealer && !$rootScope.cotizador.dealer.is_default) {
                        __det_destacados_params = {cuit: $rootScope.cotizador.dealer.cuit};
                        __filter_by_casa_central = false;
                    }
                    // Geopos Concesionarios.
                    vm.map_markers = [];
                    vm.concesionarios = ConcesionariosDAO.getDestacados(__det_destacados_params, function () {
                        var __openInfoWindow = function (index) {
                            vm.map_markers.forEach(function (marker, i) {
                                if (i !== index) {
                                    if (marker.infoWindow) {
                                        marker.infoWindow.close();
                                    }
                                } else {
                                    marker.infoWindow.open(vm.map, marker.marker);
                                }
                            });
                        };
                        vm.concesionarios.forEach(function (concesionario) {
                            concesionario.cant_publicaciones = parseInt(concesionario.cant_publicaciones, 10);
                            concesionario.sucursales.forEach(function (sucursal) {
                                var __marker = {
                                    order: concesionario.cant_publicaciones,
                                    label: (__filter_by_casa_central ? concesionario.razon_social : sucursal.descripcion)
                                };
                                if (sucursal.casa_central || !__filter_by_casa_central) {
                                    vm.map_markers.push(__marker);
                                    // Se guarda el marcador, para la información de rutas.
                                    __marker.marker = new googleMaps.Marker({
                                        map: vm.map,
                                        animation: googleMaps.Animation.DROP,
                                        position: new googleMaps.LatLng({
                                            lat: parseFloat(sucursal.lat),
                                            lng: parseFloat(sucursal.lon)
                                        }),
                                        title: concesionario.razon_social + " - " + sucursal.descripcion
                                    });
                                    __marker.marker.setLabel({
                                        text: $filter("camelCase")(concesionario.razon_social),
                                        fontFamily: "NeutraBold",
                                        fontWeight: "bold",
                                        fontSize: "16px"
                                    });
                                    __marker.marker.setIcon({
                                        url: "../images/imageWeb/isotoyota.png",
                                        labelOrigin: new googleMaps.Point(30 + (4 * concesionario.razon_social.length), 12)
                                    });
                                    // Ventana de información con el botón de cómo llego.
                                    __marker.infoWindow = new googleMaps.InfoWindow({
                                        content: $compile(
                                            '<div class="text-center">' +
                                            "<span>" +
                                            concesionario.razon_social + " - " + sucursal.descripcion +
                                            "</span>" +
                                            "<br>" +
                                            '<button ng-click="ctrl.comoLlego(' + (vm.map_markers.length - 1) + ')" ng-show="ctrl.user_marker" class="btn btn-sm">' +
                                            "¿C&oacute;mo llego?" +
                                            "</button>" +
                                            "</div>"
                                        )($scope)[0]
                                    });
                                    __marker.showMe = __showMarker;
                                    googleMaps.event.addListener(__marker.marker, "click", __openInfoWindow.bind(this, (vm.map_markers.length - 1)));
                                }
                            });
                        });
                        vm.cambiarComoLlego = function () {
                            var index,
                                routeFunction = function (result, status) {
                                    if (status === googleMaps.DirectionsStatus.OK) {
                                        vm.map_markers[index].directionsDisplay.setDirections(result);
                                        vm.map_markers[index].directionsDisplay.setMap(vm.map);
                                    } else {
                                        vm.map_markers[index].directionsDisplay = null;
                                    }
                                };
                            vm.map_markers.forEach(function (marker, i) {
                                if (marker.directionsDisplay) {
                                    if (marker.directionsDisplay.getMap() !== null) {
                                        index = i;
                                        marker.directionsDisplay.setMap(null);
                                        marker.directionsService.route({
                                            origin: vm.user_marker.position,
                                            destination: marker.marker.position,
                                            travelMode: googleMaps.TravelMode.DRIVING
                                        }, routeFunction);
                                    } else {
                                        marker.directionsDisplay = null;
                                    }
                                }
                            });
                        };

                        vm.comoLlego = function (index) {
                            var routeFunction = function (result, status) {
                                if (status === googleMaps.DirectionsStatus.OK) {
                                    vm.map_markers[index].directionsDisplay.setDirections(result);
                                }
                            };
                            vm.map_markers.forEach(function (marker, i) {
                                if (i !== index) {
                                    if (marker.directionsDisplay && (marker.directionsDisplay.getMap() !== null)) {
                                        marker.directionsDisplay.setMap(null);
                                    }
                                } else {
                                    if (marker.directionsDisplay) {
                                        // Si ya existe el objeto para mostrar la ruta, solo se setea el mapa.
                                        marker.directionsDisplay.setMap(vm.map);
                                    } else {
                                        // Si no existe el objeto para mostrar la ruta, se crea.
                                        if (marker.marker) {
                                            marker.directionsDisplay = new googleMaps.DirectionsRenderer({
                                                markerOptions: {visible: false}
                                            });
                                            marker.directionsService = new googleMaps.DirectionsService();
                                            marker.directionsDisplay.setMap(vm.map);
                                            marker.directionsService.route({
                                                origin: vm.user_marker.position,
                                                destination: marker.marker.position,
                                                travelMode: googleMaps.TravelMode.DRIVING
                                            }, routeFunction);
                                        }
                                    }
                                }
                            });
                        };
                    });
                });
            }, onError = function (error) {
                $window.console.log("Geolocation problem; construyendo mapa sin ubicacion del usuario.", error);
                onSuccess();
            }, getGoogleMaps = function (/*gm*/) {
                DependencyInjectionService.loadApi("GoogleMaps").then(function () {
                    googleMaps = google.maps;
                    GeoLocationService.location.then(onSuccess, onError);
                }, function () {
                    onError();
                });
            };
        getGoogleMaps();
    };
    Homepage_ConcesionariosDestacadosCtrl.$inject = moduleConfig.Homepage_ConcesionariosDestacadosCtrl.deps;
    module.controller(moduleConfig.Homepage_ConcesionariosDestacadosCtrl.name, Homepage_ConcesionariosDestacadosCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "contactsModule",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.SessionValidationService", "contactsDaoModule"],
            ContactConfirmationController: {
                name: "ContactConfirmationController",
                deps: ["$routeParams", "PageNavigationService", "ContactsDAO"]
            }
        },
        module, ContactConfirmationController;
    module = angular.module("TCFAutos");
    ContactConfirmationController = function ($routeParams, PageNavigationService, ContactsDAO) {
        var vm = this;
        window.moment.locale("es");
        PageNavigationService.showNavBar = true;
        vm.success = null;
        ContactsDAO.confirmarConsulta({}, $routeParams).$promise.then(function (/*promise*/) {
            vm.success = true;
        }, function (/*response*/) {
            vm.success = false;
            vm.error_msg = "Tu consulta ya ha sido validada previamente.";
        });
    };
    ContactConfirmationController.$inject = moduleConfig.ContactConfirmationController.deps;
    module.controller(moduleConfig.ContactConfirmationController.name, ContactConfirmationController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "indexModule",
            deps: ['TCFAutos.services.PageNavigationService', 'TCFAutos.services.ParametrosService', 'authDaoModule', 'termsConditionCheckerModule',
                'socialModule', 'TCFAutos.services.BannerDAO', 'TCFAutos.services.SessionValidationService', 'TCFAutos.services.TCFANewsletter'],
            FooterController: {
                name: "FooterController",
                deps: ['$scope', '$rootScope', '$location', '$uibModal', 'PageNavigationService', 'ParametrosService', 'TCFANewsletter', 'TCFAAppConfigService']
            }
        },
        module, FooterController;
    module = angular.module('TCFAutos');
    FooterController = function ($scope, $rootScope, $location, $uibModal, PageNavigationService, ParametrosService, TCFANewsletter, TCFAAppConfigService) {
        var vm = this;
        $scope.$watch(function () {
                return PageNavigationService.showNewsletterSubscription;
            }, function (newValue/*, oldValue*/) {
                vm.showNewsletterSubscription = newValue;
            }
        );
        if ($rootScope.userInfo && $rootScope.userInfo.user) {
            vm.newsletterEmail = $rootScope.userInfo.user.email;
            vm.preSettedEmail = true;
        }
        vm.facebook_link = TCFAAppConfigService.LINKS.FACEBOOK_PAGE;
        vm.gplus_link = TCFAAppConfigService.LINKS.GPLUS_PAGE;
        vm.linkedin_link = TCFAAppConfigService.LINKS.LINKEDIN_PAGE;
        vm.twitter_link = TCFAAppConfigService.LINKS.TWITTER_PAGE;
        vm.emailPattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
        // TyC sections (get section codes from params).
        vm.seccionLegalesSitio = null;
        vm.seccionPoliticasSitio = null;
        ParametrosService.getAllParameters().$promise.then(function (resp_params) {
            resp_params.some(function (param_item) {
                if (param_item.clave === "seccion_legales_sitio") {
                    vm.seccionLegalesSitio = param_item;
                } else if (param_item.clave === "seccion_uso_aplicacion") {
                    vm.seccionPoliticasSitio = param_item;
                }
                return (vm.seccionLegalesSitio && vm.seccionPoliticasSitio);
            });
        });
        vm.adherir = function () {
            var canal = null;
            vm.disableAdherirButtom = true;
            if (vm.newsletterEmail) {
                if ($rootScope.userInfo && $rootScope.userInfo.user && $rootScope.userInfo.user.email !== vm.newsletterEmail) {
                    canal = "native";
                }
                TCFANewsletter.adherirNewsletter(vm.newsletterEmail, canal).then(function () {
                    vm.disableAdherirButtom = false;
                    vm.newsletterEmail = null;
                }, function () {
                    vm.disableAdherirButtom = false;
                });
            }
        };
        vm.desadherir = function () {
            $uibModal.open({
                animation: true,
                templateUrl: './views/homepage/desadherir_newsletter.html',
                windowClass: 'tcfa-modal',
                controller: 'NewsletterUnsubscribeCtrl',
                controllerAs: 'ctrl',
                appendTo: angular.element("#modalComponent"),
                bindToController: true
            });
        };
        if (($location.search().action === 'adherir' || $location.search().action === 'desadherir') && $location.search().id_persona && $location.search().hash) {
            TCFANewsletter.validarEmailNewsletter($location.search().id_persona, $location.search().hash);
            $location.search({});
        }
    };
    // FIXME: esto es una definicion global, no deberia estar en este modulo.
    String.prototype.splice = function (idx, rem, s) {
        return (this.slice(0, idx) + s + this.slice(idx + Math.abs(rem)));
    };
    FooterController.$inject = moduleConfig.FooterController.deps;
    module.controller(moduleConfig.FooterController.name, FooterController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_HeaderCtrl",
            deps: ["carDaoModule", "TCFAutos.services.ActionDAO"],
            Homepage_HeaderCtrl: {
                name: "Homepage_HeaderCtrl",
                deps: ["$location", "$route", "$rootScope", "CarDAO", "$injector", "HomeFilterService"]
            }
        },
        module, Homepage_HeaderCtrl;
    module = angular.module("TCFAutos");
    Homepage_HeaderCtrl = function ($location, $route, $rootScope, CarDAO, $injector, HomeFilterService) {
        var vm = this,
            refreshCombos,
            ActionDAO = $injector.get("ActionDAO"),
            $httpParamSerializer = $injector.get("$httpParamSerializer");
        // Search buttons.
        vm.disable_search = false;
        vm.disable_search_by_used = false;
        vm.disable_search_by_new = false;
        // Disable search buttons when usar came from Cotizador.
        if ($rootScope.cotizador) {
            vm.disable_search_by_used = !(!!$rootScope.cotizador.show_used_cars);
            vm.disable_search_by_new = !(!!$rootScope.cotizador.show_new_cars);
        }
        vm.showAdvanced = false;
        vm.changeSearch = function () {
            vm.showAdvanced = !vm.showAdvanced;
        };
        vm.home = {searchText: ""};
        vm.doSearch = function (query) {
            var params = {sort: "dest"};
            if (query) {
                params.query = query;
                ActionDAO.createAction({
                    accion: "buscar-por-texto",
                    parametros: $httpParamSerializer(params)
                });
            } else {
                ActionDAO.createAction({accion: "ver-todas-las-publicaciones"});
                params.sort = "toyota";
            }
            $location.path("/search/").search(params);
            $route.reload();
        };
        vm.info = {is0kmCheck: 0};
        vm.brands = [];
        vm.models = [];
        vm.versions = [];
        refreshCombos = function () {
            vm.disableBrands = (!vm.brands || (vm.brands.length === 0));
            vm.disableModels = (!vm.models || (vm.models.length === 0));
            vm.disableVersions = (!vm.versions || (vm.versions.length === 0));
        };
        vm.changeBrand = function (currentBrand) {
            vm.models = [];
            vm.disableModels = true;
            vm.changeModel();
            if (currentBrand && (currentBrand.id > 0)) {
                CarDAO.getModelsByBrand({
                    id: currentBrand.id,
                    new: vm.info.is0km
                }, function (response) {
                    vm.models = response.data;
                    refreshCombos();
                });
            }
        };
        vm.changeModel = function (currentModel) {
            vm.versions = [];
            vm.disableVersions = true;
            if (currentModel && (currentModel.id > 0)) {
                CarDAO.getVersionByModel({
                    id: currentModel.id,
                    new: vm.info.is0km
                }, function (response) {
                    vm.versions = response.data;
                    refreshCombos();
                });
            }
        };
        vm.isChecked = function (value) {
            return value === true;
        };
        vm.changeTab = function (tab) {
            vm.selectedTab = tab;
        };
        vm.set0kmCheckbox = function (value) {
            if (typeof value === "boolean") {
                value = value ? 1 : 0;
            } else {
                value = parseInt(value);
            }
            if (value === 1) {
                vm.set0km(true);
            } else if (value === 0) {
                vm.set0km(false);
            } else {
                vm.set0km(null);
            }
            vm.info.is0kmCheck = value;
        };
        vm.set0km = function (value) {
            if (vm.info.is0km !== value) {
                vm.info.is0km = value;
                vm.brands = null;
                CarDAO.getOutstandingBrands({new: vm.info.is0km}, function (response) {
                    vm.brands = response.data;
                    refreshCombos();
                });
                HomeFilterService.filters.is0km = vm.info.is0km;
            }
        };
        vm.set0kmCheckbox(vm.info.is0kmCheck);
        refreshCombos();
        vm.doAdvanceSearch = function (/*str*/) {
            var advancedSearchParams = {sort: "dest"};
            // Marca/Modelo/Version.
            if (vm.info.currentBrand) {
                if (vm.info.currentBrand.id_fu) {
                    advancedSearchParams.marcaGroup = vm.info.currentBrand.id_fu;
                } else {
                    advancedSearchParams.marca = vm.info.currentBrand.id;
                }
            }
            if (vm.info.currentModel) {
                if (vm.info.currentModel.v_modelo_id) {
                    advancedSearchParams.modeloGroup = vm.info.currentModel.v_modelo_id;
                } else {
                    advancedSearchParams.modelo = vm.info.currentModel.id;
                }
            }
            if (vm.info.currentVersion) {
                advancedSearchParams.version = vm.info.currentVersion.id;
            }
            // Kilometraje.
            if (vm.info.kmDesde) {
                advancedSearchParams.kmsFrom = vm.info.kmDesde;
            }
            if (vm.info.kmHasta) {
                advancedSearchParams.kmsTo = vm.info.kmHasta;
            }
            // Año.
            if (vm.info.anoDesde) {
                advancedSearchParams.yearFrom = vm.info.anoDesde;
            }
            if (vm.info.anoHasta) {
                advancedSearchParams.yearTo = vm.info.anoHasta;
            }
            // Precio.
            if (vm.info.precioDesde) {
                advancedSearchParams.priceFrom = vm.info.precioDesde;
            }
            if (vm.info.precioHasta) {
                advancedSearchParams.priceTo = vm.info.precioHasta;
            }
            // Cuotas.
            if (vm.info.cuotaDesde) {
                advancedSearchParams.cuotaFrom = vm.info.cuotaDesde;
            }
            if (vm.info.cuotaHasta) {
                advancedSearchParams.cuotaTo = vm.info.cuotaHasta;
            }
            // Provincia.
            if (vm.info.currentProv) {
                advancedSearchParams.provincia = vm.info.currentProv.id;
            }
            if (vm.info.is0km !== null) {
                advancedSearchParams.is0km = vm.info.is0km;
            }
            ActionDAO.createAction({
                accion: "busqueda-avanzada",
                parametros: $httpParamSerializer(advancedSearchParams)
            });
            $location.path("/search/").search(advancedSearchParams);
            $route.reload();
        };
    };
    Homepage_HeaderCtrl.$inject = moduleConfig.Homepage_HeaderCtrl.deps;
    module.controller(moduleConfig.Homepage_HeaderCtrl.name, Homepage_HeaderCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_MobileFooterCtrl",
            deps: ['carDaoModule', 'utilsModule'],
            Homepage_MobileFooterCtrl: {
                name: "Homepage_MobileFooterCtrl",
                deps: ['$window', '$location', '$route', 'ParametrosService', 'UtilsHelper', 'TCFAAppConfigService']
            }
        },
        module, Homepage_MobileFooterCtrl;
    module = angular.module('TCFAutos');
    Homepage_MobileFooterCtrl = function ($window, $location, $route, ParametrosService, UtilsHelper, TCFAAppConfigService) {
        var vm = this;
        ParametrosService.getAllParameters().$promise.then(function (resp_params) {
            resp_params.some(function (param_item) {
                if (param_item.clave === "show_mobile_footer") {
                    if (param_item.valor === "true") {
                        // Footer with mobile link.
                        vm.showMobileFooter = (UtilsHelper.isMobile.android() || UtilsHelper.isMobile.iOS());
                        vm.mobileFooterController = vm.showMobileFooter;
                        if (UtilsHelper.isMobile.iOS()) {
                            vm.downloadAppLink = TCFAAppConfigService.LINKS.IOS_APP_LINK;
                        } else if (UtilsHelper.isMobile.android()) {
                            vm.downloadAppLink = TCFAAppConfigService.LINKS.ANDROID_APP_LINK;
                        } else {
                            vm.downloadAppLink = null;
                        }
                    }
                    return true;
                }
                return false;
            });
        });
        vm.hideToolBar = function () {
            vm.mobileFooterController = false;
        };
    };
    Homepage_MobileFooterCtrl.$inject = moduleConfig.Homepage_MobileFooterCtrl.deps;
    module.controller(moduleConfig.Homepage_MobileFooterCtrl.name, Homepage_MobileFooterCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_ProvinciasAutoCompleteCtrl",
            deps: [],
            Homepage_ProvinciasAutoCompleteCtrl: {
                name: "Homepage_ProvinciasAutoCompleteCtrl",
                deps: ["$timeout", "$mdUtil", "$log", "$injector", "$location"]
            }
        },
        module, Homepage_ProvinciasAutoCompleteCtrl;
    module = angular.module("TCFAutos");
    Homepage_ProvinciasAutoCompleteCtrl = function ($timeout, $mdUtil, $log, $injector, $location) {
        var vm = this;
        return AutoComplete.call(vm, $timeout, $mdUtil, $log, $injector, $location);
    };
    Homepage_ProvinciasAutoCompleteCtrl.$inject = moduleConfig.Homepage_ProvinciasAutoCompleteCtrl.deps;
    module.controller(moduleConfig.Homepage_ProvinciasAutoCompleteCtrl.name, Homepage_ProvinciasAutoCompleteCtrl);
    Homepage_ProvinciasAutoCompleteCtrl.prototype = new AutoComplete();
    Homepage_ProvinciasAutoCompleteCtrl.prototype.getValues = function () {
        var autoComplete = this,
            LocationDAO = this.$injector.get("LocationDAO");
        LocationDAO.getAllProvincias(function (data) {
            autoComplete.states = data;
        });
    };
    Homepage_ProvinciasAutoCompleteCtrl.prototype.createFilterFor = function (query) {
        var lowercaseQuery = angular.lowercase(query);
        return function (state) {
            return (angular.lowercase(state.name).indexOf(lowercaseQuery) >= 0);
        };
    };
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_PublicacionesDestacadas",
            deps: ["TCFAutos.directives.tcSlider"],
            Homepage_PublicacionesDestacadas: {
                name: "Homepage_PublicacionesDestacadas",
                deps: ["$scope", "$rootScope", "DestacadosDAO", "GeoLocationService", "$injector", "HomeFilterService", "CarDAO"]
            }
        },
        module, Homepage_PublicacionesDestacadas;
    module = angular.module("TCFAutos");
    Homepage_PublicacionesDestacadas = function ($scope, $rootScope, DestacadosDAO, GeoLocationService, $injector, HomeFilterService, CarDAO) {
        var vm = this,
            ActionDAO = $injector.get("ActionDAO"),
            filters = {};
        vm.title = "Publicaciones <strong>m&aacute;s visitadas</strong>";
        if ($rootScope.cotizador && $rootScope.cotizador.from_campaign) {
            if ($rootScope.cotizador.dealer && !$rootScope.cotizador.dealer.is_default) {
                filters.concesionario = ($rootScope.cotizador.dealer.id || 0);
            }
            HomeFilterService.filters.is0km = (!$rootScope.cotizador.show_used_cars && $rootScope.cotizador.show_new_cars);
        }
        vm.onItemGo = function (obj) {
            ActionDAO.createAction({
                accion: "ver-destacado",
                parametros: "auto_id=" + obj.id
            });
        };
        GeoLocationService.location.then(function (location) {
            filters.latitude = location.coords.latitude;
            filters.longitude = location.coords.longitude;
            vm.itemsFunction = function () {
                if ($rootScope.cotizador && $rootScope.cotizador.from_campaign) {
                    HomeFilterService.filters.is0km = (!$rootScope.cotizador.show_used_cars && $rootScope.cotizador.show_new_cars);
                    if ($rootScope.cotizador.dealer && !$rootScope.cotizador.dealer.is_default) {
                        filters.concesionario = ($rootScope.cotizador.dealer.id || 0);
                        return CarDAO.getRelatedByDistributor(filters).$promise;
                    }
                }
                return DestacadosDAO.getDestacados(filters).$promise;
            };
            vm.title = "Publicaciones <strong>m&aacute;s cercanas</strong>";
        });
        $scope.$watch(function () {
            return HomeFilterService.filters.is0km;
        }, function () {
            angular.extend(filters, HomeFilterService.filters);
            vm.itemsFunction = function () {
                if ($rootScope.cotizador && $rootScope.cotizador.from_campaign) {
                    HomeFilterService.filters.is0km = (!$rootScope.cotizador.show_used_cars && $rootScope.cotizador.show_new_cars);
                    if ($rootScope.cotizador.dealer && !$rootScope.cotizador.dealer.is_default) {
                        filters.concesionario = ($rootScope.cotizador.dealer.id || 0);
                        return CarDAO.getRelatedByDistributor(filters).$promise;
                    }
                }
                return DestacadosDAO.getDestacados(filters).$promise;
            };
        });
    };
    Homepage_PublicacionesDestacadas.$inject = moduleConfig.Homepage_PublicacionesDestacadas.deps;
    module.controller(moduleConfig.Homepage_PublicacionesDestacadas.name, Homepage_PublicacionesDestacadas);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Homepage_SliderCtrl",
            deps: ["TCFAutos.services.BannerDAO", "TCFAutos.services.SessionValidationService", "TCFAutos.services.PageNavigationService"],
            Homepage_SliderCtrl: {
                name: "Homepage_SliderCtrl",
                deps: ["BannerDAO", "SessionValidationService", "PageNavigationService", "TCFAAppConfigService"]
            }
        },
        module, Homepage_SliderCtrl;
    module = angular.module("TCFAutos");
    Homepage_SliderCtrl = function (BannerDAO, SessionValidationService, PageNavigationService, TCFAAppConfigService) {
        var vm = this,
            is_admin, bannerToShow;
        is_admin = SessionValidationService.validateStrictPermissions(["CheckUserType:admin"]);
        bannerToShow = PageNavigationService.getParameter("showBanner");
        vm.active = 0;
        vm.interval = 4500;
        vm.no_pause = false;
        vm.no_transition = false;
        vm.no_wrap = false;
        vm.loadDefault = function () {
            vm.default_images = [{src: "./images/bannerHome/bannerHome1.jpg"}];
        };
        vm.afterGetBannersSuccess = function (response) {
            if (response.data) {
                vm.banner_images = response.data.banners;
            } else {
                vm.loadDefault();
            }
        };
        vm.afterGetBannersFail = function () {
            vm.loadDefault();
        };
        vm.bannerSource = TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.BANNER_IMAGE_FOLDER + "/";
        if (is_admin && bannerToShow) {
            BannerDAO.getBanner({id: bannerToShow.id}).$promise.then(vm.afterGetBannersSuccess, vm.afterGetBannersFail);
        } else {
            BannerDAO.getPublicatedBanners({tipo_banner: "BANNERS-HOME"}).$promise.then(vm.afterGetBannersSuccess, vm.afterGetBannersFail);
        }
    };
    Homepage_SliderCtrl.$inject = moduleConfig.Homepage_SliderCtrl.deps;
    module.controller(moduleConfig.Homepage_SliderCtrl.name, Homepage_SliderCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Info_PublicationPreVisualizationCtrl",
            deps: ["TCFAutos.services.DestacadosDAO", "TCFAutos.directives.BottomBar"],
            Info_PublicationPreVisualizationCtrl: {
                name: "Info_PublicationPreVisualizationCtrl",
                deps: ["$scope", "$timeout", "$mdUtil", "$log", "$injector", "$routeParams", "usSpinnerService"]
            }
        },
        module, Info_PublicationPreVisualizationCtrl;
    module = angular.module("TCFAutos");
    Info_PublicationPreVisualizationCtrl = function ($scope, $timeout, $mdUtil, $log, $injector, $routeParams, usSpinnerService) {
        var vm = this,
            i, DestacadosDAO, CarDAO, info, $location, token, PageNavigationService,
            TCFAModalService = $injector.get("TCFAModalService"),
            TCFAAppConfigService = $injector.get("TCFAAppConfigService");
        vm.scope = $scope;
        vm.info = {};
        CarDAO = $injector.get("CarDAO");
        $location = $injector.get("$location");
        PageNavigationService = $injector.get("PageNavigationService");
        PageNavigationService.showLoginMenu = false;
        usSpinnerService.stop("info_show_cuotas");
        vm.prepareInfo = function (data) {
            vm.info.categorias = {};
            //fix para acomodar los detalles por categorias
            for (i = (data.comodidades.length - 1); i >= 0; i = i - 1) {
                if (!vm.info.categorias[data.comodidades[i].categoria.descripcion]) {
                    vm.info.categorias[data.comodidades[i].categoria.descripcion] = {};
                }
                if (!vm.info.categorias[data.comodidades[i].categoria.descripcion].detalles) {
                    vm.info.categorias[data.comodidades[i].categoria.descripcion].detalles = [];
                }
                vm.info.categorias[data.comodidades[i].categoria.descripcion].detalles.push(data.comodidades[i].descripcion);
            }
            vm.info.car = data;
            vm.info.slides = [];
            for (i = 0; i < data.car_images.length; i = i + 1) {
                vm.info.slides.push({image: data.car_images[i].url});
            }
            if (data.youtube_url) {
                vm.info.slides.push({video: data.youtube_url});
            }
            vm.info.afinanciar = data.price / 2;
            if (data.publicacion.estado.clave !== "PAUSADA") {
                info = {error: {}};
                info.error.showModal = true;
                info.error.message = "La publicacion no se encuentra en estado PAUSADA.";
                info.error.title = "Aviso!";
                TCFAModalService.showWarning(info);
                $location.path("/").search({});
            } else {
                vm.activationTokenValid = true;
            }
        };
        vm.data = {
            imagesSource: TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.AUTO_IMAGE_FOLDER,
            mostrarCuotas: false,
            isAdmin: false,
            isDealer: false
        };
        vm.searchRelacionados = function (filters) {
            return DestacadosDAO.getRelacionados(filters).$promise;
        };
        vm.activarPublicacion = function () {
            DestacadosDAO.activarPublicacion({securityToken: token}, function () {
                $location.path("/").search({});
            }, function () {
                info = {error: {}};
                info.error.showModal = true;
                info.error.message = "Ocurrió un error al activar de la publicación. Intente nuevamente en unos minutos.";
                info.error.title = "Aviso!";
                TCFAModalService.showError(info);
            });
        };
        vm.obtenerCuotaPromedio = function (cuota) {
            var i, showcuota,
                promedio = 0;
            for (i = 0; i <= cuota.Cuotas - 1; i = i + 1) {
                showcuota = {
                    fecha: cuota.FechaCuota[i],
                    saldoCapital: parseFloat(cuota.DeudaCapital[i]),
                    capital: parseFloat(cuota.Capital[i]),
                    interes: parseFloat(cuota.Interes[i]),
                    seguro: parseFloat(cuota.Seguro[i]),
                    ivaInteres: parseFloat(cuota.IvaInteres[i]),
                    costoMensual: parseFloat(cuota.Gasto),
                    ivaCostoMensual: parseFloat(cuota.Gasto) * 0.21
                };
                promedio = promedio + (showcuota.capital + showcuota.interes + showcuota.seguro + showcuota.ivaInteres + showcuota.costoMensual + showcuota.ivaCostoMensual);
            }
            return Math.ceil(promedio / cuota.Cuotas);
        };
        vm.disableCalcular = true;
        if ($routeParams.token) {
            token = $routeParams.token;
            DestacadosDAO = $injector.get("DestacadosDAO");
            DestacadosDAO.getPublicacionPorToken({securityToken: token}, function (response) {
                $routeParams.token = null;
                vm.prepareInfo(response.data);
                vm.calcularCuotas = function (carId, callback, params) {
                    var innerCallback = function (response) {
                        vm.showValorCuotas = true;
                        callback(response);
                    };
                    if (!params) {
                        params = {};
                    }
                    params.id = carId;
                    vm.disableCalcular = true;
                    CarDAO.getCuotas(params, innerCallback);
                };
                vm.calcularCuotas(response.data.id, function (response) {
                    usSpinnerService.stop("info_show_cuotas");
                    vm.info.cuotas = response.data;
                });
            }, function (response) {
                info = {error: {}};
                info.error.showModal = true;
                info.error.title = "Aviso!";
                if (response.status === 404) {
                    info.error.message = "El enlace de activación ya fue utilizado por otro administrador.";
                    TCFAModalService.showWarning(info);
                } else {
                    info.error.title = "¡Ha ocurrido un error!";
                    info.error.message = "Ocurrió un error al buscar la publicación. Intente nuevamente en unos minutos.";
                    TCFAModalService.showError(info);
                }
                $location.path("/").search({});
            });
        } else {
            info = {error: {}};
            info.error.showModal = true;
            info.error.message = "No existe un token valido para permitir la publicación.";
            info.error.title = "¡Ha ocurrido un error!";
            TCFAModalService.showError(info);
        }
    };
    Info_PublicationPreVisualizationCtrl.$inject = moduleConfig.Info_PublicationPreVisualizationCtrl.deps;
    module.controller(moduleConfig.Info_PublicationPreVisualizationCtrl.name, Info_PublicationPreVisualizationCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.LocationAutocomplete",
            deps: ["locationDaoModule"],
            LocationAutocompleteController: {
                name: "LocationAutocompleteController",
                deps: ["$timeout", "$mdUtil", "$log", "$injector", "$location"]
            }
        },
        module, LocationAutocompleteController;
    module = angular.module("TCFAutos");
    LocationAutocompleteController = function ($timeout, $mdUtil, $log, $injector, $location) {
        return AutoComplete.call(this, $timeout, $mdUtil, $log, $injector, $location);
    };
    LocationAutocompleteController.prototype = new AutoComplete();
    LocationAutocompleteController.prototype.querySearch = function (query) {
        var states,
            deferred = (this.$injector.get("$q")).defer(),
            LocationDAO = this.$injector.get("LocationDAO");
        if (query.length > 3) {
            LocationDAO.apiGoogleMapsLocalidades(query, function (data) {
                states = data.map(function (state) {
                    return {
                        value: state.id,
                        formatted_address: state.formatted_address,
                        location: state.location,
                        address_components: state.address_components,
                        search_query: query
                    };
                });
                deferred.resolve(states);
            });
        } else {
            return [];
        }
        return deferred.promise;
    };
    LocationAutocompleteController.$inject = moduleConfig.LocationAutocompleteController.deps;
    module.controller(moduleConfig.LocationAutocompleteController.name, LocationAutocompleteController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.LoginClienteCtrl",
            deps: ["TCFAutos.services.PageNavigationService", "socialModule", "directivesModule", "TCFAutos.services.NotificationsService"],
            LoginClienteCtrl: {
                name: "LoginClienteCtrl",
                deps: ["$window", "$rootScope", "$uibModal", "$uibModalInstance", "$injector", "AuthDAO", "facebookService", "googleService",
                    "linkedinService", "PageNavigationService", "ActionDAO", "NotificationsService", "TCFAAppConfigService"]
            }
        },
        module, LoginClienteCtrl,
        _MODAL_COMPONENT_ID_HTML_SELECTOR = "#modalComponent";
    module = angular.module("TCFAutos");
    LoginClienteCtrl = function ($window, $rootScope, $uibModal, $uibModalInstance, $injector, AuthDAO, facebookService, googleService,
                                 linkedinService, PageNavigationService, ActionDAO, NotificationsService, TCFAAppConfigService) {
        var vm = this, $location = $injector.get("$location"),
            checkSocialNetFirewall = function (SocialNetService) {
                if (SocialNetService.tcfautos_fallback_check) {
                    $window.alert("El inicio de sesión mediante esta Red Social se encuentra deshabilitado porque no " +
                        "se ha podido establecer una conexión con sus servidores.\n" +
                        "Por favor, compruebe que la Red Social no se encuentre bloqueada por programas " +
                        "externos (como firewalls o proxies). Disculpe las molestias.");
                }
                return !SocialNetService.tcfautos_fallback_check;
            };
        vm.registerUrl = TCFAAppConfigService.LINKS.NEW_REGISTER_URL;
        vm.lostPassword = TCFAAppConfigService.LINKS.LOST_PASSWORD;
        vm.login = {
            email: "",
            password: "",
            error: ""
        };
        vm.disableFacebookLogin = facebookService.tcfautos_fallback_check;
        vm.disableGoogleLogin = googleService.tcfautos_fallback_check;
        vm.disableLinkedinLogin = linkedinService.tcfautos_fallback_check;
        vm.doLoginCliente = function () {
            var cliente = {
                username: window.base64.encode(vm.login.email),
                password: window.base64.encode(vm.login.password),
                user_type: "cliente"
            };
            vm.login.error = "";
            PageNavigationService.showSplash = true;
            AuthDAO.login(cliente).$promise.then(function (promise) {
                var pictureUrl = "../images/user/defaultProfile.png";
                PageNavigationService.showSplash = false;
                $rootScope.userInfo = {
                    user: promise.data.user,
                    token: promise.data.token
                };
                NotificationsService.markAsUnread(promise.data.user.cliente.mensajes);
                if (promise.data.user.profile_image) {
                    pictureUrl = TCFAAppConfigService.API.IMG_SRC + "/user_images/" + promise.data.user.profile_image.url;
                }
                $rootScope.socialProfile = {pictureUrl: pictureUrl};
                $rootScope.ShowMiPerfil = true;
                $rootScope.ShowABM = false;
                $rootScope.ShowMensajes = true;
                $rootScope.showFavoritos = true;
                $uibModalInstance.close(promise.data);
            }, function (error) {
                PageNavigationService.showSplash = false;
                if (error.status === 404) {
                    if (error.data.errors.code === 100) {
                        vm.login.error = "El usuario no existe, por favor complete el proceso de registración para continuar.";
                    } else if (error.data.errors.code === 110) {
                        vm.login.error = "La contraseña es inválida.";
                    } else if (error.data.errors.code === 120) {
                        vm.login.error = "Debe finalizar el proceso de registración para continuar, revise su casilla de email.";
                    } else if (error.data.errors.code === 21) {
                        vm.login.error = "Password vencida.";
                    }
                } else if (error.status === 500) {
                    vm.login.error = "Ha ocurrido un problema en la red. Por favor intente nuevamente tras unos instantes.";
                }
            });
        };
        vm.registrarUsuario = function () {
            $location.path(vm.registerUrl);
            ActionDAO.createAction({accion: "registrar-usuario"});
            $uibModalInstance.dismiss("close");
        };
        vm.recuperarUsuario = function () {
            $location.path(vm.lostPassword);
            ActionDAO.createAction({accion: "recuperar-usuario"});
            $uibModalInstance.dismiss("close");
        };
        vm.close = function () {
            $uibModalInstance.dismiss("close");
        };
        vm.showRegister = function () {
            $uibModalInstance.dismiss("close");
            $uibModal.open({
                animation: true,
                windowClass: "tcfa-modal",
                templateUrl: "./templates/modules/nuevo_usuario_modal.html",
                controller: ["$scope", "$uibModalInstance", function (/*$scope, $uibModalInstance*/) {/*Noop.*/}],
                controllerAs: "ctrl",
                parent: angular.element(_MODAL_COMPONENT_ID_HTML_SELECTOR),
                appendTo: angular.element(_MODAL_COMPONENT_ID_HTML_SELECTOR),
                bindToController: true
            });
        };
        vm.showChangePassword = function () {
            $uibModalInstance.dismiss("close");
            $uibModal.open({
                animation: true,
                windowClass: "tcfa-modal",
                templateUrl: "./templates/modules/recordar_contraseña_modal.html",
                controller: ["$scope", "$uibModalInstance", function (/*$scope, $uibModalInstance*/) {/*Noop.*/}],
                parent: angular.element(_MODAL_COMPONENT_ID_HTML_SELECTOR),
                appendTo: angular.element(_MODAL_COMPONENT_ID_HTML_SELECTOR),
                controllerAs: "ctrl",
                bindToController: true
            });
        };
        vm.doLoginFacebook = function () {
            if (checkSocialNetFirewall(facebookService)) {
                $window.console.debug("doLoginFacebook");
                facebookService.login(function () {
                    $uibModalInstance.close($rootScope.userInfo);
                });
            } else {
                vm.disableFacebookLogin = facebookService.tcfautos_fallback_check;
            }
        };
        vm.doLoginGoogle = function () {
            if (checkSocialNetFirewall(googleService)) {
                $window.console.debug("doLoginGoogle");
                googleService.login(function () {
                    $uibModalInstance.close($rootScope.userInfo);
                });
            } else {
                vm.disableGoogleLogin = googleService.tcfautos_fallback_check;
            }
        };
        vm.doLoginLinkedin = function () {
            linkedinService.openAuthTab();
        };
    };
    LoginClienteCtrl.$inject = moduleConfig.LoginClienteCtrl.deps;
    module.controller(moduleConfig.LoginClienteCtrl.name, LoginClienteCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.LoginClienteSelector",
            deps: [],
            LoginClienteSelector: {
                name: "LoginClienteSelector",
                deps: ["$scope", "$uibModalInstance"]
            }
        },
        module, LoginClienteSelector;
    module = angular.module("TCFAutos");
    LoginClienteSelector = function ($scope, $uibModalInstance) {
        var vm = this;
        vm.clientes = $scope.clientes;
        vm.operaPor = "-1";
        vm.doAccept = function () {
            $uibModalInstance.close(vm.operaPor);
        };
    };
    LoginClienteSelector.$inject = moduleConfig.LoginClienteSelector.deps;
    module.controller(moduleConfig.LoginClienteSelector.name, LoginClienteSelector);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.LoginConcesionarioCtrl",
            deps: ["carDaoModule", "termsConditionCheckerModule", "TCFAutos.services.ParametrosService", "TCFAutos.services.PageNavigationService",
                "TCFAutos.services.NotificationsService"],
            LoginConcesionarioCtrl: {
                name: "LoginConcesionarioCtrl",
                deps: ["$window", "$rootScope", "$location", "$route", "$uibModalInstance", "$uibModal", "CarDAO", "AuthDAO", "termsAndConditionChecker",
                    "ParametrosService", "PageNavigationService", "NotificationsService", "TCFAModalService", "TCFAAppConfigService"]
            }
        },
        module, LoginConcesionarioCtrl;
    module = angular.module("TCFAutos");
    LoginConcesionarioCtrl = function ($window, $rootScope, $location, $route, $uibModalInstance, $uibModal, CarDAO, AuthDAO, termsAndConditionChecker,
                                       ParametrosService, PageNavigationService, NotificationsService, TCFAModalService, TCFAAppConfigService) {
        var vm = this;
        vm.concesionaria = {
            email: "",
            password: "",
            error: ""
        };
        vm.doLoginConcesionario = function () {
            var concesionario = {
                username: window.base64.encode(vm.concesionaria.email),
                password: window.base64.encode(vm.concesionaria.password)
            };
            vm.concesionaria.error = "";
            PageNavigationService.showSplash = true;
            AuthDAO.loginConcesionario(concesionario).$promise.then(function (userPromise) {
                var pictureUrl = "../images/user/defaultProfileDealer.png";
                PageNavigationService.showSplash = false;
                $rootScope.userInfo = {
                    user: userPromise.data.user,
                    token: userPromise.data.token
                };
                NotificationsService.clear();
                if (userPromise.data.user.profile_image) {
                    pictureUrl = TCFAAppConfigService.API.IMG_SRC + "/user_images/" + userPromise.data.user.profile_image.url;
                }
                $rootScope.socialProfile = {pictureUrl: pictureUrl};
                $rootScope.ShowMiPerfil = false;
                $rootScope.ShowABM = true;
                $rootScope.ShowMensajes = false;
                $rootScope.showFavoritos = false;
                $uibModalInstance.close(userPromise.data);
            }, function (error) {
                PageNavigationService.showSplash = false;
                if (error.status === 400) {
                    vm.concesionaria.error = "Los datos no son correctos, por favor valídelos e intente nuevamente.";
                } else {
                    vm.concesionaria.error = "Ha ocurrido un problema en la red. Por favor, intente nuevamente tras unos instantes.";
                }
            });
        };
        vm.close = function () {
            $uibModalInstance.dismiss("close");
        };
    };
    LoginConcesionarioCtrl.$inject = moduleConfig.LoginConcesionarioCtrl.deps;
    module.controller(moduleConfig.LoginConcesionarioCtrl.name, LoginConcesionarioCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.LoginConcesionarioSelector",
            deps: [],
            LoginConcesionarioSelector: {
                name: "LoginConcesionarioSelector",
                deps: ["$scope", "$uibModalInstance"]
            }
        },
        module, LoginConcesionarioSelector;
    module = angular.module("TCFAutos");
    LoginConcesionarioSelector = function ($scope, $uibModalInstance) {
        var vm = this;
        vm.concesionarios = $scope.concesionarios;
        vm.operaPor = "-1";
        vm.doAccept = function () {
            $uibModalInstance.close(vm.operaPor);
        };
    };
    LoginConcesionarioSelector.$inject = moduleConfig.LoginConcesionarioSelector.deps;
    module.controller(moduleConfig.LoginConcesionarioSelector.name, LoginConcesionarioSelector);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.NavBarCtrl",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.LogInUser", "TCFAutos.services.NotificationsService",
                "TCFAutos.services.ExternalLoginService"],
            NavBarCtrl: {
                name: "NavBarCtrl",
                deps: ["$window", "$scope", "$rootScope", "$location", "$route", "$uibModal", "$routeParams", 
                    "PageNavigationService", "AuthDAO", "LogInUser", "NotificationsService", "TCFAAppConfigService", 
                    "CotizadorService", "ExternalLoginService", "ParametrosService"]
            }
        },
        module, NavBarCtrl,
        _ABM_PUBLICACIONES_PATH = "publicaciones/",
        _ABM_IMAGENES_PREVIEW_PATH = "/administracion/preview";
    module = angular.module("TCFAutos");
    NavBarCtrl = function ($window, $scope, $rootScope, $location, $route, $uibModal, $routeParams, PageNavigationService,
        AuthDAO, LogInUser, NotificationsService, TCFAAppConfigService, CotizadorService, ExternalLoginService, ParametrosService) {
        var vm = this,
            page = angular.element($window),
            __id_campaign = parseInt($routeParams.ic, 10),
            reloadNavBar = function () {
                var el_is_visible,
                    max_height = $window.innerHeight;
                max_height = (max_height * 50 / 100);
                el_is_visible = ($window.scrollY > max_height);
                if (el_is_visible || PageNavigationService.showNavBar) {
                    $("#navigationBar").addClass("navbar-inverse").removeClass("navbar-inverse-alpha");
                } else {
                    $("#navigationBar").removeClass("navbar-inverse").addClass("navbar-inverse-alpha");
                }
            },
            __updateDealersLogo = function () {
                if (($location.path().indexOf(_ABM_IMAGENES_PREVIEW_PATH) === 0) &&
                    $rootScope.abm_imagenes_preview && $rootScope.abm_imagenes_preview.dealer) {
                    vm.dealer_preview_override = $rootScope.abm_imagenes_preview.dealer;
                } else {
                    vm.dealer_preview_override = null;
                }
            },
            __updateHeaderLogo = function () {
                vm.showAbmLogo = ($location.path().indexOf(_ABM_IMAGENES_PREVIEW_PATH) >= 0);
                __updateDealersLogo();
                // Get Toyota Ciclo.
                ParametrosService.get({key: "id_toyota_ciclo"}).$promise.then(function(param) {
                    vm.toyotaCiclo = (parseInt(param.valor, 10) === parseInt($routeParams.ic, 10));
                });
            },
            __updateDealerLogin = function () {
                vm.hideDealerLogin = !!$routeParams.ic && $rootScope.hideLoginConcesionario === true;
            };
        __updateHeaderLogo();
        $scope.$watch(function (/*scope*/) {
                return (PageNavigationService.showNavBar);
            }, function (/*newValue, oldValue*/) {
                reloadNavBar();
            }
        );
        $scope.$watch(function (/*scope*/) {
                return (PageNavigationService.showLoginMenu);
            }, function (/*newValue, oldValue*/) {
                vm.showLoginMenu = PageNavigationService.showLoginMenu;
            }
        );
        $scope.$watch(function (/*scope*/) {
                return $location.url();
            }, function (/*newValue, oldValue*/) {
                __updateHeaderLogo();
                __updateDealerLogin();
            }
        );
        $scope.$watch(function (/*scope*/) {
                return $rootScope.abm_imagenes_preview && $rootScope.abm_imagenes_preview.dealer;
            }, function (/*newValue, oldValue*/) {
                __updateHeaderLogo();
            }
        );
        $scope.$watch(function (/*scope*/) {
            return $rootScope.hideLoginConcesionario;
        }, function (/*newValue, oldValue*/) {
            __updateDealerLogin();
        }
    );
        // Endpoint de actualizacion de mensajes del usuario.
        NotificationsService.watchUnreads(function (unreadsCount) {
            $rootScope.userInfo.unreadMessages = unreadsCount;
        });
        page.bind("scroll", reloadNavBar);
        vm.showLoginConcesionario = function () {
            LogInUser.loginConcesionario().then(function (result) {
                if (result === "userlogged") {
                    ExternalLoginService.goToAbm(_ABM_PUBLICACIONES_PATH);
                }
            });
        };
        vm.showLoginCliente = function () {
            LogInUser.loginCliente().then(function () {
                $route.reload();
            });
        };
        vm.toABM = function () {
            ExternalLoginService.goToAbm();
        };
        vm.toFavoritos = function () {
            $window.console.debug("toFavoritos");
            $location.path("/favoritos/");
        };
        vm.toMisMensajes = function () {
            $location.path("/mensajes/");
        };
        vm.toMiPerfil = function () {
            $location.path("/perfil/");
        };
        /** User LogOut process. Deletes user info & navs to home (TCFAutos Home or Cotizador Home).*/
        vm.doLogout = function () {
            var __path = "/", // TCFAutos Home.
                __search = {},
                __home_cotizador = "/cotizador/"; // Cotizador Home.
            $window.console.debug("doLogout");
            delete $rootScope.socialProfile;
            $rootScope.userInfo = {};
            $rootScope.is_tasa = false;
            // Mantener usuario en el cotizador si se deslogea desde el cotizador.
            if ($location.path().indexOf(__home_cotizador) === 0) {
                __path = __home_cotizador;
                if ($location.search().ic) {
                    __search.ic = $location.search().ic; // Mantener campaña del usuario. Borrar resto de los parámetros.
                }
                CotizadorService.changeCotizarForm();
            }
            // Nav & reload.
            $location.path(__path).search(__search);
            $route.reload();
        };
        vm.esCotizador = ($location.path() === "/cotizador/");
        $rootScope.$watch(function () {
            vm.esCotizador = ($location.path() === "/cotizador/");
        });
    };
    NavBarCtrl.$inject = moduleConfig.NavBarCtrl.deps;
    module.controller(moduleConfig.NavBarCtrl.name, NavBarCtrl);
}());

(function () {
    "use strict";

    var moduleConfig = {
            name: "TCFAutos.controllers.PerfilController",
            deps: ["TCFAutos.controllers.PerfilMenuController", "TCFAutos.directives.BottomBar", "TCFAutos.services.UserDAOService",
                "TCFAutos.controllers.LocationAutocomplete", "TCFAutos.services.SessionValidationService", "verificationCodeHandlerModule"],
            PerfilController: {
                name: "PerfilController",
                deps: ["$rootScope", "$scope", "$window", "$location", "$timeout", "verificationCodeHandler", "UserDAO", "SessionValidationService", "TCFAModalService", "usSpinnerService"]
            }
        },
        module, PerfilController;
    module = angular.module("TCFAutos");
    PerfilController = function ($rootScope, $scope, $window, $location, $timeout, verificationCodeHandler, UserDAO, SessionValidationService, TCFAModalService, usSpinnerService) {
        var vm = this,
            callback = function () {
                $location.path("perfil");
            };
        if (!SessionValidationService.validateStrictPermissions(["CheckUserType:cliente", "CheckUserType:tasa"], "cliente", callback)) {
            $location.path("/");
            return;
        }
        if (SessionValidationService.validateStrictPermissions(["CheckUserType:admin", "CheckUserType:dealer"])) {
            $location.path("/");
            return;
        }
        vm.perfil = {
            nombre: $rootScope.userInfo.user.nombre,
            apellido: $rootScope.userInfo.user.apellido,
            localidad: $rootScope.userInfo.user.localidad,
            email: $rootScope.userInfo.user.email,
            prefijo_telefono: $rootScope.userInfo.user.cliente && $rootScope.userInfo.user.cliente.prefijo_telefono || null,
            telefono: $rootScope.userInfo.user.cliente && $rootScope.userInfo.user.cliente.telefono || null,
            prefijo_celular: $rootScope.userInfo.user.cliente && $rootScope.userInfo.user.cliente.prefijo_celular || null,
            celular: $rootScope.userInfo.user.cliente && $rootScope.userInfo.user.cliente.celular || null,
            email_alternativo: $rootScope.userInfo.user.cliente && $rootScope.userInfo.user.cliente.email_alternativo || null,
            email_alternativo_validado: $rootScope.userInfo.user.cliente && $rootScope.userInfo.user.cliente.email_alternativo_validado || null
        };
        vm.parseCellphone = function (callback, fail) {
            var loadingModal = $rootScope.showLoadingModal("Validando teléfono. Por favor espere.", $scope.$new(true));
            UserDAO.validatePhone({
                telefono: $rootScope.userInfo.user.cliente.celular
            }, function (response) {
                loadingModal.dismiss("error");
                $rootScope.userInfo.user.cliente.prefijo_celular = "0" + response.data.codigo_area;
                $rootScope.userInfo.user.cliente.celular = response.data.telefono;

                if (typeof callback === "function") {
                    callback();
                }
            }, function (error) {
                loadingModal.dismiss("error");
                $rootScope.userInfo.user.cliente.prefijo_celular = null;
                $rootScope.userInfo.user.cliente.celular = null;

                if (typeof fail === "function") {
                    fail(error);
                }
            });
        };
        if (!vm.perfil.prefijo_celular && vm.perfil.celular) {
            vm.parseCellphone(function () {
                vm.perfil.prefijo_celular = $rootScope.userInfo.user.cliente.prefijo_celular;
                vm.perfil.celular = $rootScope.userInfo.user.cliente.celular;
            }, function () {
                vm.perfil.prefijo_celular = null;
                vm.perfil.celular = null;
            });
        }
        vm.showBuscador = false;
        vm.showCambioPassword = $rootScope.userInfo.user.native;
        vm.pendingProfileSave = false;
        vm.changeProfile = function () {
            vm.pendingProfileSave = true;
        };
        vm.profileSaveInProgress = false;
        vm.smtp_validation_invalid = false;
        vm.smtp_validation_unavailable = false;
        vm.initInputEmailAlternativo = function (value) {
            if (parseInt(value) === 1) {
                return {
                    popoverText: "E-mail alternativo confirmado: <strong>SI</strong>",
                    statusIcon: "fa-check-square-o",
                    validationClass: "confirmed"
                };
            } else {
                return {
                    popoverText: "E-mail alternativo confirmado: <strong>NO</strong>",
                    statusIcon: "fa-square-o",
                    validationClass: "not-confirmed"
                };
            }
        };
        vm.changeEmailAlternativo = function () {
            vm.inputEmailAlternativo = vm.initInputEmailAlternativo(false);
        };
        vm.inputEmailAlternativo = vm.initInputEmailAlternativo(vm.perfil.email_alternativo_validado);
        vm.doCheckEmailAlternativo = function (form) {
            if (!vm.perfil.email_alternativo || (vm.perfil.email_alternativo.length === 0)) {
                vm.doCheckCellphone(form);
            } else {
                usSpinnerService.spin("miPerfil");
                vm.profileSaveInProgress = true;
                vm.smtp_validation_invalid = false;
                vm.smtp_validation_unavailable = false;
                UserDAO.checkSMTPEmail({email: vm.perfil.email_alternativo}).$promise.then(function () {
                    vm.profileSaveInProgress = false;
                    vm.smtp_validation_invalid = false;
                    usSpinnerService.stop("miPerfil");
                    vm.doCheckCellphone(form);
                }, function (err) {
                    vm.profileSaveInProgress = false;
                    if (err.status === 409) {
                        vm.smtp_validation_invalid = true;
                    } else {
                        vm.smtp_validation_unavailable = true;
                    }
                    usSpinnerService.stop("miPerfil");
                });
            }
        };
        vm.doCheckCellphone = function (form) {
            if (vm.perfil.prefijo_celular == $rootScope.userInfo.user.cliente.prefijo_celular
                 && vm.perfil.celular.replace("-", "") == $rootScope.userInfo.user.cliente.celular
                 && $rootScope.userInfo.user.cliente.celular_validado) {
                vm.guardarCambios(form);
            } else {
                verificationCodeHandler.showVerificationModal({
                    "email": $rootScope.userInfo.user.email,
                    "cellphone": vm.perfil.prefijo_celular + vm.perfil.celular.replace("-", "")
                }).then(function () {
                    vm.guardarCambios(form);
                })
                .catch(function () {
                    TCFAModalService.showError({
                        "error": {
                            "showModal": true,
                            "title": "Error al verificar teléfono",
                            "message": "Por favor intente nuevamente."
                        }
                    });
                });
            }
        };
        vm.guardarCambios = function (form) {
            var onSuccessFunc, onErrorFunc;
            if (!vm.profileSaveInProgress && vm.pendingProfileSave) {
                usSpinnerService.spin("miPerfil");
                vm.profileSaveInProgress = true;
                onSuccessFunc = function () {
                    $rootScope.userInfo.user.nombre = vm.perfil.nombre;
                    $rootScope.userInfo.user.apellido = vm.perfil.apellido;
                    $rootScope.userInfo.user.localidad = vm.perfil.localidad;
                    $rootScope.userInfo.user.email = vm.perfil.email;
                    if ($rootScope.userInfo.user.cliente) {
                        $rootScope.userInfo.user.cliente.prefijo_telefono = vm.perfil.prefijo_telefono;
                        $rootScope.userInfo.user.cliente.telefono = vm.perfil.telefono;
                        $rootScope.userInfo.user.cliente.prefijo_celular = vm.perfil.prefijo_celular;
                        $rootScope.userInfo.user.cliente.celular = vm.perfil.celular;
                        $rootScope.userInfo.user.cliente.email_alternativo = vm.perfil.email_alternativo;
                    }
                    vm.profileSaveInProgress = false;
                    vm.pendingProfileSave = false;
                    vm.showBuscador = false;
                    form.$setSubmitted();
                    form.$setPristine();
                    usSpinnerService.stop("miPerfil");
                };
                onErrorFunc = function () {
                    usSpinnerService.stop("miPerfil");
                };
                if ($rootScope.is_tasa) {
                    UserDAO.patchProfileTasa(vm.perfil, onSuccessFunc, onErrorFunc);
                } else {
                    UserDAO.patchProfile(vm.perfil, onSuccessFunc, onErrorFunc);
                }
            }
        };
        vm.editDireccion = function () {
            if (!vm.profileSaveInProgress) {
                vm.perfil.direccion = null;
                vm.showBuscador = true;
                vm.perfil.localidad = null;
                vm.pendingProfileSave = true;
            }
        };
        vm.changeDireccion = function (direccion) {
            var info;
            $timeout(function () {
                if (direccion) {
                    for (var i = direccion.address_components.length - 1; i >= 0; i--) {
                        for (var x = direccion.address_components[i].types.length - 1; x >= 0; x--) {
                            if (direccion.address_components[x] && direccion.address_components[x].types[x] &&
                                (direccion.address_components[i].types[x] === "locality" ||
                                    direccion.address_components[i].types[x] === "sublocality")) {
                                vm.perfil.localidad = direccion.address_components[i].long_name;
                                vm.showBuscador = false;
                                vm.pendingProfileSave = true;
                                return;
                            }
                        }
                    }
                    info = {};
                    info.error = {};
                    info.error.showModal = true;
                    info.error.message = "No se ha encontrado ninguna localidad. Por favor intente nuevamente.";
                    info.error.title = "Error de validación";
                    TCFAModalService.showWarning(info);
                } else {
                    vm.showBuscador = true;
                    vm.perfil.localidad = null;
                    vm.pendingProfileSave = true;
                }
            }, 500);
        };
        vm.changePhone = function () {
            if (vm.profileEditForm.prefijo_telefono.$valid && vm.profileEditForm.telefono.$valid) {
                var loadingModal = $rootScope.showLoadingModal("Validando teléfono. Por favor espere.", $scope.$new(true));
                UserDAO.validatePhone({
                    telefono    : vm.perfil.prefijo_telefono + vm.perfil.telefono
                }, function (response) {
                    loadingModal.dismiss("error");
                    vm.perfil.prefijo_telefono = "0" + response.data.codigo_area;
                    vm.perfil.telefono = response.data.telefono;
                }, function (error) {
                    vm.perfil.prefijo_telefono = "";
                    vm.perfil.telefono = "";
                    loadingModal.dismiss("error");
                    var info = {};
                    info.error = {};
                    info.error.showModal = true;
                    info.error.message = "Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.";
                    info.error.title = "Error de validación";
                    if (error.data.errors) {
                        if (!(error.data.errors.message instanceof Array)) {
                            info.error.message = error.data.errors.message;
                        } 
                        else {
                            info.error.message = error.data.errors.message.join(" ");
                        }
                    }
                    TCFAModalService.showWarning(info);
                });
            }
        };
        vm.changeMobilePhone = function () {
            if (vm.profileEditForm.prefijo_celular.$valid && vm.profileEditForm.celular.$valid) {
                var loadingModal = $rootScope.showLoadingModal("Validando celular. Por favor espere.", $scope.$new(true));
                UserDAO.validatePhone({
                    telefono    : vm.perfil.prefijo_celular + vm.perfil.celular
                }, function (response) {
                    loadingModal.dismiss("error");
                    vm.perfil.prefijo_celular = "0" + response.data.codigo_area;
                    vm.perfil.celular = response.data.telefono;
                }, function (error) {
                    vm.perfil.prefijo_celular = "";
                    vm.perfil.celular = "";
                    loadingModal.dismiss("error");
                    var info = {};
                    info.error = {};
                    info.error.showModal = true;
                    info.error.message = "Ha ocurrido un error al validar el celular. Intente nuevamente en unos minutos.";
                    info.error.title = "Error de validación";
                    if (error.data.errors) {
                        if (!(error.data.errors.message instanceof Array)) {
                            info.error.message = error.data.errors.message;
                        } 
                        else {
                            info.error.message = error.data.errors.message.join(" ");
                        }
                    }
                    TCFAModalService.showWarning(info);
                });
            }
        };
    };
    PerfilController.$inject = moduleConfig.PerfilController.deps;
    module.controller(moduleConfig.PerfilController.name, PerfilController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.PerfilMensajesController",
            deps: ["TCFAutos.controllers.PerfilMenuController", "TCFAutos.directives.BottomBar", "TCFAutos.services.UserDAOService",
                "contactsDaoModule", "ngScrollbars", "TCFAutos.services.LogInUser", "TCFAutos.services.NotificationsService",
                "TCFAutos.services.SessionValidationService"],
            PerfilMensajesController: {
                name: "PerfilMensajesController",
                deps: ["$route", "$window", "$rootScope", "$location", "UserDAO", "ContactsDAO", "TCFAModalService", "LogInUser",
                    "NotificationsService", "SessionValidationService", "TCFAAppConfigService"]
            }
        },
        module, PerfilMensajesController;
    module = angular.module("TCFAutos");
    PerfilMensajesController = function ($route, $window, $rootScope, $location, UserDAO, ContactsDAO, TCFAModalService,
                                         LogInUser, NotificationsService, SessionValidationService, TCFAAppConfigService) {
        var vm = this, callback = function () {
            $location.path("mensajes");
        };
        if (!SessionValidationService.validateStrictPermissions(["CheckUserType:cliente"], "cliente", callback)) {
            $location.path("/");
            return;
        }
        if (SessionValidationService.validateStrictPermissions(["CheckUserType:dealer", "CheckUserType:admin"])) {
            $location.path("/");
            return;
        }
        window.moment().locale("es");
        vm.parseDate = function (mensaje) {
            if (!mensaje.parsed_date) {
                mensaje.parsed_date = window.moment(mensaje.created_at, "YYYY-MM-DD HH:mm:ss:SSS").toDate();
            }
            return mensaje.parsed_date;
        };
        vm.scrollbarsConfig = {
            autoHideScrollbar: true,
            theme: "rounded",
            advanced: {
                updateOnContentResize: true,
                updateOnSelectorChange: true
            },
            scrollButtons: {enable: false},
            mouseWheel: {enable: true, scrollAmount: 30},
            setHeight: 150,
            maxUpdateHeight: 330,
            scrollInertia: 0,
            axis: 'y'
        };
        vm.updateScrollHeight = function (mensaje) {
            var scroll_height;
            if (!mensaje) {
                return null;
            }
            scroll_height = (vm.getCantidadConsultas(mensaje) + vm.getCantidadMensajesRecibidos(mensaje)) * 100;
            if (scroll_height > vm.scrollbarsConfig.maxUpdateHeight) {
                scroll_height = vm.scrollbarsConfig.maxUpdateHeight;
            }
            vm.scrollbarsConfig.setHeight = scroll_height;
            angular.element(".mCustomScrollbar").css({height: scroll_height + "px"});
        };
        vm.data = {img_src: TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.AUTO_IMAGE_FOLDER + "/"};
        vm.data.filters = [{key: "15", display: "Últimos 15 días"},
            {key: "30", display: "Últimos 30 días"},
            {key: "60", display: "Últimos 60 días"},
            {key: "all", display: "Todos"}];
        vm.data.currentFilter = vm.data.filters[0];
        vm.filter = function (item) {
            vm.data.currentFilter = item;
            vm.loadPage(vm.data.currentPage || 1);
        };
        vm.tieneMensajesNuevos = function (mensaje) {
            var i;
            if (mensaje.respuesta_permitida && (!mensaje.respuesta_permitida.visto)) {
                return true;
            }
            for (i = mensaje.nuevas_consultas.length - 1; i >= 0; i--) {
                if ((mensaje.nuevas_consultas[i].respuesta_permitida) &&
                    (!mensaje.nuevas_consultas[i].respuesta_permitida.visto)) {
                    return true;
                }
            }
            return false;
        };
        vm.marcarVisto = function (mensaje) {
            var i,
                mensajesVistos = [];
            if (vm.tieneMensajesNuevos(mensaje)) {
                if (mensaje.respuesta_permitida && (!mensaje.respuesta_permitida.visto)) {
                    mensajesVistos.push(mensaje.id);
                }
                for (i = mensaje.nuevas_consultas.length - 1; i >= 0; i--) {
                    if ((mensaje.nuevas_consultas[i].respuesta_permitida) &&
                        (!mensaje.nuevas_consultas[i].respuesta_permitida.visto)) {
                        mensajesVistos.push(mensaje.nuevas_consultas[i].id);
                    }
                }
                NotificationsService.markAsRead(mensajesVistos);
                UserDAO.mensajeVisto({id: mensaje.id});
            }
        };
        vm.activeMsg = -1;
        vm.expandMensaje = function (mensaje) {
            if (!mensaje) {
                vm.activeMsg = -1;
                return null;
            }
            vm.updateScrollHeight(mensaje);
            vm.activeMsg = mensaje.id;
            vm.marcarVisto(mensaje);
        };
        vm.isExpanded = function (mensaje) {
            return (mensaje.id === vm.activeMsg);
        };
        vm.loadPage = function (pageIndex) {
            vm.data.currentPage = pageIndex;
            UserDAO.getMensajes({
                page: pageIndex,
                days_before: vm.data.currentFilter.key
            }, function (response) {
                var i, viewId,
                    searchParams = $location.search();
                vm.data.page = response.data;
                vm.data.results = response.data.data;
                if (searchParams.viewMessage) {
                    viewId = parseInt(searchParams.viewMessage, 10);
                    for (i = vm.data.results.length - 1; i >= 0; i--) {
                        if (vm.data.results[i].id === viewId) {
                            vm.expandMensaje(vm.data.results[i]);
                            delete searchParams.viewMessage;
                            $location
                                .replace()
                                .search(searchParams);
                            break;
                        }
                    }
                }
            });
        };
        vm.loadPage(1);
        vm.getCantidadConsultas = function (mensaje) {
            return (1 + mensaje.nuevas_consultas.length);
        };
        vm.getCantidadMensajesRecibidos = function (mensaje) {
            var i, cantidad = 0;
            if (mensaje.respuesta_permitida) {
                cantidad = 1;
            }
            for (i = mensaje.nuevas_consultas.length - 1; i >= 0; i--) {
                if (mensaje.nuevas_consultas[i].respuesta_permitida) {
                    cantidad = cantidad + 1;
                }
            }
            return cantidad;
        };
        vm.initConsultaMensaje = function (mensaje) {
            mensaje.procesando_consulta = false;
            mensaje.draft_consulta_nueva = "";
        };
        vm.enviarConsulta = function (mensaje) {
            if (!mensaje.procesando_consulta) {
                mensaje.procesando_consulta = true;
                ContactsDAO.consultaAuto({auto_id: mensaje.publicacion.auto.id}, {
                    consulta: {
                        nombre: ($rootScope.userInfo.user.nombre + " " + $rootScope.userInfo.user.apellido),
                        texto: mensaje.draft_consulta_nueva,
                        email: $rootScope.userInfo.user.email
                    }
                }).$promise.then(function (response) {
                    mensaje.procesando_consulta = false;
                    mensaje.draft_consulta_nueva = "";
                    response.data.data.reciente = true;
                    mensaje.nuevas_consultas.push(response.data.data);
                    vm.updateScrollHeight(mensaje);
                }, function (response) {
                    mensaje.procesando_consulta = false;
                    if (response.status === 500) {
                        var info = {error: {}};
                        info.error.showModal = true;
                        info.error.message = "Ha ocurrido un error al registrar su consulta. Por favor intente nuevamente en unos minutos.";
                        info.error.title = "¡Ha ocurrido un error!";
                        TCFAModalService.showError(info);
                    }
                });
            }
        };
    };
    PerfilMensajesController.$inject = moduleConfig.PerfilMensajesController.deps;
    module.controller(moduleConfig.PerfilMensajesController.name, PerfilMensajesController);
}());

(function () {
    "use strict";

    var moduleConfig = {
            name: "TCFAutos.controllers.PerfilMenuController",
            deps: ["ngImgCrop", "ngFileUpload", "TCFAutos.services.UserDAOService"],
            PerfilMenuController: {
                name: "PerfilMenuController",
                deps: ["$window", "$rootScope", "$location", "$uibModal", "$scope", "UserDAO"]
            }
        },
        module, PerfilMenuController;
    module = angular.module('TCFAutos');
    PerfilMenuController = function ($window, $rootScope, $location, $uibModal, $scope, UserDAO) {
        var vm = this;
        $window.console.log("User info " + $rootScope.userInfo);
        if (!$rootScope.userInfo.user) {
            $location.path('/');
            return;
        }
        if ($rootScope.userInfo.user.dealer || $rootScope.userInfo.user.admin) {
            $location.path('/');
            return;
        }
        window.moment().locale("es");
        vm.profile = {date: window.moment($rootScope.userInfo.user.created_at || (new Date())).format("D [de] MMMM [de] YYYY")};
        vm.isMensajes = function () {
            return ($location.path() === "/mensajes/");
        };
        vm.isFavoritos = function () {
            return ($location.path() === "/favoritos/");
        };
        vm.isPerfil = function () {
            return ($location.path() === "/perfil/");
        };
        vm.toFavoritos = function () {
            $window.console.debug('toFavoritos');
            $location.path('/favoritos/');
        };
        vm.toMisMensajes = function () {
            $location.path('/mensajes/');
        };
        vm.toMiPerfil = function () {
            $location.path('/perfil/');
        };
        vm.changeProfileImage = function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'views/perfil/modal_crop.html',
                windowClass: 'tcfa-modal',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    var width = 180,
                        height = 180;
                    $scope.sizeParam = {w: width, h: height};
                    $scope.aspectRatio = parseFloat((width / height).toFixed(1));
                    $scope.uploadFile = function (file) {
                        if (file) {
                            $scope.new_image_profile = file;
                        }
                    };
                    $scope.ok = function () {
                        $scope.myCroppedImageBlob.name = 'crop.png';
                        $rootScope.socialProfile.pictureUrl = $scope.myCroppedImage;
                        vm.user_profile_img_src = $scope.myCroppedImage;
                        UserDAO.postProfileImage($scope.myCroppedImageBlob).then(function () {
                            $uibModalInstance.dismiss('close');
                        });
                    };
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('close');
                    };
                    $scope.cambiar = function () {
                        $scope.new_Image_profile1 = $scope.new_Image_profile;
                    };
                }],
                scope: $scope,
                size: 'lg'
            });
        };
    };
    PerfilMenuController.$inject = moduleConfig.PerfilMenuController.deps;
    module.controller(moduleConfig.PerfilMenuController.name, PerfilMenuController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            BottomSliderController: {
                name: "BottomSliderController",
                deps: ["BannerDAO", "SessionValidationService", "PageNavigationService", "TCFAAppConfigService"]
            }
        },
        module, BottomSliderController,
        _CODE_BANNER_TYPE = "BANNERS-SEARCH-BOTTOM";
    module = angular.module("TCFAutos");
    BottomSliderController = function (BannerDAO, SessionValidationService, PageNavigationService, TCFAAppConfigService) {
        var vm = this,
            is_admin, bannerToShow;
        is_admin = SessionValidationService.validateStrictPermissions(["CheckUserType:admin"]);
        bannerToShow = PageNavigationService.getParameter("showBanner", null, true);
        vm.afterGetBanners = function (response) {
            if (response.data) {
                vm.banner_images = response.data.banners;
            }
        };
        vm.activeSearchSlider = 0;
        vm.bannerSource = TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.BANNER_IMAGE_FOLDER + "/";
        if (is_admin && bannerToShow && bannerToShow.codigo === _CODE_BANNER_TYPE) {
            PageNavigationService.deleteParameter("showBanner");
            BannerDAO.getBanner({id: bannerToShow.id}).$promise.then(vm.afterGetBanners);
        } else {
            BannerDAO.getPublicatedBanners({tipo_banner: _CODE_BANNER_TYPE}).$promise.then(vm.afterGetBanners);
        }
        vm.interval = 4500;
    };
    BottomSliderController.$inject = moduleConfig.BottomSliderController.deps;
    module.controller(moduleConfig.BottomSliderController.name, BottomSliderController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "indexModule",
            deps: ['TCFAutos.services.PageNavigationService', 'TCFAutos.services.ParametrosService', 'authDaoModule',
                'termsConditionCheckerModule', 'socialModule', 'TCFAutos.services.BannerDAO', 'TCFAutos.services.SessionValidationService',
                'TCFAutos.services.TCFANewsletter'],
            SearchSlider: {
                name: "SearchSlider",
                deps: ['BannerDAO', 'SessionValidationService', 'PageNavigationService', 'TCFAAppConfigService']
            }
        },
        module, SearchSlider;
    module = angular.module('TCFAutos');
    SearchSlider = function (BannerDAO, SessionValidationService, PageNavigationService,
                             TCFAAppConfigService) {
        var vm = this,
            is_admin, bannerToShow;
        is_admin = SessionValidationService.validateStrictPermissions(['CheckUserType:admin']);
        bannerToShow = PageNavigationService.getParameter('showBanner', null, true);
        vm.activeSearchSlider = 0;
        vm.loadDefault = function () {
            vm.default_images = [{source: './images/imageWeb/banner-busqueda1.jpg'}];
        };
        vm.bannerSource = TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.BANNER_IMAGE_FOLDER + "/";
        if (is_admin && bannerToShow && bannerToShow.codigo === 'BANNERS-SEARCH') {
            PageNavigationService.deleteParameter('showBanner');
            BannerDAO.getBanner({id: bannerToShow.id}).$promise.then(function (response) {
                if (response.data) {
                    vm.banner_images = response.data.banners;
                } else {
                    vm.loadDefault();
                }
            }, function () {
                vm.loadDefault();
            });
        } else {
            BannerDAO.getPublicatedBanners({tipo_banner: 'BANNERS-SEARCH'}).$promise.then(function (response) {
                if (response.data) {
                    vm.banner_images = response.data.banners;
                } else {
                    vm.loadDefault();
                }
            }, function () {
                vm.loadDefault();
            });
        }
        vm.interval = 4500;
    };
    // FIXME: esto es una definicion global, no deberia estar en este modulo.
    String.prototype.splice = function (idx, rem, s) {
        return (this.slice(0, idx) + s + this.slice(idx + Math.abs(rem)));
    };
    SearchSlider.$inject = moduleConfig.SearchSlider.deps;
    module.controller(moduleConfig.SearchSlider.name, SearchSlider);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Search_ShowSearchCtrl",
            deps: [],
            Search_ShowSearchCtrl: {
                name: "Search_ShowSearchCtrl",
                deps: ["$window", "$scope", "$rootScope", "$routeParams", "$location", "$anchorScroll", "PageNavigationService",
                    "searchService", "CarDAO", "ActionDAO", "TCFAAppConfigService", "UtilsHelper"]
            }
        },
        module, Search_ShowSearchCtrl;
    module = angular.module("TCFAutos");
    Search_ShowSearchCtrl = function ($window, $scope, $rootScope, $routeParams, $location, $anchorScroll, PageNavigationService,
                                      searchService, CarDAO, ActionDAO, TCFAAppConfigService, UtilsHelper) {
        var vm = this,
            __filtered_by_dealer = false,
            __filtered_by_used = false,
            __filtered_by_new = false,
            checkInnerWidth = function () {
                return ($window.innerWidth < 768);
            };
        PageNavigationService.showNavBar = true;
        vm.hideConcesionario = true;
        vm.hideIs0km = false;
        vm.hideKilometros = true;
        vm.hideAnios = false;
        vm.hidePrecio = false;
        vm.hideMonto = false;
        vm.hideProvincias = true;
        vm.hideCombustible = true;
        vm.hideColor = true;
        // Filters menu.
        vm.toggleFilterSearchOverride = checkInnerWidth();
        $window.onresize = function () {
            vm.toggleFilterSearchOverride = checkInnerWidth();
        };
        vm.toggleFilterSearch = true;
        vm.flechaSearch = "./images/btnWeb/flecha_baja.png";
        vm.toggleFilter = function () {
            vm.toggleFilterSearch = !vm.toggleFilterSearch;
            if (vm.toggleFilterSearch) {
                vm.flechaSearch = "./images/btnWeb/flecha_baja.png";
            } else {
                vm.flechaSearch = "./images/btnWeb/flecha_sube.png";
            }
        };
        $scope.data = {};
        $scope.data.imagesSource = TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.AUTO_IMAGE_FOLDER;
        $scope.data.sorts = {
            dest: {display: "Más Buscados"},
            toyota: {display: "Marca Toyota"},
            priceAsc: {display: "Menor precio"},
            priceDesc: {display: "Mayor precio"},
            dateAsc: {display: "Más viejo"},
            dateDesc: {display: "Más nuevo"}
        };
        if ($routeParams.sort && $scope.data.sorts[$routeParams.sort]) {
            $scope.data.filteredLabel = $scope.data.sorts[$routeParams.sort].display;
        } else {
            $scope.data.filteredLabel = $scope.data.sorts.dest.display;
        }
        $scope.range = function (min, max, step) {
            var i,
                input = [];
            step = (step || 1);
            for (i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };
        searchService.addFromRequest($routeParams);
        CarDAO.getSearchFilters($routeParams, function (response) {
            $scope.data.filters = response.data;
        });
        CarDAO.searchCarByTitle($routeParams, function (response) {
            $scope.data.page = response.data;
            $scope.data.results = response.data.data;
        });
        $scope.addAndGoZeroAllowed = function (key, value, wait) {
            if (UtilsHelper.idCheckerWithTypeCoercion(value, 0)) {
                value = "0";
            }
            $scope.addAndGo(key, value, wait);
        };
        $scope.addAndGoBrand = function (brand, wait) {
            if(brand.id_fu) {
                $scope.addAndGo('marcaGroup', brand.id_fu, wait);
            } else {
                $scope.addAndGo('marca', brand.id, wait);
            }
        };
        $scope.addAndGoModel = function (model, wait) {
            if(model.v_modelo_id) {
                $scope.addAndGo('modeloGroup', model.v_modelo_id, wait);
            } else {
                $scope.addAndGo('modelo', model.id, wait);
            }
        };
        $scope.addAndGo = function (key, value, wait) {
            searchService.addParameter(key, value);
            if (!wait) {
                $location.path("/search/").search(searchService.navigationValues());
            }
        };
        vm.loadPage = function (pageIndex) {
            searchService.addParameter("page", pageIndex);
            $location.path("/search/").search(searchService.navigationValues());
        };
        vm.showResultItem = function (obj) {
            ActionDAO.createAction({
                accion: "ver-resultado-busqueda",
                parametros: "auto_id=" + obj.id
            });
            $location.path("/cars/" + obj.id + "/").search({concesionario: obj.publicacion.concesionario.nombre});
        };
        $scope.removeAndGo = function (keyused, wait) {
            searchService.removeParameter(keyused);
            if (!wait) {
                $location.path("/search/").search(searchService.navigationValues());
            }
        };
        $scope.removeFilterAndGo = function (filter, wait) {
            if (filter.param === "concesionario") {
                vm.removeDealerFilter();
            }
            if (filter.param === "is0km") {
                vm.removeNewOrUsedCarFilter();
            }
            searchService.removeParameter(filter.param);
            if (filter.dependency) {
                filter.dependency.split(",").forEach(function (dependency) {
                    if (dependency) {
                        if (dependency === "concesionario") {
                            vm.removeDealerFilter();
                        }
                        if (filter.param === "is0km") {
                            vm.removeNewOrUsedCarFilter();
                        }
                        searchService.removeParameter(dependency);
                    }
                });
                $location.path("/search/").search(searchService.navigationValues());
            } else if (!wait) {
                $location.path("/search/").search(searchService.navigationValues());
            }
        };
        vm.goToUp = function () {
            $anchorScroll();
        };
        // Filters from Campaigns Cotizador Dinámico.
        if ($rootScope.cotizador && $rootScope.cotizador.from_campaign) {
            // Filter by used cars only.
            if ($rootScope.cotizador.show_used_cars && !$rootScope.cotizador.show_new_cars) {
                __filtered_by_used = true;
                __filtered_by_new = false;
                $scope.addAndGo("is0km", "false");
            }
            // Filter by new cars only.
            if ($rootScope.cotizador.show_new_cars && !$rootScope.cotizador.show_used_cars) {
                __filtered_by_new = true;
                __filtered_by_used = false;
                $scope.addAndGo("is0km", "true");
            }
            // Filter by Dealer.
            if ($rootScope.cotizador.dealer && !$rootScope.cotizador.dealer.is_default) {
                __filtered_by_dealer = true;
                $scope.addAndGo("concesionario", $rootScope.cotizador.dealer.id.toString());
            }
        }
        /** Removes the condition that creates a filter by Dealer from Cotizador Dinámico. */
        vm.removeDealerFilter = function () {
            if (__filtered_by_dealer && $rootScope.cotizador && $rootScope.cotizador.dealer) {
                delete $rootScope.cotizador.dealer;
                __filtered_by_dealer = false;
            }
        };
        /** Removes the condition that creates a filter by new or used cars from Cotizador Dinámico. */
        vm.removeNewOrUsedCarFilter = function () {
            if ((__filtered_by_used || __filtered_by_new) && $rootScope.cotizador) {
                // Used cars only.
                if (__filtered_by_used && $rootScope.cotizador.show_used_cars && !$rootScope.cotizador.show_new_cars) {
                    __filtered_by_used = false;
                    $rootScope.cotizador.show_new_cars = true;
                }
                // New cars only.
                if (__filtered_by_new && $rootScope.cotizador.show_new_cars && !$rootScope.cotizador.show_used_cars) {
                    __filtered_by_new = false;
                    $rootScope.cotizador.show_used_cars = true;
                }
            }
        };
    };
    Search_ShowSearchCtrl.$inject = moduleConfig.Search_ShowSearchCtrl.deps;
    module.controller(moduleConfig.Search_ShowSearchCtrl.name, Search_ShowSearchCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "termsConditionModule",
            deps: ['TCFAutos.services.PageNavigationService', 'TCFAutos.services.SessionValidationService', 'termsConditionsDaoModule'],
            ShowSeccionController: {
                name: "ShowSeccionController",
                deps: ['$routeParams', 'TermsConditionsDAO', 'PageNavigationService']
            }
        },
        module, ShowSeccionController;
    module = angular.module('TCFAutos');
    ShowSeccionController = function ($routeParams, TermsConditionsDAO, PageNavigationService) {
        var vm = this;
        PageNavigationService.showNavBar = true;
        vm.errorTyC = false;
        vm.cargandoTyC = true;
        TermsConditionsDAO.getSeccion($routeParams.seccionId, function (data) {
            vm.cargandoTyC = false;
            vm.seccion = data;
        }, function () {
            vm.cargandoTyC = false;
            vm.errorTyC = true;
        });
    };
    ShowSeccionController.$inject = moduleConfig.ShowSeccionController.deps;
    module.controller(moduleConfig.ShowSeccionController.name, ShowSeccionController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.Info_CarInfoCtrl",
            deps: ["ngScrollbars", "directivesModule", "favoritoDaoModule", "TCFAutos.services.DestacadosDAO", "TCFAutos.directives.BottomBar",
                "utilsModule", "contactsDaoModule", "socialModule", "verificationCodeHandlerModule"],
            Info_CarInfoCtrl: {
                name: "Info_CarInfoCtrl",
                deps: ["$window", "$scope", "$rootScope", "$httpParamSerializer", "$routeParams", "$location", "$uibModal", "PublicationDAO",
                    "$anchorScroll", "$timeout", "PageNavigationService", "CarDAO", "facebookService", "FavoritoDAO", "ParametrosService",
                    "TermsConditionsDAO", "DestacadosDAO", "usSpinnerService", "ActionDAO", "UtilsHelper", "LogInUser", "ContactsDAO",
                    "TCFAModalService", "TCFAAppConfigService", "DependencyInjectionService", "StatsDAO", "$filter", "CotizadorDAO",
                    "CotizadorService", "ConcesionariosDAO", "verificationCodeHandler", "UserDAO"]
            }
        },
        module, Info_CarInfoCtrl,
        _BTN_CONSULTAR_HTML_CLASS_SELECTOR = ".btn-consultar-concesionario",
        _BTN_COTIZA_TU_SEGURO_HTML_CLASS_SELECTOR = ".btn_cotiza_tu_seguro";
    module = angular.module("TCFAutos");
    Info_CarInfoCtrl = function ($window, $scope, $rootScope, $httpParamSerializer, $routeParams, $location, $uibModal, PublicationDAO,
                                 $anchorScroll, $timeout, PageNavigationService, CarDAO, facebookService, FavoritoDAO, ParametrosService,
                                 TermsConditionsDAO, DestacadosDAO, usSpinnerService, ActionDAO, UtilsHelper, LogInUser, ContactsDAO,
                                 TCFAModalService, TCFAAppConfigService, DependencyInjectionService, StatsDAO, $filter, CotizadorDAO,
                                 CotizadorService, ConcesionariosDAO, verificationCodeHandler, UserDAO) {
        var vm = this,
            carId = $routeParams.carId,
            termConditionScope, loadAndsetDefaultCondicionImpositiva,
            // Query Params.
            __id_share_link = (parseInt($routeParams.il, 10) || 0),
            __id_person_type = parseInt($routeParams.pt, 10),
            __key_tax_condition = $routeParams.tc,
            __loan_amount = parseInt($routeParams.la, 10);
        window.moment.locale("es");
        // Save data for Cotizador.
        if (!$rootScope.cotizador) {
            $rootScope.cotizador = {};
        }
        $rootScope.cotizador.campaign_id = null;
        $rootScope.cotizador.campaign_data = null;
        $rootScope.cotizador.from_campaign = false;
        $rootScope.cotizador.vehicle_type = 1;
        $rootScope.cotizador.vehicle_is_car = true;
        $rootScope.cotizador.vehicle_is_truck = false;
        $rootScope.cotizador.operation_type = {
            id: 2,
            key: "RE",
            name: "Prendario"
        };
        $rootScope.cotizador.operation_is_retail = true;
        $rootScope.cotizador.canon = null;
        $rootScope.cotizador.plan = null;
        $rootScope.cotizador.show_used_cars = true;
        $rootScope.cotizador.show_new_cars = true;
        PageNavigationService.showNavBar = true;
        PageNavigationService.showNewsletterSubscription = false;
        $timeout(function () {
            usSpinnerService.spin("info_show_cuotas");
        }, 100);
        vm.startCuotasSpinner = true;
        // Form.
        vm.form_cotizar_changed = false;
        vm.form_submit_in_progress = false;
        /** Changes on form Cotizar inputs handler. */
        vm.onFormCotizarChange = function () {
            vm.form_cotizar_changed = true;
            CotizadorService.changeCotizarForm();
        };
        /** Cotizar form reset state handler. */
        CotizadorService.onCotizarFormChanged($scope, function () {
            vm.form_cotizar_changed = true;
        });
        /** Promo loan amount change handler. */
        CotizadorService.onLoanAmountUpdated($scope, function (event, loan_amount) {
            vm.info.afinanciar = loan_amount;
            if (vm.info.afinanciar < vm.data.minimo_valor_financiable.valor) {
                vm.info.afinanciar = vm.data.minimo_valor_financiable.valor;
            }
            if (vm.info.afinanciar > vm.data.maximo_valor_financiable.valor) {
                vm.info.afinanciar = vm.data.maximo_valor_financiable.valor;
            }
            vm.onLoanAmountChange();
            vm.calculateInstallments();
        });
        vm.askLoan = function (cuota) {
            var v,
                params = {canal: "tcfautos"},
                publicacion_id = null,
                statsPediTuPrestamo = function (cuota) {
                    var params = {},
                        auto;
                    auto = vm.info.car;
                    params.marca_id = auto.version.modelo.marca.id;
                    params.modelo_id = auto.version.modelo.id;
                    params.version_id = auto.version.id;
                    params.monto_total = auto.price_calculated;
                    params.publicacion_id = auto.publicacion.id;
                    params.auto_id_vehiculo_fu = auto.vehiculo_id_fu;
                    params.auto_anio = auto.year;
                    params.auto_nuevo = auto.is0km;
                    params.publicacion_titulo = auto.tittle;
                    if (cuota) {
                        params.tipo_operacion = cuota.DatosConsulta.idTipoOperacion;
                        params.plazo = cuota.Cuotas;
                        params.monto_a_financiar = cuota.DatosConsulta.valorAFinanciar;
                    }
                    if (vm.data.plan) {
                        params.auto_plan_id = vm.data.plan.id;
                    }
                    if (vm.data.tipoPersona) {
                        params.tipo_persona_id = vm.data.tipoPersona.id;
                    }
                    if (vm.data.condicionImpositiva.id) {
                        params.condicion_impositiva_id = vm.data.condicionImpositiva.id;
                    }
                    return StatsDAO.postPediTuPrestamo(params);
                };

            if (vm.data && (vm.data.isDealer || vm.data.isAdmin)) {
                return false;
            }
            if (cuota) {
                params.Plazo = cuota.Cuotas;
            }
            if (vm.info && vm.info.car) {
                v = vm.info.car;
                params.marca = v.marca_id_fu;
                params.modelo = v.modelo_id_fu;
                params.version = v.version_id_fu;
                params.anio = v.year;
                params.valor = v.price_calculated;
                params.utilitario = v.utilitarian;
                params.kilometros = v.kms;
                params.combustible = v.combustible.descripcion.toLowerCase();
                params.provincia = v.provincia.name.toLowerCase();
                params.transmision = v.transmision.descripcion.toLowerCase();
                params.idPublicacion = v.publicacion.id;
                if (cuota) {
                    params.idTipoOperacion = cuota.DatosConsulta.idTipoOperacion;
                    params.idPlan = cuota.DatosConsulta.idPlan;
                    params.idSubPlan = cuota.DatosConsulta.idSubPlan;
                    params.montoAFinanciar = cuota.DatosConsulta.montoAFinanciar;
                    params.canal = cuota.DatosConsulta.canal;
                }
                params.nuevo = v.is0km;
                if (!v.is0km) {
                    params.color = v.color.descripcion.toLowerCase();
                }
                if (vm.data.tipoPersona) {
                    params.tp = vm.data.tipoPersona.codigo_prestamo;
                }
                publicacion_id = v.publicacion.id;
            }
            ActionDAO.createAction({
                accion: "pedi-tu-prestamo",
                parametros: $httpParamSerializer(params),
                publicacion_id: publicacion_id
            });
            var deferredWindow = $window.open("", "_blank");
            statsPediTuPrestamo(cuota).$promise.then(function (res) { // Resolved.
                params.idPediTuPrestamo = res.data.id;
                deferredWindow.location.href = TCFAAppConfigService.LINKS.ASK_LOAN + "?" + $httpParamSerializer(params);
            }, function () { // Rejected.
                deferredWindow.location.href = TCFAAppConfigService.LINKS.ASK_LOAN + "?" + $httpParamSerializer(params);
            });
        };
        vm.findImages = function (_id) {
            $timeout(function () {
                var images = angular.element(_id + " img");
                for (var _i = 0; _i < images.length; _i++) {
                    angular.element(images[_i]).addClass("img-responsive");
                    angular.element(images[_i]).css("margin", "auto");
                }
            });
        };
        termConditionScope = $scope.$new(true);
        termConditionScope.cargandoCuotasTyC = true;
        termConditionScope.errorCuotasTyC = false;
        vm.showMobile = UtilsHelper.isMobile.android() || UtilsHelper.isMobile.iOS();
        vm.showLegals = false;
        vm.seccionResponse = null;
        ParametrosService.get({key: "seccion_detalle_cuotas"}, function (response) {
            if (!response.error) {
                TermsConditionsDAO.getSeccion(response.valor, function (data) {
                    termConditionScope.cargandoCuotasTyC = false;
                    termConditionScope.seccionCuotas = data;
                    vm.seccionResponse = data;
                    if (data.id_terminos_condiciones) {
                        vm.showLegals = true;
                    }
                }, function () {
                    termConditionScope.cargandoCuotasTyC = false;
                    termConditionScope.errorCuotasTyC = true;
                });
            } else {
                termConditionScope.cargandoCuotasTyC = false;
                termConditionScope.errorCuotasTyC = true;
            }
        });
        ParametrosService.get({key: "cant_por_pag_destacados"}).$promise.then(function (response) {
            vm.maxSliderItems = parseInt(response.valor);
        });
        vm.showLegalText = function () {
            termConditionScope.scrollbarsConfig = {
                autoHideScrollbar: false,
                theme: "rounded",
                advanced: {updateOnContentResize: true},
                scrollButtons: {enable: false},
                setHeight: 330,
                mouseWheel: {
                    enable: true,
                    preventDefault: true
                },
                scrollInertia: 0
            };
            $uibModal.open({
                animation: true,
                windowClass: "tcfa-modal",
                backdrop: true,
                keyboard: false,
                templateUrl: "./views/detalle_publicacion/info/modal_legal_text_loan.html",
                controller: ["$rootScope", "$scope", "$location", "$route", "$uibModalInstance", function ($rootScope, $scope, $location, $route, $uibModalInstance) {
                    $scope.cerrar = function () {
                        $uibModalInstance.dismiss("cerrar");
                    };
                }],
                controllerAs: "ctrl",
                bindToController: true,
                scope: termConditionScope,
                appendTo: angular.element("#modalComponent"),
                openedClass: "modal-texto-legal-wrapper"
            });
        };
        vm.searchRelacionados = function (filters) {
            return DestacadosDAO.getRelacionados(filters).$promise;
        };
        vm.getRelatedByDistributor = function (filters) {
            filters.concesionario = (vm.info.car.publicacion.concesionario.id || 0);
            return CarDAO.getRelatedByDistributor(filters).$promise;
        };
        vm.onItemGo = function (obj) {
            ActionDAO.createAction({
                accion: "ver-relacionado",
                parametros: "auto_id=" + obj.id,
                publicacion_id: vm.info.car.publicacion.id
            });
        };
        vm.info = {};
        vm.publicacionActiva = true;
        vm.data = {
            imagesSource: TCFAAppConfigService.API.IMG_SRC + TCFAAppConfigService.API.AUTO_IMAGE_FOLDER,
            mostrarCuotas: false,
            minimo_valor_financiable: ParametrosService.get({key: "minimo_valor_financiable"}),
            maximo_valor_financiable: {valor: 0},
            maximo_porcentaje_financiable: ParametrosService.get({key: "maximo_porcentaje_financiable"}),
            delta_relacionados_x_precio: ParametrosService.get({key: "delta_relacionados_x_precio"}),
            isAdmin: ($rootScope.userInfo.user && $rootScope.userInfo.user.admin),
            isDealer: ($rootScope.userInfo.user && $rootScope.userInfo.user.dealer),
            biggest: 60,
            installments_form_data: {
                tradicional: {
                    enforce_text: null,
                    enforce_installment_drop_title: null
                },
                agro: {
                    enforce_text: "Cuota Refuerzo",
                    enforce_installment_drop_title: "Plan Agro: Cuota refuerzo cada 6 meses"
                },
                ballon: {
                    enforce_text: "Cuota Final",
                    enforce_installment_drop_title: "Plan Ballon: Cuota refuerzo Última cuota (%40)"
                },
                aguinaldo: {
                    enforce_text: "Cuota Refuerzo",
                    enforce_installment_drop_title: "Plan Aguinaldo: Cuotas refuerzo enero y julio"
                }
            }
        };
        vm.info.slides = [];
        vm.slideModalObjs = {
            active: false
        };
        vm.slideObjs = {
            active: false
        };
        vm.activeSlide = 0;
        vm.activeModalSlide = 0;
        vm.showValorCuotas = false;
        vm.info.afinanciar = null;
        vm.zoomArea = false;
        vm.imageZoomed = "";
        vm.mouseTop = 0;
        vm.mouseLeft = 0;
        vm.gotoThumbImage = function (_index) {
            vm.activeSlide = _index;
        };
        vm.disableFacebookShare = facebookService.tcfautos_fallback_check;
        // De esta manera no dejamos que se Agregue/Elimine de Favoritos sin antes chequear el estado de la publicacion para el usuario en cuestion
        vm.checkFavorito = false;
        loadAndsetDefaultCondicionImpositiva = function (condicionImpositivaList) {
            // Load and set the default "condicion impositiva".
            condicionImpositivaList.forEach(function (tax_cond_item) {
                if ((__key_tax_condition && (__key_tax_condition === tax_cond_item.codigo)) ||
                    (!__key_tax_condition && tax_cond_item.default) || (condicionImpositivaList.length === 1)) {
                    vm.data.condicionImpositiva = tax_cond_item;

                    // Save cotizador data.
                    $rootScope.cotizador.tax_condition = {
                        id: vm.data.condicionImpositiva.id,
                        key: vm.data.condicionImpositiva.codigo,
                        name: vm.data.condicionImpositiva.descripcion
                    };
                }
            });
        };
        CarDAO.getAutoById({id: carId}, function (response) {
            var i, promedioEnMinutos, __image,
                data = response.data;
            vm.info.categorias = {};
            //fix para acomodar los detalles por categorias
            for (i = (data.comodidades.length - 1); i >= 0; i = i - 1) {
                if (!vm.info.categorias[data.comodidades[i].categoria.descripcion]) {
                    vm.info.categorias[data.comodidades[i].categoria.descripcion] = {};
                }
                if (!vm.info.categorias[data.comodidades[i].categoria.descripcion].detalles) {
                    vm.info.categorias[data.comodidades[i].categoria.descripcion].detalles = [];
                }
                vm.info.categorias[data.comodidades[i].categoria.descripcion].detalles.push(data.comodidades[i].descripcion);
            }
            vm.info.car = data;
            // Save cotizador data.
            $rootScope.cotizador.publicacion = data;
            $rootScope.cotizador.vehicle_brand = {
                id: data.marca_id_fu,
                name: (data.version && data.version.modelo && data.version.modelo.marca && data.version.modelo.marca.mar_descripcion_s)
            };
            $rootScope.cotizador.vehicle_model = {
                id: data.modelo_id_fu,
                descripcion: (data.version && data.version.modelo && data.version.modelo.mod_descripcion_s),
                image: (((vm.data.imagesSource.indexOf("//") === 0) ? "http:" : "") + vm.data.imagesSource +
                    "/" + data.id + "/" + (data.car_images[0] && data.car_images[0].url))
            };
            $rootScope.cotizador.vehicle_version = {
                id: data.version_id_fu,
                descripcion: (data.version && data.version.ver_descripcion_s)
            };
            $rootScope.cotizador.vehicle_condition_new = data.is0km;
            $rootScope.cotizador.vehicle_year = data.year;
            $rootScope.cotizador.vehicle_id = data.vehiculo_id_fu;
            $rootScope.cotizador.vehicle_price = data.price;
            $rootScope.cotizador.dealer = {
                id: (data.publicacion && data.publicacion.concesionario && data.publicacion.concesionario.id),
                cuit: (data.publicacion && data.publicacion.concesionario && data.publicacion.concesionario.cuit),
                name: (data.publicacion && data.publicacion.concesionario && data.publicacion.concesionario.razon_social)
            };
            $rootScope.cotizador.dealer.logo = TCFAAppConfigService.API.IMG_SRC + "/dealer_images/" + $rootScope.cotizador.dealer.cuit + "/logo.png";
            $rootScope.cotizador.dealer.logo_pdf = TCFAAppConfigService.API.IMG_SRC + "/dealer_images/" + $rootScope.cotizador.dealer.cuit + "/logo_pdf.png";
            $rootScope.cotizador.dealer.is_default = ($rootScope.cotizador.dealer.cuit && ($rootScope.cotizador.dealer.cuit === "22222222222"));
            //from campaign (or publication)
            $rootScope.cotizador.from_campaign = true;
            // Get Dealer ID + Branches.
            ConcesionariosDAO.get({sucursales: true}, function (concesionarios_response) {
                (concesionarios_response || []).some(function (conc_item) {
                    if (conc_item.cuit === $rootScope.cotizador.dealer.cuit) {
                        $rootScope.cotizador.dealer.branches = conc_item.sucursales;
                        return true;
                    }
                    return false;
                });
            });
            __image = new Image();
            __image.src = $rootScope.cotizador.dealer.logo;
            __image.onload = function () {
                $rootScope.cotizador.dealer.logo_loaded = true;
            };
            __image.onerror = function () {
                $rootScope.cotizador.dealer.logo_error = true;
            };
            for (i = 0; i < data.car_images.length; i = i + 1) {
                vm.info.slides.push({
                    image: vm.data.imagesSource + "/" + data.id + "/" + data.car_images[i].url,
                    thumbnail: vm.data.imagesSource + "/" + data.id + "/thumbnail" + data.car_images[i].url
                });
            }
            if (data.youtube_url) {
                vm.info.slides.push({
                    video: data.youtube_url
                });
            }
            vm.obtenerAnticipo = function () {
                return ((vm.info.afinanciar > vm.info.car.price_calculated) ||
                    (!vm.info.afinanciar ? Math.ceil(vm.info.car.price_calculated / 2) : vm.info.car.price_calculated - vm.info.afinanciar));
            };
            vm.rawDescription = $(vm.info.car.description).text();
            // Favoritos - Si el usuario esta logueado muestro si la publicacion esta en favoritos
            if ($rootScope.userInfo.user) {
                FavoritoDAO.isFavorito({id_publicacion: data.publicacion.id}).$promise.then(function (data) {
                    vm.info.car.favorito = data.data;
                    vm.checkFavorito = true;
                });
            } else {
                vm.checkFavorito = true;
            }
            vm.info.filtersRelacionados = {
                marca: vm.info.car.version.modelo.marca.id,
                removeId: vm.info.car.id,
                modelo: vm.info.car.version.modelo.id,
                concesionario: vm.info.car.publicacion.concesionario.id
            };
            CarDAO.getDatosCalculoCuotas().$promise.then(function (response) {
                var tiposPersona, tipoPersonaId,
                    filterByPersona = function (elem) {
                        return (elem.tipo_persona_id === tipoPersonaId);
                    };
                tiposPersona = response.data.tipos_persona;
                vm.data.tiposPersona = [];
                // Set the default.
                tiposPersona.forEach(function (t_per_item) {
                    tipoPersonaId = t_per_item.id;
                    if (vm.info.car.planes && vm.info.car.planes.length && (vm.info.car.planes.length > 0) &&
                        (vm.info.car.planes.filter(filterByPersona).length === 0)) {
                        return;
                    }
                    if ((__id_person_type && (__id_person_type === tipoPersonaId)) ||
                        (!__id_person_type && t_per_item.default)) {
                        vm.changeTipoPersona(t_per_item);
                    }
                    vm.data.tiposPersona.push(t_per_item);
                });
                if (!vm.data.tipoPersona && (vm.data.tiposPersona.length > 0)) {
                    vm.changeTipoPersona(vm.data.tiposPersona[0]);
                }
                loadAndsetDefaultCondicionImpositiva(vm.data.tipoPersona.condicion_impositiva);
                CotizadorDAO.getVehicleLoanInfoTCFAutos({
                    p_tipo_operacion_c: "RE", // Operation type.
                    p_tipo_persona_c: vm.data.tipoPersona.codigo, // Person type.
                    p_veh_nuevo_i: (data.is0km ? 1 : 0), // Vehicle condition.
                    p_cuit_dealer_c: data.publicacion.concesionario.cuit, // CUIT dealer.
                    p_veh_precio_usado_d: data.price_calculated, // Vehicle price_calculated.
                    p_veh_id_i: data.vehiculo_id_fu // Vehicle ID FU.
                }).$promise.then(function (vehicle_loan_info_response) {
                    vm.data.maximo_valor_financiable = {valor: Math.floor(parseFloat(vehicle_loan_info_response.data.max_financiacion))};
                    vm.data.minimo_valor_financiable = {valor: Math.floor(parseFloat(vehicle_loan_info_response.data.min_financiacion))};
                    vm.data.financiacion_sugerida = {valor: Math.floor(parseFloat(vehicle_loan_info_response.data.financiacion_sugerida))};
                    vm.info.afinanciar = (__loan_amount || vm.data.financiacion_sugerida.valor || vm.data.minimo_valor_financiable.valor || 50000);
                    vm.onLoanAmountChange();
                    if (__id_share_link > 0) {
                        vm.calculateInstallments();
                    }
                });
            });
            vm.info.filtersConcesionario = {
                removeId: vm.info.car.id,
                sort: "dateDesc"
            };
            vm.publicacionActiva = vm.info.car.publicacion.estado.clave === "PUBLICADA";
            if (vm.info.car.planes) {
                vm.data.plan = vm.info.car.planes[0];
            }
            if (vm.info.car.publicacion.concesionario.concesionario_promedio &&
                (vm.info.car.publicacion.concesionario.concesionario_promedio.promedio > 0)) {
                promedioEnMinutos = vm.info.car.publicacion.concesionario.concesionario_promedio.promedio;
                vm.info.car.publicacion.concesionario.concesionario_promedio.frontView = window.moment.duration(promedioEnMinutos, "minutes").humanize();
            }
        });
        vm.getMonth = function (index) {
            return window.moment().add(index, "month").format("YYYY/MM");
        };
        /** Loan amount (capital a financiar) change handler. */
        vm.onLoanAmountChange = function () {
            // Save cotizador data.
            $rootScope.cotizador.loan_amount = vm.info.afinanciar;
        };
        /** Tipo de persona on change handler. */
        vm.changeTipoPersona = function (tipoPersona) {
            vm.onFormCotizarChange();
            vm.data.tipoPersona = tipoPersona;
            vm.showValorCuotas = false;
            vm.data.planesFin = [];
            for (var i = vm.info.car.planes.length - 1; i >= 0; i--) {
                if (vm.info.car.planes[i].tipo_persona_id === tipoPersona.id) {
                    vm.data.planesFin.push(vm.info.car.planes[i]);
                }
            }
            loadAndsetDefaultCondicionImpositiva(tipoPersona.condicion_impositiva);
            vm.data.plan = vm.data.planesFin[0];
            // Save cotizador data.
            $rootScope.cotizador.person_type = {
                id: vm.data.tipoPersona.id,
                key: vm.data.tipoPersona.codigo,
                name: vm.data.tipoPersona.descripcion,
                loan_code: vm.data.tipoPersona.codigo_prestamo
            };
        };
        vm.changeCondicionImpositiva = function () {
            vm.onFormCotizarChange();
            vm.showValorCuotas = false;
            // Save cotizador data.
            $timeout(function () {
                $rootScope.cotizador.tax_condition = {
                    id: vm.data.condicionImpositiva.id,
                    key: vm.data.condicionImpositiva.codigo,
                    name: vm.data.condicionImpositiva.descripcion
                };
            }, 1);
        };
        vm.showTipoPersona = function (tipoPersona) {
            return tipoPersona.descripcion;
        };
        vm.showCondicionImpositiva = function (condicionImpositiva) {
            return condicionImpositiva.descripcion;
        };
        vm.showPlan = function (plan) {
            return plan.p_pla_descripcion_c;
        };
        vm.showPlanTooltip = function (plan) {
            if (plan.descripcion) {
                return plan.descripcion.trim();
            }
            return null;
        };
        vm.changePlan = function () {
            vm.showValorCuotas = false;
        };
        //FixMe: llevar a un solo script
        vm.obtenerCuotaPromedio = function (cuota) {
            var i, showcuota,
                promedio = 0;
            for (i = 0; i <= cuota.Cuotas - 1; i = i + 1) {
                showcuota = {
                    fecha: cuota.FechaCuota[i],
                    saldoCapital: parseFloat(cuota.DeudaCapital[i]),
                    capital: parseFloat(cuota.Capital[i]),
                    interes: parseFloat(cuota.Interes[i]),
                    seguro: parseFloat(cuota.Seguro[i]),
                    ivaInteres: parseFloat(cuota.IvaInteres[i]),
                    costoMensual: parseFloat(cuota.Gasto),
                    ivaCostoMensual: parseFloat(cuota.Gasto) * 0.21
                };
                promedio = promedio + (showcuota.capital + showcuota.interes +
                    showcuota.seguro + showcuota.ivaInteres + showcuota.costoMensual +
                    showcuota.ivaCostoMensual);
            }
            return Math.ceil(promedio / cuota.Cuotas);
        };
        vm.showInstallments = function (cuota) {
            vm.data.biggest = cuota.Cuotas;
            vm.data.biggestQuota = cuota;
            var i, showcuota,
                detalleCuotas = [],
                publicacion_id = null;
            if (vm.info.car && vm.info.car.publicacion) {
                publicacion_id = vm.info.car.publicacion.id;
            }
            vm.info.installmentForShow = cuota;
            ActionDAO.createAction({
                accion: "ver-cuotas",
                parametros: $httpParamSerializer({cuotas: cuota.Cuotas}),
                publicacion_id: publicacion_id
            });
            for (i = 0; i <= cuota.Cuotas - 1; i = i + 1) {
                showcuota = {
                    fecha: cuota.FechaCuota[i],
                    saldoCapital: parseFloat(cuota.DeudaCapital[i]),
                    capital: parseFloat(cuota.Capital[i]),
                    interes: parseFloat(cuota.Interes[i]),
                    seguro: parseFloat(cuota.Seguro[i]),
                    ivaInteres: parseFloat(cuota.IvaInteres[i]),
                    costoMensual: parseFloat(cuota.Gasto),
                    ivaCostoMensual: parseFloat(cuota.Gasto) * 0.21
                };
                detalleCuotas.push(showcuota);
            }
            vm.info.detalleCuotas = detalleCuotas;
            vm.data.mostrarCuotas = true;
            // carga legales con tasas dinámicas
            termConditionScope.seccionCuotas = vm.renderSeccionTyCTasas(vm.seccionResponse, vm.info.installmentForShow);
            $timeout(function () {
                $location.hash("installments");
                $anchorScroll.yOffset = 100;
                $anchorScroll();
            }, 500);
        };
        // Devuelve seccion reemplazando tasas de cuota actual
        vm.renderSeccionTyCTasas = function (seccionResponse, installmentForShow) {
            var seccion = angular.copy(seccionResponse);
            seccion.termino_condicion.content = seccion.termino_condicion.content
                .replace("%TNA%", $filter("number")(installmentForShow.Tna, 2))
                .replace("%TEA%", $filter("number")(installmentForShow.Tea, 2))
                .replace("%CFT%", $filter("number")(installmentForShow.Cft, 2))
                .replace("%CFTEF%", $filter("number")(installmentForShow.CftEfectivo, 2));
            return seccion;
        };
        vm.hasTelefono = function () {
            return !!($rootScope.userInfo.user &&
                $rootScope.userInfo.user.cliente &&
                $rootScope.userInfo.user.cliente.prefijo_telefono &&
                $rootScope.userInfo.user.cliente.telefono);
        };
        vm.getPrefijoTelefono = function () {
            if (vm.hasTelefono()) {
                return $rootScope.userInfo.user.cliente.prefijo_telefono;
            }
            return "";
        };
        vm.getTelefono = function () {
            if (vm.hasTelefono()) {
                return $rootScope.userInfo.user.cliente.telefono;
            }
            return "";
        };
        vm.hasCelular = function () {
            return !!($rootScope.userInfo.user &&
                $rootScope.userInfo.user.cliente &&
                $rootScope.userInfo.user.cliente.celular);
        };
        vm.hasPrefijoCelular = function () {
            return !!($rootScope.userInfo.user &&
                $rootScope.userInfo.user.cliente &&
                $rootScope.userInfo.user.cliente.prefijo_celular);
        };
        vm.parseCellphone = function (callback, fail) {
            var loadingModal = $rootScope.showLoadingModal("Validando teléfono. Por favor espere.", $scope.$new(true));
            UserDAO.validatePhone({
                telefono: $rootScope.userInfo.user.cliente.celular
            }, function (response) {
                loadingModal.dismiss("error");
                $rootScope.userInfo.user.cliente.prefijo_celular = "0" + response.data.codigo_area;
                $rootScope.userInfo.user.cliente.celular = response.data.telefono;

                if (typeof callback === "function") {
                    callback();
                }
            }, function (error) {
                loadingModal.dismiss("error");
                $rootScope.userInfo.user.cliente.prefijo_celular = null;
                $rootScope.userInfo.user.cliente.celular = null;

                if (typeof fail === "function") {
                    fail(error);
                }
            });
        };
        vm.getPrefijoCelular = function () {
            if (vm.hasPrefijoCelular()) {
                return $rootScope.userInfo.user.cliente.prefijo_celular;
            }
            return "";
        };
        vm.getCelular = function () {
            if (vm.hasCelular()) {
                return $rootScope.userInfo.user.cliente.celular;
            }
            return "";
        };
        vm.hideInstallments = function (/*installment*/) {
            vm.data.mostrarCuotas = false;
            $location.hash("headimage");
            $anchorScroll.yOffset = 100;
            $anchorScroll();
        };
        vm.request_by_publication = false;
        vm.insurance_quote = false;
        vm.procesandoConsulta = false;
        vm.init_form_request = function () {
            return {
                content: "",
                prefix: vm.getPrefijoTelefono(),
                phone: vm.getTelefono(),
                cel_prefix: vm.getPrefijoCelular(),
                cel_phone: vm.getCelular(),
                error: {
                    message: "",
                    show: false
                }
            };
        };
        vm.form_request = vm.init_form_request();
        if (!vm.hasPrefijoCelular() && vm.hasCelular()) {
            vm.parseCellphone(function () {
                vm.form_request.cel_prefix = vm.getPrefijoCelular();
                vm.form_request.cel_phone = vm.getCelular();
            }, function () {
                vm.form_request.cel_prefix = null;
                vm.form_request.cel_phone = null;
            });
        }
        vm.form_insurance_quote = {cp: ""};
        vm.request_predefine_text_opening = "Hola, ";
        vm.request_predefine_text_closing = ". Muchas gracias.";
        vm.request_predefine_text = [{
            title: "Más Fotos",
            content: "me gustaría recibir más fotos de esta publicación",
            active: true
        }, {
            title: "Financiación",
            content: "me gustaría recibir más información sobre los planes de financiación",
            active: false
        }, {
            title: "Me Interesa",
            content: "estoy interesado en esta publicación, me gustaría poder contactarlo",
            active: false
        }, {
            title: "Auto en forma de pago",
            content: "poseo un auto que me gustaría dar en forma de pago. Solicito información al respecto",
            active: false
        }];
        vm.checkUserLogged = function () {
            return !!$rootScope.userInfo.user;
        };
        vm.showConsultByPublication = function () {
            vm.insurance_quote = false;
            vm.request_by_publication = (vm.request_by_publication !== true);
            angular.element(_BTN_COTIZA_TU_SEGURO_HTML_CLASS_SELECTOR).removeClass("active");
            if (vm.request_by_publication === true) {
                $location.hash("formConsult");
                $anchorScroll.yOffset = 100;
                $anchorScroll();
                angular.element(_BTN_CONSULTAR_HTML_CLASS_SELECTOR).addClass("active");
            } else {
                $location.hash("");
                angular.element(_BTN_CONSULTAR_HTML_CLASS_SELECTOR).removeClass("active");
            }
            vm.completeConsult();
        };
        vm.getProfileUpdateData = function () {
            var data = {};
            var hasPerfilTelefono = vm.hasTelefono();
            var hasPerfilCelular = vm.hasCelular();
            var formTelefono = vm.form_request.prefix + vm.form_request.phone;
            var formCelular = vm.form_request.cel_prefix + vm.form_request.cel_phone;
            var hasConsultaTelefono = (formTelefono.length > 0);
            var hasConsultaCelular = (formCelular.length > 0);
            if (!hasPerfilTelefono && hasConsultaTelefono) {
                data.prefijo_telefono = vm.form_request.prefix;
                data.telefono = vm.form_request.phone;
            }
            if (!hasPerfilCelular && hasConsultaCelular) {
                data.prefijo_celular = vm.form_request.cel_prefix;
                data.celular = vm.form_request.cel_phone;
            }
            return data;
        };
        vm.sendRequestByPublication = function (_form) {
            var checkUser = vm.checkUserLogged(),
                doContactRequest = function () {
                    vm.form_request.error.show = false;
                    vm.form_request.error.message = "";
                    var checkUser = vm.checkUserLogged();
                    var filters = {auto_id: vm.info.car.id};
                    if (_form === false || checkUser === false) {
                        vm.form_request.error.show = true;
                        vm.form_request.error.message = "Debe completar todos los campos.";
                        return false;
                    }
                    vm.form_request.phone = vm.form_request.phone.replace("-", "");
                    var consulta = {
                        consulta: {
                            nombre: $rootScope.userInfo.user.nombre + " " + $rootScope.userInfo.user.apellido,
                            texto: vm.form_request.content,
                            prefijo_telefono: vm.form_request.prefix,
                            telefono: vm.form_request.phone,
                            prefijo_celular: vm.form_request.cel_prefix,
                            celular: vm.form_request.cel_phone,
                            email: $rootScope.userInfo.user.email
                        }
                    };
                    vm.procesandoConsulta = true;
                    ContactsDAO.consultaAuto(filters, consulta).$promise.then(function (response) {
                        var userDataToPersist = vm.getProfileUpdateData();
                        if (!angular.equals(userDataToPersist, {})) {
                            if (userDataToPersist.telefono) {
                                $rootScope.userInfo.user.cliente.prefijo_telefono = userDataToPersist.prefijo_telefono;
                                $rootScope.userInfo.user.cliente.telefono = userDataToPersist.telefono;
                            }
                            if (userDataToPersist.celular) {
                                $rootScope.userInfo.user.cliente.prefijo_celular = userDataToPersist.prefijo_celular;
                                $rootScope.userInfo.user.cliente.celular = userDataToPersist.celular;
                            }
                        }
                        vm.closeConsultByPublication();
                        $location.hash("");
                        $uibModal.open({
                            templateUrl: "views/detalle_publicacion/info/contact_modal_successful.html",
                            windowClass: "tcfa-modal",
                            controller: ["$uibModalInstance",
                                function ($uibModalInstance) {
                                    var controller = this,
                                        deltaRelacionados,
                                        precioAuto,
                                        similaresXPrecioDesde,
                                        similaresXPrecioHasta;
                                    controller.confirmacionEnviada = response.code === 101;
                                    precioAuto = parseInt(vm.info.car.price);
                                    deltaRelacionados = Math.round(precioAuto * (parseInt(vm.data.delta_relacionados_x_precio.valor) / 100));
                                    similaresXPrecioDesde = precioAuto - deltaRelacionados;
                                    similaresXPrecioHasta = precioAuto + deltaRelacionados;
                                    controller.auto = vm.info.car;
                                    controller.imgsResuorces = vm.data.imagesSource;
                                    controller.afinanciar = vm.info.car.price - Math.ceil(vm.info.car.price / 2);
                                    controller.buscarXPrecio = function () {
                                        $uibModalInstance.dismiss();
                                        $timeout(function () {
                                            $location.path("/search/").search({
                                                priceFrom: similaresXPrecioDesde,
                                                priceTo: similaresXPrecioHasta
                                            });
                                        });
                                    };
                                    controller.buscarXMarcaModelo = function () {
                                        $uibModalInstance.dismiss();
                                        $timeout(function () {
                                            $location.path("/search/").search({
                                                marca: vm.info.car.version.modelo.marca.id,
                                                modelo: vm.info.car.version.modelo.id,
                                                is0km: vm.info.car.is0km
                                            });
                                        });
                                    };
                                    controller.consulta = consulta.consulta;
                                    controller.close = function () {
                                        $uibModalInstance.dismiss();
                                    };
                                }
                            ],
                            controllerAs: "ctrl",
                            appendTo: angular.element("#modalComponent")
                        });
                        vm.form_request = vm.init_form_request();
                        vm.procesandoConsulta = false;
                        vm.request_by_publication = false;
                    }, function (response) {
                        vm.procesandoConsulta = false;
                        if (response.status === 500) {
                            vm.form_request.error.message = "Ha ocurrido un error al registrar su consulta. Por favor intente nuevamente en unos minutos.";
                            vm.form_request.error.show = true;
                            vm.request_by_publication = false;
                        }
                    });
                },
                checkCellphoneAndContact = function () {
                    if (vm.form_request.cel_prefix == $rootScope.userInfo.user.cliente.prefijo_celular
                         && vm.form_request.cel_phone.replace("-", "") == $rootScope.userInfo.user.cliente.celular
                         && $rootScope.userInfo.user.cliente.celular_validado) {
                        doContactRequest();
                    } else {
                        verificationCodeHandler.showVerificationModal({
                            "email": $rootScope.userInfo.user.email,
                            "cellphone": vm.form_request.cel_prefix + vm.form_request.cel_phone.replace("-", "")
                        }).then(function () {
                            doContactRequest();
                        })
                        .catch(function () {
                            TCFAModalService.showError({
                                "error": {
                                    "showModal": true,
                                    "title": "Error al verificar teléfono",
                                    "message": "Por favor intente nuevamente."
                                }
                            });
                        });
                    }
                };
            
            if (checkUser === false) {
                vm.request_by_publication = false;
                $location.hash("");
                LogInUser.loginCliente().then(function (result) {
                    if (result === "userlogged") {
                        checkCellphoneAndContact();
                    }
                });
            } else {
                checkCellphoneAndContact();
            }
        };
        vm.closeConsultByPublication = function () {
            vm.request_by_publication = (vm.request_by_publication !== true);
            vm.form_request = vm.init_form_request();
            angular.element(_BTN_CONSULTAR_HTML_CLASS_SELECTOR).removeClass("active");
            $location.hash("");
        };
        vm.showInsuranceQuote = function () {
            vm.request_by_publication = false;
            vm.insurance_quote = (vm.insurance_quote !== true);
            angular.element(_BTN_COTIZA_TU_SEGURO_HTML_CLASS_SELECTOR).removeClass("active");
            if (vm.insurance_quote === true) {
                $location.hash("formInsurance");
                $anchorScroll.yOffset = 100;
                $anchorScroll();
                angular.element(_BTN_COTIZA_TU_SEGURO_HTML_CLASS_SELECTOR).addClass("active");
            } else {
                $location.hash("");
                angular.element(_BTN_COTIZA_TU_SEGURO_HTML_CLASS_SELECTOR).removeClass("active");
            }
        };
        vm.closeInsuranceQuote = function () {
            vm.insurance_quote = (vm.insurance_quote !== true);
            angular.element(_BTN_COTIZA_TU_SEGURO_HTML_CLASS_SELECTOR).removeClass("active");
            $location.hash("");
        };
        vm.onTogglePredefinedText = function (pre_text) {
            pre_text.active = !pre_text.active;
            vm.completeConsult();
        };
        vm.completeConsult = function (_index) {
            if (vm.request_predefine_text.some(function (pre_text) {
                return !!pre_text.active;
            })) {
                vm.form_request.content = vm.request_predefine_text_opening +
                    vm.request_predefine_text.filter(function (pre_text) {
                        return !!pre_text.active;
                    }).map(function (pre_text) {
                        return pre_text.content;
                    }).join("; ") +
                    vm.request_predefine_text_closing;
            } else {
                vm.form_request.content = "";
            }
        };
        /** Cotizar click handler. */
        vm.calculateInstallments = function () {
            vm.hideInstallments();
            vm.cuotasSplash = true;
            vm.showValorCuotas = false;
            if (vm.info.afinanciar < vm.data.minimo_valor_financiable.valor) {
                vm.cuotasSplash = false;
                vm.info.error = {};
                vm.info.error.showModal = true;
                vm.info.error.message = "El mínimo valor financiable permitido es de $" + vm.data.minimo_valor_financiable.valor +
                    ". Por favor, modifique el valor ingresado para poder realizar el cálculo de cuotas.";
                vm.info.error.title = "Error de validación";
                TCFAModalService.showWarning(vm.info);
                return;
            }
            if (vm.info.afinanciar > vm.data.maximo_valor_financiable.valor) {
                vm.cuotasSplash = false;
                vm.info.error = {};
                vm.info.error.showModal = true;
                vm.info.error.message = "El valor máximo a financiar es de $" + vm.data.maximo_valor_financiable.valor;
                vm.info.error.title = "Error de validación";
                TCFAModalService.showWarning(vm.info);
                return;
            }
            ActionDAO.createAction({
                accion: "calcular-cuotas",
                publicacion_id: vm.info.car.publicacion.id,
                parametros: "precio=" + vm.info.afinanciar
            });
            vm.calcularCuotas();
        };
        vm.toggleFavoritos = function () {
            var checkFavorito = function () {
                var accion;
                if (vm.info.car.favorito) {
                    FavoritoDAO.delete({id_publicacion: vm.info.car.publicacion.id}).$promise.then(function (/*promise*/) {
                        vm.info.car.favorito = false;
                    });
                    accion = "eliminar-favorito";
                } else {
                    PublicationDAO.agregarAFavoritos({pubId: vm.info.car.publicacion.id}).$promise.then(function (/*promise*/) {
                        vm.info.car.favorito = true;
                    });
                    accion = "agregar-favorito";
                }
                ActionDAO.createAction({
                    accion: accion,
                    publicacion_id: vm.info.car.publicacion.id
                });
            };
            if (!$rootScope.userInfo.user) {
                LogInUser.loginCliente().then(function (result) {
                    if (result === "userlogged") {
                        checkFavorito();
                    }
                });
            } else {
                checkFavorito();
            }
        };
        vm.shareOnFacebook = function () {
            ActionDAO.createAction({
                accion: "compartir-en-facebook",
                publicacion_id: vm.info.car.publicacion.id
            });
            vm.currentURL = TCFAAppConfigService.API.HOST + "/showSocial/" + vm.info.car.id;
            if (facebookService.tcfautos_fallback_check) {
                return false;
            }
            facebookService.loginShare(vm.currentURL, vm.info.car.publicacion.id);
        };
        vm.fb_app_id = TCFAAppConfigService.FACEBOOK.APP_ID;
        vm.shareOnGooglePlus = function () {
            ActionDAO.createAction({
                accion: "compartir-en-googleplus",
                publicacion_id: vm.info.car.publicacion.id
            });
            vm.currentURL = TCFAAppConfigService.API.HOST + "/showSocial/" + vm.info.car.id;
            $window.open(TCFAAppConfigService.GOOGLE.SHARE_URL + vm.currentURL, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
        };
        DependencyInjectionService.loadApi("GoogleRecaptcha").then(function () {
            vm.showShareWithFriend = function () {
                var modalScope = $scope.$new(true);
                modalScope.publication_id = vm.info.car.publicacion.id;
                ActionDAO.createAction({
                    accion: "compartir-con-amigo",
                    publicacion_id: vm.info.car.publicacion.id
                });
                $uibModal.open({
                    animation: true,
                    backdrop: true,
                    keyboard: false,
                    windowClass: "tcfa-large-modal",
                    openedClass: "tcfa-large-modal-body",
                    templateUrl: "./views/detalle_publicacion/info/modal/modal_share_with_friends.html",
                    controller: "ShareWithFriendsCtrl",
                    controllerAs: "ctrl",
                    bindToController: true,
                    scope: modalScope
                });
            };
        });
        /** Get installments. */
        vm.calcularCuotas = function () {
            var __loading_modal,
                __params = {
                    p_cuit_dealer_c: vm.info.car.publicacion.concesionario.cuit,
                    p_tipo_operacion_c: "RE",
                    planes: (vm.info.car.planes.map(function (plan_item) {
                        return {
                            id: plan_item.p_pla_id_i,
                            subplanes: plan_item.plazos.map(function (subplan_item) {
                                return subplan_item.plazo;
                            })
                        };
                    })),
                    remove_plan_filter: false,
                    canal_tcfautos: true,
                    p_mar_id_i: vm.info.car.marca_id_fu,
                    p_mod_id_i: vm.info.car.modelo_id_fu,
                    p_ver_id_i: vm.info.car.version_id_fu,
                    p_tipo_persona_c: vm.data.tipoPersona.codigo,
                    monto_financiar: vm.info.afinanciar,
                    canon_anticipado: null,
                    condicion_impositiva_id: vm.data.condicionImpositiva.id,
                    p_veh_nuevo_i: (vm.info.car.is0km && 1 || 0),
                    p_veh_id_i: vm.info.car.vehiculo_id_fu,
                    p_precio_usado_n: vm.info.car.price,
                    p_veh_anio_i: vm.info.car.year,
                    p_tve_id_i: 1
                };
            __loading_modal = $rootScope.showLoadingModal("Cotizando. Por favor espere.", $scope.$new(true));
            vm.form_cotizar_changed = false;
            vm.form_submit_in_progress = true;
            vm.disableCalcular = true;
            CotizadorDAO.cotizar(__params).$promise.then(function (response) {
                vm.form_submit_in_progress = false;
                __loading_modal.dismiss("close");
                CotizadorService.updatePlanesAndPlazos(response.data.p_planes_o, true, false);
            }).catch(function () {
                vm.form_cotizar_changed = true;
                __loading_modal.dismiss("error");
            }).finally(function () {
                vm.disableCalcular = false;
                vm.form_submit_in_progress = false;
                usSpinnerService.stop("info_show_cuotas");
            });
        };
        vm.onRelatedLoad = function (response) {
            vm.showRelated = !!(response.data && response.data.length > 0);
        };
        vm.onRelatedDistibutorLoad = function (response) {
            vm.showRelatedDistributor = !!(response.data && response.data.length > 0);
        };
        vm.redirect = function (_url, _params) {
            $location.path(_url).search(_params);
        };
        vm.changePhone = function () {
            if (vm.form_contacto.caracteristicaTelefono.$valid && vm.form_contacto.telefono.$valid) {
                var loadingModal = $rootScope.showLoadingModal("Validando teléfono. Por favor espere.", $scope.$new(true));
                UserDAO.validatePhone({
                    telefono    : vm.form_request.prefix + vm.form_request.phone
                }, function (response) {
                    loadingModal.dismiss("error");
                    vm.form_request.prefix = "0" + response.data.codigo_area;
                    vm.form_request.phone = response.data.telefono;
                }, function (error) {
                    vm.form_request.prefix = "";
                    vm.form_request.phone = "";
                    loadingModal.dismiss("error");
                    var info = {};
                    info.error = {};
                    info.error.showModal = true;
                    info.error.message = "Ha ocurrido un error al validar el teléfono. Intente nuevamente en unos minutos.";
                    info.error.title = "Error de validación";
                    if (error.data.errors) {
                        if (!(error.data.errors.message instanceof Array)) {
                            info.error.message = error.data.errors.message;
                        } 
                        else {
                            info.error.message = error.data.errors.message.join(" ");
                        }
                    }
                    TCFAModalService.showWarning(info);
                });
            }
        };
        vm.changeMobilePhone = function () {
            if (vm.form_contacto.caracteristicaCelular.$valid && vm.form_contacto.celular.$valid) {
                var loadingModal = $rootScope.showLoadingModal("Validando celular. Por favor espere.", $scope.$new(true));
                UserDAO.validatePhone({
                    telefono    : vm.form_request.cel_prefix + vm.form_request.cel_phone
                }, function (response) {
                    loadingModal.dismiss("error");
                    vm.form_request.cel_prefix = "0" + response.data.codigo_area;
                    vm.form_request.cel_phone = response.data.telefono;
                }, function (error) {
                    vm.form_request.cel_prefix = "";
                    vm.form_request.cel_phone = "";
                    loadingModal.dismiss("error");
                    var info = {};
                    info.error = {};
                    info.error.showModal = true;
                    info.error.message = "Ha ocurrido un error al validar el celular. Intente nuevamente en unos minutos.";
                    info.error.title = "Error de validación";
                    if (error.data.errors) {
                        if (!(error.data.errors.message instanceof Array)) {
                            info.error.message = error.data.errors.message;
                        } 
                        else {
                            info.error.message = error.data.errors.message.join(" ");
                        }
                    }
                    TCFAModalService.showWarning(info);
                });
            }
        };
    };
    Info_CarInfoCtrl.$inject = moduleConfig.Info_CarInfoCtrl.deps;
    module.controller(moduleConfig.Info_CarInfoCtrl.name, Info_CarInfoCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.SplashScreenCtrl",
            deps: ["TCFAutos.services.PageNavigationService"],
            SplashScreenCtrl: {
                name: "SplashScreenCtrl",
                deps: ["$scope", "PageNavigationService"]
            }
        },
        module, SplashScreenCtrl;
    module = angular.module("TCFAutos");
    SplashScreenCtrl = function ($scope, PageNavigationService) {
        var vm = this;
        $scope.$watch(
            function (/*scope*/) {
                return (PageNavigationService.shouldShowSplashScreen());
            },
            function (newValue/*, oldValue*/) {
                vm.showSplash = newValue;
            }
        );
    };
    SplashScreenCtrl.$inject = moduleConfig.SplashScreenCtrl.deps;
    module.controller(moduleConfig.SplashScreenCtrl.name, SplashScreenCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.TcfaPaginationCtrl",
            deps: [],
            TcfaPaginationCtrl: {
                name: "TcfaPaginationCtrl",
                deps: ["$scope"]
            }
        },
        module, TcfaPaginationCtrl;
    module = angular.module("TCFAutos");
    TcfaPaginationCtrl = function ($scope) {
        var i,
            arrayIndex = 0,
            vm = this,
            updateButtons = function () {
                var i, max, prevPages, nextPages, minIndex, maxIndex;
                vm.disableFirstPageButton = (vm.currentPageIndex === vm.firstPageIndex);
                vm.disableLastPageButton = (vm.currentPageIndex === vm.lastPageIndex);
                vm.disablePreviousPageButton = vm.disableFirstPageButton;
                vm.disableNextPageButton = vm.disableLastPageButton;
                nextPages = Math.floor(vm.maxPageDisplay / 2);
                prevPages = vm.maxPageDisplay - nextPages;
                minIndex = arrayIndex - prevPages;
                maxIndex = arrayIndex + nextPages;
                if (minIndex < 0) {
                    maxIndex = maxIndex + Math.abs(minIndex);
                    minIndex = 0;
                }
                max = vm.pages.length - 1;
                if (maxIndex > max) {
                    minIndex = minIndex - (maxIndex - max);
                    if (minIndex < 0) {
                        minIndex = 0;
                    }
                    maxIndex = max;
                }
                for (i = max; i >= 0; i = i - 1) {
                    vm.pages[i].display = ((vm.pages[i].arrayIndex >= minIndex) &&
                        (vm.pages[i].arrayIndex <= maxIndex));
                }
                vm.showPreviousEllipsis = (minIndex > 0);
                vm.showNextEllipsis = (maxIndex < max);
            };
        vm.loadPage = function (page) {
            if (page.arrayIndex !== arrayIndex) {
                vm.currentPage.active = false;
                page.active = true;
                page.display = true;
                arrayIndex = page.arrayIndex;
                vm.currentPage = page;
                vm.currentPageIndex = page.index;
                // Callback.
                if ($scope.onPageChange) {
                    $scope.onPageChange(page.index);
                }
            }
            updateButtons();
        };
        vm.loadFirstPage = function () {
            vm.loadPage(vm.pages[0]);
        };
        vm.loadLastPage = function () {
            vm.loadPage(vm.pages[vm.pages.length - 1]);
        };
        vm.loadPreviousPageBlock = function () {
            var previous = arrayIndex - vm.maxPageDisplay - 1;
            if (previous >= 0) {
                vm.loadPage(vm.pages[previous]);
            } else {
                vm.loadFirstPage();
            }
        };
        vm.loadNextPageBlock = function () {
            var next = arrayIndex + vm.maxPageDisplay + 1;
            if (next <= (vm.pages.length - 1)) {
                vm.loadPage(vm.pages[next]);
            } else {
                vm.loadLastPage();
            }
        };
        vm.loadPreviousPage = function () {
            var previous = arrayIndex - 1;
            if (previous >= 0) {
                vm.loadPage(vm.pages[previous]);
            } else {
                vm.loadFirstPage();
            }
        };
        vm.loadNextPage = function () {
            var next = arrayIndex + 1;
            if (next <= (vm.pages.length - 1)) {
                vm.loadPage(vm.pages[next]);
            } else {
                vm.loadLastPage();
            }
        };
        vm.refresh = function () {
            var locArrayIndex = 0;
            vm.firstPageIndex = (parseInt($scope.firstPageIndex, 10) || 1);
            vm.lastPageIndex = (parseInt($scope.lastPageIndex, 10) || 1);
            vm.currentPageIndex = (parseInt($scope.currentPageIndex, 10) || 1);
            vm.maxPageDisplay = (parseInt($scope.maxPageDisplay, 10) || 5) - 1;
            if (vm.lastPageIndex < vm.firstPageIndex) {
                vm.lastPageIndex = vm.firstPageIndex;
            }
            if (vm.currentPageIndex < vm.firstPageIndex) {
                vm.currentPageIndex = vm.firstPageIndex;
            } else {
                if (vm.currentPageIndex > vm.lastPageIndex) {
                    vm.currentPageIndex = vm.lastPageIndex;
                }
            }
            if (vm.maxPageDisplay < 0) {
                vm.maxPageDisplay = 0;
            }
            vm.currentPage = {};
            vm.pages = [];
            for (i = vm.firstPageIndex; i <= vm.lastPageIndex; i = i + 1) {
                vm.pages.push({
                    index: i,
                    arrayIndex: locArrayIndex,
                    active: i === vm.currentPageIndex,
                    display: false
                });
                if (i === vm.currentPageIndex) {
                    vm.currentPage = vm.pages[locArrayIndex];
                    arrayIndex = locArrayIndex;
                }
                locArrayIndex = locArrayIndex + 1;
            }
            vm.loadPage(vm.currentPage);
        };
        vm.$onInit = function () {
            vm.disableFirstPageButton = false;
            vm.disableLastPageButton = false;
            vm.disablePreviousPageButton = false;
            vm.disableNextPageButton = false;
            $scope.$watch(function (scope) {
                return (scope.firstPageIndex + "-" + scope.lastPageIndex + "-" +
                    scope.maxPageDisplay);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    vm.refresh();
                }
            });
        };
    };
    TcfaPaginationCtrl.$inject = moduleConfig.TcfaPaginationCtrl.deps;
    module.controller(moduleConfig.TcfaPaginationCtrl.name, TcfaPaginationCtrl);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.UserChangePassword",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.UserDAOService"],
            UserChangePasswordController: {
                name: "UserChangePasswordController",
                deps: ["$window", "$rootScope", "$injector", "$routeParams", "AuthDAO", "PageNavigationService"]
            }
        },
        module, UserChangePasswordController;
    module = angular.module("TCFAutos");
    UserChangePasswordController = function ($window, $rootScope, $injector, $routeParams, AuthDAO, PageNavigationService) {
        var vm = this, data, modalInfo, showSuccessModal,
            $location = $injector.get("$location"),
            $timeout = $injector.get("$timeout"),
            token = $routeParams.token,
            TCFAModalService = $injector.get("TCFAModalService"),
            usSpinnerService = $injector.get("usSpinnerService");
        PageNavigationService.showNavBar = true;
        PageNavigationService.showNewsletterSubscription = false;
        vm.errors = {
            success: false,
            error: false
        };
        showSuccessModal = function () {
            modalInfo = {warning: {}};
            modalInfo.warning.showModal = true;
            modalInfo.warning.message = ["¡Exito! Has cambiado tu contraseña.", " Muchas gracias."];
            modalInfo.warning.title = "Cambio de password exitoso!";
            modalInfo.warning.showBtn = true;
            modalInfo.warning.btnText = "Cerrar";
            TCFAModalService.showOk(modalInfo).result.then(function () {
                $location.path("/");
            }, function () {
                $location.path("/");
            });
        };
        vm.deshabilitarAceptar = false;
        vm.changePassword = function () {
            vm.deshabilitarAceptar = true;
            usSpinnerService.spin("change_password");
            if (vm.passwordChange.password && vm.passwordChange.passwordConfirm) {
                vm.errors = {error: false};
                data = {
                    password: window.base64.encode(vm.passwordChange.password),
                    passwordConfirm: window.base64.encode(vm.passwordChange.passwordConfirm),
                    token: token
                };
                AuthDAO.passwordChange(data).$promise.then(function () {
                    vm.deshabilitarAceptar = false;
                    usSpinnerService.stop("change_password");
                    showSuccessModal();
                    $timeout(function () {
                        $location.path("/");
                    }, 5000);
                }, function (response) {
                    vm.deshabilitarAceptar = false;
                    usSpinnerService.stop("change_password");
                    if (response.status === 500) {
                        vm.errors.error = true;
                    } else {
                        vm.errors.tokenInvalido = true;
                    }
                    $timeout(function () {
                        $location.path("/");
                    }, 5000);
                });
            }
        };
        vm.doCancel = function () {
            $location.path("/");
        };
    };
    UserChangePasswordController.$inject = moduleConfig.UserChangePasswordController.deps;
    module.controller(moduleConfig.UserChangePasswordController.name, UserChangePasswordController);
}());

(function () {
    "use strict";
    var moduleConfig = {
            name: "TCFAutos.controllers.UserLostPassword",
            deps: ["TCFAutos.services.PageNavigationService", "TCFAutos.services.UserDAOService"],
            UserLostPasswordController: {
                name: "UserLostPasswordController",
                deps: ["$window", "$rootScope", "$injector", "AuthDAO", "PageNavigationService", "DependencyInjectionService", "TCFAAppConfigService"]
            }
        },
        module, UserLostPasswordController;
    module = angular.module("TCFAutos");
    UserLostPasswordController = function ($window, $rootScope, $injector, AuthDAO, PageNavigationService, DependencyInjectionService, TCFAAppConfigService) {
        var vm = this,
            $location = $injector.get("$location"),
            $timeout = $injector.get("$timeout"),
            TCFAModalService = $injector.get("TCFAModalService"),
            modalInfo, showSuccessModal,
            usSpinnerService = $injector.get("usSpinnerService");
        PageNavigationService.showNavBar = true;
        PageNavigationService.showNewsletterSubscription = false;
        DependencyInjectionService.loadApi("GoogleRecaptcha").then(function () {
            vm.grecaptchaKey = TCFAAppConfigService.GOOGLE.RECAPTCHA_KEY;
        });
        vm.changeTCFAPassword = TCFAAppConfigService.LINKS.TCFA_LOST_PASSWORD;
        vm.errors = {
            success: false,
            invalidUser: false,
            sendToClient: false,
            error: false
        };
        showSuccessModal = function () {
            modalInfo = {warning: {}};
            modalInfo.warning.showModal = true;
            modalInfo.warning.message = ["¡Exito! Te hemos enviado un email para cambiar tu contraseña, entrá a tu correo y seguí las instrucciones.", " Muchas gracias."];
            modalInfo.warning.title = "Cambio de password exitoso!";
            modalInfo.warning.showBtn = true;
            modalInfo.warning.btnText = "Cerrar";
            TCFAModalService.showOk(modalInfo).result.then(function () {
                $location.path("/");
            }, function () {
                $location.path("/");
            });
        };
        vm.resetPassword = function () {
            if (vm.passwordRecovery.email) {
                usSpinnerService.spin("reset_password");
                vm.disableAceptar = true;
                vm.errors = {
                    success: false,
                    invalidUser: false,
                    sendToClient: false,
                    error: false
                };
                AuthDAO.passwordReset(vm.passwordRecovery).$promise.then(function () {
                    usSpinnerService.stop("reset_password");
                    showSuccessModal();
                    vm.disableAceptar = false;
                }, function (response) {
                    usSpinnerService.stop("reset_password");
                    if (response.status === 404 && response.data.errors.code === 404) {
                        vm.errors.invalidUser = true;
                        vm.disableAceptar = false;
                    } else if (response.status === 404 && response.data.errors.code === 100) {
                        vm.errors.sendToClient = true;
                        vm.disableAceptar = false;
                        $timeout(function () {
                            $window.location.href = vm.changeTCFAPassword;
                        }, 5000);
                    } else {
                        vm.disableAceptar = false;
                        vm.errors.error = true;
                    }
                });
            }
        };
        vm.doCancel = function () {
            $location.path("/");
        };
    };
    UserLostPasswordController.$inject = moduleConfig.UserLostPasswordController.deps;
    module.controller(moduleConfig.UserLostPasswordController.name, UserLostPasswordController);
}());
